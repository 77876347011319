import React, { Component } from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';
import "moment-timezone";
import { Auth, API } from "aws-amplify";
import { Formik, Field } from 'formik';

function validateNumber(value) {
    let error;
    if (!value) {
        error = 'Required';
    }
    else if(isNaN(value)){
        error = 'Must be a number';
    }
    return error;
}

export class MissingRateOrChargeModal extends Component {
    state = {
        isRateProcessing: false,
        modalShow: false,
        invoices: undefined,
        invoicesRateValues: undefined,
        invoicesChargeValues: undefined,
        divisionName: undefined,
        status: undefined,
        isBulkDownload: false, //for bulk downloads
        clientId: undefined, //for bulk downloads
        divisionId: undefined //for bulk downloads
    };
    setModalShow = (show, invoices, divisionName, bulkDownload, clientId, divisionId) => {
        if(show === true)
        {
            let invoicesRateValues = {};
            let invoicesChargeValues = {};
            if(invoices !== undefined)
            {
                invoices.filter(e => e.NoRate === true).map(i => invoicesRateValues['Rate-' + i.ItemId] = '');
                invoices.filter(e => e.NoDeliveryCharge === true).map(i => invoicesChargeValues['Charge-' + i.ItemId] = '');
            }
            this.setState({ modalShow: show, invoices: invoices, isBulkDownload: bulkDownload, clientId: clientId, divisionId: divisionId, status: undefined, divisionName: divisionName, invoicesRateValues: invoicesRateValues, invoicesChargeValues:invoicesChargeValues});
        }
        
        if(this.props.reprocessCallback !== undefined && show === false)
            this.setState({modalShow:show},function(){this.props.reprocessCallback.reprocess();});
    }
    saveChanges = async (values, actions) => {
        try{
            const newRates = this.state.invoices.filter(e => e.NoRate === true).map(r => {
                return({
                    BillingClientId: this.props.clientId ? this.props.clientId : this.state.clientId,
                    DivisionId: this.props.divisionId ? this.props.divisionId : this.state.divisionId,
                    Postcode: r.Postcode,
                    Area: r.Area,
                    Rate: values['Rate-' + r.ItemId]
                });
            });
            const newCharges = this.state.invoices.filter(e => e.NoDeliveryCharge === true).map(r => {
                return({
                    BillingClientId: this.props.clientId ? this.props.clientId : this.state.clientId,
                    DivisionId: this.props.divisionId ? this.props.divisionId : this.state.divisionId,
                    Box: r.BoxCount,
                    Area: r.Area,
                    Charge: values['Charge-' + r.ItemId]
                });
            });

            //actions.setSubmitting(false);
            let myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    rates: newRates
                    }
            };
            //console.log(JSON.stringify(myInit));
            let result = await API.post("api", "/rates", myInit);

            if(newCharges.length > 0)
            {
                myInit = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        deliveryCharges: newCharges
                        }
                };
                result = await API.post("api", "/deliveryCharges", myInit);
                console.log("resultcharges", result);
            }

            if(result.result === 'success')
            {
                if(this.state.isBulkDownload)
                {
                    await API.get("api", "/invoices?ReprocessInvoice=true&DivisionId=" + this.state.divisionId, myInit);
                }
                this.setState({ status:'ok' });
                actions.setSubmitting(false);
            }              
            else{
                actions.setStatus({msg: result.errors[0]});
                this.setState({ status: result.errors[0] });
                actions.setSubmitting(false);
            }
        }catch(error)
        {
            console.log('error', error.message);
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
    }
    displayFields = (errors, touched, type) => {
        if(this.state.invoices !== undefined)
        {
            if(type === 'rate')
            {
                return this.state.invoices.filter(e => e.NoRate === true).map((i, index) =>
                    <Form.Row key={i.ItemId}>
                        <Form.Group as={Col} xs={5} className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">{i.Area}</Form.Label>                                    
                        </Form.Group>
                        <Form.Group as={Col} xs={3} className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">{i.Postcode}</Form.Label>                                    
                        </Form.Group>
                        <Form.Group as={Col} xs={4} controlId={'Rate-' + i.ItemId} className="margin-bottom-8" >
                            <Field name={'Rate-' + i.ItemId} validate={validateNumber} placeholder="rate" className="width-100" />
                            {errors['Rate-' + i.ItemId] && touched['Rate-' + i.ItemId] && <div className="med-font error-msg">{errors['Rate-' + i.ItemId]}</div>}                                   
                        </Form.Group>
                    </Form.Row>
                );
            }
            else{
                return this.state.invoices.filter(e => e.NoDeliveryCharge === true).map((i, index) =>
                    <Form.Row key={i.ItemId}>
                        <Form.Group as={Col} xs={5} className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">{i.Area}</Form.Label>                                    
                        </Form.Group>
                        <Form.Group as={Col} xs={3} className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">{i.BoxCount}</Form.Label>                                    
                        </Form.Group>
                        <Form.Group as={Col} xs={4} controlId={'Charge-' + i.ItemId} className="margin-bottom-8" >
                            <Field name={'Charge-' + i.ItemId} validate={validateNumber} placeholder="charge" className="width-100" />
                            {errors['Charge-' + i.ItemId] && touched['Charge-' + i.ItemId] && <div className="med-font error-msg">{errors['Charge-' + i.ItemId]}</div>}                                   
                        </Form.Group>
                    </Form.Row>
                );
            }
        }
    }
    render(){
        return(
            <Modal
                size="md"
                show={this.state.modalShow}
                onHide={() => this.setModalShow(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Kindly supply missing rates/charge for {this.state.divisionName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                    initialValues={
                        this.state.invoicesRateValues && this.state.invoicesChargeValues && Object.assign(this.state.invoicesRateValues,this.state.invoicesChargeValues)
                    }
                    onSubmit={async (values, actions) => {
                        await this.saveChanges(values, actions);
                    }}
                    >
                        {({ errors, touched, isValidating, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                {this.state.invoices && this.state.invoices.filter(e => e.NoRate === true).length > 0 &&
                                <React.Fragment>
                                <Form.Row>
                                    <Form.Group as={Col} xs={12} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                        <Form.Label className="large-font bold-font margin-bottom-zero">Rates:</Form.Label>                                    
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} xs={5} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                        <Form.Label className="med-font bold-font margin-bottom-zero">Area</Form.Label>                                    
                                    </Form.Group>
                                    <Form.Group as={Col} xs={3} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                        <Form.Label className="med-font bold-font margin-bottom-zero">Postcode</Form.Label>                                    
                                    </Form.Group>
                                    <Form.Group as={Col} xs={4} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                        <Form.Label className="med-font bold-font margin-bottom-zero">Rate</Form.Label>                                    
                                    </Form.Group>
                                </Form.Row>
                                {this.displayFields(errors, touched, 'rate')}
                                </React.Fragment>
                                }

                                {this.state.invoices && this.state.invoices.filter(e => e.NoDeliveryCharge === true).length > 0 &&
                                <React.Fragment>
                                <br />
                                <Form.Row>
                                    <Form.Group as={Col} xs={12} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                        <Form.Label className="large-font bold-font margin-bottom-zero">Delivery Charge:</Form.Label>                                    
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} xs={5} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                        <Form.Label className="med-font bold-font margin-bottom-zero">Area</Form.Label>                                    
                                    </Form.Group>
                                    <Form.Group as={Col} xs={3} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                        <Form.Label className="med-font bold-font margin-bottom-zero">Box</Form.Label>                                    
                                    </Form.Group>
                                    <Form.Group as={Col} xs={4} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                        <Form.Label className="med-font bold-font margin-bottom-zero">Charge</Form.Label>                                    
                                    </Form.Group>
                                </Form.Row>
                                {this.displayFields(errors, touched, 'charge')}
                                </React.Fragment>
                                }
                                <br />

                                {/* <button type="submit">Submit</button> */}
                                <Button variant="primary" type="submit" disabled={isSubmitting} size="sm">
                                    {isSubmitting ? 'Saving...' : 'Save'}
                                </Button>
                                {this.state.status !== undefined && 
                                <Form.Group as={Col} xs={12}>
                                    <br/>
                                    <Alert variant={this.state.status === 'ok' ? 'success' : 'danger'}>{this.state.status === 'ok' ? 'Saved Successfully!' : this.state.status}</Alert>
                                </Form.Group>}
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>                    
            </Modal>
        )
    }
}