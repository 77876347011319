import React, { Component } from 'react';
import { Auth, API } from 'aws-amplify';
import axios from 'axios';
import {Navbar as Nav, Image, Button, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {authorizationHeader} from './utility/Helper';

export default class Navbar extends Component {
    state = {
        operatorLogo: ''
    }

    handleLogOut = async event => {
        event.preventDefault();
        try {
            Auth.signOut();
            this.props.auth.setAuthStatus(false);
            this.props.auth.setUser(null);
            this.props.auth.setName(null);
        }catch(error) {
            console.log(error.message);
        }
    }

    getOperatorLogo = async () => {
        try{
            if(this.props.auth.operator.length > 0){
                const presignedRequest = await API.get('api', '/uploads?type=download&File=Logo.jpg&folder=' + this.props.auth.operator, await authorizationHeader());
                const base64String = await axios.get(presignedRequest.url, { responseType: 'arraybuffer'}).then(response => new Buffer(response.data, 'binary').toString('base64'));
                this.setState({operatorLogo:'data:image/jpeg;base64,' + base64String});
            }
        }catch(error) {
            console.log(error.message);
            this.setState({operatorLogo:''});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.auth.operator !== this.props.auth.operator) {
          this.getOperatorLogo();
        }
    }

    componentDidMount() {
        this.getOperatorLogo();
    }

    render() {
        //console.log('signInUserSession', this.props.auth.user);
        return (
            <Nav expand="lg" className="padding-top-bottom-zero">
                {!this.props.auth.isAuthenticated &&
                <Nav.Brand href="/" className="nav-center">
                    <Image src="/mediportLogo.png" rounded />
                </Nav.Brand>}
                {this.props.auth.isAuthenticated &&
                <Nav.Brand href="/" className="width-150">
                    <Image src={this.state.operatorLogo} fluid />
                </Nav.Brand>}
                {/* <Nav.Toggle /> */}
                <Nav.Collapse className="justify-content-end">
                    {this.props.auth.user && this.props.auth.name &&
                    <Nav.Text>
                    Signed in as: {this.props.auth.name}
                    </Nav.Text>}                    
                </Nav.Collapse>
                &nbsp;&nbsp;
                <Form inline>
                    {this.props.auth.isAuthenticated &&
                    <Button className="mr-sm-1" variant="primary" size="sm" onClick={this.handleLogOut}>
                        <i className="fas fa-sign-out-alt" style={{ fontSize: '1em' }} /> Logout
                    </Button>}
                    {!this.props.auth.isOnOperatorsPage && this.props.auth.isAuthenticated && this.props.auth.user && this.props.auth.user.signInUserSession && this.props.auth.user.signInUserSession.idToken.payload['cognito:groups'] !== undefined && this.props.auth.user.signInUserSession.idToken.payload['cognito:groups'][0].toLowerCase() === 'globaladministrator' &&
                    <Button className="mr-sm-1" variant="primary" size="sm" as={Link} to="/operators">
                        <i className="fas fa-shipping-fast" style={{ fontSize: '1em' }} /> Operators
                    </Button>}
                </Form>
            </Nav>
        );
    }
}