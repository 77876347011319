import axios from 'axios';
import config from '../../config';
import { Auth, API } from 'aws-amplify';
import api from './geoSearchAPI';

export async function authorizationHeader() {
    return {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
    };
}

export function getGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

export function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
}

export async function geoSearch(address) {
    let coordinates = {x: 133.323148, y: -26.250804, z: 3.5, state: '', valid: false, error: ''};
    let doGoogleGeocode = false;
    let hasHereMapsResult = false;
    let requestString = '';
    
    if(!address.toLowerCase().endsWith('australia'))
        address = address + ' Australia';
    try{
        requestString = `${encodeURIComponent(address)}&apiKey=${config.map.apikey}`;
        let response = await api.get(requestString);
        let result = response.data;
        if(result.items[0] && result.items[0].address)
        {
            console.log('has result');
            hasHereMapsResult = true;
            console.log('resultGeo', result);
            if(result.items[0].address && result.items[0].address.countryCode)
            {
                //if heremaps return a location that is not Australian address, do Google geocoding since google has high accuracy.
                if(result.items[0].address.countryCode.toLowerCase() !== 'aus')
                    doGoogleGeocode = true; 
            }                       
        }
        else
            doGoogleGeocode = true;        
                    
        if(doGoogleGeocode)
        {
            console.log('perform google geocoding');
            let newAddress = address;
            if(hasHereMapsResult === false)
            {
                if(!address.toLowerCase().endsWith('australia'))
                    newAddress = newAddress + ' Australia';
            }
            const googleResult = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(newAddress)}&key=AIzaSyBv8Ld2ZKmFIlXkTs1J3I5_hd3_znvPbTE`);
            console.log('googleResult', googleResult.data.results.length);
            if(googleResult.data.results.length > 0)
            {
                if(googleResult.data.results.filter(l => l.formatted_address.toLowerCase().indexOf('australia') > -1).length > 0)
                {
                    newAddress = googleResult.data.results.filter(l => l.formatted_address.toLowerCase().indexOf('australia') > -1)[0].formatted_address;
                    console.log('newAddress', newAddress);
                }
                else
                {
                    newAddress = googleResult.data.results[0].formatted_address;
                }                
                console.log('newAddress', newAddress);
                requestString = `${encodeURIComponent(newAddress)}&apiKey=${config.map.apikey}`;
                response = await api.get(requestString);
                result = response.data;
            }            
        }
        //return result;
        if(result.items[0] && result.items[0].address)
        {
            const Lat = result.items[0].position.lat; 
            const Long =result.items[0].position.lng;
            //if(this.state.x !== Long || this.state.y !== Lat){
                coordinates.x = Long;
                coordinates.y = Lat;
                coordinates.z = 15;
                coordinates.valid = true;
                // this.setState({x: Long, y: Lat, z: 15});
                // this.props.setCoordinates(Lat,Long);
            //}                        
            //console.log('x', this.state.x);
            if(result.items[0].address)
            {
                try{
                    coordinates.state = result.items[0].address.state;
                }catch(error) {
                    console.error('State Error', error);
                }  
            }
        }                        
        else
        {
            console.error('Invalid Address');
            // this.setState({x: 133.323148, y: -26.250804, z: 3.5});
            // this.props.setCoordinates('','');
        }
    }catch(error) {
        console.error('ERROR in Helper Try ', error);
        if(error.message.toLowerCase() === 'request failed with status code 429')
            console.log('Too many requests. Please try again later.');
        coordinates.error = error;
    }    
    return coordinates;
}



export function hasPOD(job) {
    if((job.StatusGroup && job.StatusGroup === '5') || job.StatusId === 'Status-d21d124b-8ef7-4e16-8edc-32f2068c22a1' || job.StatusId === 'Status-bc1d97d4-8da0-49b4-a24e-2e73c72e9aac' || job.StatusId === 'Status-3bb71efd-1840-46ab-a867-288aa712edc1' || job.StatusId === 'Status-072fe683-bd07-4810-9c6d-e2f5f38aa54d' || job.StatusId === 'Status-ee604e5c-9056-4756-8609-2f0f45112f6c' || job.StatusId === 'Status-0ae017fd-55fe-45f2-8639-c165bf42e19f' || job.StatusId === 'Status-360b1b1f-74ca-4322-b2ca-2f0db3bf9ca5' || job.StatusId === 'Status-4c1bd50c-60ae-4dfb-856f-0d56411dad9c')
        return true;
    else
        return false;
}

export function isPODStatus(jobStatus) {
    if((jobStatus === 'Status-c8dd3b11-52e4-4a63-8409-9e6a15d43981') || (jobStatus === 'Status-74cf6137-4d16-4d8a-8a20-400d8ea609ed') || (jobStatus === 'Status-c8dd3b11-52e4-4a63-8409-9e6a15d43981'))
        return true;
    else
        return false;
}

export function hasStateCodeInDivisionName(element, index, array) {
    return isStateCode(element);
}

export function isPostcodeDivisionMatch(division, postcode) {
    return getStateCode(division) === getStateByPostcode(postcode);
}

export function getStateCode(division) {
    let stateCode = "";
    try{
        division.split(" ").forEach(element => {
            if(isStateCode(element) === true)
                stateCode = element.trim().toLowerCase();
        });
    }catch(error) {
        console.error(error.toString());
        return "";
    }
    return stateCode;
}

export function isStateCode(element) {
    try{     
        switch(element.trim().toLowerCase()) 
        {
            case "vic":
            case "qld":
            case "nsw":
            case "wa":
            case "sa":
            case "tas":
            case "act":
            case "nt":
                return true;
            default:
                return false;
        }
    }catch(error) {
        console.error(error.toString());
        return false;
    }
}

export function getStateByPostcode(postcode) {
    let stateCode = "";
    try{     
        postcode = postcode.trim();
        switch(postcode.charAt(0)) 
        {
            case "3":
                stateCode = "vic";
                break;
            case "4":
                stateCode = "qld";
                break;
            case "2":
                stateCode = "nsw";
                break;
            case "6":
                stateCode = "wa";
                break;
            case "5":
                stateCode = "sa";
                break;
            case "7":
                stateCode = "tas";
                break;
            case "0":
                stateCode = "nt";
                break;
        }
    }catch(error) {
        console.error(error.toString());
        return "";
    }
    return stateCode;
}