import React, { useEffect, useState } from "react";
import { Accordion, Card, ListGroup, Alert, Form } from "react-bootstrap";
import { API } from "aws-amplify";
import { authorizationHeader } from "./utility/Helper";
import LoadingOverlay from "react-loading-overlay";
// Workaround to fix this bug: https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined;

const ChecklistResponse = ({ date, driverid }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [groupedDailyQuestions, setGroupedDailyQuestions] = useState(null);
  const [weeklyChecklist, setWeeklyChecklist] = useState(null);
  const [error, setError] = useState(null);

  const groupQuestions = (questions) => {
    const groupedData = {};
    questions.forEach((question) => {
      const stage = question.Stage;
      if (!groupedData[stage]) {
        groupedData[stage] = [];
      }
      groupedData[stage].push(question);
    });
    return groupedData;
  };

  const fetchChecklistResponse = async (date, driverid) => {
    try {
      setIsLoading(true);
      setError(null);
      const authHeader = await authorizationHeader();
      let filters = "?Date=" + date + "&DriverId=" + driverid;
      const response = await API.get(
        "api",
        "/checklistResponses" + filters,
        authHeader
      );

      if (response.result === "success") {
        if (response.count === "0") {
          setError("No checklist response found");
        } else {
          const dailyChecklistResponse = response.checklistResponses.find(
            (item) => item.Type === "Daily"
          );
          const weeklyChecklistResponse = response.checklistResponses.find(
            (item) => item.Type === "Weekly"
          );
          if (dailyChecklistResponse) {
            const flatQuestions = groupQuestions(
              dailyChecklistResponse.Questions
            );
            setGroupedDailyQuestions(flatQuestions);
          }
          if (weeklyChecklistResponse) {
            setWeeklyChecklist(weeklyChecklistResponse);
          }
        }
      } else {
        setError("Error fetching checklist response");
      }
      setIsLoading(false);
    } catch (err) {
      setError("Error fetching checklist response");
      throw err;
    }
  };

  useEffect(() => {
    fetchChecklistResponse(date, driverid);
  }, []);

  const calculateAnswerPercentage = (questions) => {
    const answeredQuestions = questions.filter(q => (q.Answer && q.Answer.Done)).length;
    return (answeredQuestions / questions.length) * 100;
  };

  return (
    <>
      <Accordion>
        <LoadingOverlay active={isLoading} spinner text="Loading...">
          {error && <Alert variant="warning">{error}</Alert>}
          {groupedDailyQuestions &&
            Object.entries(groupedDailyQuestions).map(
              ([stage, questions], index) => (
                <Card key={index}>
                  <Accordion.Toggle
                    className="accordion-header"
                    as={Card.Header}
                    eventKey={index.toString()}
                  >
                    <span className="accordion-header-text left-align">
                      {stage}
                    </span>
                    <div
                      class="progress-bar"
                      style={{ background:`radial-gradient(closest-side, white 79%, transparent 80% 100%),conic-gradient(#218838 ${calculateAnswerPercentage(questions)}%, #d6d7d9 0)` }}
                      role="progressbar"
                      aria-valuenow={calculateAnswerPercentage(questions)}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                    <i
                      className="fa fa-angle-down margin-left-10 right-align"
                      style={{ fontSize: "1em" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body>
                      <ListGroup variant="flush">
                        {questions.map((question, qIndex) => (
                          <ListGroup.Item className="left-align" key={qIndex}>
                            <div className="mb-3">{question.QuestionText}</div>
                            {question.Answer && (
                              <Card>
                                <Card.Header>
                                  Answer:
                                  {question.Answer.Done ? (
                                    <i
                                      className="fa fa-check margin-left-10"
                                      style={{
                                        fontSize: "1.2em",
                                        color: "#2e8540",
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className="fa fa-times margin-left-10"
                                      style={{
                                        fontSize: "1.2em",
                                        color: "#cd2026",
                                      }}
                                    />
                                  )}
                                </Card.Header>
                                <Card.Body>
                                  {question.Answer.ActionTaken && (
                                    <Form.Group className="mb-3">
                                      <Form.Label>Action Taken</Form.Label>
                                      <Form.Control
                                        disabled
                                        placeholder={
                                          question.Answer.ActionTaken
                                        }
                                      />
                                    </Form.Group>
                                  )}

                                  {question.Answer.RegistrationNumber && (
                                    <Form.Group>
                                      <Form.Label>
                                        Registration Number
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        placeholder={
                                          question.Answer.RegistrationNumber
                                        }
                                      />
                                    </Form.Group>
                                  )}
                                </Card.Body>
                              </Card>
                            )}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
            )}
        </LoadingOverlay>
      </Accordion>
      {weeklyChecklist && (
        <Card>
          <Card.Header>Weekly Checklist Response</Card.Header>
          <Card.Body>
            <ListGroup variant="flush">
              {weeklyChecklist.Questions.map((question, qIndex) => (
                <ListGroup.Item className="left-align" key={qIndex}>
                  <span>{question.QuestionText}</span>
                  {question.Answer && (
                    <Card>
                      <Card.Header>
                        Answer:
                        {question.Answer.Done ? (
                          <i
                            className="fa fa-check margin-left-10"
                            style={{
                              fontSize: "1.2em",
                              color: "#2e8540",
                            }}
                          />
                        ) : (
                          <i
                            className="fa fa-times margin-left-10"
                            style={{
                              fontSize: "1.2em",
                              color: "#cd2026",
                            }}
                          />
                        )}
                      </Card.Header>
                      <Card.Body>
                        {question.Answer.ActionTaken && (
                          <Form.Group className="mb-3">
                            <Form.Label>Action Taken</Form.Label>
                            <Form.Control
                              disabled
                              placeholder={question.Answer.ActionTaken}
                            />
                          </Form.Group>
                        )}

                        {question.Answer.RegistrationNumber && (
                          <Form.Group>
                            <Form.Label>Registration Number</Form.Label>
                            <Form.Control
                              disabled
                              placeholder={question.Answer.RegistrationNumber}
                            />
                          </Form.Group>
                        )}
                      </Card.Body>
                    </Card>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ChecklistResponse;
