import React, { Component } from 'react';
import {Button, Modal, Form, Container, Row, Col, FormGroup} from 'react-bootstrap';
import moment from "moment";
import "moment-timezone";
import axios from 'axios';
import { Auth, API } from "aws-amplify";
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import {UploadButton} from './UploadButton';
import {MissingRateOrChargeModal} from './MissingRateOrChargeModal';
import JSZip from 'jszip';

export class BDExcelDownloadButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            invoices:[],
            modalShow: false,
            divisionName: '',
            modalMessage: '',
            showFuelChargeModal: false,
            fsc: 0,
            fscError: false
        };
        this.showUploadModal = React.createRef();
        this.showMissingRateOrChargeModal = React.createRef();
    }
    
    setModalMessageShow = show => {
        this.setState({ modalShow: show, isProcessing: false });
    }   
    
    setShowFuelChargeModal = show => {
        this.setState({ showFuelChargeModal: show, isProcessing: show });
    }

    handleFSCChange = (e)  => {
        if(e.target.value.match("^[0-9]*$") != null) {
            this.setState({ fsc: e.target.value, fscError: false});
        }
        else {
            this.setState({ fscError: true});
        }
    }

    fscSubmit = () => {
        if(!this.state.fscError) {
            this.setState({ showFuelChargeModal: false});
            this.processInvoice();
        }
    }

    download = async (batchDetail, invoices, callBack) => {
        //this.setState({isProcessing: true});

        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
        };
        const invoiceDate = batchDetail.InvoiceDate;
        const divisionId = batchDetail.DivisionId;
        const invoiceId = batchDetail.Name;
        const fileName = 'IX_SCAC_' + batchDetail.Division.replace(/ /g, "") + '_' + moment(invoiceDate).format("YYYY/MM/DD").replace("/", "").replace("/", "") + '_' + (batchDetail.Sequence < 10 ? '00' : (batchDetail.Sequence < 100 ? '0' : '')) + batchDetail.Sequence + '.xlsx'
        console.log(fileName);

        const entities = ['divisions','clients','deliveryCharges','rates','operators','operatorLogo','interstateRates'].map( async (entity, index) => {
            try{
                let res;
                switch (entity) {
                    case 'divisions':
                        res = await API.get('api', '/divisions?ItemId=' + divisionId, myInit);
                        break;
                    case 'clients':
                        res = await API.get('api', '/clients?ItemId=' + batchDetail.BillingClientId, myInit);
                        break;
                    case 'operators':
                        res = await API.get('api', '/operators?ItemId=' + this.props.operatorId, myInit);
                        break;
                    case 'operatorLogo':
                        try{
                            const presignedRequest = await API.get('api', '/uploads?type=download&File=Logo.jpg&folder=' + this.props.operatorId, myInit);
                            const base64String = 'data:image/png;base64,' +  (await axios.get(presignedRequest.url, { responseType: 'arraybuffer'}).then(response => new Buffer(response.data, 'binary').toString('base64')));
                            res = base64String;
                        }catch(e){
                            console.log(e);
                            res = '';
                        }
                        break;
                    case 'deliveryCharges':
                    case 'rates':
                    case 'interstateRates':
                        res = await API.get('api', '/' + entity + '?DivisionId=' + divisionId, myInit);
                        break;
                }                 
                return (
                    {
                        entity: entity,
                        content: res
                    }
                )
            }catch(err) {
                console.log('err', err);
            }
        });
        
        const resultEntities = await Promise.all(entities);
        console.log(resultEntities);

        //let res = await API.get("api", "/divisions?ItemId=" + divisionId, myInit);
        let res = resultEntities.filter(e => e.entity === 'divisions')[0].content;
        console.log('divisions', res);
        const state = res.divisions[0].PhysicalAddressState;
        let hasRebate = res.divisions[0].HasRebate;
        let applyRebateIfAbove = res.divisions[0].ApplyRebateIfAbove;
        let rebate = res.divisions[0].Rebate;
        const hasSpecialCharge = res.divisions[0].SpecialChargeLabel ? true : false;
        const specialCharge = res.divisions[0].SpecialCharge;
        const specialChargeLabel = res.divisions[0].SpecialChargeLabel;
        const recurrence = res.divisions[0].Recurrence ? res.divisions[0].Recurrence : 'Daily';

        //res = await API.get("api", "/clients?ItemId=" + batchDetail.BillingClientId, myInit);
        res = resultEntities.filter(e => e.entity === 'clients')[0].content;
        console.log('clients', res);
        if(hasRebate === false)
        {
            if(res.clients[0].HasRebate === true)
            {
                hasRebate = res.clients[0].HasRebate;
                applyRebateIfAbove = res.clients[0].ApplyRebateIfAbove;
                rebate = res.clients[0].Rebate;
            }
        }
        const client = res.clients;
        const billToName = client[0].Name + ' - ' + state;
        const billToStreet = client[0].PhysicalAddressStreet;
        const billToCityStateZip = client[0].PhysicalAddressCity + ' ' + client[0].PhysicalAddressState + ' ' + client[0].PhysicalAddressZip;
        const customerABN = client[0].ABN ? client[0].ABN : '';

        //res = await API.get("api", "/deliveryCharges?DivisionId=" + divisionId, myInit);
        res = resultEntities.filter(e => e.entity === 'deliveryCharges')[0].content;
        const deliveryCharges = res.deliveryCharges;
        let hasChargePerArea = false;
        if(deliveryCharges.length > 0)
        {
            if(Array.from(new Set(deliveryCharges.map(dc => dc.Area))).length > 1)
                hasChargePerArea = true;
        }

        res = resultEntities.filter(e => e.entity === 'interstateRates')[0].content;
        const interstateRates = res.interstateRates;
        console.log('interstateRates', interstateRates);
        const includeInterstate = (this.props.includeInterstateCharge !== undefined && this.props.includeInterstateCharge === true) ? true : false;
        
        //res = await API.get("api", "/rates?DivisionId=" + divisionId, myInit);
        res = resultEntities.filter(e => e.entity === 'rates')[0].content;
        //console.log('rates', res);
        const rates = res.rates;
        const uniqueRates = Array.from(new Set(rates.filter(f => f.Rate !== null).map(r => r.Rate)));
        let uniqueRateDetails = uniqueRates.map((r,index) => {
            const uniqueAreas = Array.from(new Set(rates.filter(d => d.Rate === r).map(a => a.Area)));
            console.log('uniqueAreas',uniqueAreas);
            let areasString = '';
            uniqueAreas.filter(a => a !== null).forEach((w,index) => {
                if(areasString === '')
                    areasString = w;
                else
                    areasString = areasString + '/' + w; 
            });
            return ({
                index: index,
                area: areasString,
                rate: r,
                rateOverriden: false
            });
        });

        res = resultEntities.filter(e => e.entity === 'operators')[0].content.operators;
        const operatorName = res[0].Name.toUpperCase();
        const operatorABN = res[0].ABN;
        const operatorACN = res[0].ACN;
        const operatorBSB = res[0].BSB;
        const operatorAccountNumber = res[0].AccountNumber;
        const operatorAddress = res[0].Address;
        const operatorNotes = res[0].InvoiceNote;

        console.log('uniqueRateDetails',uniqueRateDetails);
        let uniqueOverrides = Array.from(new Set(invoices.filter(f => f.RateOverriden === true).map(r => r.Rate))).map((rate,index) => {
            return({
                index: index,
                area: 'RateOverride' + (index + 1),
                rate: rate.toString(),
                rateOverriden: true
            });
        });
        if(uniqueOverrides.length > 0)
        {
            uniqueRateDetails = [...uniqueRateDetails, ...uniqueOverrides];
        }

        console.log('uniqueOverrides', uniqueOverrides);
        console.log('uniqueRateDetails',uniqueRateDetails);
        const workBook = new ExcelJS.Workbook()
        let label;           
        let workSheet;
        let row;
        const self = this;
        const sheets = ['Sheet1'];
        sheets.forEach((day,index) => {
            workSheet = workBook.addWorksheet(day);
            workSheet.columns = [
                { header: 'Scac', key: 'Scac', width: 10 },
                { header: 'CustNo', key: 'CustNo', width: 10},
                { header: 'Pronum', key: 'Pronum', width: 10},
                { header: 'ConsignmentNumber', key: 'ConsignmentNumber', width: 10},
                { header: 'MB_MasterBol', key: 'MB_MasterBol', width: 10},
                { header: 'Prodate', key: 'Prodate', width: 10},
                { header: 'ShipDate', key: 'ShipDate', width: 10},
                { header: 'DelDate', key: 'DelDate', width: 10},
                { header: 'BillToNum', key: 'BillToNum', width: 10},
                { header: 'Mode', key: 'Mode', width: 10},

                { header: 'Shipper', key: 'Shipper', width: 10},
                { header: 'Oaddr1', key: 'Oaddr1', width: 10},
                { header: 'Ocity', key: 'Ocity', width: 10},
                { header: 'OstateProvience', key: 'Ostateprovince', width: 10},
                { header: 'OpostCode', key: 'OpostCode', width: 10 },
                { header: 'OCountryCode', key: 'OCountryCode', width: 10},
                { header: 'OPortCode', key: 'OPortCode', width: 10},
                { header: 'L1_OriginRegion', key: 'L1_OriginRegion', width: 10},
                { header: 'Consignee', key: 'Consignee', width: 10},
                { header: 'Daddr1', key: 'Daddr1', width: 10},

                { header: 'Dcity', key: 'Dcity', width: 10},
                { header: 'DstateProvience', key: 'DstateProvience', width: 10},
                { header: 'Dpostcode', key: 'Dpostcode', width: 10},
                { header: 'DcountryCode', key: 'DcountryCode', width: 10},
                { header: 'DPortCode', key: 'DPortCode', width: 10},
                { header: 'L2_DestinationRegion', key: 'L2_DestinationRegion', width: 10},
                { header: 'SvcLevel', key: 'SvcLevel', width: 10},
                { header: 'SvcTypeCode', key: 'SvcTypeCode', width: 10},
                { header: 'IncoTerms', key: 'IncoTerms', width: 10 },
                { header: 'Weight', key: 'Weight', width: 10},

                { header: 'DimWeight', key: 'DimWeight', width: 10},
                { header: 'WeightUom', key: 'WeightUom', width: 10},
                { header: 'Volume', key: 'Volume', width: 10},
                { header: 'VolumeUom', key: 'VolumeUom', width: 10},
                { header: 'PackageType', key: 'PackageType', width: 10},
                { header: 'Pieces', key: 'Pieces', width: 10},
                { header: 'SecondaryCarrier', key: 'SecondaryCarrier', width: 10},
                { header: 'Code1', key: 'Code1', width: 10},
                { header: 'MinimumCharge', key: 'MinimumCharge', width: 10},
                { header: 'RateQualifier1', key: 'RateQualifier1', width: 10},

                { header: 'AuditAux1', key: 'AuditAux1', width: 10},
                { header: 'RateValue1', key: 'RateValue1', width: 10},
                { header: 'Code2', key: 'Code2', width: 10},
                { header: 'Amt2', key: 'Amt2', width: 10},
                { header: 'RateQualifier2', key: 'RateQualifier2', width: 10},
                { header: 'AuditAux2', key: 'AuditAux2', width: 10},
                { header: 'RateValue2', key: 'RateValue2', width: 10},
                { header: 'Code3', key: 'Code3', width: 10},
                { header: 'Amt3', key: 'Amt3', width: 10},
                { header: 'RateQualifier3', key: 'RateQualifier3', width: 10},

                { header: 'AuditAux3', key: 'AuditAux3', width: 10},
                { header: 'RateValue3', key: 'RateValue3', width: 10},
                { header: 'Code4', key: 'Code4', width: 10},
                { header: 'Amt4', key: 'Amt4', width: 10},
                { header: 'RateQualifier4', key: 'RateQualifier4', width: 10},
                { header: 'AuditAux4', key: 'AuditAux4', width: 10},
                { header: 'RateValue4', key: 'RateValue4', width: 10},
                { header: 'Code5', key: 'Code5', width: 10},
                { header: 'Amt5', key: 'Amt5', width: 10},
                { header: 'RateQualifier5', key: 'RateQualifier5', width: 10},

                { header: 'AuditAux5', key: 'AuditAux5', width: 10},
                { header: 'RateValue5', key: 'RateValue5', width: 10},
                { header: 'Code6', key: 'Code6', width: 10},
                { header: 'Amt6', key: 'Amt6', width: 10},
                { header: 'RateQualifier6', key: 'RateQualifier6', width: 10},
                { header: 'AuditAux6', key: 'AuditAux6', width: 10},
                { header: 'RateValue6', key: 'RateValue6', width: 10},
                { header: 'Code7', key: 'Code7', width: 10},
                { header: 'Amt7', key: 'Amt7', width: 10},
                { header: 'RateQualifier7', key: 'RateQualifier7', width: 10},

                { header: 'AuditAux7', key: 'AuditAux7', width: 10},
                { header: 'RateValue7', key: 'RateValue7', width: 10},
                { header: 'Code8', key: 'Code8', width: 10},
                { header: 'Amt8', key: 'Amt8', width: 10},
                { header: 'RateQualifier8', key: 'RateQualifier8', width: 10},
                { header: 'AuditAux8', key: 'AuditAux8', width: 10},
                { header: 'RateValue8', key: 'RateValue8', width: 10},
                { header: 'Code9', key: 'Code9', width: 10},
                { header: 'Amt9', key: 'Amt9', width: 10},
                { header: 'RateQualifier9', key: 'RateQualifier9', width: 10},

                { header: 'AuditAux9', key: 'AuditAux9', width: 10},
                { header: 'RateValue9', key: 'RateValue9', width: 10},
                { header: 'Code10', key: 'Code10', width: 10},
                { header: 'Amt10', key: 'Amt10', width: 10},
                { header: 'RateQualifier10', key: 'RateQualifier10', width: 10},
                { header: 'AuditAux10', key: 'AuditAux10', width: 10},
                { header: 'RateValue10', key: 'RateValue10', width: 10},
                { header: 'Currency', key: 'Currency', width: 10},
                { header: 'BilledAmt', key: 'BilledAmt', width: 10},
                { header: 'ExchangeRate', key: 'ExchangeRate', width: 10},

                { header: 'AuxDate1', key: 'AuxDate1', width: 10},
                { header: 'Currency2', key: 'Currency2', width: 10},
                { header: 'T1_TaxRate1', key: 'T1_TaxRate1', width: 10},
                { header: 'T2_TaxRate2', key: 'T2_TaxRate2', width: 10},
                { header: 'T3_TaxRate3', key: 'T3_TaxRate3', width: 10},
                { header: 'T4_TaxRate4', key: 'T4_TaxRate4', width: 10},
                { header: 'T5_TaxRate5', key: 'T5_TaxRate5', width: 10},
                { header: 'I5_InvoiceType', key: 'I5_InvoiceType', width: 10},
                { header: 'VX_KWETaxRegistration', key: 'VX_KWETaxRegistration', width: 10},
                { header: 'RateQualifier10', key: 'RateQualifier10', width: 10},

                { header: 'F9_BDAUSTRTaxID', key: 'F9_BDAUSTRTaxID', width: 10},
                { header: 'TZ_Consolidatedinvoicevalue', key: 'TZ_Consolidatedinvoicevalue', width: 10},
                { header: 'CR_ShipperRefnum1', key: 'CR_ShipperRefnum1', width: 10},
                { header: 'CR_ShipperRefnum2', key: 'CR_ShipperRefnum2', width: 10},
                { header: 'CR_ShipperRefnum3', key: 'CR_ShipperRefnum3', width: 10},
                { header: 'CR_ShipperRefnum4', key: 'CR_ShipperRefnum4', width: 10},
                { header: 'CR_ShipperRefnum5', key: 'CR_ShipperRefnum5', width: 10},
                { header: 'CR_ShipperRefnum6', key: 'CR_ShipperRefnum6', width: 10},
                { header: 'CR_ShipperRefnum7', key: 'CR_ShipperRefnum7', width: 10},
                { header: 'CR_ShipperRefnum8', key: 'CR_ShipperRefnum8', width: 10},

                { header: 'CR_ShipperRefnum9', key: 'CR_ShipperRefnum9', width: 10},
                { header: 'CR_ShipperRefnum10', key: 'CR_ShipperRefnum10', width: 10},
                { header: 'CR_ShipperRefnum11', key: 'CR_ShipperRefnum11', width: 10},
                { header: 'CR_ShipperRefnum12', key: 'CR_ShipperRefnum12', width: 10},
                { header: 'CR_ShipperRefnum13', key: 'CR_ShipperRefnum13', width: 10},
                { header: 'CR_ShipperRefnum14', key: 'CR_ShipperRefnum14', width: 10},
                { header: 'CR_ShipperRefnum15', key: 'CR_ShipperRefnum15', width: 10},
                { header: 'CR_ShipperRefnum16', key: 'CR_ShipperRefnum16', width: 10},
                { header: 'CR_ShipperRefnum17', key: 'CR_ShipperRefnum17', width: 10}
            ];                        
                    
            let consolidatedValue = 0;

            invoices.forEach(d => {
                var pieces = 0;
                var shipdate = new Date();
                var amt1 = 0;
                var amt2 = 0;
                var code3 = '';
                var amt3 = 0;
                var code4 = '';
                var amt4 = 0;
                var code9 = '';
                var amt9 = 0;
                var amt10 = 0;
                var billedAmt = 0;
                var taxRate1 = 0;

                pieces = Number(d.BoxCount);

                if(d.JobType && (d.JobType == "Delivery Pallet" || d.JobType == "Pickup Pallet"
                || d.JobType == "Credo Delivery" || d.JobType == "Credo Pickup"
                || d.JobType == "Delivery Pallet Ambient" || d.JobType == "Pickup Pallet Ambient")) {
                    code4 = 'PPN';
                    amt4 = Number(147.5 * pieces);
                }                    
                else {
                    amt1 = Number(d.InterstateRate);
                    amt2 = Number(rates.filter(r => r.Postcode = d.Postcode)[0].Rate);
                }     
                           
                if(d.DeliveryType && d.DeliveryType === 'Same Day') {
                    code3 = 'EEF';
                    amt3 = 42.50;
                } 

                if(this.state.fsc != "" || Number(this.state.fsc) != 0) {
                    amt9 = Number((amt1 + amt2 + amt3 + amt4) * Number(this.state.fsc) / 100);
                    code9 = 'FSC';
                }
                amt10 = Number((amt1 + amt2 + amt3 + amt4 + amt9) * 0.1);
                billedAmt = Number(amt1 + amt2 + amt3 + amt4 + amt9 + amt10);
                taxRate1 = Number(amt1 + amt2 + amt3 + amt4 + amt9);

                consolidatedValue = Number(consolidatedValue + taxRate1 + amt10);

            });

            invoices.forEach(d => {

                var packageType = 'CNT';
                var pieces = 0;
                var shipdate = new Date();
                var amt1 = 0;
                var amt2 = 0;
                var code3 = '';
                var amt3 = 0;
                var code4 = '';
                var amt4 = 0;
                var code9 = '';
                var amt9 = 0;
                var amt10 = 0;
                var billedAmt = 0;
                var taxRate1 = 0;
                var refnum = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
                var clientcode = "";
                var day = new Date(d.DeliveryDate).getDay();
                if(day == 0) {
                    shipdate = new Date(d.DeliveryDate).setDate(new Date(d.DeliveryDate).getDate() - 2);
                }
                else if(day == 1) {
                    shipdate = new Date(d.DeliveryDate).setDate(new Date(d.DeliveryDate).getDate() - 3);
                }
                else {
                    shipdate = new Date(d.DeliveryDate).setDate(new Date(d.DeliveryDate).getDate() - 1);
                }

                pieces = Number(d.BoxCount);

                if(d.JobType && (d.JobType == "Delivery Pallet" || d.JobType == "Pickup Pallet"
                || d.JobType == "Credo Delivery" || d.JobType == "Credo Pickup"
                || d.JobType == "Delivery Pallet Ambient" || d.JobType == "Pickup Pallet Ambient")) {                    
                    packageType = 'PLT';
                    code4 = 'PPN';
                    amt4 = Number(147.5 * pieces);
                }                    
                else {
                    packageType = 'CNT';
                    amt1 = Number(d.InterstateRate);
                    amt2 = Number(rates.filter(r => r.Postcode = d.Postcode)[0].Rate);
                }     
                
                if(d.ClientCode != undefined) {
                    clientcode = d.ClientCode;
                }

                if(d.DeliveryType && d.DeliveryType === 'Same Day') {
                    code3 = 'EEF';
                    amt3 = 42.50;
                }       
                
                if(d.ReferenceNumbers != undefined && d.ReferenceNumbers != "") {
                    d.ReferenceNumbers.split(',').forEach((v, i) => {
                        refnum[i] = v;
                    });
                }

                if(this.state.fsc != "" || Number(this.state.fsc) != 0) {
                    amt9 = Number((amt1 + amt2 + amt3 + amt4) * Number(this.state.fsc) / 100);
                    code9 = 'FSC';
                }

                amt10 = Number((amt1 + amt2 + amt3 + amt4 + amt9) * 0.1);
                billedAmt = Number(amt1 + amt2 + amt3 + amt4 + amt9 + amt10);
                taxRate1 = Number(amt1 + amt2 + amt3 + amt4 + amt9);

                // row = workSheet.lastRow;   
                // row.eachCell(function(cell, colNumber) {                                 
                //     cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};                    
                // }); 
                workSheet.addRow(['M309',clientcode,'BD001',d.ConsignmentNumber,'','',new Date(shipdate).toLocaleDateString('en-CA'),d.DeliveryDate,'','L',
                'BD Australia','25 Ottelia Road','Kemps Creek','NSW','2178','AUS','','',d.Name,d.Street1 + ', ' + d.Street2,
                '',d.State,d.Postcode,'AUS','','','','','DDP','',
                '','',d.CubicCapacityMetresCubed,'x',packageType,pieces,'','DEL',(amt1 === 0 && packageType == 'PLT') ? '' : amt1,'',
                '','','SPA',amt2 === 0 ? '' : amt2,'','','',code3,amt3 === 0 ? '' : amt3,'',
                '','',code4,amt4 === 0 ? '' : amt4,'','','','','','',
                '','','','','','','','','','',
                '','','','','','','',code9,amt9 === 0 ? '' : amt9,'',
                '','',750,amt10,'','','','AUD',billedAmt,'',
                ,'AUD',taxRate1,'',amt10,Number(taxRate1 + amt10),'','Tax Invoice','', '',
                '',consolidatedValue,refnum[0],refnum[1],refnum[2],refnum[3],refnum[4],refnum[5],refnum[6],refnum[7],
                refnum[8],refnum[9],refnum[10],refnum[11],refnum[12],refnum[13],refnum[14],refnum[15],refnum[16]]);

                // row = workSheet.lastRow;   
                // row.eachCell(function(cell, colNumber) {                                 
                //     cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thick'},right: {style:'thin'}};                    
                // });             
            });                       
        });
        
        //INVOICE
                
        

        //DELIVERY CHARGE

        //RATES
        
        //INTERSTATE RATES        

        const buf = await workBook.xlsx.writeBuffer()
        if(callBack)
        {
            callBack(fileName);
            return buf;
        }
        else
            saveAs(new Blob([buf]), fileName)
        this.setState({isProcessing: false});
    }

    async processInvoice(){
        try{
            this.setState({isProcessing: true});
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };
            let invoiceDate;
            let divisionId;
            let divisionName;
            let res;
            let invoiceArray = [];
            let cutOffDate = new Date();
            let bulkDownload = false;
            let abort = false;
            var zip = new JSZip();

            if(this.props.invoiceDetail)
                invoiceArray.push(this.props.invoiceDetail);
            else{
                bulkDownload = true;
                while (moment(cutOffDate).day() !== 0) {
                    cutOffDate = moment(cutOffDate).subtract(1,'days');
                }
                console.log('sunday', moment(cutOffDate).format('YYYY-MM-DD'));
                const invoicesbatch = await API.get("api", "/invoicesbatch?InvoiceDate=" + moment(cutOffDate).format('YYYY-MM-DD'), myInit);
                if(invoicesbatch.result === 'success')
                    invoiceArray = invoicesbatch.invoicesbatch;
                else
                    invoiceArray = [];
                console.log('invoiceArray', invoiceArray);
            }
            const forEachPromise = invoiceArray.map(async(i) => {
                if(!abort) {
                    invoiceDate = i.InvoiceDate;
                    divisionId = i.DivisionId;
                    divisionName = i.Division;
                    res = await API.get("api", "/invoices?DivisionId=" + divisionId + "&InvoiceDate=" + invoiceDate, myInit);
                    console.log('invoices', res);
                    if(res.invoices.filter(i => (i.NoRate === true || i.NoRateCard === true || i.NoDeliveryCharge === true || i.NoUploadedDeliveryCharge === true)).length > 0)
                    {
                        abort = true;
                        if(res.invoices.filter(i => (i.NoRateCard === true || i.NoUploadedDeliveryCharge === true)).length > 0)
                        {
                            let display;
                            if(res.invoices.filter(i => (i.NoRateCard === true && i.NoUploadedDeliveryCharge === true)).length > 0)
                                display = 3; //display both rate card and delivery charge upload.
                            else{
                                if(res.invoices.filter(i => (i.NoRateCard === true)).length > 0)
                                    display = 1; //display rate card upload only.
                                else
                                    display = 2; //display delivery charge upload only.
                            }
                            if(bulkDownload)
                                this.showUploadModal.current.setModalShow(true, i.Division, display, bulkDownload, i.BillingClientId, i.DivisionId);
                            else
                                this.showUploadModal.current.setModalShow(true, i.Division, display, bulkDownload);
                        }
                        else
                        {
                            if(res.invoices.filter(i => (i.NoRate === true)).length > 0)
                                this.setState({modalShow: true, modalMessage: 'No rate on one or more records'});
                            else
                                this.showMissingRateOrChargeModal.current.setModalShow(true, res.invoices.filter(i => (i.Status.toLowerCase() === 'not ok')), i.Division, bulkDownload, i.BillingClientId, i.DivisionId);
                        }         

                        this.setState({isProcessing: false});
                    }
                    else{
                        if(bulkDownload)
                        {
                            console.log('file process');
                            let fileName = '';
                            let file = await this.download(i, res.invoices, (response) => {fileName = response});
                            zip.file(fileName, new Blob([file]));
                        }else{
                            if(this.props.includeInterstateCharge !== undefined && this.props.includeInterstateCharge === true && res.invoices.filter(i => (i.IsInterstateNotOk === true)).length > 0)
                            {
                                if(res.invoices.filter(i => (i.NoInterstateRate === true)).length > 0)
                                    this.setState({modalShow: true, modalMessage: 'No interstate rate on one or more records'});
                                else
                                    this.setState({modalShow: true, modalMessage: 'No minimum interstate charge set for the division'});
                            }
                            else {
                                await this.download(i, res.invoices);
                            }
                        }
                    }
                }
                
            });
            await Promise.all(forEachPromise);
            if(invoiceArray.length === 0 && bulkDownload)
                this.setState({modalShow: true, modalMessage: 'No Invoice generated from last week'});
            else{
                if(bulkDownload && !abort){
                    console.log('end zip');
                    zip.generateAsync({type:"blob"})
                        .then(function(content) {
                            saveAs(content, 'invoices_' + moment(cutOffDate).format("DD/MM/YYYY").replace("/", "_").replace("/", "_"));
                    });
                    this.setState({isProcessing: false});
                }
            }  
        }catch(error) {
            this.setState(() => { throw error; })
        }              
    }

    render(){
        return(
            <React.Fragment>
                <Button className='btn-sm mr-sm-2' disabled={(this.state.isProcessing || (this.props.disabled !== undefined && this.props.disabled === true)) ? true : false} variant="success" size="sm" onClick={()=> this.setShowFuelChargeModal(true)}>
                    <i className="fa fa-download" style={{ fontSize: '1em' }} /> {this.state.isProcessing ? (this.props.display.toLowerCase().indexOf('all') > -1 ? 'Downloading All...' : 'Downloading Selected...') : this.props.display}                              
                </Button>
                <Modal
                    size="sm"
                    show={this.state.modalShow}
                    onHide={() => this.setModalMessageShow(false)}
                    backdrop= "static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Invoice Message
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body> 
                        {this.state.modalMessage}                       
                    </Modal.Body>
                </Modal>
                <Modal
                    size="sm"
                    show={this.state.showFuelChargeModal}
                    onHide={() => this.setShowFuelChargeModal(false)}
                    backdrop= "static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Fuel Surge Charge
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body> 
                        <Form>
                            <Form.Row>
                                <Form.Group as="Col">
                                    <Form.Label className="mr-sm-12">Enter Fuel Surge Charge</Form.Label>
                                    <Form.Control className="mr-sm-12"
                                        size="sm"
                                        type="text"
                                        placeholder="Fuel Surge Charge"
                                        name="fsc"
                                        value={this.state.fsc}
                                        onChange={this.handleFSCChange} 
                                        isInvalid={this.state.fscError}>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Enter valid number
                                    </Form.Control.Feedback>
                                </Form.Group>                                
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as="Col">
                                    <Button variant="primary" onClick={this.fscSubmit} size="sm"> Submit</Button>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                </Modal>
                <UploadButton reprocessCallback={this.props.reprocessCallback} ref={this.showUploadModal} hideButton='true' clientId={this.props.invoiceDetail ? this.props.invoiceDetail.BillingClientId : undefined} divisionId={this.props.invoiceDetail ? this.props.invoiceDetail.DivisionId : undefined} display='Upload Actions' />
                <MissingRateOrChargeModal reprocessCallback={this.props.reprocessCallback} ref={this.showMissingRateOrChargeModal} clientId={this.props.invoiceDetail ? this.props.invoiceDetail.BillingClientId : undefined} divisionId={this.props.invoiceDetail ? this.props.invoiceDetail.DivisionId : undefined}></MissingRateOrChargeModal>
            </React.Fragment>            
        )
    }
}