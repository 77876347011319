import React, { Component, useRef } from 'react';
import {Map as HereMap, TileLayer, Marker, Polyline, Popup} from "react-leaflet";
import config from "../../config";
import axios from 'axios';
import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import polyline from '@mapbox/polyline';
import LoadingOverlay from 'react-loading-overlay'; 
import {PDFButton} from './PDFDocument'; 
import { Link } from 'react-router-dom';
import {UserConsumer} from './UserContext';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

//just an extension of the default Marker that is set to auto open the popup upon loading.
const MyMarker = props => {
    const initMarker = ref => {
      if (ref) {
        ref.leafletElement.openPopup()
      }
    }  
    return <Marker ref={initMarker} {...props}/>
}


export default class MainMap extends Component {
    state = {
        isLoading:false,
        x: 133.323148,
        y: -26.250804,
        z: 3.5,
        routes:[],
        details:undefined
    };

    componentDidMount() {
        L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling); 
        //this.getRoutes(this.props.details);  
        //console.log('component did mount');     
    }

    componentDidUpdate() {
        //console.log('component did update');
    }

    componentWillReceiveProps(newProps) {
        // if(this.props.selectedJobs !== newProps.selectedJobs)
        //     this.setState({ selectedJobs: newProps.selectedJobs });
        // console.log("Props: ", this.state.selectedJobs);
    }

    getBounds() {
        if(this.state.details !== undefined && this.state.details.length > 0)
        {
            return L.latLngBounds([this.state.details.map(marker => [marker.lat,marker.long])]);
        }
        else
        {
            //console.log('bounds default');
            return L.latLngBounds([[-10.070786, 108.625136],[-41.367241, 161.171241]]);;
        }
    }

    async getRoutes(details) {
        console.log('details', details);
        this.setState({details: details});
        this.props.setLoadingDisplay !== undefined && this.props.setLoadingDisplay(true);
        //filtered to display routes for statusGroup 2 and 3 only.
        const routes = details.filter(d => d.statusGroup === 2 || d.statusGroup === 3).reduce((job, { driverId, lat, long}) => {
            (job[driverId] = job[driverId] || []).push([lat, long]);
            return job;
        }, {});
        let routeState = [];
        let directionsArray = [];
        if(Object.keys(routes).length > 0){
            for (const route of Object.keys(routes)){
                try{
                    if(routes[route].length > 1){
                        directionsArray.push(this.getDirections(routes[route]));
                        this.setState({
                            isLoading: false
                        });                    
                    }
                }
                catch(err) {
                    console.log('error in route', err.message);
                }
                
            }
            if(directionsArray.length > 0){
                routeState = await Promise.all(directionsArray);
                this.setState({
                    routes: routeState, isLoading: false
                });
            }
            //console.log(routeState);
            if(routeState.length === 0)
                this.setState({ routes: [],isLoading:false });
        }
        else{
            this.setState({
                routes: [],isLoading: false
            });
        }
        this.props.setLoadingDisplay !== undefined && this.props.setLoadingDisplay(false);        
    }

    showRoutes() {
        const colors = ['blue','red','#6610f2','#fd7e14','#e83e8c','#17a2b8','#20c997','yellow','#343a40','#fd7e14'];
        //console.log('latest showRoutes', this.state.routes);
        return this.state.routes.map((route, index) => {
            let color;
            color = colors[+(index.toString().slice(-1))];
            return(
                <Polyline key={index} positions={route} color={color} weight={3}/>
            )
        })
    }

    handleSelectedJobsChange(id, consignmentNumber, checked) {
        var options = this.state.details.filter(j => j.selected == true).map(j => ({ value: j.id, label: j.consignmentNumber }));
        if(checked) {            
            options.push({ value: id, label: consignmentNumber });            
        }
        else {
            var index = options.findIndex(j => j.value == id);
            if(index >= 0) {
                options.splice(index, 1);
            }
        }
        this.props.setSelectedJobs(options);
        var popups = document.getElementsByClassName('leaflet-popup-close-button');
        for(var i = 0; i < popups.length; i++) {
            popups[i].click();
        }
    }

    async getDirections(waypoints) {
        try{
            let waypointsString = '';
            var originPoint = waypoints[0];
            var destinationPoint = waypoints[waypoints.length-1];
            waypoints.forEach((w,index) => {
                waypointsString = waypointsString + '&waypoint' + index + '=geo!' + w[0] + ',' + w[1]; 
            });

            const result = await axios.get(`https://router.hereapi.com/v8/routes?transportMode=car&origin=${originPoint}&destination=${destinationPoint}&return=polyline&apikey=${config.map.apikey}`);
            console.log('  polylne - -' + result.data.routes[0]["sections"][0]["polyline"]);
            const shape = polyline.decode(result.data.routes[0]["sections"][0]["polyline"]);
            return shape;
        }catch(err) {
            console.log('error in route', err.message);
            return [];
        }
    }

    getMarkers() {       
        return this.state.details.map((marker) => {
            const { id, lat, long, initials, statusGroup, showPopup, consignmentNumber, name, driverName, address, status, sequence, showSequence, client, division, selected } = marker
            let color;
            if(statusGroup === 2)
                color = 'green';
            if(statusGroup === 3)
                color = 'orange';
            if(statusGroup === 4)
                color = 'red';
            if(statusGroup === 5)
                color = 'grayBorder';
            if(showPopup === true){
                return (
                    <UserConsumer key={id}>
                        {userType => {
                            let noHistory = false;
                            if(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser')
                                noHistory = true;
                            let htmlText = "";
                            if(selected) {
                                htmlText = `<div class="map-label ${color}"><div class="map-label-content bold-font"><i class='fas fa-check' style='font-size:12px;color:black'></i></div><div class="map-label-arrow"></div></div>`;                                
                            }
                            else {
                                htmlText = `<div class="map-label ${color}"><div class="map-label-content bold-font">${showSequence ? sequence : initials}</div><div class="map-label-arrow"></div></div>`;
                            }
                            return (                                
                                <MyMarker key={id} position={[lat,long]} icon={L.divIcon({popupAnchor: [0, -45], className: 'my-div-icon', html:htmlText})} >
                                    <Popup>
                                        <span>
                                            {/* <span className='bold-font'>Job</span>: <a href='' onClick={this.changePage.bind(this,id,'jobs')}> #{consignmentNumber} - {name} </a><br/> */}
                                            <span className='bold-font'>Job</span>: <Link to={'/jobs/editJob/' + id}> #{consignmentNumber} - {name} </Link><br/>
                                            <span className='bold-font'>Address</span>: {address} <br/>
                                            <span className='bold-font'>Client</span>: {client} <br/>
                                            <span className='bold-font'>Division</span>: {division} <br/>
                                            <span className='bold-font'>Driver</span>: {driverName} <br/>
                                            <span className='bold-font'>Status</span>: {status} <br/>
                                        </span>
                                        <br/>
                                        <ButtonGroup className="mb-2">
                                            <ToggleButton
                                                className="mb-2"
                                                id="toggle-check"
                                                type="checkbox"
                                                variant="outline-primary"
                                                checked={selected}
                                                value="0"
                                                onChange={(e) => this.handleSelectedJobsChange(id, consignmentNumber, e.currentTarget.checked)}
                                            >
                                               {selected ? 
                                               <span>&nbsp; Unselect</span> : <span>&nbsp; Select</span>} 
                                            </ToggleButton>
                                        </ButtonGroup>                                        
                                        <br/>
                                        {statusGroup === 5 &&
                                        <PDFButton size='small' type='download' id={id} display='Download POD' noHistory={true}/>}
                                        {statusGroup === 5 &&
                                        <PDFButton size='small' type='view' id={id} display='View POD' noHistory={noHistory}/>}
                                        {statusGroup === 5 &&
                                        <PDFButton size='small' type='email' id={id} display='Email POD' noHistory={true}/>}
                                    </Popup>
                                </MyMarker>
                            )
                        }}
                    </UserConsumer>
                )
            }else{
                return (
                    <UserConsumer key={id}>
                        {userType => {
                            let noHistory = false;
                            if(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser')
                                noHistory = true;
                            let htmlText = "";
                            if(selected) {
                                htmlText = `<div class="map-label ${color}"><div class="map-label-content bold-font"><i class='fas fa-check' style='font-size:12px;color:black'></i></div><div class="map-label-arrow"></div></div>`;                                
                            }
                            else {
                                htmlText = `<div class="map-label ${color}"><div class="map-label-content bold-font">${showSequence ? sequence : initials}</div><div class="map-label-arrow"></div></div>`;
                            }
                            return (
                                <Marker key={id} position={[lat,long]} icon={L.divIcon({popupAnchor: [0, -45], className: 'my-div-icon', html: htmlText})} >
                                    <Popup>
                                        <span>
                                            {/* <span className='bold-font'>Job</span>: <a href='' onClick={this.changePage.bind(this,id,'jobs')}> #{consignmentNumber} - {name} </a><br/> */}
                                            <span className='bold-font'>Job</span>: <Link to={'/jobs/editJob/' + id}> #{consignmentNumber} - {name} </Link><br/>
                                            <span className='bold-font'>Address</span>: {address} <br/>
                                            <span className='bold-font'>Client</span>: {client} <br/>
                                            <span className='bold-font'>Division</span>: {division} <br/>
                                            <span className='bold-font'>Driver</span>: {driverName} <br/>
                                            <span className='bold-font'>Status</span>: {status} <br/>
                                        </span>
                                        <br/>
                                        <ButtonGroup className="mb-2">
                                            <ToggleButton
                                                className="mb-2"
                                                id="toggle-check"
                                                type="checkbox"
                                                variant="outline-primary"
                                                checked={selected}
                                                value="0"
                                                onChange={(e) => this.handleSelectedJobsChange(id, consignmentNumber, e.currentTarget.checked)}
                                            >
                                               {selected ? 
                                               <span>&nbsp; Unselect</span> : <span>&nbsp; Select</span>} 
                                            </ToggleButton>
                                        </ButtonGroup>    
                                        <br/>
                                        {statusGroup === 5 &&
                                        <PDFButton size='small' type='download' id={id} display='Download POD' noHistory={true}/>}
                                        {statusGroup === 5 &&
                                        <PDFButton size='small' type='view' id={id} display='View POD' noHistory={noHistory}/>}
                                        {statusGroup === 5 &&
                                        <PDFButton size='small' type='email' id={id} display='Email POD' noHistory={true}/>}
                                    </Popup>
                                </Marker>
                            )
                        }}
                    </UserConsumer>
                )
            }            
        })
    }

    changePage(id, page, e) {
        //console.log('page', page);
        //console.log('id', id);
        e.preventDefault()
        this.props.navProp.changeToJobPaneFromMap('editJob',id); 
    }
    
    render() {
        const position = [this.state.y, this.state.x]
        const zoom = this.state.z;
        const hereTileUrl = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&style=explore.day&&ppi=400&apiKey=${config.map.apikey}`;
        if(this.state.details !== undefined && this.state.details.length > 0){
            return (  
                <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text='Loading map...'
                >         
                    <HereMap
                    style={{ width: '100%', height: '900px' }}
                    center={position}
                    zoom={zoom}
                    maxZoom={19}
                    bounds={this.getBounds()}
                    gestureHandling={true}
                    ref='map'
                    >
                        <TileLayer
                        attribution='&copy; HERE 2019'
                        url={hereTileUrl}
                        />
                        {this.getMarkers()}
                    </HereMap>
                </LoadingOverlay> 
            );
        }
        else{
            return (            
                <HereMap
                style={{ width: '100%', height: '900px' }}
                center={position}
                zoom={zoom}
                bounds={this.getBounds()}
                maxZoom={19}
                gestureHandling={true}
                >
                    <TileLayer
                    attribution='&copy; HERE 2019'
                    url={hereTileUrl}
                    />                    
                </HereMap>
            );
        }
        
    }
}