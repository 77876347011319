import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Col, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import "moment-timezone";
import axios from 'axios';
import { Auth, API } from "aws-amplify";
import LoadingOverlay from 'react-loading-overlay';
import { BootstrapTable, TableHeaderColumn, SizePerPageDropDown } from 'react-bootstrap-table';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import Select from 'react-select';


function ExportRemainingJobsButton(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessingExport, setIsProcessingExport] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [error, setError] = useState('');
    const [fromDate, setFromDate] = useState(props.fromDate ? props.fromDate : new Date());
    const [toDate, setToDate] = useState(props.toDate ? props.toDate : new Date());
    const [clients, setClients] = useState(props.clients ? props.clients : []);
    const [divisions, setDivisions] = useState(props.divisions ? props.divisions : []);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedStates, setSelectedStates] = useState([]);
    const [stateOptions, setStateOptions] = useState(props.stateOptions ? props.stateOptions : []);

    useEffect(() => {
        if (show)
            fetchJobs();
    }, [fromDate, toDate, props.fromDate, props.toDate])

    useEffect(() => {
        setFromDate(props.fromDate);
        setToDate(props.toDate);
    }, [props.fromDate, props.toDate, show])

    useEffect(() => { // this hook will get called everytime when [selectedStates, selectedClient, selectedDivision] has changed
        // perform some action which will get fired everytime when [selectedStates, selectedClient, selectedDivision] gets updated
        if (show)
            fetchJobs();
    }, [selectedStates, selectedClient, selectedDivision])

    const handleJobStateChange = async (option) => {
        var states = option ? option : [];
        await setSelectedStates(states);
    }

    const handleClientChange = async (e) => {
        await setSelectedClient(e.target.value);
        await setSelectedDivision('');
        // fetchJobs();
    }

    const handleDivisionChange = async (e) => {
        await setSelectedDivision(e.target.value);
        // fetchJobs();
    }

    const getSelectedStateOptionsMatches = (list) => {
        let states = "";
        list.forEach(s => {
            if (s.matches && s.matches.length > 0) {
                s.matches.forEach(i => {
                    if (states === "")
                        states = i;
                    else
                        states = states + "|" + i;
                });
            }
        });
        return states;
    }

    const getClientOptions = () => {
        if (clients.length > 0) {
            return clients.map((c) => {
                const { Name, ItemId } = c
                return (
                    <option key={ItemId} value={ItemId} >{Name}</option>
                )
            });
        }
    }

    const getDivisionOptions = () => {
        if (divisions.length > 0 && clients.length > 0) {
            if (selectedClient !== '') {
                return divisions.filter(d => d.ParentItemId === selectedClient).map((c) => {
                    const { Name, ItemId } = c
                    return (
                        <option key={ItemId} value={ItemId} >{Name}</option>
                    )
                });
            }
        }
    }

    const fetchJobs = async () => {
        setIsProcessing(true);
        setShow(true);
        setError('');
        try {
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };

            let res = await API.get("api", "/accounts?AccountType=Driver", myInit);
            const drivers = res.accounts;
            console.log(drivers);
            const from = moment(fromDate).format('YYYY-MM-DD');
            const to = moment(toDate).format('YYYY-MM-DD') + ' 23:59:59';
            let filters = '?Where=DeliveryDate~between~' + from + '|' + to;
            if (selectedClient !== '') {
                filters = filters + '&BillingClientId=' + selectedClient;
            }
            if (selectedDivision !== '') {
                filters = filters + '&ParentItemId=' + selectedDivision;
            }
            if (selectedStates && selectedStates.length > 0) {
                filters = filters + '&Filter=State~in~' + getSelectedStateOptionsMatches(selectedStates);
            }
            filters = filters + '&export=true';
            res = await API.get("api", "/remainingjobs" + filters, myInit);
            const jobs = res.remainingjobs;
            const remainingJobs = jobs.filter(j => j.StatusGroup !== '4' && j.StatusGroup !== '5' && j.StatusGroup !== '2');
            console.log("Export RMJ: ", remainingJobs);
            setJobs(remainingJobs);
        } catch (error) {
            setError(error.toString().toLowerCase());
        }
        setIsProcessing(false);
    }

    const addressFormatter = (job) => {
        let address = '';
        if (job.Street1 && job.Street1 !== '')
            address = job.Street1;
        if (job.Street2 && job.Street2 !== '' && (job.Street1 !== null ? job.Street1.trim().toLowerCase() : '') !== job.Street2.trim().toLowerCase())
            address = (address + ' ' + job.Street2).trim();
        if (job.Suburb && job.Suburb !== '')
            address = (address + ' ' + job.Suburb).trim();
        if (job.Zip && job.Zip !== '')
            address = (address + ', ' + job.Zip).trim();
        if (job.Postcode && job.Postcode !== '')
            address = (address + ', ' + job.Postcode).trim();
        if (address.startsWith(','))
            address = address.substring(1).trim();
        return address;
    }
    const etaFormatter = (job) => {
        let time = '';
        if (job.StatusGroup === '3')
            time = moment(job.ETA).format('LT').toLowerCase() === 'invalid date' ? '' : moment(job.ETA).format('LT');
        if (job.StatusGroup === '5')
            time = moment(job.DeliveredTime).local().format('LT').toLowerCase() === 'invalid date' ? '' : moment(job.DeliveredTime).local().format('DD-MMM-YY LT');
        return time;
    }

    const download = async (jobs, isInvoice) => {
        setIsProcessingExport(true);
        let workSheet;
        let row;
        let fileName = 'RemainingJobs.csv';
        const workBook = new ExcelJS.Workbook();
        workSheet = workBook.addWorksheet('Jobs');
        workSheet.columns = [
            { header: 'Delivery Date', key: 'deliveryDate', width: 15 },
            { header: 'Consignment Number', key: 'consignmentNumber', width: 10 },
            { header: 'Job Type', key: 'jobType', width: 10 },
            { header: 'Client', key: 'billingClient', width: 10 },
            { header: 'Delivery Client', key: 'locationName', width: 10 },
            { header: 'Delivery Address', key: 'deliveryAddress', width: 10 },
            { header: 'Postcode', key: 'postcode', width: 10 },
            { header: 'Suburb', key: 'suburb', width: 10 },
            { header: 'ETA/Delivered Date', key: 'eta', width: 10 },
            { header: 'Notes', key: 'notes', width: 10 },
            { header: 'Division', key: 'division', width: 10 },
            { header: 'Cartons', key: 'cartons', width: 10 },
            { header: 'Driver', key: 'driver', width: 10 },
            { header: 'Status', key: 'status', width: 10 },
        ];
        row = workSheet.lastRow;
        row.eachCell(function (cell, colNumber) {
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thick' }, right: { style: 'thin' } };
            cell.font = { bold: true };
        });
        jobs.forEach((j, index) => {
            workSheet.addRow(
                [
                    moment(j.DeliveryDate).format("DD-MMM-YY"),
                    j.ConsignmentNumber,
                    j.JobType,
                    j.BillingClient,
                    j.Name,
                    addressFormatter(j),
                    j.Zip,
                    j.Suburb,
                    etaFormatter(j),
                    j.Notes,
                    j.Division,
                    j.Cartons,
                    j.DriverName,
                    j.Status
                ]
            );
            row = workSheet.lastRow;
            row.eachCell(function (cell, colNumber) {
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            });
        });
        const buf = await workBook.csv.writeBuffer()
        saveAs(new Blob([buf]), fileName);
        setIsProcessingExport(false);
    }

    const exportFile = async () => {
        await download(jobs);
    }

    return (
        <React.Fragment>
            <Button variant="success" size="sm" className="mr-sm-1" onClick={() => { fetchJobs(); }} disabled={props.disabled ? props.disabled : false}>
                <i className="fas fa-eye" style={{ fontSize: '1em' }} /> Export Remaining Jobs
            </Button>
            <Modal
                size="md"
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Export Remaining Jobs
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            <Form inline>
                                <Form.Label className="mr-sm-2">State</Form.Label>
                                <Select
                                    closeMenuOnSelect={true}
                                    className={"basic-multi-select form-text-align mr-sm-3 width-full"}
                                    classNamePrefix="select"
                                    placeholder="filter by state..."
                                    isMulti
                                    options={stateOptions}
                                    name="stateSelect"
                                    onChange={(option) => handleJobStateChange(option)}
                                />
                            </Form>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={6}>
                            <Form inline>
                                <Form.Label className="mr-sm-2">Client</Form.Label>
                                <Form.Control as="select"
                                    size="sm"
                                    className="mr-sm-3 width-200"
                                    onChange={handleClientChange}
                                >
                                    <option value="">{clients.length === 0 ? 'Loading...' : 'All'}</option>
                                    {getClientOptions()}
                                </Form.Control>
                            </Form>
                        </Form.Group>
                        <Form.Group as={Col} xs={6}>
                            <Form inline>
                                <Form.Label className="mr-sm-2">Division</Form.Label>
                                <Form.Control as="select"
                                    size="sm"
                                    className="mr-sm-3 width-200"
                                    disabled={selectedClient === ''}
                                    onChange={handleDivisionChange}
                                >
                                    <option value="">{clients.length === 0 ? 'Loading...' : (selectedClient !== '' ? 'All' : 'select client first')}</option>
                                    {getDivisionOptions()}
                                </Form.Control>
                            </Form>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={6}>
                            <Form.Label className="mr-sm-2">From</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={fromDate} maxDate={toDate} onChange={value => setFromDate(value)} className='form-control form-control-sm mr-sm-3 width-100' placeholderText='Select from date' />
                        </Form.Group>
                        <Form.Group as={Col} xs={6}>
                            <Form.Label className="mr-sm-2">To</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={toDate} minDate={fromDate} onChange={value => setToDate(value)} className='form-control form-control-sm width-100' placeholderText='Select to date' />
                        </Form.Group>                        
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            <Button variant="success" size="sm" className="mr-sm-1" onClick={() => { exportFile(); }} disabled={isProcessing || isProcessingExport}>
                                <i className="fas fa-file-export" style={{ fontSize: '1em' }} /> {isProcessingExport === true ? 'Exporting..' : 'Export To CSV'}
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default ExportRemainingJobsButton