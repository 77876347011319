import React, { Component } from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';
import "moment-timezone";
import axios from 'axios';
import { Auth, API } from "aws-amplify";
import Download from "./Download";

export class DownloadButton extends Component {
    state = {
        isProcessing: false,
        modalShow: false,
        fileType: 'rateCard',
        url: null,
        status: 'ok'
    };

    setModalShow = (show) => {
        this.setState({ modalShow: show});
        if(show === false)
            this.setState({status:'ok',url:null,isProcessing:false});
    }

    onClickHandler = async () => { 
        const myInit = {
            headers: {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }            
        };     
        this.setState({isProcessing: true, status: 'ok', url: null}, async function () {
            const result = await API.get("api", `/uploads?File=${this.state.fileType === 'rateCard' ? 'RateCard' : (this.state.fileType === 'deliveryCharge' ? 'DeliveryCharge' : 'InterstateRateCard')}.csv&Folder=${this.props.clientId}/${this.props.divisionId}&type=download`, myInit);
            console.log('test',result);
            if(result['result'] === 'success')
            {
                await axios({ method: 'get', url: result['url']})
                    .then(response => {
                        console.log(response);
                        this.setState({url: result['url'], isProcessing: false}); 
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({status: 'failed', isProcessing: false});
                    })            
            }
            this.setState({isProcessing: false});
        });
        
                            
    }

    handleChange = (e) => {
        this.setState({ fileType: e.target.value });
    }

    render(){
        return(
            <div className={this.props.rightAlign ? 'float-right' : 'inline'}>
                {(this.props.hideButton === undefined || this.props.hideButton === false) &&
                <Button className={`mr-sm-2 ${this.props.size === 'small' ? 'btn-cust-sm' : 'btn-sm'}`} variant="success" size="sm" onClick={()=> this.setModalShow(true)}>
                    <i className="fa fa-download" style={{ fontSize: '1em' }} /> {this.props.display}                              
                </Button>}
                <Modal
                    size="md"
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    backdrop= "static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        {this.props.display}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body> 
                        <Form.Row>
                            <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                <Form.Control as="select"
                                    size="sm"
                                    value={this.state.fileType}
                                    name="fileType"
                                    onChange={this.handleChange}
                                >
                                <option value='rateCard'>Rate Card</option>
                                <option value='deliveryCharge'>Delivery Charge</option>
                                <option value='interstateRate'>Interstate Rate Card</option>
                                </Form.Control>                                
                            </Form.Group>                            
                            <Form.Group as={Col} xs={12} className="margin-top-20 margin-bottom-zero">
                                <Button disabled={this.state.isProcessing} className="mr-sm-2" variant="success" size="sm" onClick={() => this.onClickHandler()}>
                                    <i className="fa fa-download" style={{ fontSize: '1em' }} /> {this.state.isProcessing ? 'Downloading...' : 'Download'}
                                </Button>
                            </Form.Group>
                            {this.state.status !== 'ok' && 
                            <Form.Group as={Col} xs={12}>
                                <br/>
                                <Alert variant='danger'>File not found!</Alert>
                            </Form.Group>}
                        </Form.Row>                              
                    </Modal.Body>                    
                </Modal>
                <Download url={this.state.url} />
            </div>
        )
    }
}