import React, {Component} from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';

class ErrorBoundary extends Component {
    constructor(props){
        super(props)
        this.state = {
            hasError: false,
            isNetworkError: false
        }
    }

    static getDerivedStateFromError(error){
        console.log('error boundary', error.toString());
        //console.log('is network error ', error.toString().toLowerCase().indexOf('network error') > -1 ? 'true' : 'false');
        return {
            hasError: true,
            isNetworkError: error.toString().toLowerCase().indexOf('network error') > -1 ? true : false
        }
    }

    render(){
        if(this.state.hasError) {
            return(
                <Alert variant='danger'>
                    {this.state.isNetworkError ? 'Network Error encountered. Kindly check internet connection and refresh the page.' : 'Error encountered. Please refresh the page to try again.'}
                </Alert>
            )
        }
        return this.props.children        
    }
}
export default ErrorBoundary
