import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import {Form, Button, Container, Col, Card, Collapse, Spinner} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import AutoResizeTextArea from './utility/AutoResizeTextArea';
import {UserConsumer} from './utility/UserContext';

export default class EditAccount extends Component {
  state = {
    staff: null,
    clients: null,
    divisions: null,
    officeUserAccess:[],
    allJobAccess: false,
    allInvoiceAccess: false,
    accountId: undefined,
    notificationGroups: null,
    selectedNotificationGroups: [],
    redirect: null
  }
  handleAllInvoiceClicked(e) {
    //console.log(e.target.checked);
    const access = this.state.officeUserAccess.map(item => ({
      value: item.value,
      label: item.label,
      invoice: e.target.checked === true ? true : false,
      job: this.state.officeUserAccess.filter(i => i.value === item.value).length > 0 ? this.state.officeUserAccess.filter(i => i.value === item.value)[0].job : false
    }))
    this.setState({officeUserAccess: access, allInvoiceAccess: e.target.checked});
  }
  handleAllJobClicked(e) {
    //console.log(e.target.checked);
    const access = this.state.officeUserAccess.map(item => ({
      value: item.value,
      label: item.label,
      invoice: this.state.officeUserAccess.filter(i => i.value === item.value).length > 0 ? this.state.officeUserAccess.filter(i => i.value === item.value)[0].invoice : false,
      job: e.target.checked === true ? true : false
    }))
    this.setState({officeUserAccess: access, allJobAccess: e.target.checked});
  }
  handleInvoiceAccess(e, item) {
    let allChecked = true;
    const access = this.state.officeUserAccess; 
    const objIndex = access.findIndex((obj => obj.value === item));
    access[objIndex].invoice = e.target.checked;
    if(access.filter(i => i.invoice === false).length > 0)
      allChecked = false;
    this.setState({officeUserAccess: access, allInvoiceAccess: allChecked});
  }
  handleJobAccess(e, item) {
    let allChecked = true;
    const access = this.state.officeUserAccess; 
    const objIndex = access.findIndex((obj => obj.value === item));
    access[objIndex].job = e.target.checked;
    if(access.filter(i => i.job === false).length > 0)
      allChecked = false;
    this.setState({officeUserAccess: access, allJobAccess: allChecked});
  }
  setOfficeUserAccess(items) {
    console.log('param', items);
    let access;
    let allInvoiceChecked = true;
    let allJobChecked = true;
    if(items){
      access = items.map(item => ({
        value: item.value,
        label: item.label,
        invoice: this.state.officeUserAccess.filter(i => i.value === item.value).length > 0 ? this.state.officeUserAccess.filter(i => i.value === item.value)[0].invoice : (item.invoice === undefined ? false : item.invoice),
        job: this.state.officeUserAccess.filter(i => i.value === item.value).length > 0 ? this.state.officeUserAccess.filter(i => i.value === item.value)[0].job : (item.job === undefined ? false : item.job)
      }))
    }
    if(items === null || access.filter(i => i.invoice === false).length > 0)
      allInvoiceChecked = false;
    if(items === null || access.filter(i => i.job === false).length > 0)
      allJobChecked = false;
    console.log('processed',access);
    this.setState({officeUserAccess: items !== null ? access : [], allInvoiceAccess: allInvoiceChecked, allJobAccess: allJobChecked});
  }
  componentDidMount() {    
    this.fetchClients();
    this.fetchNotificationGroups()
    if(this.props.match.params.accountId !== undefined && this.props.match.params.accountId.length > 0)
    {
        this.setState({accountId:this.props.match.params.accountId }, function(){
            this.fetchStaff();
        });
    }
    
  }
  async fetchStaff() {
    try{
        await this.fetchDivisions();
        const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        };
        const res = await API.get("api", "/accounts/" + this.state.accountId, myInit);
        if(res.accounts[0].AccountType === 'OfficeUser')
        {
            this.setOfficeUserAccess(res.accounts[0].DivisionIds.map(i => ({
                value: i.divisionId,
                label: this.state.divisions.filter(d => d.ItemId === i.divisionId)[0].Name,
                invoice: i.invoice,
                job: i.job
            })))
        }
        
        this.setState({
            staff: res.accounts[0],
            selectedNotificationGroups: res.accounts[0].NotificationGroupsList === null ? [] : res.accounts[0].NotificationGroupsList
        })
    }catch(err) {
      this.setState(() => { throw err; })
    }
  }
  async fetchClients() {
    try{
        const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        };
        const res = await API.get("api", "/clients", myInit);
        this.setState({
            clients: res.clients
        })
    }catch(err) {
      this.setState(() => { throw err; })
    }
  }
  async fetchDivisions(clientId) {
    try{
        const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        };
        const res = await API.get("api", "/divisions", myInit);
        this.setState({
        divisions: res.divisions
        })
    }catch(err) {
      this.setState(() => { throw err; })
    }  
  }
  async fetchNotificationGroups() {
    try{
        const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        };
        const res = await API.get("api", "/workgroups", myInit);
        this.setState({
            notificationGroups: res.workgroups
        })
    }catch(err) {
      this.setState(() => { throw err; })
    } 
  }
  getDivisionArray(clientId) {
    const filtered = this.state.divisions.filter(division =>
      division.ParentItemId === clientId);
    return filtered.map((division, index) => {
      const { Name, ItemId } = division
      return (
        { value: ItemId, label: Name }
      )
    })
  }
  getClients() {        
    return this.state.clients.map((client, index) => {
       const { Name, ItemId } = client
       return (
           <option key={ItemId} value={ItemId} >{Name}</option>
       )
    })
  }
  getClientArray() {
    return this.state.clients.map((client, index) => {
      const { Name, ItemId } = client
      return (
        { value: ItemId, label: Name }
      )
    })
  }
  getNotificationGroupsArray() {        
    return this.state.notificationGroups.map((group, index) => {
       const { Name, ItemId } = group
       return (
        { value: ItemId, label: Name }
       )
    })
  }
  getOperatorName = async () => {
    try{
      const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
      };
      const res = await API.get("api", "/operators?ItemId=" + this.props.auth.operator , myInit);
      return res.operators[0].Name;
    }catch(err) {
      return '';
    } 
  }
  createAccount = async (values, actions) => {
    try{
      let clientIds = [];
      let divisionIds = [];
      let company = await this.getOperatorName();
      if(values.type.toLowerCase() === 'officeadministrator'){
        clientIds = values.companyArray;
        company = this.state.clients.filter(c => values.companyArray.indexOf(c.ItemId) >= 0).map(i => i.Name).join();
      }
      if(values.type.toLowerCase() === 'officeuser'){
        clientIds = [values.company];
        divisionIds = this.state.officeUserAccess.map(access => ({
          divisionId: access.value,
          invoice: access.invoice,
          job: access.job
        }))
        company = this.state.clients.filter(c => c.ItemId === values.company)[0].Name;
      }
      const notificationGroupsString = (values.type.toLowerCase() !== 'globaladministrator' && this.state.notificationGroups !== null) ? this.state.notificationGroups.filter(i => this.state.selectedNotificationGroups.indexOf(i.ItemId) > -1).map(n => n.Name).join() : '';
    //   console.log('notificationGroupsString', notificationGroupsString);
    //   console.log('company',company);
      const myInit = {
          headers: {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          },
          body: {
              accounts: [
                  {
                      ItemId: this.state.staff.ItemId,
                      FirstName: values.firstName.trim(),
                      LastName: values.lastName.trim(),
                      Email: values.email,
                      MobileNumber: values.mobile,
                      LandlineNumber: values.landline,
                      CustomInitials: values.initials, 
                      Company: company, 
                      OperatorId: (this.props.auth && this.props.auth.operator) ? this.props.auth.operator : undefined,                    
                      Country: values.country,
                      Street: values.street,
                      City: values.city,
                      State: values.state,
                      Zip: values.zip,
                      Notes: values.notes,
                      AccountType: values.type,
                      StateManager: values.type === 'Driver' ? values.stateManager : false,
                      ClientIds: clientIds, 
                      DivisionIds: divisionIds,
                      NotificationGroups: notificationGroupsString,
                      NotificationGroupsList: (values.type.toLowerCase() === 'globaladministrator' || this.state.selectedNotificationGroups.length === 0) ? null : this.state.selectedNotificationGroups,
                      TerritoryPostcodes: values.postCodes,
                      Status: values.accountStatus === true ? "active" : "inactive",
                      ResendTempPassword: values.resendTempPassword                      
                  }
              ]
              }
      };
      //console.log(JSON.stringify(myInit));
      const result = await API.post("api", "/accounts", myInit);
      if(result.result === 'success')
          this.setState({redirect:'/accounts'});
      else{
        actions.setStatus({msg: result.errors[0]});
        actions.setSubmitting(false);
      }
    }catch(error)
    {
        console.log('error', error.message);
        actions.setStatus({msg: error.message});
        actions.setSubmitting(false);
    }
  }
  render() {
    const schema = yup.object().shape({
      firstName: yup.string()
            .required('Required'),
      lastName: yup.string()
            .required('Required'),
      email: yup.string()
            .email('Must be a valid email')
            .required('Required'),
      mobile: yup.string()
            .required('Required'),
      initials: yup.string()
            .max(3, 'Custom Initials can be of max 3 characters'),
      type: yup.string()
            .required('Required'),
      state: yup.string()
            .required('Required'),
      country: yup.string()
            .required('Required'),
      street: yup.string()
            .required('Required'),
      city: yup.string()
            .required('Required'),
      zip: yup.string()
            .required('Required'),
      companyArray: yup.array()
            .ensure()
            .test('ok', 'Required', function(value){
            if(this.parent.type === 'OfficeAdministrator' && value.length === 0){
              return false;
            }              
            return true;
            }),
      divisionArray: yup.array()
            .ensure()
            .test('ok', 'Required', function(value){
            if(this.parent.type === 'OfficeUser' && this.parent.company !== '' && value.length === 0){
              return false;
            }              
            return true;
            }),
      company: yup.string()
            .ensure()
            .test('ok', 'Required', function(value){
            if(this.parent.type === 'OfficeUser' && value === ''){
              return false;
            }              
            return true;
            }),
    });
    if (this.state.redirect) 
        return <Redirect to={{pathname: this.state.redirect}} />
    if(this.state.staff === null || this.state.clients === null || this.state.divisions === null || this.state.notificationGroups === null)
      {
          return(
            <div><br/><br/><br/>
              <Spinner animation="border" />
            </div>
          );
      }
      else{
        return (
            <Formik
                validationSchema={schema}
                onSubmit={async (values, actions) => {
                    await this.createAccount(values, actions);
                }}
                
                initialValues={{
                firstName: this.state.staff.FirstName,
                lastName: this.state.staff.LastName,
                email: this.state.staff.Email,
                //this.getClientArray().filter(option => values.companyArray.indexOf(option.value) >= 0)
                companyArray: this.state.staff.AccountType.toLowerCase() === 'officeadministrator' ? this.state.staff.ClientIds : [],
                divisionArray: this.state.staff.AccountType.toLowerCase() === 'officeuser' ? this.state.staff.DivisionIds.map(d => d.divisionId) : [],
                company: this.state.staff.AccountType.toLowerCase() === 'officeuser' ? this.state.staff.ClientIds[0] : '',
                mobile: this.state.staff.MobileNumber,
                landline: this.state.staff.LandlineNumber !== null ? this.state.staff.LandlineNumber : '',
                initials: this.state.staff.CustomInitials !== null ? this.state.staff.CustomInitials : '',
                type: this.state.staff.AccountType,
                country: this.state.staff.Country,
                //notificationGroups: this.state.staff.NotificationGroups === null ? [] : this.state.staff.NotificationGroups.map(n => n.ItemId),
                postCodes:this.state.staff.TerritoryPostcodes !== null ? this.state.staff.TerritoryPostcodes : '',
                street:this.state.staff.Street,
                city:this.state.staff.City,
                zip:this.state.staff.Zip,
                state:this.state.staff.State,
                notes:this.state.staff.Notes !== null ? this.state.staff.Notes : '',
                stateManager:this.state.staff.StateManager,
                accountStatus: this.state.staff.Status.toLowerCase() === "active" ? true : false,
                resendTempPassword: false
                }}
            >
                {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isSubmitting,
                status,
                setFieldValue
                }) => (
                <Container className="no-left-right-margin form-text-align">
                    <Form onSubmit={handleSubmit} >
                    <Card>
                    <Card.Header><h4>Edit Staff</h4></Card.Header>
                    <Card.Body>
                        <Form.Row>                            
                        <Form.Group as={Col} controlId="firstName" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">First Name</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"                                
                                value={values.firstName}
                                name="firstName"
                                placeholder="First Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.firstName}
                                touched={touched.firstName}
                                isInvalid={errors.firstName && touched.firstName}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.firstName}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="lastName" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Last Name</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={values.lastName}
                                name="lastName"
                                placeholder="Last Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.lastName}
                                touched={touched.lastName}
                                isInvalid={errors.lastName && touched.lastName}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.lastName}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="email" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">eMail</Form.Label>
                            <Form.Control
                                readOnly
                                size="sm"
                                type="text"
                                value={values.email}
                                name="email"
                                placeholder="eMail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.email}
                                touched={touched.email}
                                isInvalid={errors.email && touched.email}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>                            
                        <Form.Group as={Col} xs={4} controlId="mobile" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Mobile #</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={values.mobile}
                                name="mobile"
                                placeholder="Mobile #"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.mobile}
                                touched={touched.mobile}
                                isInvalid={errors.mobile && touched.mobile}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.mobile}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xs={4} controlId="landline" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Landline #</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={values.landline}
                                name="landline"
                                placeholder="Landline #"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.landline}
                                touched={touched.landline}
                                isInvalid={errors.landline && touched.landline}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.landline}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xs={4} controlId="initials" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Custom Initials</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={values.initials}
                                name="initials"
                                placeholder="Custom Initials"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.initials}
                                touched={touched.initials}
                                isInvalid={errors.initials && touched.initials}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.initials}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                    <Form.Group as={Col} xs={8} className="margin-bottom-8">
                    <Form.Row> 
                        <UserConsumer>
                            {userType => {
                                return (
                                    <Form.Group as={Col} xs={6} controlId="type" className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">Staff Type</Form.Label>
                                        <Form.Control as="select"
                                            disabled={userType.toLowerCase() !== 'globaladministrator' && this.state.staff.AccountType.toLowerCase() === 'globaladministrator'}
                                            size="sm"
                                            value={values.type}
                                            name="type"
                                            placeholder="Mobile #"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.type}
                                            touched={touched.type}
                                            isInvalid={errors.type && touched.type}
                                        >
                                            <option value="">Choose...</option>
                                            {
                                                (userType.toLowerCase() === 'globaladministrator' || this.state.staff.AccountType.toLowerCase() === 'globaladministrator') &&
                                                <option value="GlobalAdministrator">Global Administrator</option>
                                            }
                                            <option value="CompanyAdministrator">Company Administrator</option>
                                            <option value="OfficeAdministrator">Office Administrator</option>
                                            <option value="OfficeUser">Office User</option>
                                            <option value="Driver">Employee</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.type}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )
                            }}
                        </UserConsumer>
                    </Form.Row>
                    <Collapse in={values.type === 'Driver'}>
                    <Form.Row>
                        <Form.Group className="margin-left-10">
                        <Form.Check 
                            className="med-font margin-bottom-zero"
                            defaultChecked={values.stateManager}
                            type="switch"
                            id="stateManager"
                            label="State Manager"
                            value={values.stateManager}
                            onChange={handleChange}
                            />
                        </Form.Group>
                    </Form.Row>
                    </Collapse>
                    <Collapse in={values.type === 'OfficeAdministrator'}>
                    <Form.Row>
                        <Form.Group as={Col} xs={12} controlId="companyArray" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Company</Form.Label>
                            <Select 
                                // isDisabled={true}
                                className="basic-multi-select" 
                                classNamePrefix="select" 
                                isMulti 
                                options={this.getClientArray()} 
                                name="companyArray"                                                        
                                value={this.getClientArray().filter(option => values.companyArray.indexOf(option.value) >= 0)}
                                onChange={(option) => {
                                //console.log('companyChange', option);
                                setFieldValue('companyArray', option ? option.map((item,index) => item.value) : []);
                                }}
                                error={errors.companyArray}
                                touched={touched.companyArray}
                            />                            
                            {errors.companyArray && <div className="invalid-feedback-persist">{errors.companyArray}</div>}
                        </Form.Group>
                    </Form.Row>
                    </Collapse>
                    <Collapse in={values.type === 'OfficeUser'}>
                    <Form.Row>
                        <Form.Group as={Col} xs={12} controlId="company" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Company</Form.Label>
                            <Form.Control as="select"                              
                                size="sm"
                                value={values.company}
                                name="company"
                                placeholder="Company"
                                onChange={(e) => {
                                    //console.log('onchange', e);
                                    setFieldValue('divisionArray', []);
                                    this.setOfficeUserAccess([]);
                                    // this.syncInvoiceAccessWithSelectedDivisions([]);
                                    // this.syncJobAccessWithSelectedDivisions([]);
                                    handleChange(e);
                                    //this.fetchDivisions(e.target.value);
                                }}
                                onBlur={handleBlur}
                                error={errors.company}
                                touched={touched.company}
                                isInvalid={errors.company && touched.company}
                            >
                                <option value="">Choose...</option>
                                {this.getClients()}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.company}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>   
                    </Collapse> 
                    <Collapse in={values.type === 'OfficeUser'}>                                 
                    <Form.Row>
                        <Form.Group as={Col} xs={12} controlId="divisionArray" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Division</Form.Label>
                            <Select 
                                isDisabled={values.company !== '' ? false : true}
                                className="basic-multi-select" 
                                classNamePrefix="select" 
                                isMulti 
                                options={values.company !== '' ? this.getDivisionArray(values.company) : []} 
                                name="divisionArray"                            
                                value={values.company !== '' ? this.getDivisionArray(values.company).filter(option => values.divisionArray.indexOf(option.value) >= 0) : []}
                                onChange={(option) => {
                                this.setOfficeUserAccess(option);
                                setFieldValue('divisionArray', option ? option.map((item,index) => item.value) : []);
                                }}
                                error={errors.divisionArray}
                                touched={touched.divisionArray}
                            />                        
                            {errors.divisionArray && <div className="invalid-feedback-persist">{errors.divisionArray}</div>}
                        </Form.Group>
                    </Form.Row> 
                    </Collapse>                                                           
                    <Form.Row> 
                        {values.type.toLowerCase() !== 'globaladministrator' &&                            
                        <Form.Group as={Col} xs={6} controlId="notificationGroups" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Notification Groups</Form.Label>
                            <Select 
                                // isDisabled={true}
                                className="basic-multi-select" 
                                classNamePrefix="select" 
                                isMulti 
                                options={this.getNotificationGroupsArray()} 
                                name="notificationGroups"                                                        
                                value={this.getNotificationGroupsArray().filter(option => this.state.selectedNotificationGroups.indexOf(option.value) >= 0)}
                                onChange={(option) => {
                                    console.log('onChange', option);
                                    this.setState({selectedNotificationGroups:option ? option.map((item,index) => item.value) : []});                                            
                                }}
                            />   
                        </Form.Group>
                        }
                        {values.type.toLowerCase() === 'driver' &&
                        <Form.Group as={Col} xs={6} controlId="postCodes" className="margin-bottom-8">
                            <Form.Label className="med-font margin-bottom-zero">Territory Post Codes</Form.Label>
                            <AutoResizeTextArea onChange={setFieldValue} value={values.postCodes} valueType='postCodes' placeholder='Enter assigned postcodes ...'/>
                            {/* <Form.Control
                                size="sm"
                                type="text"
                                value={values.postCodes}
                                name="postCodes"
                                placeholder="Territory Post Codes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.postCodes}
                                touched={touched.postCodes}
                                isInvalid={errors.postCodes && touched.postCodes}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.postCodes}
                            </Form.Control.Feedback> */}
                        </Form.Group>
                        }
                    </Form.Row>  
                    </Form.Group>
                    <Collapse in={values.type === 'OfficeUser' && values.divisionArray.length > 0}>  
                    <Form.Group as={Col} xs={4}>
                        <Card>
                        <Card.Header>Office User Access</Card.Header>
                        <Card.Body className="office-user-container padding-top-8 padding-left-40">
                        <span className="med-font bold-font">Invoices</span>
                        <div key={'ivoice-all'}>
                            <Form.Check 
                            custom
                            checked={this.state.allInvoiceAccess}
                            type={'checkbox'}
                            id={'invoice-all'}
                            label='All'
                            className='med-font'
                            onChange={e => this.handleAllInvoiceClicked(e)}
                            />
                        </div>
                        {/* {values.divisionArray.map((item, index) => ( */}
                        {this.state.officeUserAccess.map((item, index) => ( 
                            <div key={`ivoice-${index}`}>                            
                            <Form.Check 
                                custom
                                checked={item.invoice}
                                type={'checkbox'}
                                id={`ivoice-${item.value}`}
                                label={this.getDivisionArray(values.company).filter(option => option.value === item.value)[0].label}
                                className='med-font'
                                onChange={e => this.handleInvoiceAccess(e, item.value)}
                            />                                                     
                            </div>
                        ))}
                        <span className="med-font bold-font">Jobs / Pins</span>
                        <div key={'job-all'}>
                            <Form.Check 
                            custom
                            checked={this.state.allJobAccess}
                            type={'checkbox'}
                            id={'job-all'}
                            label='All'
                            className='med-font'
                            onChange={e => this.handleAllJobClicked(e)}
                            />
                        </div>
                        {/* {values.divisionArray.map((item, index) => ( */}
                        {this.state.officeUserAccess.map((item, index) => (
                            <div key={`jobs-${index}`}>
                            <Form.Check 
                                custom
                                checked={item.job}
                                type={'checkbox'}
                                id={`jobs-${item.value}`}
                                label={this.getDivisionArray(values.company).filter(option => option.value === item.value)[0].label}
                                className='med-font'
                                onChange={e => this.handleJobAccess(e, item.value)}
                            />                          
                            </div>
                        ))}
                        </Card.Body>
                        </Card>                    
                    </Form.Group>
                    </Collapse>
                    </Form.Row> 
                    <Form.Row>
                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                            <Form.Label><h5>Personal Address</h5></Form.Label>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="country" className="margin-bottom-8">
                        <Form.Label className="med-font margin-bottom-zero">Country</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={values.country}
                            name="country"
                            placeholder="Country"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.country}
                            touched={touched.country}
                            isInvalid={errors.country && touched.country}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.country}
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="street" className="margin-bottom-8">
                        <Form.Label className="med-font margin-bottom-zero">Street Address</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={values.street}
                            name="street"
                            placeholder="Street Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.street}
                            touched={touched.street}
                            isInvalid={errors.street && touched.street}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.street}
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="city" className="margin-bottom-8">
                        <Form.Label className="med-font margin-bottom-zero">City</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={values.city}
                            name="city"
                            placeholder="City"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.city}
                            touched={touched.city}
                            isInvalid={errors.city && touched.city}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.city}
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={4} controlId="zip" className="margin-bottom-8">
                        <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={values.zip}
                            name="zip"
                            placeholder="Postcode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.zip}
                            touched={touched.zip}
                            isInvalid={errors.zip && touched.zip}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.zip}
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xs={4} controlId="state" className="margin-bottom-8">
                        <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                        <Form.Control as="select"
                            size="sm"
                            value={values.state}
                            name="state"
                            placeholder="State"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.state}
                            touched={touched.state}
                            isInvalid={errors.state && touched.state}
                        >
                            <option value=''>Choose...</option>
                            <option>Queensland</option>
                            <option>New South Wales</option>
                            <option>Australian Capital Territory</option>
                            <option>Victoria</option>
                            <option>South Australia</option>
                            <option>Western Australia</option>
                            <option>Tasmania</option>
                            <option>Northern Territory</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.state}
                        </Form.Control.Feedback>
                        </Form.Group>                    
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={8} controlId="notes" className="margin-bottom-8">
                        <Form.Label className="med-font margin-bottom-zero">Notes</Form.Label>
                        <Form.Control as="textarea" rows="3" 
                            size="sm"
                            placeholder="Notes" 
                            name="notes"
                            value={values.notes}
                            onChange={handleChange} 
                        />
                        </Form.Group>
                    </Form.Row>  
                    {this.state.staff.UserStatus === 'FORCE_CHANGE_PASSWORD' &&
                    <Form.Row>
                        <Form.Group as={Col} controlId="resendTempPassword" className="margin-bottom-8">
                            <Form.Check 
                            className="med-font margin-bottom-zero"
                            type="switch"
                            id="resendTempPassword"
                            label="Resend Temporary Password"
                            defaultChecked={values.resendTempPassword}
                            value={values.resendTempPassword}
                            onChange={handleChange}                                        
                            />                                        
                        </Form.Group>
                    </Form.Row>}
                    <Form.Row>
                        <Form.Group as={Col} className="margin-bottom-8">
                        <Form.Check 
                            className="med-font margin-bottom-zero"
                            defaultChecked={values.accountStatus}
                            type="switch"
                            id="accountStatus"
                            label="Enabled"
                            value={values.accountStatus}
                            onChange={handleChange}
                            />
                        </Form.Group>
                    </Form.Row>
                    {status && status.msg && 
                    <Form.Row>
                        <Form.Group>
                            <Form.Label className="error-msg">{status.msg}</Form.Label>
                        </Form.Group>
                    </Form.Row>
                    }
                    </Card.Body>
                    <Card.Footer>
                        <Form.Row>                                
                            <span className="d-inline-block">
                            <Button variant="primary" type="submit" disabled={isSubmitting} size="sm">
                            {isSubmitting ? 'Loading…' : 'Save'}
                            </Button>
                            </span>
                            &nbsp;&nbsp;
                            <span className="d-inline-block">
                                <Button as={Link} to="/accounts" variant="primary" size="sm">
                                Cancel
                                </Button>
                            </span>
                        </Form.Row>
                    </Card.Footer>
                    </Card>
                    </Form>
                </Container>
                )}
                </Formik>
        )
      }
      
  }
}