import React, { Component } from 'react';
import {Table, Spinner, Navbar, Form, FormGroup, FormControl, Button, Popover, Modal} from 'react-bootstrap';
import Clients from '../Clients';
import Divisions from '../Divisions';
import Locations from '../Locations';
import AddEditClient from '../AddEditClient';
import AddEditDivision from '../AddEditDivision';
import AddEditLocation from '../AddEditLocation';
import ErrorBoundary from './ErrorBoundary';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';

export default class ClientsAndDivisionsWrapper extends Component {

    render() {
        return (
            <Switch>
                <Route
                path="/clients"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <Clients {...props} navProp={this.props.navProp}/>
                    </ErrorBoundary>
                                    }
                />
                <Route
                path={["/clients/editClient/:clientId","/clients/addClient"]}
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <AddEditClient {...props} navProp={this.props.navProp} />
                    </ErrorBoundary>
                                    }
                />
                <Route
                path="/clients/divisionList/:clientId"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <Divisions {...props} navProp={this.props.navProp} id={this.props.id}/>
                    </ErrorBoundary>
                                    }
                />
                <Route
                path={["/clients/editDivision/:divisionId","/clients/addDivision/:clientId","/clients/addDivision"]}
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <AddEditDivision {...props} navProp={this.props.navProp} id={this.props.id}/>
                    </ErrorBoundary>
                                    }
                />
                <Route
                path="/clients/locationList/:divisionId"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <Locations {...props} navProp={this.props.navProp}/>
                    </ErrorBoundary>
                                    }
                />
                <Route
                path={["/clients/addLocation","/clients/addLocation/:divisionId","/clients/editLocation/:locationId"]}
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <AddEditLocation {...props} navProp={this.props.navProp}/>
                    </ErrorBoundary>
                                    }
                />
                <Route render={() => <Redirect to={{pathname: "/clients"}} />} />   
            </Switch>
        );
  }
}

