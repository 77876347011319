import React, { useState, useEffect } from 'react';
import {Navbar, Form, Button, Alert} from 'react-bootstrap';
import {authorizationHeader, compareValues} from './utility/Helper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from "moment";
import "moment-timezone";
import { API } from "aws-amplify";
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';

function KPIReport(props) {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [divisions, setDivisions] = useState([]);
    const [selectedClientDivisions, setSelectedClientDivisions] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchClients();
        fetchDivisions();
    },[])

    useEffect(() => {
        setSelectedClientDivisions(divisions.filter(d => d.ParentItemId === selectedClient).sort(compareValues('Name', 'asc')));
    },[selectedClient])

    const fetchClients = async() => {
        try{
            const authHeader = await authorizationHeader();
            const res = await API.get("api", "/clients?ForInvoicePage=true", authHeader);
            setClients(res.clients.sort(compareValues('Name', 'asc')));
        }catch(err) {
            setError(err.toString().toLowerCase());
        }
    }

    const fetchDivisions = async() => {
        try{
            const authHeader = await authorizationHeader();
            const res = await API.get("api", "/divisions", authHeader);
            setDivisions(res.divisions.sort(compareValues('Name', 'asc')));
        }catch(err) {
            setError(err.toString().toLowerCase());
        }
    }

    const getClientOptions = () => {   
        if(clients.length > 0){
            return clients.map((c) => {
                const { Name, ItemId } = c
                return (
                    <option key={ItemId} value={ItemId} >{Name}</option>
                )
             });
        }else{
            return (
                <option key='' value='' >loading...</option>
            )
        }        
    }

    const getDivisionOptions = () => {
        if(divisions.length > 0){
            if(selectedClient.length === 0){
                return (
                    <option key='' value='' >Please select client first...</option>
                )
            }else{
                return selectedClientDivisions.map((c) => {
                    const { Name, ItemId } = c
                    return (
                        <option key={ItemId} value={ItemId} >{Name}</option>
                    )
                });
            }
        }else{
            return (
                <option key='' value='' >loading...</option>
            )
        }        
    }

    if(error.length > 0){
        return(
            <Alert variant='danger'>
                {error.indexOf('network error') > -1 ? 'Network Error encountered. Kindly check internet connection and refresh the page.' : 'Error encountered. Please refresh the page to try again.'}
            </Alert>
        );        
    }

    const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const generateReport = async(values, actions) => {
        try{
            // console.log('submit');
            // console.log(moment(values.reportDate).endOf('month').format('YYYY-MM-DD') + ' 23:59:59');
            const clientName = clients.filter(c => c.ItemId === values.selectedClient)[0].Name;
            const authHeader = await authorizationHeader();
            const endPoint = values.fileType.toLowerCase() === "pdf" ? "/kpiPDFReport" : "/kpiData"
            const res = await API.get("api", endPoint + "?date=" + moment(values.reportDate).format('YYYY-MM-DD') + "&deliveryType=" + values.deliveryType + "&client=" + values.selectedClient + "&division=" + values.division, authHeader);
            console.log("kpi", res);
            if(values.fileType.toLowerCase() === "pdf"){
                saveAs(b64toBlob(res.base64String), 'KPI_' + clientName.replace(/[^a-zA-Z0-9.]/g, '') + '_' + moment(values.reportDate).format("MMMM/YYYY").replace("/", "_").replace("/", "_") + '.pdf');
            }else{
                let workSheet;
                let row;
                const workBook = new ExcelJS.Workbook();
                workSheet = workBook.addWorksheet(values.selectedClient);
                workSheet.columns = [
                    { header: 'Consignment ID', key: 'Consignment ID', width: 15 },
                    { header: 'Division', key: 'Division', width: 10 },
                    { header: 'Deliver To', key: 'Deliver To', width: 10},
                    { header: 'Manifest Upload', key: 'Manifest Upload', width: 10},
                    { header: 'Delivered Time', key: 'Delivered Time', width: 10},
                    { header: 'POD', key: 'POD', width: 10},
                ];
                row = workSheet.lastRow;
                row.eachCell(function(cell, colNumber) {            
                    cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thick'},right: {style:'thin'}};
                    cell.font = {bold: true};
                });
                res.jobs.forEach((j,index) => {
                    let uploadTime = '';
                    let deliveredTime = moment(j.DeliveredTime).tz("Australia/Sydney").format('HH:mm Do MMM YYYY')

                    if(j.CreatedUTCValid === true && j.CreatedUTC.length > 0)
                    {
                        uploadTime = moment(j.CreatedUTC).tz("Australia/Sydney").format('HH:mm Do MMM YYYY');
                    }else{
                        if(j.Created && j.Created.length > 0)
                        {
                            uploadTime = moment(j.Created).format('HH:mm Do MMM YYYY');
                        }
                    }

                    workSheet.addRow([j.ConsignmentNumber,j.Division,j.Name,uploadTime,deliveredTime,"https://app.fitlogistics.com.au/publicDownload?id=" + j.ItemId]);
                    row = workSheet.lastRow;
                    row.eachCell(function(cell, colNumber) {
                        cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
                    });
                });
                const buf = await workBook.csv.writeBuffer()
                saveAs(new Blob([buf]), 'KPI_' + clientName.replace(/[^a-zA-Z0-9.]/g, '') + '_' + moment(values.reportDate).format("MMMM/YYYY").replace("/", "_").replace("/", "_") + '.csv');
            }
            
        }catch(err) {
            console.log(err.toString());
            setError(err.toString().toLowerCase());
        }       
        
        actions.setSubmitting(false);
    }

    const schema = yup.object().shape({
        selectedClient: yup.string()
            .required('Required'),
        division: yup.string()
            .required('Required')
    });

    return(
        <Formik
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                await generateReport(values, actions);
            }}
            
            initialValues={{
            selectedClient: '',
            division: selectedClientDivisions.length > 0 ? 'all' : '',
            reportDate: new Date(),
            deliveryType: 'All',
            fileType: 'pdf'
            }}
        >
            {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
            setFieldValue
            }) => (
            <Form onSubmit={handleSubmit} >
                <Navbar className="bg-light padding-top-bottom-zero">
                    <Navbar.Brand href="/">
                        <h4 className="padding-top-bottom-zero">KPI Report</h4>
                    </Navbar.Brand>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                    <Form inline>
                        <Form.Label className="margin-bottom-zero mr-sm-2">Client</Form.Label>                            
                        <Form.Control as="select"
                            size="sm"
                            className="mr-sm-3"
                            onChange={(e) => {
                                setSelectedClient(e.target.value);
                                setFieldValue('selectedClient', e.target.value);
                                setFieldValue('division', divisions.filter(d => d.ParentItemId === e.target.value).length > 0 ? 'all' : '');
                            }}
                            name="selectedClient"
                            onBlur={handleBlur}
                            error={errors.selectedClient}
                            touched={touched.selectedClient}
                            isInvalid={errors.selectedClient && touched.selectedClient}
                        >
                            {clients.length > 0 &&
                            <option key='' value='' >Please select...</option>}
                            {getClientOptions()}
                        </Form.Control>
                        <Form.Control.Feedback className="error-inline" type="invalid">
                            {errors.selectedClient}
                        </Form.Control.Feedback>
                    </Form>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                    <Form inline>
                        <Form.Label className="margin-bottom-zero mr-sm-2">Divisions</Form.Label>                            
                        <Form.Control as="select"
                            size="sm"
                            className="mr-sm-3"
                            name="division"
                            value={values.division}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={errors.division}
                            touched={touched.division}
                            isInvalid={errors.division && touched.division}
                        >
                            {selectedClientDivisions.length > 0 &&
                            <option key='all' value='all' >All</option>}
                            {getDivisionOptions()}
                        </Form.Control>
                        <Form.Control.Feedback className="error-inline" type="invalid">
                            {errors.division}
                        </Form.Control.Feedback>
                    </Form>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                    <Form inline>
                        <Form.Label className="margin-bottom-zero mr-sm-2">Month/Year</Form.Label>                            
                        <DatePicker 
                        dateFormat="MMMM/yyyy" 
                        selected={values.reportDate} 
                        onChange={date => setFieldValue('reportDate', date)}
                        minDate={new Date('2020-01-01')}
                        maxDate={new Date()}
                        showMonthYearPicker
                        className='form-control form-control-sm'
                        />
                    </Form>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                    <Form inline>
                        <Form.Label className="margin-bottom-zero mr-sm-2">Delivery Type</Form.Label>                            
                        <Form.Control as="select"
                            size="sm"
                            className="mr-sm-3"
                            name="deliveryType"
                            value={values.deliveryType}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={errors.deliveryType}
                            touched={touched.deliveryType}
                            isInvalid={errors.deliveryType && touched.deliveryType}
                        >
                            <option key='All' value='All' >All</option>
                            <option key='Same Day' value='Same Day' >Same Day</option>
                            <option key='Next Day' value='Next Day' >Next Day</option>
                        </Form.Control>
                    </Form>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                    <Form inline>
                        <Form.Label className="margin-bottom-zero mr-sm-2">File Type</Form.Label>                            
                        <Form.Control as="select"
                            size="sm"
                            className="mr-sm-3"
                            name="fileType"
                            value={values.fileType}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={errors.fileType}
                            touched={touched.fileType}
                            isInvalid={errors.fileType && touched.fileType}
                        >
                            <option key='pdf' value='pdf' >PDF</option>
                            <option key='csv' value='csv' >CSV</option>
                        </Form.Control>
                    </Form>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                    <Button variant="success" type="submit" disabled={isSubmitting} size="sm">
                        {isSubmitting ? 'Downloading…' : 'Download'}
                    </Button>
                </Navbar>
            </Form>
            )}
        </Formik>
    )
}

export default KPIReport