import React, { Component } from 'react';
import {Button, Modal} from 'react-bootstrap';
import AddAccount from '../AddAccount';
import AddJob from '../AddJob';
import AddLocation from '../AddLocation';
import Reassign from '../Reassign';
import UpdateStatus from '../UpdateStatus';
import DeleteJobs from '../DeleteJobs';
import Reschedule from '../Reschedule';
import ChecklistResponse from '../ChecklistResponse';

export default class ModalWrapper extends Component {

    renderBody() {
        if(this.props.type === "addAccount")
        {
            return (<AddAccount onHide={this.props.onHide} refresh={this.props.refresh}/>)
        }
        if(this.props.type === "addJob")
        {
            return (<AddJob onHide={this.props.onHide} refresh={this.props.refresh}/>)
        }
        if(this.props.type === "addLocation")
        {
            return (<AddLocation onHide={this.props.onHide} update={this.props.update}/>)
        }
        if(this.props.type === "message")
        {
            return (<span>Successfully Saved!</span>)
        }
        if(this.props.type === "note")
        {
            return (<span>{this.props.note}</span>)
        }
        if(this.props.type === "reassign")
        {
            return (<Reassign jobs={this.props.jobs} staffs={this.props.staffs} onHide={this.props.onHide} refresh={this.props.refresh}/>)
        }
        if(this.props.type === "updateStatus")
        {
            return (<UpdateStatus jobs={this.props.jobs} status={this.props.status} onHide={this.props.onHide} refresh={this.props.refresh}/>)
        }
        if(this.props.type === "deleteJobs")
        {
            return (<DeleteJobs jobs={this.props.jobs} onHide={this.props.onHide} refresh={this.props.refresh}/>)
        }
        if(this.props.type === "reschedule")
        {
            return (<Reschedule jobs={this.props.jobs} onHide={this.props.onHide} refresh={this.props.refresh}/>)
        }
        if(this.props.type === "checklist")
        {
            return (<ChecklistResponse date={this.props.deliverydate} driverid={this.props.driverid}/>)
        }
     }

    render() {
        return (
        <Modal
            {...this.props}
            backdrop= "static"
            size={this.props.size ? this.props.size : ((this.props.type === 'reassign' || this.props.type === 'updateStatus' || this.props.type === 'deleteJobs' || this.props.type === 'reschedule') && this.props.jobs.length > 0 ? 'md' : 'sm')}
            dialogClassName={this.props.size && this.props.size === "xl" ? "modal-max-width" : ""}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{ background: this.props.headercolor }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {this.props.modaltitle}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.props.type && this.renderBody()}
            </Modal.Body>
            {(this.props.type === 'note' || (this.props.type === 'reassign' && this.props.jobs.length === 0) || (this.props.type === 'updateStatus' && this.props.jobs.length === 0) || (this.props.type === 'deleteJobs' && this.props.jobs.length === 0)) && <Modal.Footer>
                <Button onClick={this.props.onHide}>Close</Button>
            </Modal.Footer>}
        </Modal>
        );
  }
}

