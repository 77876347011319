import React, { useState, useEffect } from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import "moment-timezone";
import { Auth, API } from "aws-amplify";
import LoadingOverlay from 'react-loading-overlay';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';

function RemainingJobsButton(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [error, setError] = useState('');
    const [fromDate, setFromDate] = useState(props.fromDate ? props.fromDate : new Date());
    const [toDate, setToDate] = useState(props.toDate ? props.toDate : new Date());
    const [state, setState] = useState('');

    useEffect(() => {
        if(show)
            fetchJobs();
    },[fromDate, toDate, props.fromDate, props.toDate, state])

    useEffect(() => {
        setFromDate(props.fromDate);
        setToDate(props.toDate);
    },[props.fromDate, props.toDate, show])

    const fetchJobs = async () => {
        setIsProcessing(true);
        setShow(true);
        setError('');
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            // let res = await API.get("api", "/accounts?AccountType=Driver", myInit);
            // const drivers = res.accounts;
            // console.log(drivers);
            const from = moment(fromDate).format('YYYY-MM-DD');
            const to = moment(toDate).format('YYYY-MM-DD') + ' 23:59:59';
            let filters = '?Where=DeliveryDate~between~' + from + '|' + to;
            filters = filters + "&state=" + state;
            filters = filters + "&export=false";
            let res = await API.get("api", "/remainingjobs" + filters, myInit);
            const remainingJobs = res.remainingjobs;            
            setJobs(remainingJobs.filter(j => j.totalJobs > 0));
        }catch(error) {
            setError(error.toString().toLowerCase());
        }  
        setIsProcessing(false);      
    }

    const download = async () => {
        setIsProcessing(true);
        let workSheet;
        let row;
        const workBook = new ExcelJS.Workbook();
        workSheet = workBook.addWorksheet('Remaining Jobs');
        if(state !== '')
        {
            workSheet.columns = [
                { header: 'Driver', key: 'driver', width: 15 },
                { header: 'Remaining Jobs', key: 'remaining', width: 10},
                { header: 'Total Jobs', key: 'total', width: 10},
            ];
            row = workSheet.lastRow;
            row.eachCell(function(cell, colNumber) {            
                cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thick'},right: {style:'thin'}};
                cell.font = {bold: true};
            });
            jobs.forEach((j,index) => {
                workSheet.addRow([j.driver,j.remainingJobs,j.totalJobs]);
                row = workSheet.lastRow;
                row.eachCell(function(cell, colNumber) {
                    cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
                });
            });
        }
        else{
            ['Victoria','New South Wales','Queensland','Western Australia','Australian Capital Territory','South Australia','Tasmania','Northern Territory'].forEach(s => {
                workSheet.addRow([s + ':','','']);
                workSheet.addRow(['Driver','Remaining Jobs','Total Jobs']);
                jobs.filter(j => j.state === s).forEach((j,index) => {
                    workSheet.addRow([j.driver,j.remainingJobs,j.totalJobs]);
                    row = workSheet.lastRow;
                    row.eachCell(function(cell, colNumber) {
                        cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
                    });
                });
                workSheet.addRow('');
            });
        }
        const buf = await workBook.csv.writeBuffer()
        saveAs(new Blob([buf]), 'RemainingJobs_' + moment(new Date()).format("DD/MM/YYYY").replace("/", "_").replace("/", "_") + '.csv');
        setIsProcessing(false);
    }

    return(
        <React.Fragment>
            <Button variant="success" size="sm" className="mr-sm-1" onClick={() => {fetchJobs();}} disabled={props.disabled ? props.disabled : false}>
                <i className="fas fa-eye" style={{ fontSize: '1em' }} /> Remaining Jobs
            </Button>
            <Modal
                size="md"
                show={show}
                onHide={() => setShow(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Remaining Jobs
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            <Button variant="success" size="sm"  className="mr-sm-5" onClick={() => download()} disabled={isProcessing || error.length > 0}>
                                <i className="fa fa-download" style={{ fontSize: '1em' }} /> Download
                            </Button>
                            <Form.Label className="mr-sm-2">From</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={fromDate} maxDate={toDate} onChange={value => setFromDate(value)} className='form-control form-control-sm mr-sm-3 width-100' placeholderText='Select from date'/>
                            <Form.Label className="mr-sm-2">To</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={toDate} minDate={fromDate} onChange={value => setToDate(value)} className='form-control form-control-sm width-100' placeholderText='Select to date'/>
                        </Form.Group>
                        <Form.Group as={Col} xs={12}>
                            <Form.Row>
                                <Form.Group as={Col} xs={1} className="margin-bottom-zero">
                                    <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} className="margin-bottom-zero">
                                    <Form.Control as="select"
                                        size="sm"                                        
                                        name="state"
                                        placeholder="State"
                                        onChange={(e) => setState(e.target.value)}
                                    >   
                                    <option value=''>All</option>                                 
                                    <option>Victoria</option>
                                    <option>New South Wales</option>
                                    <option>Queensland</option>
                                    <option>Western Australia</option>
                                    <option>Australian Capital Territory</option>                                   
                                    <option>South Australia</option>                                   
                                    <option>Tasmania</option>
                                    <option>Northern Territory</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        {error.length > 0 &&
                            <Alert variant='danger'>
                                {(() => {
                                        if (error.indexOf('network error')) {
                                          return (
                                            <div>Maximum response time/data limit reached. Please try again with lesser date range.</div>
                                          )
                                        } else if (error.indexOf('Unable to expand length')) {
                                          return (
                                            <div>Maximum response limit reached. Please try again with lesser date range.</div>
                                          )
                                        } else if (error.indexOf('timeout')) {
                                            return (
                                              <div>Maximum response time limit reached. Please try again with lesser date range.</div>
                                            )
                                        } else {
                                          return (
                                            <div>Error encountered. Please refresh the page to try again.</div>
                                          )
                                        }
                                })()}
                            </Alert>
                        }
                        {error.length === 0 &&
                            <>
                            {state === '' && <h5>Victoria</h5>}
                            <LoadingOverlay
                            active={isProcessing}
                            spinner
                            text='Loading...'
                            >
                            <BootstrapTable data={state === '' ? jobs.filter(j => j.state === 'Victoria') : jobs} options={{noDataText: '' }}>
                                <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                <TableHeaderColumn dataField='driver' width='250' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                <TableHeaderColumn dataField='remainingJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Remaining Jobs</span></TableHeaderColumn>
                                <TableHeaderColumn dataField='totalJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Total Jobs</span></TableHeaderColumn>
                            </BootstrapTable>
                            </LoadingOverlay>
                            </>
                        } 
                        {error.length === 0 && state === '' &&
                            <>
                                {state === '' && <h5 className='margin-top-20'>New South Wales</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'New South Wales')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='250' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='remainingJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Remaining Jobs</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='totalJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Total Jobs</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Queensland</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Queensland')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='250' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='remainingJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Remaining Jobs</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='totalJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Total Jobs</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Western Australia</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Western Australia')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='250' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='remainingJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Remaining Jobs</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='totalJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Total Jobs</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Australian Capital Territory</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Australian Capital Territory')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='250' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='remainingJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Remaining Jobs</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='totalJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Total Jobs</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>South Australia</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'South Australia')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='250' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='remainingJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Remaining Jobs</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='totalJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Total Jobs</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Tasmania</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Tasmania')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='250' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='remainingJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Remaining Jobs</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='totalJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Total Jobs</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Northern Territory</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Northern Territory')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='250' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='remainingJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Remaining Jobs</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='totalJobs' dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Total Jobs</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>
                            </>
                        }                       
                    </Form.Row>                              
                </Modal.Body>                    
            </Modal>
        </React.Fragment>
    )
}

export default RemainingJobsButton