import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import {Form, Button, Container, Row, Col, FormGroup} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

export default class LogIn extends Component {

    handleSubmit = async (values) => {
        try {
          const user = await Auth.signIn(values.email.toLowerCase(), values.password);
          console.log("loginuser", user);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.props.auth.setUser(user);
            this.props.auth.setAuthStatus(true);
            this.props.auth.setName(user.challengeParam.userAttributes.given_name + ' ' + user.challengeParam.userAttributes.family_name);
            this.props.history.push("/requirechangepassword");
          }else{
            const attr = await Auth.userAttributes(user);
            const operator = attr.filter(att => att.Name === 'custom:operatorId').length > 0 ? attr.filter(att => att.Name === 'custom:operatorId')[0].Value : '';
            this.props.auth.setOperator(operator);
            this.props.auth.setAuthStatus(true);
            this.props.auth.setName(user.attributes.given_name + ' ' + user.attributes.family_name);
            this.props.auth.setUser(user);
            this.props.history.push("/");
          }
    
        }catch(error) {
          console.log("error", error);
          return error.message;
        }
    };
    
    render() {  
      if(this.props.auth.isAuthenticated)
            this.props.history.push("/");
      const schema = yup.object().shape({
          email: yup.string()
            .email('Must be a valid email')
            .required('Required'),
          password: yup.string()
            .required('Required')
      });
        return (
          <Formik
            validationSchema={schema}
            onSubmit={async (values, actions) => {
              const msg = await this.handleSubmit(values);
              actions.setStatus({msg: msg});
              actions.setSubmitting(false);
            }}
            initialValues={{
              email: '',
              password: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              isSubmitting,
              status,
            }) => (
            <Container>
                <Row>
                <Col></Col>
                <Col md={4}>
                    <br/><br/>
                    <Form style={{ border: 'solid thin', padding: '1em' }} className="form-text-align" onSubmit={handleSubmit} >
                        <FormGroup>
                          <Form.Label><h2>Login</h2></Form.Label>
                        </FormGroup>                        
                        
                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                              type="email" 
                              placeholder="Enter email" 
                              name="email"
                              value={values.email} 
                              onChange={handleChange} 
                              onBlur={handleBlur}
                              isInvalid={errors.email && touched.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                              type="password" 
                              placeholder="Password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={errors.password && touched.password} 
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        {status && status.msg && 
                        <Form.Group>
                          <Form.Label className="error-msg">{status.msg}</Form.Label>
                        </Form.Group>}
                        
                        <Button className="margin-bottom-15" variant="primary" type="submit" disabled={isSubmitting}>
                            Submit
                        </Button>
                        <Form.Group>
                          <Link to="/reset">Forgot password?</Link>
                        </Form.Group>
                    </Form>
                  </Col>
                <Col></Col>                    
                </Row>
            </Container>
            )}
            </Formik>
        );
    }
}