import React, { Component } from 'react';
import { Button, Modal, Form, Col, Alert } from 'react-bootstrap';
import moment from "moment";
import "moment-timezone";
import { Auth, API } from "aws-amplify";
import LoadingOverlay from 'react-loading-overlay';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
const htmlToText = require('html-to-text');

export class SendJobSummarySMSModal extends Component {

    state = {
        isProcessing: false,
        modalShow: false,
        jobs: [],
        users: undefined,
        recipients: [],
        allowedRecipients: [],
        jobSummary: "",
        jobSummaryList: [],
        clients: [],
        status: undefined,
        selectedGroup: undefined,
    };

    states = [
        {
            value: "act", label: "ACT", matches: ["Australian Capital Territory", "act"]
        },
        {
            value: "nsw", label: "NSW", matches: ["New South Wales", "nsw"]
        },
        {
            value: "nt", label: "NT", matches: ["Northern Territory", "nt"]
        },
        {
            value: "qld", label: "QLD", matches: ["Queensland", "qld"]
        },
        {
            value: "sa", label: "SA", matches: ["South Australia", "sa"]
        },
        {
            value: "tas", label: "TAS", matches: ["Tasmania", "tas"]
        },
        {
            value: "vic", label: "VIC", matches: ["Victoria", "vic"]
        },
        {
            value: "wa", label: "WA", matches: ["Western Australia", "wa"]
        }
    ];

    setModalShow = (show) => {
        if (show)
            this.fetchJobSummary();
        this.setState({ modalShow: show, status: show === false ? undefined : this.state.status });
    }

    async componentDidMount() {

    }

    async fetchJobSummary() {
        await this.setState({ isProcessing: true });
        console.log(this.state.isProcessing);
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
        };
        let filters = '';
        //const workgroupres = await API.get("api", "/workgroups" + filters, myInit);
        // let selectedgrp = workgroupres.workgroups.filter((g) => g.Name === 'State Managers')[0];
        const usersres = await API.get("api", "/accounts", myInit);
        let members = usersres.accounts.filter((u) => u.StateManager === true).map(m => m.ItemId);
        this.setState({ users: usersres.accounts, recipients: members, allowedRecipients: members });
        const from = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
        const to = moment(new Date()).add(1, 'days').format('YYYY-MM-DD') + ' 23:59:59';
        filters = '?Where=DeliveryDate~between~' + from + '|' + to;
        const jobres = await API.get("api", "/jobs" + filters, myInit);
        this.setState({ jobs: jobres.jobs });        
        const clientres = await API.get('api', '/clients', myInit);
        let cls = clientres.clients.map((c) => {
            return {
                "id": c.ItemId,
                "clientName": c.Name,
                "clientAbbr": c.ClientAbbreviation ? c.ClientAbbreviation : c.Name
            }
        });
        this.setState({ clients: cls });
        await this.createJobSummary();
        await this.setState({ isProcessing: false });
        console.log(this.state.isProcessing);
    }

    async createJobSummary() {
        await new Promise((resolve) => {
            let jobs = this.state.jobs.map((j) => {
                return {
                    state: this.state.users.filter(d => d.AccountType === 'Driver' && d.ItemId === j.DriverId)[0].State,
                    clientAbbr: this.state.clients.filter(c => c.id === j.BillingClientId)[0].clientAbbr
                }
            });
            let summaryList = [];
            for (var i = 0; i < this.states.length; i++) {
                var name = this.states[i].label;
                var stateJobs = jobs.filter(j => this.states[i].matches.includes(j.state));
                var clientJobsObj = {};
                var clientJobs = [];
                stateJobs.forEach(function (j) { clientJobsObj[j.clientAbbr] = (clientJobsObj[j.clientAbbr] || 0) + 1; });

                for (var key in clientJobsObj) {
                    clientJobs.push({
                        state: key,
                        totalCount: clientJobsObj[key]
                    })
                }

                var summary = {
                    state: name,
                    totalCount: stateJobs.length,
                    clientJobs: clientJobs
                }
                summaryList.push(Object.assign({}, summary));
            }
            this.setState({ jobSummaryList: summaryList })
            resolve(true);
        })
    }

    getJobSummaryString() {
        var htmlText = this.state.jobSummaryList.map((j) => {
            return (
                <div>
                    <p className="margin-bottom-zero">{j.state} - {j.totalCount}</p>
                    {j.clientJobs.map((c) => {
                        return (
                            <p className="margin-bottom-zero">{c.state} - {c.totalCount}</p>
                        )
                    })}
                    <p> </p>
                </div>

            )
        });

        setTimeout(() => {
            var summaryText = document.getElementById("SummaryText");
            if (summaryText) {
                var text = htmlToText.fromString(summaryText.innerHTML, {
                    wordwrap: 130,
                    singleNewLineParagraphs: true
                });
                this.setState({ jobSummary: text });
            }
        }, 100);

        return htmlText;
    }

    getUsersArray() {
        return this.state.users.filter(u => this.state.allowedRecipients.indexOf(u.ItemId) > -1).map((user, index) => {
            const { FullName, ItemId, AccountType } = user
            return (
                { value: ItemId, label: FullName + (AccountType.toLowerCase().indexOf('admin') > -1 ? ' - Admin' : '') }
            )
        })
    }

    sendSMS = async (values, actions) => {
        try {
           //const { selectedGroup } = this.state;
            let topic = ''; //selectedGroup.TopicARN;
            // if (values.sendTo === 'admin')
            //     topic = selectedGroup.TopicARNAdmin;
            // if (values.sendTo === 'staff')
            //     topic = selectedGroup.TopicARNStaff;
            const excluded = this.state.allowedRecipients.filter(a => this.state.recipients.indexOf(a) < 0);
            const recipients = this.state.users.filter(u => this.state.recipients.indexOf(u.ItemId) > -1).map(r => {
                return (
                    {
                        name: r.FullName,
                        mobileNumber: r.MobileNumber,
                        id: r.ItemId
                    }
                )
            });
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    sms: [
                        {
                            TopicARN: topic,
                            SMSMessage: this.state.jobSummary,
                            Excluded: excluded,
                            Recipients: recipients
                        }
                    ]
                }
            };
            console.log(JSON.stringify(myInit));
            console.log('excluded', this.state.users.filter(u => excluded.indexOf(u.ItemId) > -1));
            const sendSMS = await API.post("api", "/sms", myInit);
            if (sendSMS.result === 'success') {
                this.setState({ status: sendSMS.result });
                actions.setStatus({ msg: "SMS Sent" });
                actions.setSubmitting(false);
            }
            else {
                actions.setStatus({ msg: sendSMS.errors[0] });
                this.setState({ status: sendSMS.result });
                actions.setSubmitting(false);
            }
        } catch (error) {
            console.log('error', error.message);
            actions.setStatus({ msg: error.message });
            actions.setSubmitting(false);
        }
    }
    render() {
        const schema = yup.object().shape({
            message: yup.string()
        });
        return (
            <Formik
                validationSchema={schema}
                initialValues={{
                    message: '',
                    sendTo: 'all'
                }}
                onSubmit={async (values, actions) => {
                    await this.sendSMS(values, actions);
                }}
            >
                {({ errors, touched, handleBlur, isValidating, handleSubmit, isSubmitting, values, handleChange, setFieldValue, status }) => (


                    <Modal
                        size="md"
                        show={this.state.modalShow}
                        onHide={() => this.setModalShow(false)}
                        backdrop="static"
                    >
                        <LoadingOverlay
                            active={this.state.isProcessing}
                            spinner
                            text='Loading...'
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Send SMS to State Managers
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit}>
                                    {
                                        <React.Fragment>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                                    <Form.Label className="large-font bold-font margin-bottom-zero">Recipients:</Form.Label>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="usersSelect" className="margin-bottom-8">
                                                    {this.state.users &&
                                                        <Select
                                                            className="basic-multi-select form-text-align"
                                                            classNamePrefix="select"
                                                            isMulti
                                                            options={this.getUsersArray()}
                                                            name="usersSelect"
                                                            value={this.getUsersArray().filter(option => this.state.recipients.indexOf(option.value) >= 0)}
                                                            onChange={(option) => {
                                                                console.log('onChange', option);
                                                                this.setState({ recipients: option ? option.map((item, index) => item.value) : [] });
                                                            }} />}
                                                </Form.Group>
                                            </Form.Row>
                                            {/* <Form.Row>
                            <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                <Form.Label className="large-font bold-font margin-bottom-zero">Send To:</Form.Label>                                    
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={6} controlId="sendTo" className="margin-bottom-8">
                                <Form.Control as="select"
                                    size="sm"
                                    value={values.sendTo}
                                    name="sendTo"
                                    onChange={ (e) => {
                                        this.changeRecipients(e.target.value);
                                        setFieldValue('sendTo', e.target.value);
                                    }}
                                >
                                <option value="all">All</option>
                                <option value="admin">Admin only</option>
                                <option value="staff">Staff only</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row> */}
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="summaryText" className="margin-bottom-8">
                                                    <div id="SummaryText">
                                                        {!this.state.isProcessing && this.getJobSummaryString()}
                                                    </div>
                                                </Form.Group>
                                            </Form.Row>
                                        </React.Fragment>}                                    
                                    {this.state.status &&
                                        <Form.Row>
                                            <Form.Group>
                                                <Alert variant={this.state.status === 'failure' ? 'danger' : 'success'}>{status && status.msg}</Alert>
                                            </Form.Group>
                                        </Form.Row>
                                    }
                                    <br />
                                    <Form.Row>
                                        <Button variant="primary" className="mr-sm-1" type="submit" disabled={(isSubmitting === true || this.state.recipients.length === 0) ? true : false || this.state.isProcessing} size="sm">
                                            {isSubmitting ? 'Sending…' : 'Send'}
                                        </Button>
                                        <Button variant="primary" className="mr-sm-2" disabled={isSubmitting} onClick={() => { setFieldValue('sendTo', 'all'); setFieldValue('message', ''); this.setModalShow(false) }} size="sm">
                                            Cancel
                            </Button>
                                    </Form.Row>
                                </Form>
                            </Modal.Body>
                        </LoadingOverlay>
                    </Modal>

                )}
            </Formik>
        )
    }
}