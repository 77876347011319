import React, { Component } from 'react';
import Badge from 'react-bootstrap/Badge';
import { Auth, API } from "aws-amplify";
import config from "../config";
import { Navbar, Form, Col, FormControl, Button, ButtonGroup} from 'react-bootstrap';
import ModalWrapper from './utility/ModalWrapper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import LoadingOverlay from 'react-loading-overlay';
import { Link, Redirect } from 'react-router-dom';
import {UserConsumer} from './utility/UserContext';
import ExportRemainingJobsButton from './utility/ExportRemainingJobsButton';
import RemainingJobsButton from './utility/RemainingJobsButton';
import LastDeliveryTimeButton from './utility/LastDeliveryTimeButton';
import ExportButton from './utility/ExportButton';
import {authorizationHeader} from './utility/Helper';
import { SendJobSummarySMSModal } from './utility/SendJobSummarySMSModal';
// import { SendDriverSMSModal } from './utility/SendDriverSMSModal';
import Select from 'react-select';


export default class Jobs extends Component {
    _isMounted = false;
    state = {
        isLoading: true,
        enableExportRMJButton: false,
        jobs: [],
        staffs: [],
        clients: [],
        divisions: [],
        statusList: [],
        selectedClient: '',
        selectedDivision: '',
        error: null,
        modalShow: false,
        modalNoteShow: false,
        modalUpdateStatusShow: false,
        modalDeleteJobsShow: false,
        modalRescheduleShow: false,
        modalChecklistShow: false,
        note:'',
        pdfnotes:'',
        fromDate: new Date(), //moment(),
        toDate: new Date(),//moment(),
        status: '',
        selectedJobs: [],
        refresh: false,
        scheduleFilter:'',
        searchKeyword:'',
        searchBy:'Name',
        sortName: '',
        sortOrder: 'asc',
        pageSize: '50',
        page: 1,
        nextToken: false, //undefined,
        prevToken: false, //undefined,
        forwardToken: undefined,
        backwardToken: undefined,
        selectedStatusGroup: 1,
        redirect: null,
        greenCount: '0',
        orangeCount: '0',
        redCount: '0',
        grayCount: '0',
        processId:'',
        selectedStates: [],
        stateOptions: [
            {value: "act", label: "ACT", matches: ["Australian Capital Territory", "act"]
            },
            {value: "nsw", label: "NSW", matches: ["New South Wales", "nsw"]
            },
            {value: "nt", label: "NT", matches: ["Northern Territory", "nt"]
            },
            {value: "qld", label: "QLD", matches: ["Queensland", "qld"]
            },
            {value: "sa", label: "SA", matches: ["South Australia", "sa"]
            },
            {value: "tas", label: "TAS", matches: ["Tasmania", "tas"]
            },
            {value: "vic", label: "VIC", matches: ["Victoria", "vic"]
            },
            {value: "wa", label: "WA", matches: ["Western Australia", "wa"]
            }
        ],
        isFilterCartons: false,
        isFilterPallets: false,
        isUrgentFilter: false,
        checklistDriverId: '',
        checklistDate: ''
    }
    showSendJobSummarySMSModal = React.createRef();
    showSendDriverSMSModal = React.createRef();

    sendJobSummarySMSModalShow( show ) {
        this.showSendJobSummarySMSModal.current.setModalShow(true);
    }

    sendDriverSMSModalShow( show ) {
        this.showSendDriverSMSModal.current.setModalShow(true);
    }

    setFromDate = value => {
        //console.log('fromDate', new Date(value).toISOString().split("T")[0]);
        let date = value;
        if(value > this.state.toDate)
            date = this.state.toDate;
        this.setState({ page: 1, fromDate: date, isLoading: true }, function () {
            this.fetchJobs();});
    }

    setToDate = value => {
        let date = value;
        if(value < this.state.fromDate)
            date = this.state.fromDate;
        this.setState({ page: 1, toDate: date, isLoading: true }, function () {
            this.fetchJobs();});
    }

    setForwardToken = () => {
        // this.setState({ forwardToken: this.state.nextToken, backwardToken: undefined, refresh: true, isLoading: true},function () {
        //     this.fetchJobs();});
        this.setState({ page: this.state.page + 1, isLoading: true},function () {
            this.fetchJobs();});
    }

    setBackwardToken = () => {
        // this.setState({ backwardToken: this.state.prevToken, forwardToken: undefined, refresh: true, isLoading: true},function () {
        //     this.fetchJobs();});
        this.setState({ page: this.state.page - 1, isLoading: true},function () {
            this.fetchJobs();});
    }

    updatePageSize = (e) => {
        this.setState({ page: 1, pageSize: e.target.value, refresh: true, isLoading: true }, function () {
            this.fetchJobs();});
    }

    renderPaginationPanel = (props) => {
        return (
            <Form as={Col} xs={12} className="form-inline bottom-margin">
                <Form.Group as={Col} xs={10}>
                <Form.Label>Records per page:&nbsp;</Form.Label>
                <Form.Control as="select" onChange={this.updatePageSize} defaultValue='50' size='sm'>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>    
                    <option value='25'>25</option>                             
                    <option value='30'>30</option> 
                    <option value='35'>35</option>
                    <option value='40'>40</option>    
                    <option value='45'>45</option>                             
                    <option value='50'>50</option> 
                    <option value='70'>70</option>
                    <option value='100'>100</option>
                    <option value='150'>150</option>
                    <option value='200'>200</option>
                    <option value='500'>500</option>
                </Form.Control>    
                </Form.Group>
                
                <Form.Group as={Col} xs={2}>
                    {!this.state.prevToken &&
                    <Button variant="success" disabled size="sm">Prev</Button>}
                    {this.state.prevToken &&
                    <Button variant="success" size="sm" onClick={this.setBackwardToken}>Prev</Button>}
                    &nbsp;
                    {!this.state.nextToken &&
                    <Button variant="success" disabled size="sm">Next</Button>}
                    {this.state.nextToken &&
                    <Button variant="success" size="sm" onClick={this.setForwardToken}>Next</Button>}
                </Form.Group>         
                
            </Form>
        );
      }

    onSortChange = (sortName, sortOrder) => {
        this.setState({
          page: 1, 
          sortName: sortName,
          sortOrder: sortOrder,
          isLoading: true,
          forwardToken: undefined,
          backwardToken: undefined
        },function () {
            this.fetchJobs();});
    }

    handleStatusChange = e => {
        this.setState({ page: 1, status: e.target.value, isLoading: true }, function () {
            this.fetchJobs();});
    }

    handleClientChange = e => {
        this.setState({ page: 1, selectedClient: e.target.value, selectedDivision: '', isLoading: true }, function () {
            this.fetchJobs();});
    }

    handleDivisionChange = e => {
        this.setState({ page: 1, selectedDivision: e.target.value, isLoading: true }, function () {
            this.fetchJobs();});
    }

    handleCartonFilterChange = e => {
        this.setState({ page: 1, isFilterCartons: Boolean(e.target.checked) , isLoading: true }, () => {
            this.updateDisplay(true); });
    }

    handlePalletFilterChange = e => {
        this.setState({ page: 1, isFilterPallets: Boolean(e.target.checked) , isLoading: true }, () => {
            this.updateDisplay(true); });
    }

    handleUrgentFilterChange = e => {
        this.setState({ page: 1, isUrgentFilter: Boolean(e.target.checked) , isLoading: true }, () => {
            this.updateDisplay(true);
        });
    }

    setNoDataText = () => {
        if(this.state.isLoading === false){
           return "No record found";
        }else{
            return 'Loading...';
        }
    }

    valueFormatter(cell, row) {
        return <span className="small-font">{cell}</span>;
    }

    etaFormatter(cell, row) {
        let time = '';
        if(row.StatusGroup === '3')
            time = moment(cell).format('LT').toLowerCase() === 'invalid date' ? '' : moment(cell).format('LT');
        if(row.StatusGroup === '5')
            time = moment(row.DeliveredTime).local().format('LT').toLowerCase() === 'invalid date' ? '' : moment(row.DeliveredTime).local().format('LT');
        return <span className="small-font">{time}</span>;
    }

    statusFormatter(cell, row) {
        let icon = '';
        if(row.StatusGroup === '2')
            icon = '../status-sml-green.png';
        if(row.StatusGroup === '3')
            icon = '../status-sml-orange.png';
        if(row.StatusGroup === '4')
            icon = '../status-sml-red.png';
        if(row.StatusGroup === '5')
            icon = '../status-sml-grey.png';
        return <span className="small-font"><img alt='' className="mr-sm-1" src={icon}></img>{cell}</span>;
    }    

    notesFormatter(cell, row) {
        if(cell && cell !== '')
            return <span className='clickable' onClick={this.setModalNoteShow.bind(this,true, cell)}><i className="fas fa-sticky-note" style={{ fontSize: '1em', color: 'green' }} /></span>
        else
            return <i className="fas fa-sticky-note" style={{ fontSize: '1em', color: 'gray' }} />
    }

    mapFormatter(cell, row) {
        // return <span className='clickable' onClick={this.goToMap.bind(this,row)}><i className="fas fa-map-marked-alt" style={{ fontSize: '1em', color: 'green' }} /></span>
        if(row.Status.toLowerCase() !== 'unallocated')
            return <Link to={`/map/${row.ItemId}`} ><i className="fas fa-map-marked-alt" style={{ fontSize: '1em', color: 'green' }} /></Link>
    }

    checklistFormatter(cell, row) {
        return <span className='clickable' onClick={this.setModalChecklistShow.bind(this,true, row)}><i className="fas fa-list" style={{ color: 'green' }} /></span>
    }

    dateFormatter(cell, row) {
        return <span className="small-font">{moment(cell).format("DD-MMM-YY")}</span>;
    }

    addressFormatter(cell, row) {
        let address = '';
        if(row.Street1 && row.Street1 !== '')
            address = row.Street1;
        if(row.Street2 && row.Street2 !== '' && (row.Street1 !== null ? row.Street1.trim().toLowerCase() : '') !== row.Street2.trim().toLowerCase())
            address = (address + ' ' + row.Street2).trim();
        if(row.Suburb && row.Suburb !== '')
            address = (address + ' ' + row.Suburb).trim();
        if(row.Zip && row.Zip !== '')
            address = (address + ', ' + row.Zip).trim();
        if(address.startsWith(','))
            address = address.substring(1).trim();
        return <span className="small-font">{address}</span>;
        // if(row.LocationName && row.LocationName !== '')
        //     return row.LocationName + '<br/>' + cell;
        // else
        //     return cell;
    }

    zipFormatter(cell, row) {
        let zip = '';
        if(row.Zip && row.Zip !== '')
            zip = Number(row.Zip.trim());
        return <span className="small-font">{zip}</span>;
        // if(row.LocationName && row.LocationName !== '')
        //     return row.LocationName + '<br/>' + cell;
        // else
        //     return cell;
    }

    searchKeywordOnChange = (e) => {
        this.setState({ searchKeyword: e.target.value });
    }

    searchByOnChange = (e) => {
        this.setState({ searchBy: e.target.value });
    }

    searchClick = () => {
        this.setState({ page: 1, isLoading: true }, function () {
            this.fetchJobs();});
    }

    handleEnterKeySearch = e => {
        if(e.charCode === 13)
        {
            e.preventDefault();
            this.searchClick();
        }            
    }

    // scheduleFilterChange = filter => {
    //     if(filter === 1)
    //         this.setState({ scheduleFilter: 'Where=DeliveryDate~beginswith~2' });
    //     if(filter === 2)
    //         this.setState({ scheduleFilter: '' });
    //     if(filter === 3)
    //         this.setState({ scheduleFilter: 'Where=DeliveryDate~is~Unscheduled' });
    //     this.setState({ refresh: true, isLoading: true});
    // }

    updateDisplay = update => {
        if(update === true)
        {
            this.setState({ page: 1, isLoading: true, modalShow: false, modalUpdateStatusShow: false, modalRescheduleShow: false, modalDeleteJobsShow: false, selectedJobs: [] }, function () { this.refs.table.reset(); this.fetchJobs();});
            // this.refs.table.setState({
            //     selectedRowKeys: []
            //   });
            // this.onSelectAll(false);
        }            
            //this.setState({ refresh: true, isLoading: true, modalShow: false });
    }

    setModalShow = show => {
        this.setState({ modalShow: show });
    }

    setModalUpdateStatusShow = show => {
        this.setState({ modalUpdateStatusShow: show });
    }

    setModalRescheduleShow = show => {
        this.setState({ modalRescheduleShow: show });
    }

    setModalDeleteJobsShow = show => {
        this.setState({ modalDeleteJobsShow: show });
    }

    setStatusGroup = group => {
        this.setState({ selectedStatusGroup: group, page: 1, isLoading: true }, function () {this.fetchJobs();});
    }

    setModalNoteShow = (show,note,e) => {
        if(show === true)
            e.stopPropagation();
        this.setState({ note: note, modalNoteShow: show });
    }

    setModalChecklistShow = (show, row, e) => {
        if(show === true)
            e.stopPropagation();

        this.setState({ modalChecklistShow: show });
        if(row) {
            this.setState({ checklistDriverId: row.DriverId, checklistDate: row.DeliveryDate.split(' ')[0] });
        }
    }

    linkFormatter(cell, row) {
        if(row.Priority && row.Priority === "Urgent") {
            return <div>
                <Link to={`/jobs/editJob/${row.ItemId}`} className="small-font">{cell}</Link>
                <p className="customBadgeDark"><Badge bg="warning" text="dark">Urgent</Badge></p>
            </div>                        
        }
        else return <div>
            <Link to={`/jobs/editJob/${row.ItemId}`} className="small-font">{cell}</Link>
        </div> 

    }

    goToMap = (row, e) => {
        e.stopPropagation();
        //this.props.navProp.changePane('map');
        this.props.navProp.changeMapPage('map',row.ItemId);
    }

    rowStyleFormat(row, rowIdx) {
        if(row) {
            var style = {};
            if(row.isCarton) {
                style = { backgroundColor: '#fcfae0'}
            }
            else {
                style = { backgroundColor: '#e8fcff'}
            }
            if(row.Priority === "Urgent") {
                style = { backgroundColor: '#F0FF42'}
            }
            return style;
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        this.fetchJobs();
        this.fetchStaff();
        // this.fetchStatus();
        // this.fetchClientsAndDivisions();
        await Promise.all([this.fetchStatus(), this.fetchClientsAndDivisions()]);
        this.setState({enableExportRMJButton: true});
        this.connect();
        // this.connection = new WebSocket(config.ws.url);
        // console.log(this.connection);
        // this.connection.onclose = e => {
        //     console.log('close reason', e.reason);
        // }
        // this.connection.onerror = err => {
        //     console.error(
        //         "Socket encountered error: ",
        //         err.message,
        //         "Closing socket"
        //     );
        //     this.connection.close();
        // };
        // this.connection.onmessage = evt => { 
        //     console.log('websocket receive message', evt);
        //     if(this._isMounted)
        //         this.fetchJobs();
        // };
    }

    componentWillUnmount() {
        try{
            this._isMounted = false;
            this.connection.close();
        }catch(error){
            console.log(error);
        }
    }

    connect = () => {
        this.connection = new WebSocket(config.ws.url);
        let that = this; // cache the this
        var connectInterval;

        // websocket onopen event listener
        this.connection.onopen = () => {
            //this.setState({ ws: ws });
            that.timeout = 250; // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };

        // websocket onclose event listener
        this.connection.onclose = e => {
            if(this._isMounted){
                console.log(
                    `Socket is closed. Reconnect will be attempted in ${Math.min(
                        10000 / 1000,
                        (that.timeout + that.timeout) / 1000
                    )} second.`,
                    e.reason
                );
                that.timeout = that.timeout + that.timeout; //increment retry interval
                connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
            }else{
                that.timeout = 250; // reset timer to 250 on open of websocket connection 
                clearTimeout(connectInterval); // clear Interval on on open of websocket connection
            }
        };

        // websocket onerror event listener
        this.connection.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );
            this.connection.close();
        };

        this.connection.onmessage = evt => { 
            if(this._isMounted)
            {
                const processId = (Math.random() * (9999999 - 1000000) + 1000000).toString().split('.')[0];
                this.setState({ processId: processId }, async function () {
                    this.fetchJobs(this.state.processId);
                });
            }
        };
    };

    check = () => {
        //const { ws } = this.state;
        if (!this.connection || this.connection.readyState === WebSocket.CLOSED)
        {
            if(this._isMounted)
                this.connect(); //check if websocket instance is closed, if so call `connect` function.
        }
    };

    async fetchClientsAndDivisions() {
        try{         
            const entityArray = ['clients','divisions'];
            const authHeader = await authorizationHeader();
            const entities = entityArray.map( async (entity, index) => {
                try{
                    const res = await API.get('api', '/' + entity, authHeader);                
                    return (
                        {
                            entity: entity,
                            content: res
                        }
                    )
                }catch(err) {
                    this.setState(() => { throw err; })
                }
            });            
            const resultEntities = await Promise.all(entities);
            this.setState({ clients: resultEntities.filter(e => e.entity === 'clients')[0].content.clients, divisions: resultEntities.filter(e => e.entity === 'divisions')[0].content.divisions })
        }catch(error) {
            this.setState(() => { throw error; })
        }        
    }

    async fetchStaff() {
        try{
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };
            const res = await API.get("api", "/accounts?AccountType=Driver", myInit);
            this.setState({ staffs: res.accounts })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }          
    }

    async fetchStatus() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/status?IncludeUnallocated=true", myInit);
            this.setState({
                statusList: res.status.filter(ss => ss.IsActive)
                });
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    getStatus() {        
        return this.state.statusList.length > 0 && this.state.statusList.map((status) => {
           const { Label, ItemId } = status
           return (
               <option key={ItemId} value={ItemId} >{Label}</option>
           )
        })
     }

    getClientOptions = () => {   
        if(this.state.clients.length > 0){
            return this.state.clients.map((c) => {
                const { Name, ItemId } = c
                return (
                    <option key={ItemId} value={ItemId} >{Name}</option>
                )
             });
        }     
    }

    getDivisionOptions = () => {
        const {clients, divisions, selectedClient} = this.state;
        if(divisions.length > 0 && clients.length > 0){
            if(selectedClient !== '')
            {
                return divisions.filter(d => d.ParentItemId === selectedClient).map((c) => {
                    const { Name, ItemId } = c
                    return (
                        <option key={ItemId} value={ItemId} >{Name}</option>
                    )
                });
            }
        }   
    }
    
    handleDriverNamesWithExtraSpace = (searchString) => {
        try{
            if(searchString.indexOf(' ') > -1){
                const firstName = searchString.substring(0, searchString.indexOf(' ')).trim().toLowerCase();
                const lastName = searchString.substring(searchString.indexOf(' ')).trim().toLowerCase();
                if(this.state.staffs !== undefined && this.state.staffs.length > 0){
                    const matchedDriver = this.state.staffs.filter(i => i.FirstName.trim().toLowerCase() === firstName && i.LastName.trim().toLowerCase() === lastName);
                    if(matchedDriver !== undefined && matchedDriver.length > 0)
                        searchString = matchedDriver[0].FullName.trim().toLowerCase();
                }
            }
            return searchString;
        }catch(err) {
            console.log('error', err);
            return searchString;
        }
    }

    getSelectedStateOptionsMatches = (selectedStates) => {
        let states = "";
        selectedStates.forEach(s => {
            if(s.matches && s.matches.length > 0)
            {
                s.matches.forEach(i => {
                    if(states === "")
                        states = i;
                    else
                        states = states + "|" + i;
                });
            }
        });
        return states;
    }
      
    fetchJobs = async (processId) => {
        try{
            if(processId)
                await this.sleep(2000);
            let filters = '';
            let isFilteredByStatus = false;
            const authHeader = await authorizationHeader();
            const {sortName, sortOrder, searchKeyword, pageSize, forwardToken, backwardToken, fromDate, toDate, status, page, selectedStatusGroup, selectedClient, selectedDivision, selectedStates} = this.state;
            //const from = new Date(fromDate).toISOString().split("T")[0];
            const from = moment(fromDate).format('YYYY-MM-DD');
            //const to = new Date(toDate).toISOString().split("T")[0];
            const to = moment(toDate).format('YYYY-MM-DD') + ' 23:59:59';
            filters = '?pageSize=' + pageSize + '&page=' + page;
            if(sortName !== '' && sortOrder !== '')
            {
                filters = filters + '&Sort=' + sortName + '~' + sortOrder;
            }
            filters = filters + '&Where=DeliveryDate~between~' + from + '|' + to;
            if(selectedClient !== '')
            {
                filters = filters + '&BillingClientId=' + selectedClient;
            }
            if(selectedDivision !== '')
            {
                filters = filters + '&ParentItemId=' + selectedDivision;
            }
            if(status !== '')
            {
                isFilteredByStatus = true;
                filters = filters + '&StatusId=' + status;
            }
            if(selectedStatusGroup > 1)
            {
                isFilteredByStatus = true;
                filters = filters + '&StatusGroup=' + selectedStatusGroup;
            }
            
            if(selectedStates && selectedStates.length > 0)
            {
                filters = filters + '&Filter=State~in~' + this.getSelectedStateOptionsMatches(selectedStates);
            }
            if(this.state.isFilterCartons && !this.state.isFilterPallets) 
            {
                filters = filters + '&JobType~contains~' + 'Carton';
            }
            if(this.state.isFilterPallets && !this.state.isFilterCartons) 
            {
                filters = filters + '&JobType~contains~' + 'Pallet';
            }
            if(this.state.isUrgentFilter) 
            {
                filters = filters + '&Priority=' + 'Urgent';
            }
            if(isFilteredByStatus)
            {
                let keywordFilter = '';
                if(searchKeyword !== '')
                {
                    keywordFilter = '&Condition=SearchField~contains~' + this.handleDriverNamesWithExtraSpace(searchKeyword.trim().toLowerCase());
                }
                if(selectedClient !== '')
                {
                    keywordFilter = keywordFilter + '&BillingClientId=' + selectedClient;
                }
                if(selectedDivision !== '')
                {
                    keywordFilter = keywordFilter + '&ParentItemId=' + selectedDivision;
                }
                if(selectedStates && selectedStates.length > 0)
                {
                    keywordFilter = keywordFilter + '&Filter=State~in~' + this.getSelectedStateOptionsMatches(selectedStates);
                }
                if(processId === undefined || processId === this.state.processId){
                    const resultCounts = await API.get("api", "/jobs?IncludeUnallocated=true&Where=DeliveryDate~between~" + from + '|' + to + keywordFilter, authHeader);
                    this.setState({
                        greenCount: resultCounts.green ? resultCounts.green : '0',
                        orangeCount: resultCounts.orange ? resultCounts.orange : '0',
                        redCount: resultCounts.red ? resultCounts.red : '0',
                        grayCount: resultCounts.gray ? resultCounts.gray : '0'
                    });
                }                        
            }
            if(searchKeyword !== '')
            {
                filters = filters + '&Condition=SearchField~contains~' + this.handleDriverNamesWithExtraSpace(searchKeyword.trim().toLowerCase());
                // if(searchBy === 'Address')
                //     filters = scheduleFilter !== '' ? filters + '&Condition=SearchAddress~contains~' + searchKeyword : filters + '&Where=SearchAddress~contains~' + searchKeyword; 
                // else
                //     filters = scheduleFilter !== '' ? filters + '&Condition=' + searchBy + '~beginswith~' + searchKeyword : filters + '&Where=' + searchBy + '~beginswith~' + searchKeyword; 
            }
            if(forwardToken)
                filters = filters + '&ForwardToken=' + forwardToken;
            if(backwardToken)
                filters = filters + '&BackwardToken=' + backwardToken;
            filters = filters + '&IncludeUnallocated=true';

            if(processId === undefined || (this._isMounted && processId === this.state.processId))
            {
                const res = await API.get("api", "/jobs" + filters, authHeader);
                if(res["result"] === "failure" && res["errors"] && res["errors"] === "user is disabled")
                {
                    Auth.signOut();
                    this.props.auth.setAuthStatus(false);
                    this.props.auth.setUser(null);
                    this.props.auth.setName(null);
                    this.props.history.push("/login");
                }
                res.jobs = res.jobs.map(jj => {
                    var isCarton = jj.JobType.indexOf("Carton") > -1;
                    if(isCarton) {
                        return {
                            ...jj,
                            isCarton,
                            CartonCount: Number(jj.Cartons),
                            PalletCount: 0
                        }
                    }
                    else {
                        return {
                            ...jj,
                            isCarton,
                            CartonCount: 0,
                            PalletCount: Number(jj.Cartons)
                        }
                    }
                })
                // if(sortName === 'CartonCount') {
                //     res.jobs = res.jobs.sort((a, b) => {
                //         if (sortOrder === 'desc') {
                //             return Number(b.CartonCount) - Number(a.CartonCount);
                //         } else {
                //             return Number(a.CartonCount) - Number(b.CartonCount);
                //         }
                //     })
                // }
                // else if(sortName === 'PalletCount') {
                //     res.jobs = res.jobs.sort((a, b) => {
                //         if (sortOrder === 'desc') {
                //             return Number(b.PalletCount) - Number(a.PalletCount);
                //         } else {
                //             return Number(a.PalletCount) - Number(b.PalletCount);
                //         }
                //     })
                // }
                
                this.setState({
                    jobs: res.jobs,
                    nextToken: res.forwardPageToken,
                    prevToken: res.backwardPageToken,
                    isLoading: false,
                    refresh: false,
                    forwardToken: undefined,
                    backwardToken: undefined,
                    greenCount: isFilteredByStatus ? this.state.greenCount : (res.green ? res.green : '0'),
                    orangeCount: isFilteredByStatus ? this.state.orangeCount : (res.orange ? res.orange : '0'),
                    redCount: isFilteredByStatus ? this.state.redCount : (res.red ? res.red : '0'),
                    grayCount: isFilteredByStatus ? this.state.grayCount : (res.gray ? res.gray : '0')
                });
            }
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onRowSelect(row, isSelected, e) {
        const records = this.state.selectedJobs;
        const index = records.findIndex(x => x.ItemId === row.ItemId);
        if(isSelected === true)
        {
            if(index < 0)          
                records.push(row);
        }
        else
        {            
            if(index > -1)
                records.splice(index, 1);
        }
        this.setState({selectedJobs: records});
        // let rowStr = '';
        // for (const prop in row) {
        //   rowStr += prop + ': "' + row[prop] + '"';
        // }
        // console.log(e);
        // alert(`is selected: ${isSelected}, ${rowStr}`);
    }
    
    onSelectAll(isSelected, rows) {
        //alert(`is select all: ${isSelected}`);
        const records = [];
        if (isSelected) {
            //alert('Current display and selected data: ');
            for (let i = 0; i < rows.length; i++) {
                records.push(rows[i]);
            }
        }
        this.setState({selectedJobs: records});
        // for (let i = 0; i < rows.length; i++) {
        //     alert(rows[i].id);
        // }
    }

    handleOnRowClick(row) {
        this.props.navProp.changeJobPage('editJob',row.ItemId); 
        this.setState({redirect:'/jobs/editJob/' + row.ItemId});       
        //this.props.navProp.changePane('map');
        //this.props.navProp.nav('/');
    }

    changeJobPage(page) {
        this.props.navProp.changeJobPage(page);
    }
    
    render() {
        if (this.state.redirect) 
            return <Redirect to={{pathname: this.state.redirect}} />
        const selectRowProp = {
            mode: 'checkbox',
            columnWidth: '38px',
            onSelect: this.onRowSelect.bind(this),
            onSelectAll: this.onSelectAll.bind(this)
        };
        if(this.state.refresh)
            this.fetchJobs();
        return (
        <React.Fragment>
        <Navbar className="bg-light padding-top-bottom-zero">
            <Navbar.Brand href="/">
                <h4 className="padding-top-bottom-zero">Jobs</h4>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                <Form inline>
                    <UserConsumer>
                        {userType => {
                            return (
                                <React.Fragment>
                                    <Button as={userType.toLowerCase() === 'driver' ? Button : Link} to="/jobs/addJob" className="mr-sm-1" disabled={userType.toLowerCase() === 'driver'} variant="success" size="sm">
                                    <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Job
                                    </Button>
                                    <Button as={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser') ? Button : Link} to="/jobs/importJob" className="mr-sm-1" variant="success" size="sm" type="button" disabled={userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser'}>
                                    <i className="fas fa-file-import" style={{ fontSize: '1em' }} /> Import Job
                                    </Button>
                                    {
                                        this.state.enableExportRMJButton && <ExportRemainingJobsButton fromDate={this.state.fromDate} toDate={this.state.toDate} clients={this.state.clients} divisions={this.state.divisions} stateOptions={this.state.stateOptions}
                                        disabled={userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser'}/>
                                    }                                       
                                    <RemainingJobsButton fromDate={this.state.fromDate} toDate={this.state.toDate} disabled={userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser'}/>
                                    <LastDeliveryTimeButton date={this.state.toDate} disabled={userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser'}/>
                                    <ExportButton fromDate={this.state.fromDate} toDate={this.state.toDate} sortName={this.state.sortName} sortOrder={this.state.sortOrder} searchKeyword={this.state.searchKeyword} status={this.state.status} selectedStatusGroup={this.state.selectedStatusGroup} selectedClient={this.state.selectedClient} selectedDivision={this.state.selectedDivision} staffs={this.state.staffs} selectedStates={this.getSelectedStateOptionsMatches(this.state.selectedStates)}/>
                                    {
                                        ((userType.toLowerCase() === 'companyadministrator') || (userType.toLowerCase() === 'globaladministrator')) ? 
                                            <React.Fragment>
                                                <Button className="mr-sm-3" variant="success" size="sm" type="button" onClick={() => this.sendJobSummarySMSModalShow(true)}>
                                                    <i className="far fa-comment-dots" style={{ fontSize: '1em' }} /> Send SMS
                                                </Button>
                                            </React.Fragment> :
                                            <React.Fragment></React.Fragment>
                                    }
                                    <SendJobSummarySMSModal ref={this.showSendJobSummarySMSModal} />
                                    {/* {
                                        ((userType.toLowerCase() === 'companyadministrator') || (userType.toLowerCase() === 'globaladministrator')) ? 
                                            <React.Fragment>
                                                <Button className="mr-sm-3" variant="success" size="sm" type="button" onClick={() => this.sendDriverSMSModalShow(true)}>
                                                    <i className="far fa-comment-dots" style={{ fontSize: '1em' }} /> Send SMS to Drivers
                                                </Button>
                                            </React.Fragment> :
                                            <React.Fragment></React.Fragment>
                                    }
                                    <SendDriverSMSModal ref={this.showSendDriverSMSModal} /> */}
                                </React.Fragment>
                            )
                        }}
                    </UserConsumer>
                </Form>
            </Navbar.Collapse>
        </Navbar>
        <Navbar className="bg-light padding-top-bottom-zero">
            <Form inline>
                <Form.Label className="mr-sm-2">Status</Form.Label>
                <Form.Control as="select"
                    size="sm"
                    className="mr-sm-3 width-200"
                    onChange={this.handleStatusChange}
                >
                    <option value="">All</option>
                    {this.getStatus()}
                </Form.Control>
            </Form>
            <UserConsumer>
                {userType => {
                    if(userType !== null && userType !== undefined && userType.toLowerCase() !== 'driver' && userType.toLowerCase() !== 'officeadministrator' && userType.toLowerCase() !== 'officeuser')
                    {
                        return (
                            <React.Fragment>
                                <Form inline>
                                    <Form.Label className="mr-sm-2">Client</Form.Label>                            
                                    <Form.Control as="select"
                                        size="sm"
                                        className="mr-sm-3 width-200"
                                        onChange={this.handleClientChange}
                                    >
                                        <option value="">{this.state.clients.length === 0 ? 'Loading...' : 'All'}</option>
                                        {this.getClientOptions()}
                                    </Form.Control>
                                </Form>
                                <Form inline>
                                    <Form.Label className="mr-sm-2">Division</Form.Label>                            
                                    <Form.Control as="select"
                                        size="sm"
                                        className="mr-sm-3 width-200"
                                        disabled={this.state.selectedClient === ''}
                                        onChange={this.handleDivisionChange}
                                    >
                                        <option value="">{this.state.clients.length === 0 ? 'Loading...' : (this.state.selectedClient !== '' ? 'All' : 'select client first')}</option>
                                        {this.getDivisionOptions()}
                                    </Form.Control>
                                </Form>
                                <Form inline>
                                    <Form.Label className="mr-sm-2">State</Form.Label>                            
                                    <Select
                                        closeMenuOnSelect={true}
                                        className={"basic-multi-select form-text-align mr-sm-3" + (this.state.selectedStates.length === 0 ? " width-200" : "")} 
                                        classNamePrefix="select"
                                        placeholder="filter by state..."
                                        isMulti 
                                        options={this.state.stateOptions} 
                                        name="stateSelect"
                                        onChange={(option) => {                                     
                                            this.setState({page: 1, selectedStates: (option ? option : []), isLoading: true}, function () {
                                                this.fetchJobs();});                                        
                                        }}
                                    />
                                </Form>
                            </React.Fragment>
                        )
                    }
                }}
            </UserConsumer>
            <Navbar.Collapse className="justify-content-end">
                <Form inline>
                    <Form.Label className="mr-sm-2">From</Form.Label>
                    <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={Date.parse(this.state.fromDate)} maxDate={this.state.toDate} onChange={value => this.setFromDate(value)} className='form-control form-control-sm mr-sm-3 width-100' placeholderText='Select from date'/>
                    <Form.Label className="mr-sm-2">To</Form.Label>
                    <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={Date.parse(this.state.toDate)} minDate={this.state.fromDate} onChange={value => this.setToDate(value)} className='form-control form-control-sm width-100' placeholderText='Select to date'/>
                </Form>
            </Navbar.Collapse>
        </Navbar>
        <Navbar className="bg-light padding-top-bottom-zero">
            <Navbar.Brand>
                <UserConsumer>
                    {userType => {
                        return (
                            <React.Fragment>
                                <Button className="mr-sm-1" variant="success" size="sm" type="button" onClick={()=> this.setModalShow(true)} disabled={userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser'}>
                                    <i className="fas fa-user-friends" style={{ fontSize: '1em' }} /> Reassign
                                </Button>
                                <Button className="mr-sm-1" variant="success" size="sm" type="button" onClick={()=> this.setModalRescheduleShow(true)} disabled={userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser'}>
                                    <i className="fa fa-calendar" style={{ fontSize: '1em' }} /> Reschedule
                                </Button>
                                <Button className="mr-sm-1" variant="success" size="sm" type="button" onClick={()=> this.setModalUpdateStatusShow(true)} disabled={userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser'}>
                                    <i className="fas fa-edit" style={{ fontSize: '1em' }} /> Update Status
                                </Button>
                                <Button className="mr-sm-3" variant="success" size="sm" type="button" onClick={()=> this.setModalDeleteJobsShow(true)} disabled={userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser'}>
                                    <i className="fa fa-trash" style={{ fontSize: '1em' }} /> Delete
                                </Button>
                            </React.Fragment>
                        )
                    }}
                </UserConsumer>
                {/* <Button className="mr-sm-3" variant="success" size="sm" type="button" onClick={()=> this.setModalShow(true)}>
                    <i className="fas fa-user-friends" style={{ fontSize: '1em' }} /> Reassign
                </Button> */}

                <ButtonGroup className="mr-2" aria-label="">
                    <Button onClick={()=> this.setStatusGroup(1)} size="sm" className={this.state.selectedStatusGroup === 1 ? "selected-cat" : "unselected-cat"}>
                        All
                    </Button>
                    <Button onClick={()=> this.setStatusGroup(2)} size="sm" className={this.state.selectedStatusGroup === 2 ? "selected-cat" : "unselected-cat"}>
                        <img alt='' className="mr-sm-1" src="../status-sml-green.png"></img>
                        {`(${this.state.greenCount})`}
                    </Button>
                    <Button onClick={()=> this.setStatusGroup(3)} size="sm" className={this.state.selectedStatusGroup === 3 ? "selected-cat" : "unselected-cat"}>
                        <img alt='' className="mr-sm-1" src="../status-sml-orange.png"></img>
                        {`(${this.state.orangeCount})`}
                    </Button>
                    <Button onClick={()=> this.setStatusGroup(4)} size="sm" className={this.state.selectedStatusGroup === 4 ? "selected-cat" : "unselected-cat"}>
                        <img alt='' className="mr-sm-1" src="../status-sml-red.png"></img>
                        {`(${this.state.redCount})`}
                    </Button>
                    <Button onClick={()=> this.setStatusGroup(5)} size="sm" className={this.state.selectedStatusGroup === 5 ? "selected-cat" : "unselected-cat"}>
                        <img alt='' className="mr-sm-1" src="../status-sml-grey.png"></img>
                        {`(${this.state.grayCount})`}
                    </Button>
                </ButtonGroup>
            </Navbar.Brand>
            <Form.Row>
                <div className='col-12'>
                    <ul className="legend">
                        <li className="cartons">
                            <Form.Check type="checkbox" id="cartonsBox">
                                <Form.Check.Input type="checkbox"
                                value={this.state.isFilterCartons}
                                onChange={this.handleCartonFilterChange} />
                                <Form.Check.Label>Cartons</Form.Check.Label>
                            </Form.Check>
                        </li>
                        <li className="pallets">
                            <Form.Check type="checkbox" id="palletsBox">
                                <Form.Check.Input type="checkbox"                            
                                value={this.state.isFilterPallets}
                                onChange={this.handlePalletFilterChange} />
                                <Form.Check.Label>Pallets</Form.Check.Label>
                            </Form.Check>
                        </li>
                        <li className="urgent">
                            <Form.Check type="checkbox" id="urgentBox">
                                <Form.Check.Input type="checkbox"                            
                                value={this.state.isUrgentFilter}
                                onChange={this.handleUrgentFilterChange} />
                                <Form.Check.Label>Urgent</Form.Check.Label>
                            </Form.Check>
                        </li>
                    </ul>
                </div>
            </Form.Row>
            <Navbar.Collapse className="justify-content-end">
                <Form inline>
                <Navbar.Text>
                    <FormControl type="text" placeholder="Search by Consignment, Client, Driver, Address" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                    <span className='clickable' onClick={()=> this.searchClick()}>
                        <i className="fas fa-search" style={{ fontSize: '1em' }} />
                    </span>
                </Navbar.Text>
                </Form>
            </Navbar.Collapse>  
            <Form inline>
                <ModalWrapper
                    show={this.state.modalUpdateStatusShow}
                    headercolor="transparent"
                    onHide={() => this.setModalUpdateStatusShow(false)}
                    modaltitle="Update Status"
                    type="updateStatus"
                    jobs={this.state.selectedJobs}
                    status={this.state.statusList}
                    refresh={this.updateDisplay}
                />
                <ModalWrapper
                    show={this.state.modalDeleteJobsShow}
                    headercolor="transparent"
                    onHide={() => this.setModalDeleteJobsShow(false)}
                    modaltitle="Delete Jobs"
                    type="deleteJobs"
                    jobs={this.state.selectedJobs}
                    refresh={this.updateDisplay}
                />
                <ModalWrapper
                    show={this.state.modalNoteShow}
                    size="sm"
                    headercolor="transparent"
                    onHide={() => this.setModalNoteShow(false)}
                    modaltitle="Note"
                    type="note"
                    note={this.state.note}
                />
                <ModalWrapper
                    show={this.state.modalShow}
                    headercolor="transparent"
                    onHide={() => this.setModalShow(false)}
                    modaltitle="Reassign Job"
                    type="reassign"
                    jobs={this.state.selectedJobs}
                    staffs={this.state.staffs.filter(s => s.Status === "active")}
                    refresh={this.updateDisplay}
                />
                <ModalWrapper
                    show={this.state.modalRescheduleShow}
                    headercolor="transparent"
                    onHide={() => this.setModalRescheduleShow(false)}
                    modaltitle="Reschedule Job"
                    type="reschedule"
                    jobs={this.state.selectedJobs}
                    refresh={this.updateDisplay}
                />
                <ModalWrapper
                    show={this.state.modalChecklistShow}
                    size="lg"
                    headercolor="transparent"
                    onHide={() => this.setModalChecklistShow(false)}
                    modaltitle="Checklist Response"
                    type="checklist"
                    driverid={this.state.checklistDriverId}
                    deliverydate={this.state.checklistDate}
                />
            </Form>
        </Navbar>        
        <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text='Loading jobs...'
        >  
        <BootstrapTable  ref='table' trStyle={this.rowStyleFormat} data={this.state.jobs} options={{onSortChange: this.onSortChange, noDataText: this.setNoDataText(), sortIndicator: false, paginationPanel: this.renderPaginationPanel, onRowClick: this.handleOnRowClick.bind(this) }} remote={true} hover pagination selectRow={ selectRowProp }>
            <TableHeaderColumn dataField='ItemId' isKey hidden>ItemId</TableHeaderColumn>
            <TableHeaderColumn dataField='DeliveryDate' thStyle={{ whiteSpace: 'normal' }} dataSort={true}  dataFormat={this.dateFormatter}><span className="small-header-font">Delivery Date</span></TableHeaderColumn>
            <TableHeaderColumn dataField='ConsignmentNumber' dataSort={true} dataFormat={this.linkFormatter.bind(this)}><span className="small-header-font">Id</span></TableHeaderColumn>
            <TableHeaderColumn dataField='JobType' tdStyle={{ whiteSpace: 'normal' }}  dataSort={true} dataFormat={this.valueFormatter}><span className="small-header-font">Type</span></TableHeaderColumn>
            <TableHeaderColumn dataField='BillingClient' tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}  dataSort={true} dataFormat={this.valueFormatter}><span className="small-header-font">Client</span></TableHeaderColumn>
            <TableHeaderColumn dataField='Name' tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}  dataSort={true} dataFormat={this.valueFormatter}><span className="small-header-font">Delivery Client</span></TableHeaderColumn>
            <TableHeaderColumn dataField='Street1' tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataSort={true} dataFormat={this.addressFormatter}><span className="small-header-font">Delivery Address</span></TableHeaderColumn>  
            <TableHeaderColumn dataField='ETA' dataAlign="middle"  dataSort={true} dataFormat={this.etaFormatter}><span className="small-header-font">ETA/DT</span></TableHeaderColumn>
            <TableHeaderColumn dataField='Notes' dataAlign="middle"  dataFormat={this.notesFormatter.bind(this)}><span className="small-header-font">Notes</span></TableHeaderColumn>   
            <TableHeaderColumn dataField='Division' tdStyle={{ whiteSpace: 'normal' }} dataFormat={this.valueFormatter} dataSort={true} ><span className="small-header-font">Division</span></TableHeaderColumn>
            <TableHeaderColumn dataField='Zip' dataAlign="middle" tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataSort={false} dataFormat={this.zipFormatter} ><span className="small-header-font">Zip Code</span></TableHeaderColumn> 
            {/* <TableHeaderColumn dataField='Cartons' dataSort={true} dataAlign="middle" width='80' dataFormat={this.valueFormatter}><span className="small-header-font">BoxCount</span></TableHeaderColumn>          */}
            <TableHeaderColumn dataField='CartonCount' dataSort={false} dataAlign="middle"  dataFormat={this.valueFormatter}><span className="small-header-font">Cartons</span></TableHeaderColumn>         
            <TableHeaderColumn dataField='PalletCount' dataSort={false} dataAlign="middle"  dataFormat={this.valueFormatter}><span className="small-header-font">Pallets</span></TableHeaderColumn>                     
            <TableHeaderColumn dataField='CubicCapacityMetresCubed' dataSort={true} dataAlign="middle"  dataFormat={this.valueFormatter}><span className="small-header-font">Cubic Capacity Metres Cubed</span></TableHeaderColumn>                 
            <TableHeaderColumn dataField='DriverName' tdStyle={{ whiteSpace: 'normal' }} dataFormat={this.valueFormatter} dataSort={true} ><span className="small-header-font">Driver</span></TableHeaderColumn>
            <TableHeaderColumn dataField='Status' tdStyle={{ whiteSpace: 'normal' }} dataAlign="middle" dataFormat={this.statusFormatter} dataSort={true} ><span className="small-header-font">Status</span></TableHeaderColumn>
            <TableHeaderColumn  dataAlign="middle" dataFormat={this.mapFormatter.bind(this)}><span className="small-header-font">Map</span></TableHeaderColumn>
            {/* <TableHeaderColumn width='55' dataAlign="middle" dataFormat={this.checklistFormatter.bind(this)}><span className="small-header-font">Checklist</span></TableHeaderColumn> */}
        </BootstrapTable>
        </LoadingOverlay>
        </React.Fragment>
        );
    }
}