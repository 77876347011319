import React, { Component } from 'react';
import {Table, Spinner, Navbar, Form, FormGroup, FormControl, Button, Popover, Modal} from 'react-bootstrap';
import NotificationGroups from '../NotificationGroups';
import ErrorBoundary from './ErrorBoundary';
import AddEditNotificationGroup from '../AddEditNotificationGroup';

import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';

export default class NotificationGroupsWrapper extends Component {

    render() {
        return (
            <Switch>
                <Route
                path="/notificationGroups"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <NotificationGroups {...props} />
                    </ErrorBoundary>
                                    }
                />
                <Route
                path={["/notificationGroups/addNotificationGroup","/notificationGroups/editNotificationGroup/:notificationGroupId"]}
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <AddEditNotificationGroup {...props} />
                    </ErrorBoundary>
                                    }
                />
                <Route render={() => <Redirect to={{pathname: "/notificationGroups"}} />} />   
            </Switch>
        );
  }
}

