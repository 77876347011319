import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import { Navbar, Form, FormControl, Button, Col} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

export default class Accounts extends Component {
    state = {
        isLoading: true,
        accounts: [],
        searchKeyword:'',
        sortName: 'FullName',
        sortOrder: 'asc',
        pageSize: '50',
        page: 1,
        nextToken: false,
        prevToken: false,
        forwardToken: undefined,
        backwardToken: undefined,
        userStatus: "all"
    }

    onSortChange = (sortName, sortOrder) => {
        //console.info('onSortChange', arguments);
        //console.log('sortName', sortName);
        // console.log('sortOrder', sortOrder);
        this.setState({
          page: 1, 
          sortName: sortName,
          sortOrder: sortOrder,
          isLoading: true,
          forwardToken: undefined,
          backwardToken: undefined
        },function () {
            this.fetchUserAccounts();});
        // console.log('sortName', this.state.sortName);
        // console.log('sortOrder', this.state.sortOrder);
        // this.fetchJobs();
    }

    handleOnRowClick(row) {
        //console.log('row clicked', row);
        //console.log('modal', this.state.modalNoteShow);
        this.props.navProp.changeAccountPage('editAccount',row.ItemId);        
        //this.props.navProp.changePane('map');
        //this.props.navProp.nav('/');
    }

    searchKeywordOnChange = (e) => {
        this.setState({ searchKeyword: e.target.value });
    }

    searchClick = () => {
        this.setState({ page: 1, isLoading: true }, function () {
            this.fetchUserAccounts();});
    }

    userStatusClick = event => {
        this.setState({ page: 1, isLoading: true, userStatus: event.target.value }, function () {
            this.fetchUserAccounts();});
    }

    handleEnterKeySearch = e => {
        if(e.charCode === 13)
        {
            e.preventDefault();
            this.searchClick();
        }            
    }

    changeAccountPage(page) {
        this.props.navProp.changeAccountPage(page);
    }

    setNoDataText = () => {
        if(this.state.isLoading === false){
           return "No record found";
        }else{
            return 'Loading...';
        }
    }

    accountTypeFormatter(cell, row) {
        if(cell.toString().toLowerCase() === 'companyadministrator')
            return 'Company Administrator';
        if(cell.toString().toLowerCase() === 'officeadministrator')
            return 'Office Administrator';
        if(cell.toString().toLowerCase() === 'officeuser')
            return 'Office User';
        if(cell.toString().toLowerCase() === 'driver')
            return 'Employee';
        return cell;
    }

    nameFormatter(cell, row) {
        // return <a href='' onClick={this.goToEdit.bind(this,row.ItemId)}>{cell}</a>
        return <Link to={`/accounts/editAccount/${row.ItemId}`} >{cell}</Link>
    }

    goToEdit(id, e) {
        // if (this.props.onClick) {
        //   this.props.onClick()
        // }
        e.preventDefault()
        this.props.navProp.changeAccountPage('editAccount',id); 
    }

    setForwardToken = () => {
        // this.setState({ forwardToken: this.state.nextToken, backwardToken: undefined, refresh: true, isLoading: true},function () {
        //     this.fetchJobs();});
        this.setState({ page: this.state.page + 1, isLoading: true},function () {
            this.fetchUserAccounts();});
    }

    setBackwardToken = () => {
        // this.setState({ backwardToken: this.state.prevToken, forwardToken: undefined, refresh: true, isLoading: true},function () {
        //     this.fetchJobs();});
        this.setState({ page: this.state.page - 1, isLoading: true},function () {
            this.fetchUserAccounts();});
    }

    updatePageSize = (e) => {
        this.setState({ page: 1, pageSize: e.target.value, refresh: true, isLoading: true }, function () {
            this.fetchUserAccounts();});
    }

    onSortChange = (sortName, sortOrder) => {
        //console.info('onSortChange', arguments);
        // console.log('sortOrder', sortOrder);
        this.setState({
          page: 1, 
          sortName: sortName,
          sortOrder: sortOrder,
          isLoading: true,
          forwardToken: undefined,
          backwardToken: undefined
        },function () {
            this.fetchUserAccounts();});
        // console.log('sortName', this.state.sortName);
        // console.log('sortOrder', this.state.sortOrder);
        // this.fetchJobs();
    }

    renderPaginationPanel = (props) => {
        return (
            <Form as={Col} xs={12} className="form-inline bottom-margin">
                <Form.Group as={Col} xs={10}>
                <Form.Label>Records per page:&nbsp;</Form.Label>
                <Form.Control as="select" onChange={this.updatePageSize} defaultValue='50' size='sm'>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>    
                    <option value='25'>25</option>                             
                    <option value='30'>30</option>
                    <option value='50'>50</option>
                    <option value='70'>70</option>
                    <option value='100'>100</option> 
                </Form.Control>    
                </Form.Group>
                
                <Form.Group as={Col} xs={2}>
                    {!this.state.prevToken &&
                    <Button variant="success" disabled size="sm">Prev</Button>}
                    {this.state.prevToken &&
                    <Button variant="success" size="sm" onClick={this.setBackwardToken}>Prev</Button>}
                    &nbsp;
                    {!this.state.nextToken &&
                    <Button variant="success" disabled size="sm">Next</Button>}
                    {this.state.nextToken &&
                    <Button variant="success" size="sm" onClick={this.setForwardToken}>Next</Button>}
                </Form.Group>         
                
            </Form>
        );
      }

    componentDidMount() {
        this.fetchUserAccounts();
    }

    async fetchUserAccounts() {
        try{
            let filters = '';
            const {sortName, sortOrder, searchKeyword, pageSize, forwardToken, backwardToken, page, userStatus} = this.state;
            filters = '?pageSize=' + pageSize + '&page=' + page;
            if(sortName !== '' && sortOrder !== '')
                filters = filters + '&Sort=' + sortName + '~' + sortOrder;
            if(searchKeyword !== '')
                filters = filters + '&Condition=SearchField~contains~' + searchKeyword.trim().toLowerCase();
            if(userStatus !== "all")
                filters = filters + '&Status=' + userStatus;
            if(forwardToken)
                filters = filters + '&ForwardToken=' + forwardToken;
            if(backwardToken)
                filters = filters + '&BackwardToken=' + backwardToken;

            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/accounts" + filters, myInit);
            this.setState({
                accounts: res.accounts,
                nextToken: res.forwardPageToken,
                prevToken: res.backwardPageToken,
                isLoading: false,
                forwardToken: undefined,
                backwardToken: undefined
            })
        }catch(err) {
            this.setState(() => { throw err; })
        } 
    }

    render() {
        return (
            <React.Fragment>
                <Navbar className="bg-light padding-top-bottom-zero">
                    <Navbar.Brand href="/">
                        <h4 className="padding-top-bottom-zero">Staff</h4>
                    </Navbar.Brand>
                    <Navbar.Collapse className="" >
                            <Form.Row as={Col} xs={12}>
                                <Form.Group as={Col}className="margin-bottom-zero">
                                    <Form.Check className="med-font margin-bottom-zero" disabled={this.state.isLoading} onChange={(e)=> this.userStatusClick(e)} defaultChecked={true} type="radio" label="All" name="userStatus" value='all' />
                                </Form.Group>
                                <Form.Group as={Col}className="margin-bottom-zero">
                                    <Form.Check className="med-font margin-bottom-zero" disabled={this.state.isLoading} onChange={(e)=> this.userStatusClick(e)}  type="radio" label="Enabled Users" name="userStatus" value='active' />
                                </Form.Group>
                                <Form.Group as={Col}className="margin-bottom-zero">
                                    <Form.Check className="med-font margin-bottom-zero" disabled={this.state.isLoading} onChange={(e)=> this.userStatusClick(e)}  type="radio" label="Disabled Users" name="userStatus" value='inactive' />
                                </Form.Group>
                            </Form.Row>                        
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                            <Navbar.Text>
                                <FormControl type="text" placeholder="Search by Name or Company" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                                <span className='clickable mr-sm-2' onClick={()=> this.searchClick()}>
                                    <i className="fas fa-search" style={{ fontSize: '1em' }} />
                                </span>
                            </Navbar.Text>
                            <Button as={Link} to="/accounts/addAccount" className="mr-sm-1" variant="success" size="sm" >
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Staff
                            </Button>
                            <Button as={Link} to="/accounts/postcodes" className="mr-sm-1" variant="success" size="sm" >
                            <i className="fa fa-location-arrow" style={{ fontSize: '1em' }} /> Postcodes
                            </Button>
                            
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                {/* <Navbar className="bg-light padding-top-bottom-zero">
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                        <Button className="mr-sm-1" variant="success" size="sm" onClick={()=> this.changeAccountPage('addAccount')}>
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Staff
                        </Button>
                        <Navbar.Text>
                            <FormControl type="text" placeholder="Search by Name or Company" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                            <span className='clickable' onClick={()=> this.searchClick()}>
                                <i className="fas fa-search" style={{ fontSize: '1em' }} />
                            </span>
                        </Navbar.Text>
                        </Form>
                    </Navbar.Collapse>                  
                </Navbar> */}
                <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text='Loading staff accounts...'
                >
                <BootstrapTable ref='table' data={this.state.accounts} options={{onSortChange: this.onSortChange, noDataText: this.setNoDataText(), sortIndicator: false, paginationPanel: this.renderPaginationPanel }} remote={true} hover pagination>
                    <TableHeaderColumn dataField='ItemId' isKey hidden>ItemId</TableHeaderColumn>
                    <TableHeaderColumn dataField='FullName' thStyle={{ whiteSpace: 'normal' }} dataFormat={this.nameFormatter.bind(this)} dataSort={true}>Staff Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='AccountType' thStyle={{ whiteSpace: 'normal' }} dataFormat={this.accountTypeFormatter} dataSort={true}>Account Type</TableHeaderColumn>
                    <TableHeaderColumn dataField='Company' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true}>Company</TableHeaderColumn>
                    <TableHeaderColumn dataField='MobileNumber' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} >Mobile Number</TableHeaderColumn>
                    <TableHeaderColumn dataField='NotificationGroups' thStyle={{ whiteSpace: 'normal' }} dataSort={true}>Notification Groups</TableHeaderColumn>
                </BootstrapTable>
                </LoadingOverlay>
            </React.Fragment>
        )                
    }
}