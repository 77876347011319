import React, { Component } from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';
import "moment-timezone";
import { Auth, API } from "aws-amplify";
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';

export class SendSMSModal extends Component {
    state = {
        isProcessing: false,
        modalShow: false,
        users: undefined,
        recipients: [],
        allowedRecipients: [],
        selectedGroup: undefined,
        status: undefined
    };
    setModalShow = (show, selectedGroup) => {
        this.setState({modalShow:show, status:show===false ? undefined : this.state.status, selectedGroup: selectedGroup, recipients: selectedGroup === undefined ? [] : selectedGroup.Members.map(m => m.AccountId), allowedRecipients: selectedGroup === undefined ? [] : selectedGroup.Members.map(m => m.AccountId)});
    }
    changeRecipients = (to) => {
        const {selectedGroup} = this.state;
        if(to === 'all')
            this.setState({recipients: selectedGroup === undefined ? [] : selectedGroup.Members.map(m => m.AccountId), allowedRecipients: selectedGroup === undefined ? [] : selectedGroup.Members.map(m => m.AccountId)});
        if(to === 'admin')
            this.setState({recipients: selectedGroup === undefined ? [] : selectedGroup.Members.filter(i => i.AccountType.toLowerCase().indexOf('admin') > -1).map(m => m.AccountId), allowedRecipients: selectedGroup === undefined ? [] : selectedGroup.Members.filter(i => i.AccountType.toLowerCase().indexOf('admin') > -1).map(m => m.AccountId)});
        if(to === 'staff')
            this.setState({recipients: selectedGroup === undefined ? [] : selectedGroup.Members.filter(i => i.AccountType.toLowerCase().indexOf('admin') < 0).map(m => m.AccountId), allowedRecipients: selectedGroup === undefined ? [] : selectedGroup.Members.filter(i => i.AccountType.toLowerCase().indexOf('admin') < 0).map(m => m.AccountId)});
    }
    async componentDidMount() {    
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
        };
        const users = await API.get("api", "/accounts", myInit);
        this.setState({users: users.accounts});
    }
    getUsersArray() {
        return this.state.users.filter(u => this.state.allowedRecipients.indexOf(u.ItemId) > -1).map((user, index) => {
          const { FullName, ItemId, AccountType } = user
          return (
            { value: ItemId, label: FullName + (AccountType.toLowerCase().indexOf('admin') > -1 ? ' - Admin' : '') }
          )
        })
    }
    sendSMS = async (values, actions) => {
        try{
            const {selectedGroup} = this.state;
            let topic = selectedGroup.TopicARN;
            if(values.sendTo === 'admin')
                topic = selectedGroup.TopicARNAdmin;
            if(values.sendTo === 'staff')
                topic = selectedGroup.TopicARNStaff;
            const excluded = this.state.allowedRecipients.filter(a => this.state.recipients.indexOf(a) < 0);
            const recipients = this.state.users.filter(u => this.state.recipients.indexOf(u.ItemId) > -1).map(r => {
                return(
                    {
                        name: r.FullName,
                        mobileNumber:r.MobileNumber,
                        id: r.ItemId
                    }
                )
            });
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    sms: [
                        {
                            TopicARN: topic,
                            SMSMessage: values.message,
                            Excluded: excluded,
                            Recipients: recipients
                        }
                    ]
                    }
            };
            console.log('excluded', this.state.users.filter(u => excluded.indexOf(u.ItemId) > -1));
            const sendSMS = await API.post("api", "/sms", myInit);
            if(sendSMS.result === 'success')
            {
                this.setState({status:sendSMS.result});
                actions.setStatus({msg: "SMS Sent"});
                actions.setSubmitting(false);
            }                
            else{
                actions.setStatus({msg: sendSMS.errors[0]});
                this.setState({status:sendSMS.result});
                actions.setSubmitting(false);
            }
        }catch(error)
        {
            console.log('error', error.message);
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
    }
    render(){
        const schema = yup.object().shape({
            message: yup.string()
                    .required('Required')
        });
        return(
            <Formik
                validationSchema={schema}
                initialValues={{
                    message: '',
                    sendTo: 'all'
                }}
                onSubmit={async (values, actions) => {
                    await this.sendSMS(values, actions);
                }}
                >
                    {({ errors, touched, handleBlur, isValidating, handleSubmit, isSubmitting, values, handleChange, setFieldValue, status }) => (
                
                <Modal
                    size="md"
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    backdrop= "static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Send SMS to {this.state.selectedGroup && this.state.selectedGroup.Name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>         
                        <Form onSubmit={handleSubmit}>              
                        {this.state.selectedGroup &&
                        <React.Fragment>
                        <Form.Row>
                            <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                <Form.Label className="large-font bold-font margin-bottom-zero">Recipients:</Form.Label>                                    
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={12} controlId="usersSelect" className="margin-bottom-8">
                                {this.state.users &&
                                <Select 
                                //isDisabled={true}
                                className="basic-multi-select form-text-align" 
                                classNamePrefix="select" 
                                isMulti 
                                options={this.getUsersArray()} 
                                name="usersSelect"                                                        
                                value={this.getUsersArray().filter(option => this.state.recipients.indexOf(option.value) >= 0)}
                                onChange={(option) => {
                                    this.setState({recipients:option ? option.map((item,index) => item.value) : []});                                            
                                }}/>}                                   
                            </Form.Group>                            
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                <Form.Label className="large-font bold-font margin-bottom-zero">Send To:</Form.Label>                                    
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={6} controlId="sendTo" className="margin-bottom-8">
                                <Form.Control as="select"
                                    size="sm"
                                    value={values.sendTo}
                                    name="sendTo"
                                    onChange={ (e) => {
                                        this.changeRecipients(e.target.value);
                                        setFieldValue('sendTo', e.target.value);
                                    }}
                                >
                                <option value="all">All</option>
                                <option value="admin">Admin only</option>
                                <option value="staff">Staff only</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={12} controlId="message" className="margin-bottom-8">
                                <Form.Control as="textarea" rows="4" 
                                    size="sm"
                                    placeholder="Message" 
                                    name="message"
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.message}
                                    touched={touched.message}
                                    isInvalid={errors.message && touched.message}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row> 
                        </React.Fragment>}  
                        {this.state.selectedGroup === undefined && 
                        <Form.Row>
                            <Form.Group as={Col} xs={12} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                <Form.Label className="large-font bold-font margin-bottom-zero">No selected Notification Group.</Form.Label>                                    
                            </Form.Group>
                        </Form.Row>} 
                        {this.state.status && 
                        <Form.Row>
                            <Form.Group>
                                <Alert variant={this.state.status === 'failure' ? 'danger' : 'success'}>{status && status.msg}</Alert>
                            </Form.Group>
                        </Form.Row>
                        }
                        <br/>
                        <Form.Row>                             
                            <Button variant="primary" className="mr-sm-1" type="submit" disabled={(isSubmitting === true || this.state.recipients.length === 0) ? true : false} size="sm">
                            {isSubmitting ? 'Sending…' : 'Send'}
                            </Button>
                            <Button variant="primary" className="mr-sm-2" disabled={isSubmitting} onClick={() => {setFieldValue('sendTo', 'all');setFieldValue('message', '');this.setModalShow(false)}} size="sm">                                
                            Cancel
                            </Button>
                        </Form.Row> 
                        </Form>                                                                      
                    </Modal.Body>                   
                </Modal>                
            )}
            </Formik>
        )
    }
}