import React, {  useState, useEffect  } from 'react';
import {Container, Row, Col, Form, Button, Modal, Alert} from 'react-bootstrap';
import { Auth, API } from "aws-amplify";
import {authorizationHeader} from './Helper';

function DeleteSingleEntityButton(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');

    const deleteEntity = async () => {
        try{
            setIsProcessing(true);
            await API.del("api", "/" + props.entity + "/" + props.entityId, await authorizationHeader());
            props.history.push(props.postDeleteLocation);
        }catch(error)
        {
            if(error.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }        
    }

    return(
        <React.Fragment>
            <Button variant="primary" size="sm"  className="mr-sm-1" onClick={() => setShow(true)}>
                <i className="fa fa-trash" style={{ fontSize: '1em' }} /> Delete
            </Button>
            <Modal
                size="sm"
                show={show}
                onHide={() => setShow(false)}
                backdrop= "static"
            >
                <Modal.Body>
                    <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                        <Form.Label>Are you sure you want to delete?</Form.Label>                                
                    </Form.Group>
                    <Form.Group as={Col} xs={12} className="margin-top-10">
                        <Button disabled={(error || isProcessing) ? true : false} className="mr-sm-2" variant="success" size="sm" onClick={() => deleteEntity()}>
                            {isProcessing ? 'Deleting...' : 'Yes'}
                        </Button>
                        <Button disabled={(error || isProcessing) ? true : false} className="mr-sm-2" variant="success" size="sm" onClick={() => setShow(false)}>
                            No
                        </Button>
                    </Form.Group>
                    {error.length > 0 &&
                    <Form.Row>
                        <Form.Group>
                            <Alert variant='danger'>
                                {error}
                            </Alert>
                        </Form.Group>                                    
                    </Form.Row>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteSingleEntityButton