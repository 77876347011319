import React, { Component } from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';
import {Page, Text, View, Document, StyleSheet, Font, Image,  pdf, PDFViewer, Link } from "@react-pdf/renderer";
import moment from "moment";
import "moment-timezone";
import axios from 'axios';
import { Auth, API } from "aws-amplify";
import { saveAs } from 'file-saver';
import LoadingOverlay from 'react-loading-overlay';

//work around to reflect fontWeight
Font.register({
    family: 'Roboto',
    fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 600 }
    ]
});
// Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: "#ffffff"
    },
    section: {
      marginTop: 60,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      padding: 10,
      flexGrow: 1,
      alignItems : 'center'
    },
    header: {
        margin:10,
        padding: 10,
        flexGrow: 1,
        display: "flex",
        flexDirection: "row"
    },
    subdetails: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: 150,
        alignItems: "center",
        marginBottom: 12
    },
    logo: {
        height: 70,
        width: 150
    },
    icon: {
        margin: 5,
        marginRight: 1,
        height: 10,
        width: 10
    },
    image: {
        height: 500,
        width: 550,
        textAlign: 'center'
    },
    titleWithMargin: {
        fontSize: 15,
        marginBottom: 40
    },
    title: {
        fontSize: 15
    },
    description: { 
        margin: 5, 
        fontSize: 10,
        marginBottom: 40 
      },
      audiodescription: { 
        margin: 5, 
        fontSize: 10,
        marginBottom: 5 
      },
    body: {
        padding: 30
      },
      table: { 
        display: "table", 
        width: "auto"
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableRowWithBackground: { 
        margin: "auto", 
        flexDirection: "row",
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderTopWidth: 1,
        backgroundColor: "#e9ecef"
      },
      tableColHeader: { 
        width: "25%"
      },
      tableColHeaderRight: { 
        width: "25%",
        textAlign: 'right'
      },
      tableColHeader50: { 
        width: "50%"
      },
      tableColHeader50Right: { 
        width: "50%",
        textAlign: 'right'
      },
      tableColHeader33: { 
        width: "33.33%"
      },
      tableColHeader33Inline: { 
        width: "33.33%",
        flexDirection: "row"
      },
      tableColHeader20: { 
        width: "20%"
      },
      tableColHeader40: { 
        width: "40%"
      },
      tableColHeader80: { 
        width: "80%"
      },
      tableColHeader100: { 
        width: "100%"
      },
      tableCellHeader: {
        margin: 5, 
        fontSize: 12,
        fontWeight: 500
      },  
      tableCell: { 
        margin: 5, 
        fontSize: 10 
      },
      tableCellLarge: { 
        margin: 5, 
        fontSize: 22 
      },
      tableCellLargeBold: { 
        marginTop: 2,
        margin: 1, 
        fontSize: 18,
        fontWeight: 600,
        fontFamily: "Roboto"
      },
      tableCellMedium: { 
        margin: 1, 
        fontSize: 15 
      },
      tableCellMediumBold: { 
        marginTop: 5,
        margin: 1, 
        fontSize: 17,
        fontWeight: 600,
        fontFamily: "Roboto"
      },
      tableCellSmall: { 
        margin: 1, 
        fontSize: 10 
      },
      tableCellSmallBold: { 
        marginTop: 5,
        margin: 1, 
        fontSize: 10,
        fontWeight: 600,
        fontFamily: "Roboto"
      },
      tableCellText: { 
        margin: 5, 
        fontSize: 10 
      },
      tableCellTextBold: { 
        margin: 5, 
        fontSize: 10,
        fontWeight: 600,
        fontFamily: "Roboto"
      },
      pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        fontSize: 10,
        textAlign: 'center'
      },
      //for Label prints
      tableColHeaderLeftLabel: {
        margin: "auto", 
        width: "100%",
        textAlign: 'left', 
        flexDirection: "row",
        //borderStyle: "solid", 
        //borderColor: '#bfbfbf',
        //borderWidth: 1
      },
      tableColHeaderTop30Label: { 
        marginTop: 30,
        width: "auto",
        //borderStyle: "solid", 
        //borderColor: '#bfbfbf',
        //borderWidth: 1,
        display: "flex",
        //flexDirection: "row"
      },
      tableColHeaderLabel: { 
        width: "auto",
        //borderStyle: "solid", 
        //borderColor: '#bfbfbf',
        //borderWidth: 1,
        display: "flex",
        //flexDirection: "row"
      },
      tableColHeader78Label: { 
        width: "78%",
        //borderStyle: "solid", 
        //borderColor: '#bfbfbf',
        //borderWidth: 1,
        display: "flex",
        //flexDirection: "row"
      },
      tableCellLargeBold78Label: { 
        width: "78%",
        marginTop: 3,
        margin: 1, 
        fontSize: 35,
        fontWeight: 600,
        fontFamily: "Roboto",
        //borderStyle: "solid", 
        //borderColor: '#bfbfbf',
        //borderWidth: 1,
        wordWrap: 'break-word',
        display: "flex",
        //flexDirection: "row"
      },
      tableColHeader87Label: { 
        width: "87%",
        //borderStyle: "solid", 
        //borderColor: '#bfbfbf',
        //borderWidth: 1,
        display: "flex",
        //flexDirection: "row"
      },
      tableCellLargeBold87Label: { 
        width: "87%",
        marginTop: 3,
        margin: 1, 
        fontSize: 35,
        fontWeight: 600,
        fontFamily: "Roboto",
        //borderStyle: "solid", 
        //borderColor: '#bfbfbf',
        //borderWidth: 1,
        wordWrap: 'break-word',
        display: "flex",
        //flexDirection: "row"
      },
      tableCellLargeBoldLabel: { 
        width: "auto",
        marginTop: 3,
        margin: 1, 
        fontSize: 35,
        fontWeight: 600,
        fontFamily: "Roboto",
        //borderStyle: "solid", 
        //borderColor: '#bfbfbf',
        //borderWidth: 1,
        wordWrap: 'break-word',
        display: "flex",
        //flexDirection: "row"
      },
});

export async function DownloadPDF(attachments, job, history, type, noHistory, callBack, isLabel) {
    const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
    };

    const entities = ['files','operators'].map( async(entity) => {
        let res;
        if(entity === 'files')
            res = await API.get('api', '/files?ParentItemId=' + job.ItemId, myInit);
        else{
            try{
                const presignedRequest = await API.get('api', '/uploads?type=download&File=Logo.jpg&folder=' + job.OperatorId, myInit);
                res = 'data:image/png;base64,' +  (await axios.get(presignedRequest.url, { responseType: 'arraybuffer'}).then(response => new Buffer(response.data, 'binary').toString('base64')));
            }catch(err){
                res = '';
                console.log(err);
            }
        }
        return (
            {
                entity: entity,
                content: res
            }
        )
    });
    const resultEntities = await Promise.all(entities);
    const files = resultEntities.filter(e => e.entity === 'files')[0].content.files;
    const logo = resultEntities.filter(e => e.entity === 'operators')[0].content;
    //const images = attachments !== null ? attachments.filter(f => (f.toLowerCase().endsWith(".jpeg") || f.toLowerCase().endsWith(".jpg") || f.toLowerCase().endsWith(".png"))).map( async (file, index) => {
    const images = (attachments !== null && files !== null) ? files.filter(f => (f.Name.toLowerCase().endsWith(".jpeg") || f.Name.toLowerCase().endsWith(".jpg") || f.Name.toLowerCase().endsWith(".png"))).map( async (file, index) => {
        const res = await API.get('api', '/uploads?type=download&File=' + file.Name + '&folder=' + job.ItemId, myInit);
        const base64String = await axios.get(res.url, { responseType: 'arraybuffer'}).then(response => new Buffer(response.data, 'binary').toString('base64'));
        return (
            {
                fileName: file.Name,
                url:'data:image/' + (base64String.startsWith("i") ? 'png' : 'jpeg') + ';base64,' + base64String
            }
        )}) : [];
    const resultImages = await Promise.all(images);
    console.log('resultImages',resultImages);
    const txtFiles = (attachments !== null && files !== null) ? files.filter(f => (f.Name.toLowerCase().endsWith(".txt"))).map( async (file, index) => {
        const res = await API.get('api', '/uploads?type=download&File=' + file.Name + '&folder=' + job.ItemId, myInit);
        const fileData = await axios.get(res.url).then(response => response.data);
        return (
            {
                fileName: file.Name,
                content: fileData
            }
        )}) : [];
    const resultText = await Promise.all(txtFiles);
    console.log('resultText',resultText);
    const audioFiles = (attachments !== null && files !== null) ? files.filter(f => (f.Name.toLowerCase().endsWith(".m4a") || f.Name.toLowerCase().endsWith(".3gpp") || f.Name.toLowerCase().endsWith(".3gp"))).map( async (file, index) => {
        const res = await API.get('api', '/uploads?type=download&File=' + file.Name + '&folder=' + job.ItemId, myInit);
        const fileData = await axios.get(res.url).then(response => response.data);
        return (
            {
                fileName: file.Name,
                content: fileData
            }
        )}) : [];
    const resultAudio = await Promise.all(audioFiles);
    console.log('resultAudio',resultAudio);
    if(type === 'download')
    {    
        const blob = await pdf((
            <PDFDocument  audioFiles = {resultAudio} txtFiles= {resultText}  imageFiles={resultImages} job={job} logo={logo} history={history} noHistory={noHistory} files={files} />
        )).toBlob();
        saveAs(blob, job.ConsignmentNumber + '.pdf');
        callBack(true);
    }
    else {
        if(type === 'email')
        {
            const blob = await pdf((
                <PDFDocument audioFiles = {resultAudio} txtFiles= {resultText}  imageFiles={resultImages} job={job} logo={logo} history={history} noHistory={noHistory} files={files} />
            )).toBlob();
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };
            const res = await API.get("api", "/uploads?File=" + job.ConsignmentNumber + ".pdf" + "&Folder=Email", myInit);
            const uploadResponse = await axios({ method: 'put', url: res.url, data: blob});
            callBack(job.ConsignmentNumber + ".pdf");
        }
        else
            callBack(<PDFDocument audioFiles = {resultAudio}  txtFiles= {resultText} imageFiles={resultImages} isLabel={isLabel ? isLabel : false} job={job} logo={logo} history={history} noHistory={noHistory} files={files} />);
    }        
}

export class PDFButton extends Component {
    state = {
        isProcessing: false,
        isEmailing: false,
        modalShow: false,
        pdfContent: undefined,
        subject: '',
        recipients: '',
        message: '',
        uploadedFileName: '',
        error: ''
    };

    setModalShow = show => {
        this.setState({ modalShow: show });
        if(show === false)
            this.setState({ pdfContent: undefined, subject: '', recipients: '', message: '', uploadedFileName: '', error: '' });
    }

    download = async (attachments, job, history, id) => {
        this.setState({isProcessing: true});
        if(this.props.type === 'email')
            this.setState({ modalShow: true});
        if(id !== undefined)
        {
            const entityArray = ['job','history'];
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };
            const entities = entityArray.map( async (entity, index) => {
                let res;
                if(entity === 'job')
                    res = await API.get('api', '/jobs/' + id, myInit);
                if(entity === 'history')
                    res = await API.get('api', '/histories?ParentItemId=' + id, myInit);
                console.log('entity',entity);
                return (
                    {
                        entity: entity,
                        content: res
                    }
                )});
            const resultEntities = await Promise.all(entities);
            console.log('resultEntities',resultEntities);
            job = resultEntities.filter(e => e.entity === 'job')[0].content.jobs[0];
            attachments = job.Attachments;
            history = resultEntities.filter(e => e.entity === 'history')[0].content.histories;
        }        
        if(this.props.type === 'download')
            await DownloadPDF(attachments, job, history, this.props.type, this.props.noHistory ? this.props.noHistory : false, (response) => {this.setState({isProcessing: !response})}); 
        if(this.props.type === 'email')
            await DownloadPDF(attachments, job, history, this.props.type, this.props.noHistory ? this.props.noHistory : false, (response) => {this.setState({uploadedFileName: response})}); 
        if(this.props.type === 'view'){
            this.setState({ modalShow: true});
            await DownloadPDF(attachments, job, history, this.props.type, this.props.noHistory ? this.props.noHistory : false, (response) => {this.setState({pdfContent: response})}, this.props.isLabel ? this.props.isLabel : false);
        }            
        //console.log('response', isProcessing);
    }

    emailFile = async () => {
        try{
            this.setState({ isEmailing: true });
            this.setState({ error: '' });
            //check if POD is already uploaded. Loop if not yet.
            const authHeader = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                }
            };
            let isUploaded = false;
            for (let i = 0; i < 4; i++){
                if(isUploaded)
                    break;
                if(this.state.uploadedFileName.length > 0)
                {
                    const fileResponse = await API.get('api', '/uploads?type=download&File=' + this.state.uploadedFileName + '&Folder=Email', authHeader);
                    if(fileResponse['result'] === 'success')
                    {
                        await axios({ method: 'get', url: fileResponse['url']})
                            .then(response => {
                                //console.log(response);
                                isUploaded = true; 
                            })
                            .catch(error => {
                                console.log(error);
                            })            
                    }
                }
                await this.sleep(3000);
            }
            //
            if(isUploaded)
            {
                const myInit = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        files: [{ParentItemId: 'Email', Name: this.state.uploadedFileName}],
                        subject: this.state.subject.trim(),
                        recipients: this.state.recipients.trim(),
                        message: this.state.message.trim()
                        }
                };            
                const res = await API.post("api", "/email", myInit);
                if(res.result === 'failure')
                    this.setState({ error: res.errors });
                else
                    this.setModalShow(false);
            }
            else{
                this.setState({ error: 'Unexpected Error. File cannot be found' });
            }
            this.setState({ isEmailing: false });
        }catch(error)
        {
            if(this.state.error.toString().toLowerCase().indexOf('network error'))
                this.setState({ error: 'Network error. Please check internet connection then refresh the page.' })
            else
                this.setState({ error: 'Unexpected error encountered.' })
            this.setState({ isEmailing: false });
        }        
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render(){
        return(
            <div className={this.props.rightAlign ? 'float-right' : 'inline'}>
                <Button className={`mr-sm-2 ${this.props.size === 'small' ? 'btn-cust-sm' : 'btn-sm'}`} disabled={(this.state.isProcessing && this.props.type === 'download') ? true : false} variant={this.props.variant ? this.props.variant : "success"} size="sm" onClick={()=> this.download(this.props.attachments, this.props.job, this.props.history, this.props.id)}>
                    {(this.state.isProcessing && this.props.type === 'download') ? 'Downloading...' : this.props.display}                              
                </Button>
                <Modal
                    className="podModal"
                    size={'xl'}
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    backdrop= "static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        {this.props.type === 'email' ? this.props.display : ((this.props.isLabel && this.props.isLabel === true) ? 'LABEL' : 'POD')}
                        </Modal.Title>
                    </Modal.Header>
                    {this.props.type === 'view' &&
                    <LoadingOverlay
                        active={this.state.pdfContent === undefined}
                        spinner
                        text={(this.props.isLabel && this.props.isLabel === true) ? 'Loading...' : 'Loading POD...'}
                        >
                    <Modal.Body style={{height:'80vh'}}> 
                        {this.state.pdfContent !== undefined &&                   
                        <PDFViewer width='100%' height='100%'>
                            {this.state.pdfContent}
                        </PDFViewer>}                       
                    </Modal.Body>
                    </LoadingOverlay>
                    }
                    {this.props.type === 'email' &&
                    <Modal.Body> 
                        <Form.Group as={Col} xs={12}>
                            <Form.Label>Subject</Form.Label>   
                            <Form.Control 
                                size="sm"
                                type="text"
                                placeholder='Subject'
                                onChange={(e) => this.setState({ subject: e.target.value })}
                            />                            
                        </Form.Group>
                        <Form.Group as={Col} xs={12}>
                            <Form.Label>Recipients</Form.Label>   
                            <Form.Control 
                                size="sm"
                                type="text"
                                placeholder='separate email addresses using comma'
                                onChange={(e) => this.setState({ recipients: e.target.value })}
                            />                            
                        </Form.Group>
                        <Form.Group as={Col} xs={12}>
                            <Form.Label>Email Body</Form.Label>   
                            <Form.Control as="textarea" rows="3" 
                                size="sm"
                                onChange={(e) => this.setState({ message: e.target.value })}
                            />                             
                        </Form.Group>
                        <Form.Group as={Col} xs={12} className="margin-top-10">
                            <Button disabled={this.state.isEmailing ? true : false} className="mr-sm-2" variant="success" size="sm" onClick={() => this.emailFile()}>
                                {this.state.isEmailing ? 'Sending...' : 'Send Email'}
                            </Button>
                            <Button disabled={this.state.isEmailing ? true : false} className="mr-sm-2" variant="success" size="sm" onClick={() => this.setModalShow(false)}>
                                Cancel
                            </Button>
                        </Form.Group>
                        {this.state.error.length > 0 &&
                        <Form.Row>
                            <Form.Group>
                                <Alert variant='danger'>
                                    {this.state.error}
                                </Alert>
                            </Form.Group>                                    
                        </Form.Row>
                        }                     
                    </Modal.Body>
                    }
                </Modal>
            </div>
        )
    }
}

export default class PDFDocument extends Component {

    getStatusIcon = (statusGroup) => {
        if(statusGroup === 2)
            return '/status-sml-green.png';
        if(statusGroup === 3)
            return '/status-sml-orange.png';
        if(statusGroup === 4)
            return '/status-sml-red.png';
        if(statusGroup === 5)
            return '/status-sml-grey.png';        
        return '';
    }

    generateHistoryMessage = (history, index) => {
        const name = history.User.toLowerCase() === 'recurring' ? 'The System' : history.User;
        if(index > 0)
        {
            if(history.Category === 'status')
                return(`${name} updated the status to "${history.Status}".`);
            if(history.Category === 'driver')
                return(`${name} assigned the job to "${history.DriverName}".`);
        }            
        else
        {
            if(history.Category === 'status')
                return(`${name} created the job with status "${history.Status}".`);
            if(history.Category === 'driver')
                return(`${name} created the job and assigned to "${history.DriverName}".`);
        }   
    }

    componentDidMount() {
    }

    render() {
        if(this.props.isLabel && this.props.isLabel === true){
            var pages = [];
            let address = '';
            if(this.props.job.Street1 && this.props.job.Street1 !== '')
                address = this.props.job.Street1;
            if(this.props.job.Street2 && this.props.job.Street2 !== '' && (this.props.job.Street1 !== null ? this.props.job.Street1.trim().toLowerCase() : '') !== this.props.job.Street2.trim().toLowerCase())
                address = (address + ' ' + this.props.job.Street2).trim();
            if(this.props.job.Suburb && this.props.job.Suburb !== '')
                address = (address + ' ' + this.props.job.Suburb).trim();
            if(this.props.job.Zip && this.props.job.Zip !== '')
                address = (address + ', ' + this.props.job.Zip).trim();
            if(address.startsWith(','))
                address = address.substring(1).trim();
            for (var i = 1; i <= this.props.job.Cartons; i++) {
                pages.push(
                    <Page key={i} wrap={true} orientation="landscape" style={styles.body}>
                        <View style={styles.table} wrap={true}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeaderLeftLabel}>
                                    <View style={styles.tableColHeaderTop30Label}>
                                        <Text style={styles.tableCellLargeBoldLabel}>{"Consignment # : "}</Text>
                                    </View>
                                    <View style={styles.tableColHeaderTop30Label}>
                                        <Text style={styles.tableCellLargeBoldLabel}>{this.props.job.ConsignmentNumber}</Text>
                                    </View>
                                </View>                                
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeaderLeftLabel}>
                                    <View style={styles.tableColHeaderLabel}>
                                        <Text style={styles.tableCellLargeBoldLabel}>{"Delivery Client : "}</Text>
                                    </View>
                                    <View style={styles.tableColHeader78Label}>
                                        <Text style={styles.tableCellLargeBold78Label}>{this.props.job.BillingClient}</Text>
                                    </View>
                                </View> 
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeaderLeftLabel}>
                                    <View style={styles.tableColHeaderLabel}>
                                        <Text style={styles.tableCellLargeBoldLabel}>{"Delivery Address : "}</Text>
                                    </View>
                                    <View style={styles.tableColHeader78Label} wrap>
                                        <Text style={styles.tableCellLargeBold78Label} wrap>{address}</Text>
                                    </View>
                                </View> 
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeaderLeftLabel}>
                                    <View style={styles.tableColHeaderLabel}>
                                        <Text style={styles.tableCellLargeBoldLabel}>{"Notation : "}</Text>
                                    </View>
                                    <View style={styles.tableColHeader87Label}>
                                        <Text style={styles.tableCellLargeBold87Label}>{this.props.job.Notes}</Text>
                                    </View>
                                </View> 
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeaderLeftLabel}>
                                    <View style={styles.tableColHeaderLabel}>
                                        <Text style={styles.tableCellLargeBoldLabel}>{"Division : "}</Text>
                                    </View>
                                    <View style={styles.tableColHeader87Label}>
                                        <Text style={styles.tableCellLargeBold87Label}>{this.props.job.Division}</Text>
                                    </View>
                                </View> 
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeaderLeftLabel}>
                                    <View style={styles.tableColHeaderLabel}>
                                        <Text style={styles.tableCellLargeBoldLabel}>{'Box ' + i + ' of ' + this.props.job.Cartons}</Text>
                                    </View>
                                    <View style={styles.tableColHeaderLabel}>
                                        <Text style={styles.tableCellLargeBoldLabel}>{' '}</Text>
                                    </View>
                                </View>                                
                            </View>                      
                        </View>
                    </Page>
                );
            }
            return (
                <Document title={`${this.props.job.ConsignmentNumber}_LABEL`}>
                {pages}
                </Document>
            )
        }
        else{
            return (
                <Document title={`${this.props.job.ConsignmentNumber}_POD`}>
                    <Page style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeader}></View>
                                <View style={styles.tableColHeader}></View>
                                <View style={styles.tableColHeader}></View> 
                                <View style={styles.tableColHeader}> 
                                    <Image source={this.props.logo} alt="logo" /> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeader50}>
                                    <Text style={styles.tableCellLargeBold}>{this.props.job.Name}</Text>
                                    <Text style={styles.tableCellMedium}>{`Job #${this.props.job.ConsignmentNumber}`}</Text>
                                    {this.props.job.ReferenceNumbers !== null &&
                                    <Text style={styles.tableCellSmall}>{`Reference: ${this.props.job.ReferenceNumbers}`}</Text>}
                                    <Text style={styles.tableCellSmall}>{`Status: ${this.props.job.Status}`}</Text>
                                    <Text style={styles.tableCellSmall}>{`Number of boxes: ${this.props.job.Cartons}`}</Text>
                                    <Text style={styles.tableCellLarge}></Text>
                                    <Text style={styles.tableCellSmallBold}>Client:</Text>
                                    <Text style={styles.tableCellSmall}>{this.props.job.Name}</Text>
                                    <Text style={styles.tableCellSmall}>{this.props.job.Street1}</Text>
                                    {this.props.job.Street2 !== null &&
                                    <Text style={styles.tableCellSmall}>{this.props.job.Street2}</Text>}
                                    <Text style={styles.tableCellSmall}>{this.props.job.Suburb}</Text>
                                    <Text style={styles.tableCellSmall}>{this.props.job.Zip}</Text>
                                    <Text style={styles.tableCellLarge}></Text>
                                    <Text style={styles.tableCellSmall}>{this.props.job.Division}</Text>                                
                                </View>
                                <View style={styles.tableColHeader50Right}>
                                    <Text style={styles.tableCellSmallBold}>Assigned to:</Text>
                                    <Text style={styles.tableCellSmall}>{this.props.job.DriverName}</Text>
                                    {/* <Text style={styles.tableCellSmall}>All deliveries in AEST (Australian Eastern Standard Time)</Text> */}
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeader50}>
                                    <Text style={styles.tableCellLarge}></Text>
                                    <Text style={styles.tableCellLarge}></Text>
                                    <Text style={styles.tableCellMediumBold}>Status Updates</Text>
                                </View>
                                <View style={styles.tableColHeader50}>
                                </View>
                            </View>
                            <View style={styles.tableRowWithBackground}>
                                <View style={styles.tableColHeader33}>
                                    <Text style={styles.tableCellTextBold}>Time</Text>
                                </View>
                                <View style={styles.tableColHeader33}>
                                    <Text style={styles.tableCellTextBold}>Staff</Text>
                                </View>
                                <View style={styles.tableColHeader33}>                                
                                    <Text style={styles.tableCellTextBold}>Status</Text>
                                </View>
                            </View>
                            {this.props.history && this.props.history.filter(i => i.Category === 'status').map((h, index) => {
                            return(
                                <View style={styles.tableRow} key={index}>
                                    <View style={styles.tableColHeader33}>
                                        <Text style={styles.tableCellText}>{moment(h.DateUTC).tz("Australia/Sydney").format('HH:mm Do MMM YYYY')}</Text>
                                    </View>
                                    <View style={styles.tableColHeader33}>
                                        <Text style={styles.tableCellText}>{h.User.toLowerCase() === 'recurring' ? 'System' : h.User}</Text>
                                    </View>
                                    <View style={styles.tableColHeader33Inline}>
                                        <Image style={styles.icon} source={this.getStatusIcon(h.StatusGroup)} alt="icon" />
                                        <Text style={styles.tableCellText}>{h.Status}</Text>
                                    </View>
                                </View>                            
                            );                    
                            })} 
    
                            {(!this.props.noHistory || this.props.noHistory === false) &&
                            <React.Fragment>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColHeader50}>
                                        <Text style={styles.tableCellLarge}></Text>
                                        <Text style={styles.tableCellLarge}></Text>
                                        <Text style={styles.tableCellMediumBold}>History</Text>
                                    </View>
                                    <View style={styles.tableColHeader50}>
                                    </View>
                                </View>
                                <View style={styles.tableRowWithBackground}>
                                    <View style={styles.tableColHeader20}>
                                        <Text style={styles.tableCellTextBold}>Time</Text>
                                    </View>
                                    <View style={styles.tableColHeader80}>
                                        <Text style={styles.tableCellTextBold}>Action</Text>
                                    </View>
                                </View>
                                {this.props.history && this.props.history.map((h, index) => {
                                return(
                                    <View style={styles.tableRow} key={index}>
                                        <View style={styles.tableColHeader20}>
                                            <Text style={styles.tableCellText}>{moment(h.DateUTC).tz("Australia/Sydney").format('HH:mm Do MMM YYYY')}</Text>
                                        </View>
                                        <View style={styles.tableColHeader80}>
                                            <Text style={styles.tableCellText}>{this.generateHistoryMessage(h,index)}</Text>
                                        </View>
                                    </View>                            
                                );                    
                                })} 
                            </React.Fragment>
                            }

                            {this.props.job.PODNotes &&                       
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeader50}>
                                    <Text style={styles.tableCellLarge}></Text>
                                    <Text style={styles.tableCellLarge}></Text>
                                    <Text style={styles.tableCellMediumBold}>POD Notes</Text>
                                </View>
                                <View style={styles.tableColHeader50}>
                                </View>
                            </View>}
                            {this.props.job.PODNotes &&                       
                            <View style={styles.tableRow}>
                                 <View style={styles.tableColHeader100}>
                                    <Text style={styles.tableCellText}>{this.props.job.PODNotes}</Text>
                                </View>
                            </View>}

                            {this.props.files && this.props.files.length > 0 &&                       
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeader50}>
                                    <Text style={styles.tableCellLarge}></Text>
                                    <Text style={styles.tableCellLarge}></Text>
                                    <Text style={styles.tableCellMediumBold}>Notes</Text>
                                </View>
                                <View style={styles.tableColHeader50}>
                                </View>
                            </View>}
                            {this.props.files && this.props.files.length > 0 && 
                            <View style={styles.tableRowWithBackground}>
                                <View style={styles.tableColHeader20}>
                                    <Text style={styles.tableCellTextBold}>Date</Text>
                                </View>
                                <View style={styles.tableColHeader40}>
                                    <Text style={styles.tableCellTextBold}>Description</Text>
                                </View>
                                <View style={styles.tableColHeader40}>
                                    <Text style={styles.tableCellTextBold}>Attachment</Text>
                                </View>
                            </View>}
                            {this.props.files && this.props.files.map((f, index) => {
                            return(
                                <View style={styles.tableRow} key={index}>
                                    <View style={styles.tableColHeader20}>
                                        <Text style={styles.tableCellText}>{moment(f.Created).tz("Australia/Sydney").format('DD-MM-YYYY HH:mm')}</Text>
                                    </View>
                                    <View style={styles.tableColHeader40}>
                                        <Text style={styles.tableCellText}>{(f.Description && f.Description !== 'undefined') ? f.Description : 'N/A' }</Text>
                                    </View>
                                    <View style={styles.tableColHeader40}>
                                        <Text style={styles.tableCellText}>{f.Name}</Text>
                                    </View>
                                </View>                           
                            );                    
                            })}                        
                        </View>                 
                        {this.props.imageFiles && this.props.imageFiles.map((d, index) => {
                            const file = (this.props.files && this.props.files.filter(f => f.Name === d.fileName).length > 0) ? this.props.files.filter(f => f.Name === d.fileName) : [];
                            const description = (file.length > 0 && file[0].Description !== 'undefined')  ? file[0].Description : 'N/A';
                            return(
                                <View style={styles.section} key={index} break>
                                    <Text style={description !== undefined ? styles.title : styles.titleWithMargin}>{`Attachment ${d.fileName}`}</Text>   
                                    {description !== 'N/A'  &&                             
                                    <Text style={styles.description}>{description}</Text>}
                                    <Image style={styles.image} source={d.url} alt="attachment" />
                                </View>
                            );                    
                        })}
                        {this.props.txtFiles && this.props.txtFiles.map((d, index) => {
                            const file = (this.props.files && this.props.files.filter(f => f.Name === d.fileName).length > 0) ? this.props.files.filter(f => f.Name === d.fileName) : [];
                            console.log('file',file);
                            const description = (file.length > 0 && file[0].Description !== 'undefined')  ? file[0].Description : 'N/A';
                            console.log('description',description);
                            return(
                                <View style={styles.section} key={index} break>
                                    <Text style={description !== undefined ? styles.title : styles.titleWithMargin}>{`Attachment ${d.fileName}`}</Text>   
                                    {description !== undefined &&                             
                                    <Text style={styles.description}>{description}</Text>}
                                    <Text style={styles.description}>{d.content}</Text>
                                </View>
                            );                    
                        })}
                        {this.props.audioFiles && this.props.audioFiles.map((d, index) => {
                            const file = (this.props.files && this.props.files.filter(f => f.Name === d.fileName).length > 0) ? this.props.files.filter(f => f.Name === d.fileName) : [];
                            console.log('file',file);
                            const description = (file.length > 0 && file[0].Description !== 'undefined')  ? file[0].Description : 'N/A';
                            console.log('description',description);
                            const cloudFrontURL = (file.length > 0 && file[0].CloudFrontURL !== 'undefined')  ? file[0].CloudFrontURL : 'N/A';
                            return(
                                <View style={styles.section} key={index} break>
                                    <Text style={description !== undefined ? styles.title : styles.titleWithMargin}>{`Attachment ${d.fileName}`}</Text>   
                                    {description !== undefined &&                             
                                    <Text style={styles.audiodescription}>{description}</Text>}
                                    {cloudFrontURL !== undefined && 
                                            <Text style={styles.audiodescription}>Click 
                                            <Link  src= {cloudFrontURL}> here </Link>
                                            to download this audio file.
                                            </Text>}
                                </View>
                                
                            );                    
                        })}
                    <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                        )} fixed /> 
                    </Page>
                </Document>
            )
        }        
    }
    
}