import React, { useState, useEffect, useRef } from 'react';
import {Table, Alert, Navbar, Form, Button, Modal, Col} from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { Auth, API } from "aws-amplify";
import {authorizationHeader, compareValues} from './utility/Helper';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';

const initialPostcodes = {
    NSW:[], VIC:[], QLD:[], WA:[], ACT:[], NT:[], SA:[], TAS:[]
};

let selectedPostcodes = [];

function Postcodes(props) {
    const [postcodes, setPostcodes] = useState(initialPostcodes);
    const [isProcessing, setIsProcessing] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [state, setState] = useState('VIC');
    const [sortBy, setSortBy] = useState('Postcode');
    const [sortDirection, setSortDirection] = useState('asc');
    const [withOneDriver, setWithOneDriver] = useState(true);
    const [withMultipleDriver, setWithMultipleDriver] = useState(true);
    const [notAssigned, setNotAssigned] = useState(true);
    const [error, setError] = useState('');
    const [singleSelectedDriver, setSingleSelectedDriver] = useState('All');
    const checkBoxRef = useRef([]);
    const selectAllCheckBoxRef = useRef([]);

    useEffect(() => {
        //console.log('useEffectPostcode');
        fetchEntities();
    },[]);

    useEffect(() => {
        //console.log('useEffectPostcode');
        fetchEntities(); 
        clearCheckboxes();    
    },[singleSelectedDriver]);

    const fetchEntities = async (driverOnly) => {
        if(!driverOnly)
            setIsProcessing(true);
        try{
            let entityArray = ['drivers','nsw','vic','qld','wa','act','nt','sa','tas'];
            if(driverOnly)
                entityArray = ['drivers'];
            const authHeader = await authorizationHeader();
            const entities = entityArray.map( async (entity, index) => {
                try{
                    let res;
                    switch (entity) {
                        case 'drivers':
                            res = await API.get('api', '/accounts?AccountType=Driver', authHeader);
                            break;
                        default:
                            res = await API.get('api', '/postcodes?State=' + entity.toUpperCase(), authHeader);
                      }                 
                    //console.log('entity',entity);
                    return (
                        {
                            entity: entity,
                            content: res
                        }
                    )
                }catch(err) {
                    console.log('err', err);
                    if(err.toString().toLowerCase().indexOf('network error'))
                        setError('Network error. Please check internet connection then refresh the page.');
                    else
                        setError('Unexpected error encountered.');
                }
            });
            
            const resultEntities = await Promise.all(entities);
            // console.log('resultEntities',resultEntities);
            let assignedDrivers = [];
            //setDrivers(resultEntities.filter(e => e.entity === 'drivers')[0].content.accounts);
            resultEntities.filter(e => e.entity === 'drivers')[0].content.accounts.forEach(d => {
                if(d.TerritoryPostcodes !== null && d.TerritoryPostcodes.length > 0)
                {
                    d.TerritoryPostcodes.split(',').forEach(p => {
                        if(assignedDrivers.filter(a => a.Driver === d.FullName && a.Postcode.trim() === p.trim()).length === 0)
                        {
                            assignedDrivers.push({
                                ItemId: d.ItemId,
                                Driver: d.FullName,
                                Postcode: p
                            })
                        }
                    });
                }
            });
            setDrivers(assignedDrivers);
            if(!driverOnly){
                resultEntities.forEach(s => {
                    if(s.entity !== 'drivers')
                    {
                        if(singleSelectedDriver === "All") {
                            initialPostcodes[s.entity.toUpperCase()] = resultEntities.filter(e => e.entity === s.entity)[0].content.postcodes;
                        }
                        else {
                            initialPostcodes[s.entity.toUpperCase()] = resultEntities.filter(e => e.entity === s.entity)[0].content.postcodes;
                            initialPostcodes[s.entity.toUpperCase()] = initialPostcodes[s.entity.toUpperCase()].filter(p => {
                                var driverNames = drivers.filter(d => d.Postcode.trim() === p.Postcode.trim()).length > 0 ? drivers.filter(d => d.Postcode.trim() === p.Postcode.trim()) : [];
                                if(driverNames.length > 0) {
                                    if(driverNames.map(d => d.Driver.toLowerCase()).indexOf(singleSelectedDriver.toLowerCase()) > -1) {
                                        return p;
                                    }                                        
                                }
                            });
                        }
                    }
                });
                setPostcodes(initialPostcodes);                
            }            
            console.log('assigned entities to variables');            
        }catch(error) {
            //setError(error.toString().toLowerCase());
            console.log('error',error);
        } 
        if(!driverOnly) 
            setIsProcessing(false);      
    }

    const handleStateChange = event => {
        setState(event.target.value);
        clearCheckboxes();
    }

    const clearCheckboxes = () => {        
        selectAllCheckBoxRef.current.map(c => {
            if(c !== null)
            {
                if (c.checked) {
                    c.click();
                }
            }
        });
        checkBoxRef.current.map(c => {
            if(c !== null)
            {
                if (c.checked) {
                    c.click();
                }
            }
        });
    }

    const getSelectedPostcodes = () => {
        return selectedPostcodes;
    }

    const displayPostcodes = () => {
        if(error.length > 0)
        {
            return(
                <Form.Row>
                    <Form.Group>
                        <Alert variant='danger'>
                            {error}
                        </Alert>
                    </Form.Group>                                    
                </Form.Row>
            )
        }else{
            if(postcodes[state].length > 0)
            {
                let sortedList = postcodes[state].map((p,index) => {
                    const driverNames = drivers.filter(d => d.Postcode.trim() === p.Postcode.trim()).length > 0 ? drivers.filter(d => d.Postcode.trim() === p.Postcode.trim()) : [];
                    let displayName = '';
                    driverNames.forEach(d => {
                        displayName = displayName === '' ? displayName + d.Driver : displayName + '<br>' + d.Driver;
                    });
                    return({
                        ItemId: p.ItemId,
                        Postcode: p.Postcode,
                        Locality: p.Locality,
                        Driver: displayName,
                        DriverNames: driverNames
                    })
              
                });
                sortedList = sortedList.filter(p => {
                    if(withOneDriver && p.DriverNames.length === 1)
                        return p;
                    if(withMultipleDriver && p.DriverNames.length > 1)
                        return p;
                    if(notAssigned && p.DriverNames.length === 0)
                        return p;
                });
                //removed selected that are no longer displayed
                selectedPostcodes = selectedPostcodes.filter(p => sortedList.filter(s => s.Postcode === p).length > 0);
                sortedList.sort(compareValues(sortBy, sortDirection));                              
                return(
                    <React.Fragment>
                        <Toaster />
                        <Form.Row>
                            <Form.Group className='left-align margin-bottom-zero padding-bottom-5 padding-left-15' as={Col}>
                                <Form.Check
                                    defaultChecked={selectedPostcodes.length == sortedList.length ? true : false}
                                    isChecked={selectedPostcodes.length == sortedList.length ? true : false}
                                    ref={el => selectAllCheckBoxRef.current[0] = el}
                                    className="med-font margin-bottom-zero"
                                    label="Select all postcodes" 
                                    name="alldrivers"
                                    onChange={(e) => {
                                        if(e.target.checked)
                                        {
                                            checkBoxRef.current.map(c => {
                                                if(c !== null)
                                                {
                                                    if(!c.checked)
                                                        c.click();
                                                }
                                            });
                                        }  
                                        else {
                                            if(selectedPostcodes.length === sortedList.length) {
                                                checkBoxRef.current.map(c => {
                                                    if(c !== null)
                                                    {
                                                        if(c.checked)
                                                            c.click();
                                                    }
                                                });
                                            }
                                        }  
                                                                      
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col}></Form.Group>
                            <Form.Group as={Col}></Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} >
                                <PostcodeTable>
                                    {showRows(1)}
                                </PostcodeTable>
                            </Form.Group>
                            {postcodes[state].length > 1 &&
                            <Form.Group as={Col} >
                                <PostcodeTable>
                                    {showRows(2)}
                                </PostcodeTable>
                            </Form.Group>
                            }
                            {postcodes[state].length > 2 &&
                            <Form.Group as={Col} >
                                <PostcodeTable>
                                    {showRows(3)}
                                </PostcodeTable>
                            </Form.Group>   
                            }                                 
                        </Form.Row>
                    </React.Fragment>                    
                )
            }else{
                if(isProcessing){
                    return(
                        <Form.Row>
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/>                               
                        </Form.Row>
                    )
                }
            }
        }        
    }

    const getDriversArrayForSelection = () => {
        let filteredDrivers = drivers;   
        filteredDrivers = [...new Map(filteredDrivers.map(item => [item['Driver'], item])).values()]     
        return filteredDrivers.map((driver, index) => {
          const { Driver, ItemId } = driver
          return (
            <option key={ItemId} value={Driver} >{Driver}</option>
          )
        })
    }

    const showRows = (tableNumber) => {
        if(postcodes[state].length > 0){
            // const total = postcodes[state].length;
            // const recordsPerTable = Math.ceil(total / 3);
            let sortedList = postcodes[state].map((p,index) => {
                const driverNames = drivers.filter(d => d.Postcode.trim() === p.Postcode.trim()).length > 0 ? drivers.filter(d => d.Postcode.trim() === p.Postcode.trim()) : [];
                let displayName = '';
                driverNames.forEach(d => {
                    displayName = displayName === '' ? displayName + d.Driver : displayName + '<br>' + d.Driver;
                });
                return({
                    ItemId: p.ItemId,
                    Postcode: p.Postcode,
                    Locality: p.Locality,
                    Driver: displayName,
                    DriverNames: driverNames
                })
          
            });
            sortedList = sortedList.filter(p => {
                if(withOneDriver && p.DriverNames.length === 1)
                    return p;
                if(withMultipleDriver && p.DriverNames.length > 1)
                    return p;
                if(notAssigned && p.DriverNames.length === 0)
                    return p;
            });
            //removed selected that are no longer displayed
            selectedPostcodes = selectedPostcodes.filter(p => sortedList.filter(s => s.Postcode === p).length > 0);
            sortedList.sort(compareValues(sortBy, sortDirection));
            const total = sortedList.length;
            const recordsPerTable = Math.ceil(total / 3);            
            return sortedList.map((p,index) => {
                let show = false;
                if(tableNumber === 1 && (index + 1) <= recordsPerTable)
                    show = true;
                if(tableNumber === 2 && (index + 1) > recordsPerTable && (index + 1) <= (recordsPerTable * 2))
                    show = true;
                if(tableNumber === 3 && (index + 1) > (recordsPerTable * 2))
                    show = true;
                const driverNames = drivers.filter(d => d.Postcode.trim() === p.Postcode.trim()).length > 0 ? drivers.filter(d => d.Postcode.trim() === p.Postcode.trim()) : [];
                // let displayName = '';
                // driverNames.forEach(d => {
                //     displayName = displayName === '' ? displayName + d.Driver : displayName + '<br>' + d.Driver;
                // });
                let rowColor = '';
                if(driverNames.length === 0)
                    rowColor = 'row-yellow';
                if(driverNames.length > 1)
                    rowColor = 'row-orange';
                if(show){
                    return (
                        <tr className={rowColor} key={p.ItemId}>
                        <td className="small-font App">
                            <Form.Check
                            defaultChecked={selectedPostcodes.filter(s => s === p.Postcode).length > 0 ? true : false}
                            isChecked={selectedPostcodes.filter(s => s === p.Postcode).length > 0 ? true : false}
                            ref={el => checkBoxRef.current[index] = el}
                            onChange={(e) => {
                                if(e.target.checked)
                                {
                                    //setSelectedPostcodes(selectedPostcodes => [...selectedPostcodes, p.Postcode])
                                    selectedPostcodes = [...selectedPostcodes, p.Postcode];
                                    if(selectedPostcodes.length === sortedList.length) {
                                        selectAllCheckBoxRef.current.map(c => {
                                            if(c !== null)
                                            {
                                                if (!c.checked) {
                                                    c.click();
                                                }
                                            }
                                        });
                                    }
                                }  
                                else{
                                    selectedPostcodes = selectedPostcodes.filter(s => s !== p.Postcode);
                                    if(selectedPostcodes.length !== sortedList.length) {
                                        selectAllCheckBoxRef.current.map(c => {
                                            if(c !== null)
                                            {
                                                if (c.checked) {
                                                    c.click();
                                                }
                                            }
                                        });
                                    }
                                }                                  
                            }}
                            />
                        </td>
                        <td className="small-font">{p.Postcode}</td>
                        <td className="small-font left-align">{p.Locality}</td>
                        <td className="small-font left-align">{p.Driver.split('<br>').map((item, i) => {
                                        return <p className="margin-bottom-zero" key={i}>{item}</p>})}</td>
                        <td className="small-font bold-font"><EditDriverAssignmentModal updateParent={fetchEntities} postcode={p.Postcode} drivers={p.DriverNames} /></td>
                        </tr>
                    );
                }
            });                       
        }        
    }

    return(
        <React.Fragment>
            <Navbar className="bg-light padding-top-bottom-zero">
                <Navbar.Brand href="/">
                    <h4 className="padding-top-bottom-zero">Postcode Allocations</h4>
                </Navbar.Brand>
                <Navbar.Collapse className="" >
                        <Form.Row as={Col} xs={7}>
                            <Form.Group as={Col}className="margin-bottom-zero">
                                <Form.Check className="med-font margin-bottom-zero" onChange={handleStateChange} defaultChecked={true} type="radio" label="VIC" name="states" value='VIC' />
                            </Form.Group>
                            <Form.Group as={Col}className="margin-bottom-zero">
                                <Form.Check className="med-font margin-bottom-zero" onChange={handleStateChange} type="radio" label="NSW" name="states" value='NSW' />
                            </Form.Group>
                            <Form.Group as={Col}className="margin-bottom-zero">
                                <Form.Check className="med-font margin-bottom-zero" onChange={handleStateChange} type="radio" label="QLD" name="states" value='QLD' />
                            </Form.Group>
                            <Form.Group as={Col}className="margin-bottom-zero">
                                <Form.Check className="med-font margin-bottom-zero" onChange={handleStateChange} type="radio" label="WA" name="states" value='WA' />
                            </Form.Group>
                            <Form.Group as={Col}className="margin-bottom-zero">
                                <Form.Check className="med-font margin-bottom-zero" onChange={handleStateChange} type="radio" label="SA" name="states" value='SA' />
                            </Form.Group>
                            <Form.Group as={Col}className="margin-bottom-zero">
                                <Form.Check className="med-font margin-bottom-zero" onChange={handleStateChange} type="radio" label="TAS" name="states" value='TAS' />
                            </Form.Group>
                            <Form.Group as={Col}className="margin-bottom-zero">
                                <Form.Check className="med-font margin-bottom-zero" onChange={handleStateChange} type="radio" label="ACT" name="states" value='ACT' />
                            </Form.Group>
                            <Form.Group as={Col}className="margin-bottom-zero">
                                <Form.Check className="med-font margin-bottom-zero" onChange={handleStateChange} type="radio" label="NT" name="states" value='NT' />
                            </Form.Group>
                        </Form.Row>                                               
                </Navbar.Collapse>
            </Navbar>
            <Form.Row>
                <Form.Group as={Col} xs={12}>
                    <Form.Row>
                        <Form.Group className='left-align top-margin-20 margin-bottom-zero' as={Col}>
                            <Form.Check
                            defaultChecked={true}
                            className="med-font margin-bottom-zero row-orange"
                            label="With multiple drivers"
                            onChange={(e) => setWithMultipleDriver(e.target.checked)}
                            />
                            {/* <span className="legend-span mr-sm-2 row-orange"></span><span className="small-font bold-font">With multiple drivers</span> */}
                        </Form.Group>
                        <Form.Group className='left-align top-margin-20 margin-bottom-zero' as={Col}>
                            <Form.Check
                            defaultChecked={true}
                            className="med-font margin-bottom-zero row-yellow"
                            label="Not assigned"
                            onChange={(e) => setNotAssigned(e.target.checked)}
                            />
                            {/* <span className="legend-span mr-sm-2 row-yellow"></span><span className="small-font bold-font">Not assigned</span> */}
                        </Form.Group>
                        <Form.Group className='left-align top-margin-20 margin-bottom-zero' as={Col}>
                            <Form.Check
                            defaultChecked={true}
                            className="med-font margin-bottom-zero"
                            label="With one driver"
                            onChange={(e) => setWithOneDriver(e.target.checked)}
                            />
                            {/* <span className="legend-span mr-sm-2"></span><span className="small-font bold-font">With one driver</span> */}
                        </Form.Group>
                        <Form.Group className='left-align top-margin-20 margin-bottom-zero' as={Col} xs={6}>
                            <Form.Row>
                                <Form.Group className="margin-bottom-zero" as={Col}>
                                    <Form.Label className="med-font margin-bottom-zero">Sort by:</Form.Label>
                                </Form.Group>
                                <Form.Group className="margin-bottom-zero" as={Col} xs={3}>
                                    <Form.Control as="select"
                                        size="sm"
                                        name="sortBy"
                                        placeholder="Sort By"
                                        onChange={(e) => setSortBy(e.target.value)}
                                    >
                                    <option value='Postcode'>Postcode</option>
                                    <option value='Locality'>Locality</option>
                                    <option value='Driver'>Driver</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="margin-bottom-zero" as={Col}>
                                    <Form.Label className="med-font margin-bottom-zero">Direction:</Form.Label>
                                </Form.Group>
                                <Form.Group className="margin-bottom-zero" as={Col} xs={2}>
                                    <Form.Control as="select"
                                        size="sm"
                                        name="sortDirection"
                                        placeholder="Sort Direction"
                                        onChange={(e) => setSortDirection(e.target.value)}
                                    >
                                    <option value='asc'>asc</option>
                                    <option value='desc'>desc</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="margin-bottom-zero" as={Col}>
                                    <Form.Label className="med-font margin-bottom-zero">Drivers:</Form.Label>
                                </Form.Group>
                                <Form.Group className="margin-bottom-zero" as={Col} xs={4}>
                                    <Form.Control as="select"
                                        size="sm"
                                        name="driversList"
                                        placeholder="List of Drivers"
                                        onChange={(e) => { setSingleSelectedDriver(e.target.value);  } }
                                    >
                                    <option value='All'>All</option>
                                    {drivers && getDriversArrayForSelection()}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>                            
                        </Form.Group>
                    </Form.Row>
                </Form.Group>
            </Form.Row>
            <Form.Row> 
                <Form.Group>
                    <Form.Row>
                        <Form.Group className="mb-0" as={Col} xs={12}>
                            <EditDriverAssignmentModal updateParent={fetchEntities} getSelectedPostcodes={getSelectedPostcodes} isButton={true} />
                                {/* <Button variant="primary" className="mr-sm-1" size="sm">
                                    Assign
                                </Button> */}
                                <Button variant="primary" className="mr-sm-2" size="sm" onClick={() => {
                                    selectedPostcodes = [];
                                    //console.log(checkBoxRef);
                                    clearCheckboxes();
                                }}>                                
                                    Clear
                                </Button>
                                <CheckForPostcodesUpdateModal updateParent={fetchEntities}  />
                        </Form.Group>
                    </Form.Row>
                </Form.Group>
            </Form.Row>
            <LoadingOverlay
            active={isProcessing}
            spinner
            text='Loading postcodes...'
            >
            {
                displayPostcodes()                  
            }
            </LoadingOverlay>
            {/* Only used for Postcode parsing from other sources */}
            {/* <PostcodeParsing /> */}
        </React.Fragment>        
    )
}

function PostcodeTable(props) {
    return(
        <Table bordered className="table-border-medium" size="sm">
            <tbody>
                <tr>
                <td className="small-font bold-font"></td>
                <td className="small-font bold-font">Postcode</td>
                <td className="small-font bold-font left-align">Locality</td>
                <td className="small-font bold-font left-align">Driver</td>
                <td className="small-font bold-font">Edit</td>
                </tr>
                {props.children}
            </tbody>
        </Table>
    )
}

function EditDriverAssignmentModal(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [groupDrivers, setGroupDrivers] = useState([]);
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [notificationGroups, setNotificationGroups] = useState([]);
    const [selectedNotificationGroup, setSelectedNotificationGroup] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if(show)
            fetchEntities();
        else
        {
            setDrivers([]);
            setNotificationGroups([]);
        }
    },[show])

    useEffect(() => {
        if(selectedNotificationGroup !== '' && notificationGroups.filter(n => n.ItemId === selectedNotificationGroup).length > 0)
        {
            setGroupDrivers(notificationGroups.filter(n => n.ItemId === selectedNotificationGroup)[0].Members.filter(m => m.AccountType.toLowerCase() === 'driver').map(m => m.AccountId));
        }
        else
        {
            setGroupDrivers([]);
        }
    },[selectedNotificationGroup])

    useEffect(() => {
        if(selectedNotificationGroup !== '' )
        {
            setSelectedDrivers(selectedDrivers.filter(s => groupDrivers.indexOf(s) >= 0));
        }
    },[groupDrivers])

    const fetchEntities = async () => {
        setIsProcessing(true);
        try{
            const entityArray = ['drivers','workgroups'];
            const authHeader = await authorizationHeader();
            const entities = entityArray.map( async (entity, index) => {
                try{
                    let res;
                    switch (entity) {
                        case 'drivers':
                            res = await API.get('api', '/accounts?AccountType=Driver', authHeader);
                            break;
                        default:
                            res = await API.get('api', '/' + entity, authHeader);
                      }                 
                    return (
                        {
                            entity: entity,
                            content: res
                        }
                    )
                }catch(err) {
                    if(err.toString().toLowerCase().indexOf('network error'))
                        setError('Network error. Please check internet connection then refresh the page.');
                    else
                        setError('Unexpected error encountered.');
                }
            });
            
            const resultEntities = await Promise.all(entities);
            setDrivers(resultEntities.filter(e => e.entity === 'drivers')[0].content.accounts);
            setNotificationGroups(resultEntities.filter(e => e.entity === 'workgroups')[0].content.workgroups);
        }catch(error) {
            //setError(error.toString().toLowerCase());
        }  
        setIsProcessing(false);      
    }

    const getDriversArray = () => {
        let filteredDrivers = drivers;
        if(selectedNotificationGroup !== '')
        {
            filteredDrivers = filteredDrivers.filter(d => groupDrivers.indexOf(d.ItemId) >= 0);
        }
        return filteredDrivers.map((driver, index) => {
          const { FullName, ItemId } = driver
          return (
            { value: ItemId, label: FullName }
          )
        })
    }    

    const getNotificationGroups = () => {        
        return notificationGroups.map((group, index) => {
           const { Name, ItemId } = group
           return (
               <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }

    const editAssignment = (e, postcode, drivers) =>{
        e.preventDefault();
        setSelectedDrivers(drivers.map(d => d.ItemId));
        setShow(true);
        setIsSaving(false);
        setError('');
    }

    const bulkAssignment = () =>{
        setShow(true);
        setSelectedDrivers([]);
        setIsSaving(false);
        setError('');
        console.log('getSelected', props.getSelectedPostcodes());
    }

    const saveChanges = async () => {
        console.log('saving');
        setIsSaving(true);
        setIsProcessing(true);
        let postcodes = [];
        let Drivers;
        if(props.postcode)
            postcodes.push(props.postcode);
        else
            postcodes = props.getSelectedPostcodes();

        postcodes.map(postcode => {
            const originalDriversId = props.drivers ? props.drivers.map(d => d.ItemId) : drivers.filter(d => d.TerritoryPostcodes !== null && d.TerritoryPostcodes.split(',').filter(t => t === postcode).length > 0).map(o => o.ItemId);
            console.log(originalDriversId);
            if(!Drivers)
                Drivers = drivers.filter(d => originalDriversId.indexOf(d.ItemId) >= 0);
            else
            {
                drivers.filter(d => originalDriversId.indexOf(d.ItemId) >= 0).forEach(a => {
                    if(Drivers.filter(b => b.ItemId === a.ItemId).length === 0)
                        Drivers.push(a);
                });
            }
            drivers.filter(d => selectedDrivers.indexOf(d.ItemId) >= 0).forEach(a => {
                if(Drivers.filter(b => b.ItemId === a.ItemId).length === 0)
                    Drivers.push(a);
            });
            Drivers = Drivers.map(d => {
                const newData = d;
                let territoryPostcodes = newData.TerritoryPostcodes !== null ? newData.TerritoryPostcodes : '';
                if(originalDriversId.indexOf(d.ItemId) >= 0 && selectedDrivers.indexOf(d.ItemId) < 0)
                {
                    //initially assigned but removed
                    newData.TerritoryPostcodes = territoryPostcodes.split(',').filter(p => p !== postcode).join();
                    console.log('removal',territoryPostcodes.split(',').filter(p => p !== postcode).join());
                }
                if(originalDriversId.indexOf(d.ItemId) < 0 && selectedDrivers.indexOf(d.ItemId) >= 0)
                {
                    //drivers to assign
                    newData.TerritoryPostcodes = territoryPostcodes === '' ? territoryPostcodes + postcode : territoryPostcodes + ',' + postcode;
                }
                // console.log('TerritoryPostcodes', territoryPostcodes);
                // console.log('New TerritoryPostcodes', newData.TerritoryPostcodes);
                return newData;
            });
        });
        
        console.log('Drivers',Drivers);
        const myHeaders = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            body: {
                accounts: Drivers
                }
        };
        let savedDrivers;
        try{
            savedDrivers = await API.post("api", "/accounts", myHeaders);
            //console.log('savedDrivers', savedDrivers.accounts);
            await props.updateParent(true);
            setShow(false);
        }catch(err){
            console.log('err', err);
            if(err.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }
        console.log('end saving');
        setIsSaving(false);
        setIsProcessing(false);
    }

    return(
        <React.Fragment>
            {!props.isButton &&
                <a href='' onClick={(e) => {editAssignment(e,props.postcode,props.drivers)}}>Edit</a>
            }
            {props.isButton &&
                <Button variant="primary" className="mr-sm-1" size="sm" onClick={() => {bulkAssignment(props.postcodes)}}>
                    Assign
                </Button>
            }
            <Modal
                size="md"
                show={show}
                onHide={() => setShow(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {props.postcode ? 'Postcode ' + props.postcode : 'Assign Postcodes'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    {show && !props.postcode && props.getSelectedPostcodes().length === 0 &&
                        <Form.Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label className="margin-bottom-zero"><h6>No selected Postcodes</h6></Form.Label>
                            </Form.Group>
                        </Form.Row>
                    }
                    {/* {show && props.getSelectedPostcodes && props.getSelectedPostcodes().length > 0 &&
                        props.getSelectedPostcodes().map((p,index) => {
                            return(
                                <Form.Row key={index}>
                                    <Form.Group className="margin-bottom-zero" as={Col} xs={12}>
                                    <Form.Label className="margin-bottom-zero"><h5>Postcode # {p}</h5></Form.Label>
                                    </Form.Group>
                                </Form.Row>
                            )
                        })
                    } */}
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            <Form.Label className="margin-bottom-zero top-margin-20"><h6>Filter driver by Notification Group</h6></Form.Label>
                            <Form.Control as="select"
                                size="sm"
                                disabled={isProcessing}
                                value={selectedNotificationGroup}
                                name="notificationGroup"
                                onChange={ (e) => {
                                    setSelectedNotificationGroup(e.target.value);
                                }}
                            >
                                <option value="">{isProcessing ? 'Loading...' : 'Filter by...'}</option>
                                {getNotificationGroups()}
                            </Form.Control>
                        </Form.Group>
                        
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={12} className="margin-bottom-zero">
                            <Form.Label className="margin-bottom-zero"><h6>Driver/s</h6></Form.Label>
                            <Select 
                            isDisabled={isProcessing}
                            className="basic-multi-select form-text-align" 
                            classNamePrefix="select" 
                            isMulti 
                            options={getDriversArray()} 
                            name="driversSelect" 
                            placeholder={isProcessing ? 'Loading...' : 'Select...'}                                                       
                            value={getDriversArray().filter(option => selectedDrivers.indexOf(option.value) >= 0)}
                            onChange={(option) => {
                                //console.log('onChange', option);
                                setSelectedDrivers(option ? option.map((item,index) => item.value) : []);                                          
                            }}/>
                        </Form.Group>
                    </Form.Row>
                    {error.length > 0 &&
                    <Form.Row>
                        <Form.Group>
                            <Alert variant='danger'>
                                {error}
                            </Alert>
                        </Form.Group>                                    
                    </Form.Row>
                    }
                    <Form.Row>                             
                        <Button variant="primary" className="mr-sm-1 top-margin-20" type="submit" onClick={saveChanges} disabled={isProcessing || (show && !props.postcode && props.getSelectedPostcodes().length === 0)} size="sm">
                            {isSaving ? 'Saving...' : 'Save'}
                        </Button>
                        <Button variant="primary" className="mr-sm-2 top-margin-20" disabled={isProcessing} onClick={() => setShow(false)} size="sm">                                
                            Cancel
                        </Button>
                    </Form.Row>                          
                </Modal.Body>                    
            </Modal>
        </React.Fragment>
    )
}


function CheckForPostcodesUpdateModal(props) {
    const [show, setShow] = useState(false);
    const [isProcessingUpdate, setIsProcessingUpdate] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [postcodes, setPostcodes] = useState([]);    
    const [error, setError] = useState('');    
    const [info, setInfo] = useState('');
    const checkBoxRef = useRef([]);
    const selectAllCheckBoxRef = useRef([]);
    let selectedPostcodesNew = [];

    useEffect(() => {
        if(show)
            fetchUpdates();
        clearCheckboxes();        
    },[show])

    const fetchUpdates = async () => {
        setIsProcessingUpdate(true);
        try{
            const authHeader = await authorizationHeader();
            try {
                let res = await API.get('api', '/checkpostcodes', authHeader);
                setPostcodes(res.checkpostcodes);
                if(res.checkpostcodes.length == 0) {
                    setInfo("No new Postcodes");
                }
            }
            catch(err) {
                if(err.toString().toLowerCase().indexOf('network error'))
                    setError('Network error. Please check internet connection then refresh the page.');
                else
                    setError('Unexpected error encountered.');
            }            
        }catch(error) {
            //setError(error.toString().toLowerCase());
        }  
        setIsProcessingUpdate(false);      
    }        

    const getSelectedPostcodes = () => {
        if(selectedPostcodesNew.length > 0) {
            return postcodes.filter(pp => selectedPostcodesNew.indexOf(pp.Postcode) != -1);
        }
        else {
            setError("No postcodes selected");
            return [];
        }        
    }

    const clearCheckboxes = () => {        
        selectAllCheckBoxRef.current.map(c => {
            if(c !== null)
            {
                if (c.checked) {
                    c.click();
                }
            }
        });
        checkBoxRef.current.map(c => {
            if(c !== null)
            {
                if (c.checked) {
                    c.click();
                }
            }
        });
    }

    const saveChanges = async () => {
        console.log('saving');
        setIsSaving(true);
        setIsProcessingUpdate(true);

        const myHeaders = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            body: {
                postcodes: getSelectedPostcodes()
            }
        };
        let savedPostcodes;
        try{
            savedPostcodes = await API.post("api", "/postcodes", myHeaders);
            //console.log('savedDrivers', savedDrivers.accounts);
            await props.updateParent(false);
            toast.success("Successfully added new postcodes", {
                duration: 5000
            });
            setShow(false);
        }catch(err){
            console.log('err', err);
            if(err.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }
        console.log('end saving');
        setIsSaving(false);
        setIsProcessingUpdate(false);
    }

    return(
        <React.Fragment>         
            <Button variant="primary" className="mr-sm-1" size="sm" onClick={() => {setShow(true);}}>
                Check for Postcode Updates
            </Button>            
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        New Postcodes
                    </Modal.Title>
                </Modal.Header>
                <LoadingOverlay
                active={isProcessingUpdate}
                spinner
                text='Loading postcodes...'
                >
                <Modal.Body> 
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            <div>
                            <Form.Check
                                        defaultChecked={(selectedPostcodesNew.length == postcodes.length && postcodes.length > 0) ? true : false}
                                        isChecked={(selectedPostcodesNew.length == postcodes.length && postcodes.length > 0) ? true : false}
                                        ref={el => selectAllCheckBoxRef.current[0] = el}
                                        className="med-font margin-bottom-zero"
                                        label="Select all postcodes" 
                                        name="alldrivers"
                                        onChange={(e) => {
                                            if(e.target.checked)
                                            {
                                                checkBoxRef.current.map(c => {
                                                    if(c !== null)
                                                    {
                                                        if(!c.checked)
                                                            c.click();
                                                    }
                                                });
                                            }  
                                            else {
                                                if(selectedPostcodesNew.length === postcodes.length) {
                                                    checkBoxRef.current.map(c => {
                                                        if(c !== null)
                                                        {
                                                            if(c.checked)
                                                                c.click();
                                                        }
                                                    });
                                                }
                                            }  
                                                                          
                                        }}
                                        />
                            </div>

                            <Table bordered className="table-border-medium" size="sm">
                                <tbody>
                                    <tr>  
                                    <td className="small-font App">
                                        
                                    </td>                                     
                                        <td className="small-font bold-font">Postcode</td>
                                        <td className="small-font bold-font left-align">State</td>
                                        <td className="small-font bold-font left-align">Locality</td>
                                        <td className="small-font bold-font left-align">Status</td>
                                        <td className="small-font bold-font left-align">Type</td>
                                    </tr>
                                    {
                                        postcodes.map((pp,index) => { return (
                                            <tr key={pp.ItemId}>      
                                                <td className="small-font App">
                                                    <Form.Check
                                                    defaultChecked={selectedPostcodesNew.filter(s => s === pp.Postcode).length > 0 ? true : false}
                                                    isChecked={selectedPostcodesNew.filter(s => s === pp.Postcode).length > 0 ? true : false}
                                                    ref={el => checkBoxRef.current[index] = el}                                                 
                                                    onChange={(e) => {
                                                        if(e.target.checked)
                                                        {
                                                            //setSelectedPostcodes(selectedPostcodes => [...selectedPostcodes, p.Postcode])
                                                            selectedPostcodesNew = [...selectedPostcodesNew, pp.Postcode];
                                                            if(selectedPostcodesNew.length === postcodes.length) {
                                                                selectAllCheckBoxRef.current.map(c => {
                                                                    if(c !== null)
                                                                    {
                                                                        if (!c.checked) {
                                                                            c.click();
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        }  
                                                        else{
                                                            selectedPostcodesNew = selectedPostcodesNew.filter(s => s !== pp.Postcode);
                                                            if(selectedPostcodesNew.length !== postcodes.length) {
                                                                selectAllCheckBoxRef.current.map(c => {
                                                                    if(c !== null)
                                                                    {
                                                                        if (c.checked) {
                                                                            c.click();
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        }                                  
                                                    }}
                                                    />
                                                </td>                               
                                                <td className="small-font">{pp.Postcode}</td>
                                                <td className="small-font left-align">{pp.State}</td>
                                                <td className="small-font left-align">{pp.Locality}</td>
                                                <td className="small-font left-align">{pp.Status}</td>
                                                <td className="small-font left-align">{pp.Type}</td>
                                            </tr> )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Form.Group>
                    </Form.Row>
                    {error.length > 0 &&
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            <Alert variant='danger'>
                                {error}
                            </Alert>
                        </Form.Group>                                    
                    </Form.Row>
                    }
                    {info.length > 0 &&
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            <Alert variant='info'>
                                {info}
                            </Alert>
                        </Form.Group>                                    
                    </Form.Row>
                    }
                    <Form.Row>                             
                        <Button variant="primary" className="mr-sm-1 top-margin-20" type="submit" onClick={saveChanges} disabled={isProcessingUpdate || (show && postcodes.length === 0)} size="sm">
                            {isSaving ? 'Saving...' : 'Save'}
                        </Button>
                        <Button variant="primary" className="mr-sm-2 top-margin-20" disabled={isProcessingUpdate} onClick={() => setShow(false)} size="sm">                                
                            Cancel
                        </Button>
                    </Form.Row>                          
                </Modal.Body>   
                </LoadingOverlay>                 
            </Modal>            
        </React.Fragment>
    )
}

//UNCOMMENT BELOW TO PARSE POSTCODES FROM OTHER SOURCES
// function PostcodeParsing(props) {
//     const [postcodeString, setPostcodeString] = useState('');
//     const [isProcessing, setIsProcessing] = useState(false);

//     const parseString = () => {
//         const state = 'NT';
//         const postcodesObject = JSON.parse(postcodeString).filter(p => p.type === 'Delivery Area' && p.state === state);
//         let converted = [];
//         postcodesObject.forEach(p => {
//             if(converted.filter(c => c.Postcode === p.postcode).length === 0)
//             {
//                 converted.push({
//                     Postcode: p.postcode,
//                     Locality: p.locality,
//                     State: p.state,
//                     DC: p.dc,
//                     Type: p.type,
//                     Status: p.status
//                 })
//             }
//             else{
//                 const localities = converted.filter(c => c.Postcode === p.postcode)[0].Locality.split(',');
//                 if(localities.length === 1)
//                 {
//                     converted.filter(c => c.Postcode === p.postcode)[0].Locality = localities.join() + ', ' + p.locality;
//                 }
//                 if(localities.length === 2)
//                 {
//                     converted.filter(c => c.Postcode === p.postcode)[0].Locality = localities.join() + ', etc..';
//                 }
//             }
//         });
//         console.log(converted);
//         console.log(JSON.stringify(converted));
//     }

//     return(
//         <Form.Group controlId="description" className="margin-bottom-8">
//             <Form.Label className="med-font margin-bottom-zero">Raw string</Form.Label>
//             <Form.Control as="textarea" rows="3" 
//                 size="sm"
//                 placeholder="Raw string" 
//                 name="postcodes"
//                 value={postcodeString}
//                 onChange={(e) => setPostcodeString(e.target.value)} 
//             />
//             <Button variant="primary" className="mr-sm-1 top-margin-20" type="submit" onClick={parseString} disabled={isProcessing} size="sm">
//                 {isProcessing ? 'Processing...' : 'Convert'}
//             </Button>
//         </Form.Group>
//     )
// }

export default Postcodes