import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import { Navbar, Form,  FormControl, Button, Col} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import ModalWrapper from './utility/ModalWrapper';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

export default class Clients extends Component {
    state = {
        isLoading: true,
        clients: [],
        searchKeyword:'',
        note:'',
        modalNoteShow: false,
        sortName: 'Name',
        sortOrder: 'asc',
        pageSize: '30',
        page: 1,
        nextToken: false, //undefined,
        prevToken: false, //undefined,
        forwardToken: undefined,
        backwardToken: undefined,
        enableAddDivision: false,
        enableAddLocation: false
    }

    componentDidMount() {
        this.fetchClients();
    }

    async fetchClients() {
        try{
            let filters = '';
            const {sortName, sortOrder, searchKeyword, pageSize, forwardToken, backwardToken, page} = this.state;
            filters = '?pageSize=' + pageSize + '&page=' + page;
            if(sortName !== '' && sortOrder !== '')
                filters = filters + '&Sort=' + sortName + '~' + sortOrder;
            if(searchKeyword !== '')
                filters = filters + '&Condition=SearchField~contains~' + searchKeyword.trim().toLowerCase();
            if(forwardToken)
                filters = filters + '&ForwardToken=' + forwardToken;
            if(backwardToken)
                filters = filters + '&BackwardToken=' + backwardToken;

            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            console.log('filters', filters);
            const res = await API.get("api", "/clients" + filters, myInit);
            console.log("Clients", res);
            this.setState({
                clients: res.clients,
                nextToken: res.forwardPageToken,
                prevToken: res.backwardPageToken,
                isLoading: false,
                forwardToken: undefined,
                backwardToken: undefined,
                enableAddDivision: res.count !== "0" ? true : false,
                enableAddLocation: res.count !== "0" && res.clients.filter(c => c.DivisionCount > 0).length > 0 ? true : false
            })
        }catch(err) {
            this.setState(() => { throw err; })
        }
    }

    searchKeywordOnChange = (e) => {
        this.setState({ searchKeyword: e.target.value });
    }

    searchClick = () => {
        this.setState({ page: 1, isLoading: true }, function () {
            this.fetchClients();});
    }

    handleEnterKeySearch = e => {
        if(e.charCode === 13)
        {
            e.preventDefault();
            this.searchClick();
        }            
    }

    addressFormatter(cell, row) {
        let address = '';
        if(row.PhysicalAddressStreet && row.PhysicalAddressStreet !== '')
            address = row.PhysicalAddressStreet;
        if(row.PhysicalAddressCity && row.PhysicalAddressCity !== '')
            address = (address + ' ' + row.PhysicalAddressCity).trim();
        if(row.PhysicalAddressState && row.PhysicalAddressState !== '')
            address = (address + ', ' + row.PhysicalAddressState).trim();
        if(row.PhysicalAddressZip && row.PhysicalAddressZip !== '')
            address = (address + ', ' + row.PhysicalAddressZip).trim();
        return <span className="med-font">{address}</span>;
    }

    setModalNoteShow (show,note) {
        this.setState({ note: note, modalNoteShow: show });
    }

    nameFormatter(cell, row) {
        // return <a href='' onClick={this.changePage.bind(this,row.ItemId,'editClient')}><span className="med-font">{cell}</span></a>
        return <Link to={`/clients/editClient/${row.ItemId}`} ><span className="med-font">{cell}</span></Link>
    }

    countFormatter(cell, row) {
        // return <a href='' onClick={this.changePage.bind(this,row.ItemId,'divisionList')}><span className="med-font">{cell}</span></a>
        return <Link to={`/clients/divisionList/${row.ItemId}`} ><span className="med-font">{cell}</span></Link>
    }

    changePage(id, page, e) {
        console.log('e', e);
        e.preventDefault()
        this.props.navProp.changeClientPage(page,id); 
    }

    valueFormatter(cell, row) {
        return <span className="med-font">{cell}</span>;
    }

    notesFormatter(cell, row) {
        if(cell && cell !== '')
            return <span className='clickable' onClick={this.setModalNoteShow.bind(this,true, cell)}><i className="fas fa-sticky-note" style={{ fontSize: '1em', color: 'green' }} /></span>
        else
            return <i className="fas fa-sticky-note" style={{ fontSize: '1em', color: 'gray' }} />
    }

    setForwardToken = () => {
        this.setState({ page: this.state.page + 1, isLoading: true},function () {
            this.fetchClients();});
    }

    setBackwardToken = () => {
        this.setState({ page: this.state.page - 1, isLoading: true},function () {
            this.fetchClients();});
    }

    updatePageSize = (e) => {
        this.setState({ page: 1, pageSize: e.target.value, isLoading: true }, function () {
            this.fetchClients();});
    }

    setNoDataText = () => {
        if(this.state.isLoading === false){
           return "No record found";
        }else{
            return 'Loading...';
        }
    }

    renderPaginationPanel = (props) => {
        return (
            <Form as={Col} xs={12} className="form-inline bottom-margin">
                <Form.Group as={Col} xs={10}>
                <Form.Label>Records per page:&nbsp;</Form.Label>
                <Form.Control as="select" onChange={this.updatePageSize} defaultValue='30' size='sm'>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>    
                    <option value='25'>25</option>                             
                    <option value='30'>30</option> 
                </Form.Control>    
                </Form.Group>
                
                <Form.Group as={Col} xs={2}>
                    {!this.state.prevToken &&
                    <Button variant="success" disabled size="sm">Prev</Button>}
                    {this.state.prevToken &&
                    <Button variant="success" size="sm" onClick={this.setBackwardToken}>Prev</Button>}
                    &nbsp;
                    {!this.state.nextToken &&
                    <Button variant="success" disabled size="sm">Next</Button>}
                    {this.state.nextToken &&
                    <Button variant="success" size="sm" onClick={this.setForwardToken}>Next</Button>}
                </Form.Group>         
                
            </Form>
        );
    }

    onSortChange = (sortName, sortOrder) => {
        console.log('sortName', sortName);
        console.log('sortOrder', sortOrder);
        this.setState({
          page: 1, 
          sortName: sortName,
          sortOrder: sortOrder,
          isLoading: true,
          forwardToken: undefined,
          backwardToken: undefined
        },function () {
            this.fetchClients();});
    }

    render() {
        return (
            <React.Fragment>
                <Navbar className="bg-light padding-top-bottom-zero">
                    <Navbar.Brand href="/">
                        <h4 className="padding-top-bottom-zero">Clients</h4>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                            {/* <Navbar.Text>
                                <FormControl type="text" placeholder="Search by Name or Company" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                                <span className='clickable mr-sm-2' onClick={()=> this.searchClick()}>
                                    <i className="fas fa-search" style={{ fontSize: '1em' }} />
                                </span>
                            </Navbar.Text> */}
                            <Button as={Link} to="/clients/addClient" className="mr-sm-2" variant="success" size="sm" >
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Client
                            </Button>
                            <Button as={Link} to="/clients/addDivision" className="mr-sm-2" variant="success" size="sm" disabled={!this.state.enableAddDivision} >
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Division
                            </Button>
                            <Button as={Link} to="/clients/addLocation" className="mr-sm-2" variant="success" size="sm" disabled={!this.state.enableAddLocation} >
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Delivery Location
                            </Button>
                            
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <Navbar className="bg-light padding-top-bottom-zero top-border-1">
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                        <Navbar.Text>
                            <FormControl type="text" placeholder="Search by Client Name" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                            <span className='clickable' onClick={()=> this.searchClick()}>
                                <i className="fas fa-search" style={{ fontSize: '1em' }} />
                            </span>
                        </Navbar.Text>
                        </Form>
                    </Navbar.Collapse>                      
                </Navbar>
                <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text='Loading clients...'
                >
                {/* <BootstrapTable ref='table' data={this.state.clients} options={{onSortChange: this.onSortChange, noDataText: this.setNoDataText(), sortIndicator: false, paginationPanel: this.renderPaginationPanel }} remote={true} hover pagination> */}
                <BootstrapTable ref='table' data={this.state.clients} options={{onSortChange: this.onSortChange, noDataText: this.setNoDataText(), sortIndicator: false, paginationPanel: this.renderPaginationPanel}} remote={true} hover pagination>
                    <TableHeaderColumn dataField='ItemId' isKey hidden>ItemId</TableHeaderColumn>
                    <TableHeaderColumn dataField='Name' dataFormat={this.nameFormatter.bind(this)} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Client Name</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='Email' dataFormat={this.valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Email</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='Landline' width='115' dataFormat={this.valueFormatter} thStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Phone</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='FaxNumber' width='115' dataFormat={this.valueFormatter} thStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Fax</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='PhysicalAddressStreet' width='350' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataFormat={this.addressFormatter}><span className="med-header-font">Physical Address</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='Notes' width='75' dataAlign="middle" dataFormat={this.notesFormatter.bind(this)} thStyle={{ whiteSpace: 'normal' }}><span className="med-header-font">Notes</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='DivisionCount' width='100' dataFormat={this.countFormatter.bind(this)} dataAlign="middle" thStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Divisions</span></TableHeaderColumn>
                </BootstrapTable>
                </LoadingOverlay>
                <ModalWrapper
                    show={this.state.modalNoteShow}
                    size="sm"
                    headercolor="transparent"
                    onHide={() => this.setModalNoteShow(false)}
                    modaltitle="Note"
                    type="note"
                    note={this.state.note}
                />
            </React.Fragment>
        )                
    }
}