import React, { Component } from 'react';
import {Table, Spinner, Navbar, Form, Tooltip, FormControl, Button, Popover, OverlayTrigger, Col} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn, SizePerPageDropDown} from 'react-bootstrap-table';
import { Auth, API } from "aws-amplify";
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import ExportButton from './utility/ExportButton';
import {ExcelDownloadButton} from './utility/ExcelDocument';
import { BDExcelDownloadButton } from './utility/BDExcelDownload';
import InvoiceSummaryButton from './utility/InvoiceSummaryButton';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


export default class Invoice extends Component {
    state = {
        userGroup: '',
        isLoading: true,
        clients: undefined,
        clientId: '0',
        divisions: undefined,
        divisionId: '0',
        invoices: [],
        invoiceBatch: undefined,
        invoiceBatchId: '0',
        invoiceDivisionId:'0',
        invoiceDate: undefined,
        invoiceDetail: undefined,
        searchKeyword:'',
        sortName: 'DeliveryDate',
        sortOrder: 'asc',
        pageSize: '50',
        page: 1,
        nextToken: false, //undefined,
        prevToken: false, //undefined,
        forwardToken: undefined,
        backwardToken: undefined,
        reprocess:false,
        includeInterstateCharge: false,
        reprocessSelected: false,
        fromDate:  moment().startOf('month').format('YYYY-MM-DD'), //moment(),
        toDate: new Date(),//moment(),
        showDatePicker:false
    }
    async componentDidMount() {
        const session = await Auth.currentSession();
        this.setState({ userGroup: session.idToken.payload['cognito:groups'] !== undefined ? session.idToken.payload['cognito:groups'][0] : '' });
        console.log("group", session.idToken.payload['cognito:groups'] !== undefined ? session.idToken.payload['cognito:groups'][0] : '');
        this.fetchData(1);
    }
    dateFormatter(cell, row) {
        return <span className="small-font">{moment(cell).format("DD-MMM-YY")}</span>;
    }
    valueFormatter(cell, row) {
        return <span className="small-font">{cell}</span>;
    }
    amountFormatter(cell, row) {
        //console.log('row',row);
        if(row.NoRate === false && row.NoRateCard === false)
            return <span className="small-font">${cell}</span>;
        else
            return <span className="small-font error-msg">no rate</span>;
    }
    interstateFormatter(cell, row) {
        if(row.IsInterstateNotOk === false && row.IsInterstate)
            return <span className="small-font">${cell}</span>;
        else{
            if(row.NoInterstateRate)
                return <span className="small-font error-msg">no interstate rate</span>;
            if(row.NoMinimumInterstateCharge)
                return <span className="small-font error-msg">no minimum interstate charge set</span>;
        }
            
    }
    additionalDeliveryChargeFormatter(cell, row) {
        //console.log('row',row);
        if(row.NoDeliveryCharge === false && row.NoUploadedDeliveryCharge === false)
            return <span className="small-font">{cell}</span>;
        else
            return <span className="small-font error-msg">no delivery charge</span>;
    }
    addressFormatter(cell, row) {
        // console.log('cell', cell);
        //console.log('row', row);
        let address = '';
        if(row.Street1 && row.Street1 !== '')
            address = row.Street1;
        if(row.Street2 && row.Street2 !== '' && ((row.Street1 && row.Street1.trim().toLowerCase() !== row.Street2.trim().toLowerCase()) || row.Street1 === null))
            address = (address + ' ' + row.Street2).trim();
        if(row.Suburb && row.Suburb !== '')
            address = (address + ' ' + row.Suburb).trim();
        if(row.Postcode && row.Postcode !== '')
            address = (address + ', ' + row.Postcode).trim();
        if(address.startsWith(','))
            address = address.substring(1).trim();
        return <span className="small-font">{address}</span>;
        // if(row.LocationName && row.LocationName !== '')
        //     return row.LocationName + '<br/>' + cell;
        // else
        //     return cell;
    }
    async fetchData(level) {
        try{
      
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };
            if(level === 1)
            {
                const clients = await API.get("api", "/clients?ForInvoicePage=true", myInit);
                console.log("clients", clients);       
                this.setState({clients: clients.clients, divisionId:'0', invoiceBatchId: '0'}, function () {
                    this.fetchInvoices();});
            }
            if(level === 2)
            {
                const divisions = await API.get("api", "/divisions?ForInvoicePage=true&ParentItemId=" + this.state.clientId, myInit);
                console.log("divisions", divisions);      
                this.setState({divisions: divisions.divisions, invoiceBatchId: '0'}, function () {
                    this.fetchInvoices();});
            }
            if(level === 3)
            {
                if(this.state.divisionId=='all')
                {
                    const from = moment(this.state.fromDate).format('YYYY-MM-DD');
                    const to = moment(this.state.toDate).format('YYYY-MM-DD');
                    const invoicesbatch = await API.get("api", "/invoicesbatch?Where=InvoiceDate~between~"+from +'|'+to + '&BillingClientId='+this.state.clientId + '&DivisionId=' + this.state.divisionId, myInit);
                    console.log("invoicesbatch", invoicesbatch);      
                    this.setState({invoiceBatch: invoicesbatch.invoicesbatch, invoiceBatchId: '0'}, function () {
                    this.fetchInvoices();});
                }
                else
                {
                    const invoicesbatch = await API.get("api", "/invoicesbatch?DivisionId=" + this.state.divisionId, myInit);
                    console.log("invoicesbatch", invoicesbatch);      
                    this.setState({invoiceBatch: invoicesbatch.invoicesbatch, invoiceBatchId: '0'}, function () {
                    this.fetchInvoices();});
                }
            }
        }catch(err) {
            this.setState(() => { throw err; })
        }        
    }
    getClients() {        
        return this.state.clients && this.state.clients.map((client, index) => {
           const { Name, ItemId } = client
           return (
               <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }
    handleClientChange = e => {
        this.setState({ page: 1, clientId: e.target.value, divisionId: '0', divisions:undefined, invoiceBatchId: '0', invoiceBatch:undefined, isLoading: true, showDatePicker: false}, function () {
            this.fetchData(2);});
    }
    getDivisions() {        
        return this.state.divisions && this.state.divisions.map((division, index) => {
           const { Name, ItemId } = division
           return (
               <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }
    handleDivisionChange = e => {
        if(e.target != null && e.target.value != null & e.target.value == 'all')
            this.setState({ page: 1, divisionId: e.target.value, invoiceBatchId: '0', invoiceBatch:undefined, isLoading: true, showDatePicker: true}, function () {
                this.fetchData(3);});
        else
            this.setState({ page: 1, divisionId: e.target.value, invoiceBatchId: '0', invoiceBatch:undefined, isLoading: true, showDatePicker: false }, function () {
                this.fetchData(3);});
    }
    getInvoiceBatch() {        
        return this.state.invoiceBatch && this.state.invoiceBatch.map((batch, index) => {
           const { Name, ItemId, InvoiceDate, Division } = batch;
           if( this.state.divisionId == 'all')
                return (
                    <option key={ItemId} value={ItemId} >{`${Division} - Invoice#:${Name} (Date:${moment(InvoiceDate).format("DD/MM/YYYY")})`}</option>
                    )
           else
            return (
                <option key={ItemId} value={ItemId} >{`Invoice#:${Name} (Date:${moment(InvoiceDate).format("DD/MM/YYYY")})`}</option>
            )
        })
    }
    handleInvoiceBatchChange = e => {
        this.setState({ page: 1, invoiceBatchId: e.target.value, isLoading: true,
            invoiceDivisionId:(this.state.invoiceBatch.filter(i => i.ItemId === e.target.value).length > 0) ? this.state.invoiceBatch.filter(i => i.ItemId === e.target.value)[0].DivisionId : undefined,
            invoiceDate:(this.state.invoiceBatch.filter(i => i.ItemId === e.target.value).length > 0) ? this.state.invoiceBatch.filter(i => i.ItemId === e.target.value)[0].InvoiceDate : undefined,
            invoiceDetail:(this.state.invoiceBatch.filter(i => i.ItemId === e.target.value).length > 0) ? this.state.invoiceBatch.filter(i => i.ItemId === e.target.value)[0] : undefined  }
            , function () {this.fetchInvoices();});
    }
    reprocess = () => {
        console.log('reprocess');
        this.setState({page: 1, isLoading: true, reprocess: true}, function () {this.fetchInvoices();});
    }
    setNoDataText = () => {
        if(this.state.isLoading === false){
           return "No record found";
        }else{
            return 'Loading...';
        }
    }
    setForwardToken = () => {
        this.setState({ page: this.state.page + 1, isLoading: true},function () {
            this.fetchInvoices();});
    }

    setBackwardToken = () => {
        this.setState({ page: this.state.page - 1, isLoading: true},function () {
            this.fetchInvoices();});
    }

    updatePageSize = (e) => {
        this.setState({ page: 1, pageSize: e.target.value, isLoading: true }, function () {
            this.fetchInvoices();});
    }
    setFromDate = value => {
            let date = value;
            this.setState({fromDate: date, isLoading: true}, function(){
                this.fetchData(3);
            });
    }

    setToDate = value => {
        let date = value;
        this.setState( {toDate: date, isLoading: true}, function (){
            this.fetchData(3);
        });
    }

    renderDatePicker = () => {
        if(this.state.showDatePicker === true)
        {
            return(
                <Form.Group>
                    <Form.Label className="med-font margin-bottom-zero mr-sm-1">From</Form.Label>
                    <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={Date.parse(this.state.fromDate)} maxDate={this.state.toDate} onChange={value => this.setFromDate(value)} className='form-control form-control-sm mr-sm-3 width-100' placeholderText='Select from date'/>
                    <Form.Label className="med-font margin-bottom-zero mr-sm-1">To</Form.Label>
                    <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={Date.parse(this.state.toDate)} minDate={this.state.fromDate} onChange={value => this.setToDate(value)} className='form-control form-control-sm width-100 mr-sm-3' placeholderText='Select to date'/>
                </Form.Group>
            );
        } 
    }
    renderPaginationPanel = (props) => {
        return (
            <Form as={Col} xs={12} className="form-inline bottom-margin">
                <Form.Group as={Col} xs={10}>
                <Form.Label>Records per page:&nbsp;</Form.Label>
                <Form.Control as="select" onChange={this.updatePageSize} defaultValue='50' size='sm'>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>    
                    <option value='25'>25</option>                             
                    <option value='30'>30</option>
                    <option value='40'>40</option> 
                    <option value='50'>50</option> 
                    <option value='80'>80</option> 
                    <option value='100'>100</option>
                </Form.Control>    
                </Form.Group>
                
                <Form.Group as={Col} xs={2}>
                    {!this.state.prevToken &&
                    <Button variant="success" disabled size="sm">Prev</Button>}
                    {this.state.prevToken &&
                    <Button variant="success" size="sm" onClick={this.setBackwardToken}>Prev</Button>}
                    &nbsp;
                    {!this.state.nextToken &&
                    <Button variant="success" disabled size="sm">Next</Button>}
                    {this.state.nextToken &&
                    <Button variant="success" size="sm" onClick={this.setForwardToken}>Next</Button>}
                </Form.Group>         
                
            </Form>
        );
    }
    onSortChange = (sortName, sortOrder) => {
        console.log('sortName', sortName);
        console.log('sortOrder', sortOrder);
        this.setState({
          page: 1, 
          sortName: sortName,
          sortOrder: sortOrder,
          isLoading: true,
          forwardToken: undefined,
          backwardToken: undefined
        },function () {
            this.fetchInvoices();});
    }
    async fetchInvoices() {
        let filters = '';
        const {sortName, sortOrder, searchKeyword, pageSize, forwardToken, backwardToken, page, invoiceBatchId, divisionId, clientId, invoiceDate, reprocess, reprocessSelected} = this.state;
        if((invoiceDate !== undefined && invoiceBatchId !== '0') || (this.state.divisionId == 'all' && invoiceBatchId == 'all'))
        {
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };
            if (divisionId == 'all')
            {
                if(invoiceBatchId == 'all')
                {
                    const from = moment(this.state.fromDate).format('YYYY-MM-DD');
                    const to = moment(this.state.toDate).format('YYYY-MM-DD'); 
                    filters = '?pageSize=' + pageSize + '&page=' + page + '&InvoiceDateBetween='+ from +'|'+to +  '&DivisionId=all' + '&BillingClientId='+this.state.clientId ;
                }
                else
                    filters = '?pageSize=' + pageSize + '&page=' + page + '&InvoiceDate=' + invoiceDate + '&DivisionId=' + this.state.invoiceDivisionId;
            }
            else
            {
                filters = '?pageSize=' + pageSize + '&page=' + page + '&InvoiceDate=' + invoiceDate + '&DivisionId=' + divisionId;
            }
            
            if(sortName !== '' && sortOrder !== '')
                filters = filters + '&Sort=' + sortName + '~' + sortOrder;
            if(searchKeyword !== '')
                filters = filters + '&Condition=SearchField~contains~' + searchKeyword.trim().toLowerCase();
            if(reprocess === true)
                filters = filters + '&ReprocessInvoice=true';
            if(reprocessSelected === true)
                filters = filters + '&ReprocessSelectedInvoice=true';
            const invoices = await API.get("api", "/invoices" + filters, myInit);
            console.log('invoices',filters);
            this.setState({
                isLoading: false, 
                invoices: invoices.invoices,
                nextToken: invoices.forwardPageToken,
                prevToken: invoices.backwardPageToken,
                reprocess: false,
                reprocessSelected: false
            });
        }
        else
            this.setState({isLoading: false, reprocess: false, invoices: []});       
    }
    searchKeywordOnChange = (e) => {
        this.setState({ searchKeyword: e.target.value });
    }

    searchClick = () => {
        this.setState({ page: 1, isLoading: true }, function () {
            this.fetchInvoices();});
    }

    reprocessSelectedInvoiceClick = () => {
        this.setState({ page: 1, isLoading: true, reprocessSelected: true }, function () {
            this.fetchInvoices();});
    }

    handleEnterKeySearch = e => {
        if(e.charCode === 13)
        {
            e.preventDefault();
            this.searchClick();
        }            
    }
    toggleIncludeInterstate = e => {
        this.setState({ includeInterstateCharge: e });
    }
    render() {
        const funcProp = {
            reprocess: this.reprocess
        }
        return(
            <React.Fragment>
                <Navbar className="bg-light padding-top-bottom-zero">
                    <Navbar.Brand href="/">
                        <h4 className="padding-top-bottom-zero">Invoices</h4>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                            <ExcelDownloadButton reprocessCallback={funcProp} operatorId={this.props.auth.operator} display="Download All Latest Invoices" disabled={this.props.userType.toLowerCase() === 'officeadministrator' || this.props.userType.toLowerCase() === 'officeuser' || this.props.userType.toLowerCase() === 'driver'} includeInterstateCharge={this.state.includeInterstateCharge}/>
                            <ExcelDownloadButton reprocessCallback={funcProp} operatorId={this.props.auth.operator} invoiceDetail={this.state.invoiceDetail} display="Download Selected Invoice" disabled={this.state.invoiceBatchId === '0' ? true : false} includeInterstateCharge={this.state.includeInterstateCharge}/>                            
                            <BDExcelDownloadButton reprocessCallback={funcProp} operatorId={this.props.auth.operator} invoiceDetail={this.state.invoiceDetail} display="Download CTSI Formatted Invoice" disabled={this.state.invoiceBatchId === '0' ? true : false} includeInterstateCharge={this.state.includeInterstateCharge}/>
                            <ExportButton sortName={this.state.sortName} sortOrder={this.state.sortOrder} searchKeyword={this.state.searchKeyword} selectedBillingClientId={ this.state.clientId } selectedDivision={ this.state.invoiceBatchId == 'all' ? this.state.divisionId : this.state.invoiceDivisionId } invoiceDate={ this.state.invoiceBatchId == 'all' ?  moment(this.state.fromDate).format('YYYY-MM-DD')+'|'+ moment(this.state.toDate).format('YYYY-MM-DD') : this.state.invoiceDate} disabled={this.state.invoiceBatchId === '0' ? true : false} isInvoice={true}/>
                            <InvoiceSummaryButton />
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <Navbar className="bg-light padding-top-bottom-zero">
                    <Form inline>
                        <Form.Label className="med-font margin-bottom-zero mr-sm-1">Clients</Form.Label>                            
                        <Form.Control as="select"
                            size="sm"
                            className='mr-sm-3'
                            value={this.state.clientId}
                            onChange={this.handleClientChange}
                        >
                            <option key='0' value='0' >Select Billing Client</option>
                                {  this.state.userGroup.toLowerCase() === 'globaladministrator' || this.state.userGroup.toLowerCase() === 'companyadministrator' && this.state.clients  && 
                                    <option key='all' value='all' >All</option>
                                }
                            {this.getClients()}
                        </Form.Control>
                        <Form.Label className="med-font margin-bottom-zero mr-sm-1">Divisions</Form.Label>                            
                        <Form.Control as="select"
                            disabled={(this.state.clientId === '0' || (this.state.divisionId === '0' && this.state.divisions === undefined)) ? true : false}
                            size="sm"
                            defaultValue='0'
                            className='mr-sm-3'
                            value={this.state.divisionId}
                            onChange={this.handleDivisionChange}
                        >
                            <option key='0' value='0' >{(this.state.clientId !== '0' && this.state.divisions === undefined) ? 'Loading...' : 'Select Division'}</option>
                            {this.state.divisions  && this.state.divisions.length > 0 &&
                            <option key='all' value='all' >All</option>}
                            {this.getDivisions()}
                        </Form.Control>
                        { this.renderDatePicker()}
                        <Form.Label className="med-font margin-bottom-zero mr-sm-1">Invoices</Form.Label>                            
                        <Form.Control as="select"
                            disabled={(this.state.divisionId === '0' || (this.state.invoiceBatchId === '0' && this.state.invoiceBatch === undefined)) ? true : false}
                            size="sm"
                            defaultValue='0'
                            className='mr-sm-1'
                            value={this.state.invoiceBatchId}
                            onChange={this.handleInvoiceBatchChange}
                        >
                            <option key='0' value='0' >{(this.state.divisionId !== '0' && this.state.invoiceBatch === undefined) ? 'Loading...' : 'Select Invoice'}</option>
                            {this.state.divisionId == 'all' &&  this.state.invoiceBatch && this.state.invoiceBatch.length > 0 &&
                                 <option key='all' value='all' >All</option>
                            }
                            {this.getInvoiceBatch()}
                        </Form.Control>
                        <Button disabled={(this.state.invoiceBatchId === '0' || this.state.isLoading) ? true : false} variant="success" size="sm" onClick={() => this.reprocessSelectedInvoiceClick()}><i className="fas fa-sync" style={{ fontSize: '1em' }} /> {(this.state.isLoading && this.state.reprocessSelected) ? 'Processing...' : 'Reprocess'}</Button>
                    </Form>
                    {/* <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                        <Navbar.Text>
                            <FormControl type="text" placeholder="Search" size="sm" className="mr-sm-2 width-200" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                            <span className='clickable' onClick={()=> this.searchClick()}>
                                <i className="fas fa-search" style={{ fontSize: '1em' }} />
                            </span>
                        </Navbar.Text>
                        </Form>
                    </Navbar.Collapse>                       */}
                </Navbar>
                <Navbar className="bg-light padding-top-bottom-zero top-border-1">
                    <Navbar.Brand href="/">
                        <Form.Check
                            className="med-font margin-bottom-zero"
                            defaultChecked={this.state.includeInterstateCharge}
                            type="switch"
                            id="includeInterstateSwitch"
                            label="Include Interstate Charge"
                            onChange={(e)=> this.toggleIncludeInterstate(e.target.checked)}
                        />
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                        <Navbar.Text>
                            <FormControl type="text" placeholder="Search" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                            <span className='clickable' onClick={()=> this.searchClick()}>
                                <i className="fas fa-search" style={{ fontSize: '1em' }} />
                            </span>
                        </Navbar.Text>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text='Loading Records...'
                >
                    {/* <BootstrapTable ref='table' data={this.state.locations} options={{onSortChange: this.onSortChange, noDataText: this.setNoDataText(), sortIndicator: false, paginationPanel: this.renderPaginationPanel}} remote={true} hover pagination> */}
                    <BootstrapTable data={this.state.invoices} options={{onSortChange: this.onSortChange, noDataText: this.setNoDataText(), sortIndicator: false, paginationPanel: this.renderPaginationPanel}} remote={true} hover pagination>
                        <TableHeaderColumn dataField='ItemId' isKey hidden>ItemId</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ whiteSpace: 'normal' }} dataSort={true} width='95' dataField='DeliveryDate' dataFormat={this.dateFormatter}><span className="small-header-font">Delivery Date</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='ConsignmentNumber' width='115' dataSort={true} thStyle={{ whiteSpace: 'normal' }} dataFormat={this.valueFormatter}><span className="small-header-font">Consignment Number</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Name' width='200' dataSort={true} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataFormat={this.valueFormatter}><span className="small-header-font">Location Name</span></TableHeaderColumn>
                        {(this.state.divisionId == 'all' && this.state.invoiceBatchId == 'all') &&
                            <TableHeaderColumn dataField='Division'  width='200' dataSort={true} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataFormat={this.valueFormatter}><span className="small-header-font">Division</span></TableHeaderColumn>
                        }
                        <TableHeaderColumn dataField='Street1' tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataSort={true} dataFormat={this.addressFormatter}><span className="small-header-font">Delivery Address</span></TableHeaderColumn>  
                        <TableHeaderColumn dataField='Rate' dataSort={true} dataAlign="middle" thStyle={{ whiteSpace: 'normal' }} width='90' dataFormat={this.amountFormatter}><span className="small-header-font">Rate</span></TableHeaderColumn>         
                        <TableHeaderColumn dataField='InterstateRate' hidden={!this.state.includeInterstateCharge} dataSort={true} dataAlign="middle" thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} width='100' dataFormat={this.interstateFormatter}><span className="small-header-font">Interstate Rate</span></TableHeaderColumn>         
                        <TableHeaderColumn dataField='BoxCount' dataSort={true} dataAlign="middle" thStyle={{ whiteSpace: 'normal' }} width='90' dataFormat={this.valueFormatter}><span className="small-header-font">Box Count</span></TableHeaderColumn>         
                        <TableHeaderColumn dataField='CubicCapacityMetresCubed' dataSort={true} dataAlign="middle" thStyle={{ whiteSpace: 'normal' }} width='200' dataFormat={this.valueFormatter}><span className="small-header-font">Cubic Capacity Metres Cubed</span></TableHeaderColumn>         
                        <TableHeaderColumn dataField='AdditionalDeliveryCharge' dataSort={true} dataAlign="middle" thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} width='100' dataFormat={this.additionalDeliveryChargeFormatter}><span className="small-header-font">Additional Delivery Charged</span></TableHeaderColumn>         
                        <TableHeaderColumn dataField='TotalCharged' dataSort={true} dataAlign="middle" thStyle={{ whiteSpace: 'normal' }} width='100' dataFormat={this.valueFormatter}><span className="small-header-font">Total Charged</span></TableHeaderColumn>
                    </BootstrapTable>
                </LoadingOverlay>
                
            </React.Fragment>
            
        )
    }
}