import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import {Form, Button, Container, Col, Card, Collapse, Spinner} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import {UploadButton} from './utility/UploadButton';
import {DownloadButton} from './utility/DownloadButton';
import { Link, Redirect } from 'react-router-dom';
import {UserConsumer} from './utility/UserContext';

export default class AddEditDivision extends Component {
    state = {
        division: undefined,
        clients: undefined,
        clientId: undefined,
        divisionId: undefined,
        redirect: null
    }
    addEditDivision = async (values, actions) => {
        try{
          const myInit = {
              headers: {
                  Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
              },
              body: {
                  divisions: [
                      {
                        ItemId: this.state.division !== undefined ? this.state.division.ItemId : undefined,
                        ParentItemId: values.client,
                        Name: values.division.toUpperCase(),                        
                        FirstName: values.firstName,
                        LastName: values.lastName,
                        Email: values.email,
                        MobileNumber: values.mobile,
                        Landline: values.landline,
                        FaxNumber: values.faxnumber,
                        PhysicalAddressCountry: values.physicalAddressCountry,
                        PhysicalAddressStreet: values.physicalAddressStreet,
                        PhysicalAddressCity: values.physicalAddressCity,
                        PhysicalAddressState: values.physicalAddressState,
                        PhysicalAddressZip: values.physicalAddressZip,
                        SameWithPhysicalAddress: values.mailingAddressSwitch,
                        MailingAddressCountry: values.mailingAddressCountry,
                        MailingAddressStreet: values.mailingAddressStreet,
                        MailingAddressCity: values.mailingAddressCity,
                        MailingAddressState: values.mailingAddressState,
                        MailingAddressZip: values.mailingAddressZip,
                        Notes: values.notes,
                        Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                        HasRebate: values.rebateSwitch,
                        ApplyRebateIfAbove: values.applyRebateIfAbove,
                        Rebate: values.rebate,
                        AutoSendPOD: values.autoEmailPODSwitch,
                        HyperCare: values.hyperCareSwitch,
                        SpecialCharge: values.specialChargeSwitch ? values.specialCharge : 0,
                        SpecialChargeLabel: values.specialChargeSwitch ? values.specialChargeLabel : null,
                        Recurrence: values.specialChargeSwitch ? values.recurrence : null,
                        DIFOT: values.difotSwitch,
                        DIFOTEmail: values.difotSwitch ? values.difotEmail : null,
                        MinimumInterstateCharge: (values.minimumInterstateCharge && values.minimumInterstateCharge.length > 0) ? values.minimumInterstateCharge : null,
                        SendSMS: values.sendSMS,
                        SMSMessageInVan: values.smsMessageInVan,
                        SMSMessageOnMyWay: values.smsMessageOnMyWay
                      }
                  ]
                  }
          };
          //console.log(JSON.stringify(myInit));
          let result;
          if(this.state.division !== undefined)
            result = await API.patch("api", "/divisions", myInit);
          else
            result = await API.post("api", "/divisions", myInit);
          if(result.result === 'success')
          {
            //this.props.navProp.changeClientPage('divisionList', values.client);
            this.setState({ redirect:'/clients/divisionList/' + values.client});
          }
          else{
            actions.setStatus({msg: result.errors[0]});
            actions.setSubmitting(false);
          }
        }catch(error)
        {
            console.log('error', error.message);
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
    }
    componentDidMount() {
        this.fetchClients();
        //console.log('divisionId', this.props.id);
        if(this.props.match.params.divisionId !== undefined && this.props.match.params.divisionId.length > 0)
        {
            this.setState({divisionId:this.props.match.params.divisionId}, function () {
                this.fetchDivision();});
            //this.fetchDivision();
        }
        if(this.props.match.params.clientId !== undefined && this.props.match.params.clientId.length > 0)
        {
            this.setState({clientId:this.props.match.params.clientId});
        }
        // if(this.props.id !== undefined && this.props.id.startsWith('Division'))
        //     this.fetchDivision();
        // if(this.props.id !== undefined && this.props.id.startsWith('Client'))
        //     this.setState({clientId: this.props.id});
    }
    async fetchDivision() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            const res = await API.get("api", "/divisions/" + this.state.divisionId, myInit);
            this.setState({
                division: res.divisions[0],
                clientId: res.divisions.length > 0 ? res.divisions[0].ParentItemId : undefined
            })
        }catch(err) {
            this.setState(() => { throw err; })
        } 
    }
    async fetchClients() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            const res = await API.get("api", "/clients", myInit);
            this.setState({
                clients: res.clients
            })
        }catch(err) {
            this.setState(() => { throw err; })
        } 
    }
    getClients() {        
        return this.state.clients.map((client, index) => {
           const { Name, ItemId } = client
           return (
               <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }
    render() {
        const schema = yup.object().shape({
            client: yup.string()
                    .required('Required'),
            firstName: yup.string()
                    .required('Required'),
            // lastName: yup.string()
            //         .required('Required'),
            division: yup.string()
                    .required('Required'),
            email: yup.string()
                    .email('Must be a valid email')
                    .required('Required'),
            // mobile: yup.string()
            //         .required('Required'),
            // landline: yup.string()
            //         .required('Required'),
            physicalAddressState: yup.string()
                    .required('Required'),
            physicalAddressCountry: yup.string()
                    .required('Required'),
            physicalAddressStreet: yup.string()
                    .required('Required'),
            physicalAddressCity: yup.string()
                    .required('Required'),
            physicalAddressZip: yup.string()
                    .required('Required'),
            mailingAddressState: yup.string()
                    .required('Required'),
            mailingAddressCountry: yup.string()
                    .required('Required'),
            mailingAddressStreet: yup.string()
                    .required('Required'),
            mailingAddressCity: yup.string()
                    .required('Required'),
            mailingAddressZip: yup.string()
                    .required('Required'),
            applyRebateIfAbove: yup.number()
                    //.ensure()
                    .test('ok', 'Required', function(value){
                        if(this.parent.rebateSwitch === true && (value === undefined || value.length === 0)){
                            return false;
                        }              
                    return true;
                    })
                    .typeError('Must be number'),
            rebate: yup.number()
                    .test('ok', 'Required', function(value){
                        if(this.parent.rebateSwitch === true && (value === undefined || value.length === 0)){
                            return false;
                        }              
                    return true;
                    })
                    .typeError('Must be number'),
            specialCharge: yup.number()
                    //.ensure()
                    .test('ok', 'Required', function(value){
                        if(this.parent.specialChargeSwitch === true && (value === undefined || value.length === 0)){
                            return false;
                        }              
                    return true;
                    })
                    .typeError('Must be number'),
            specialChargeLabel: yup.string()
                    //.ensure()
                    .test('ok', 'Required', function(value){
                        if(this.parent.specialChargeSwitch === true && (value === undefined || value.length === 0)){
                            return false;
                        }              
                    return true;
                    }),
            difotEmail: yup.string()
                    .test('ok', 'Required', function(value){
                        if(this.parent.difotSwitch === true && (value === undefined || value.length === 0)){
                            return false;
                        }              
                    return true;
                    }),
            minimumInterstateCharge: yup.number()
                    .typeError('Must be number'),
            smsMessageInVan: yup.string()
                    .test('ok', '{consignment#} placeholder is required', function(value){
                        if((value === undefined || value === null || value.length === 0 || value.indexOf('{consignment#}') < 0)){
                            return false;
                        }              
                    return true;
                    }),
            smsMessageOnMyWay: yup.string()
                    .test('ok', '{consignment#} placeholder is required', function(value){
                        if((value === undefined || value === null || value.length === 0 || value.indexOf('{consignment#}') < 0)){
                            return false;
                        }              
                    return true;
                    }),
        });
        //if((this.props.id !== undefined && this.props.id.startsWith('Division') && this.state.division === undefined) || this.state.clients === null)
        if (this.state.redirect) 
            return <Redirect to={{pathname: this.state.redirect}} />
        if((this.state.divisionId && this.state.division === undefined) || this.state.clients === undefined)
        {
            return(
                <div><br/><br/><br/>
                <Spinner animation="border" />
                </div>
            );
        }
        else{
            return(
                <Formik
                  validationSchema={schema}
                  onSubmit={async (values, actions) => {
                      await this.addEditDivision(values, actions);
                  }}
                  
                  initialValues={{
                    client: this.state.clientId !== undefined ? this.state.clientId : '',
                    firstName: this.state.division === undefined ? '' : this.state.division.FirstName,
                    lastName: this.state.division === undefined ? '' : this.state.division.LastName,
                    email: this.state.division === undefined ? '' : this.state.division.Email,
                    division: this.state.division === undefined ? '' : this.state.division.Name,
                    mobile: this.state.division === undefined ? '' : this.state.division.MobileNumber,
                    landline: this.state.division === undefined ? '' : this.state.division.Landline,
                    faxnumber: this.state.division === undefined ? '' : this.state.division.FaxNumber,
                    physicalAddressCountry: this.state.division === undefined ? 'Australia' : this.state.division.PhysicalAddressCountry,
                    physicalAddressStreet: this.state.division === undefined ? '' : this.state.division.PhysicalAddressStreet,
                    physicalAddressCity: this.state.division === undefined ? '' : this.state.division.PhysicalAddressCity,
                    physicalAddressZip: this.state.division === undefined ? '' : this.state.division.PhysicalAddressZip,
                    physicalAddressState: this.state.division === undefined ? '' : this.state.division.PhysicalAddressState,
                    mailingAddressSwitch: this.state.division === undefined ? false : this.state.division.SameWithPhysicalAddress,
                    mailingAddressCountry: this.state.division === undefined ? 'Australia' : this.state.division.MailingAddressCountry,
                    mailingAddressStreet: this.state.division === undefined ? '' : this.state.division.MailingAddressStreet,
                    mailingAddressCity: this.state.division === undefined ? '' : this.state.division.MailingAddressCity,
                    mailingAddressZip: this.state.division === undefined ? '' : this.state.division.MailingAddressZip,
                    mailingAddressState: this.state.division === undefined ? '' : this.state.division.MailingAddressState,
                    notes: this.state.division === undefined ? '' : this.state.division.Notes,
                    rebateSwitch: this.state.division === undefined ? false : this.state.division.HasRebate,
                    applyRebateIfAbove: this.state.division === undefined ? '' : (this.state.division.ApplyRebateIfAbove === 0 ? '' : this.state.division.ApplyRebateIfAbove),
                    rebate: this.state.division === undefined ? 0 : this.state.division.Rebate,
                    autoEmailPODSwitch: this.state.division === undefined ? false : this.state.division.AutoSendPOD,
                    hyperCareSwitch: this.state.division === undefined ? false: this.state.division.HyperCare,
                    specialChargeSwitch: this.state.division === undefined ? false : (this.state.division.SpecialChargeLabel ? true : false),
                    specialCharge: this.state.division === undefined ? 0 : this.state.division.SpecialCharge,
                    specialChargeLabel: this.state.division === undefined ? '' : (this.state.division.SpecialChargeLabel ? this.state.division.SpecialChargeLabel : ''),
                    recurrence: this.state.division === undefined ? 'Daily' : (this.state.division.Recurrence ? this.state.division.Recurrence : 'Daily'),
                    difotSwitch: this.state.division === undefined ? false : this.state.division.DIFOT,
                    difotEmail: this.state.division === undefined ? '' : (this.state.division.DIFOTEmail ? this.state.division.DIFOTEmail : ''),
                    minimumInterstateCharge: this.state.division === undefined ? '' : (this.state.division.MinimumInterstateCharge ? this.state.division.MinimumInterstateCharge : ''),
                    sendSMS: this.state.division === undefined ? false : this.state.division.SendSMS,
                    smsMessageInVan: (this.state.division === undefined || this.state.division === null || this.state.division.SMSMessageInVan === null || this.state.division.SMSMessageInVan === '') ? 'Your order {consignment#} from {division} is on board for delivery today with Mediport' : this.state.division.SMSMessageInVan,
                    smsMessageOnMyWay: (this.state.division === undefined || this.state.division === null || this.state.division.SMSMessageOnMyWay === null || this.state.division.SMSMessageOnMyWay === '') ? 'Your order {consignment#} from {division} will be the next delivery made' : this.state.division.SMSMessageOnMyWay
                  }}
                >
                    {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    status,
                    setFieldValue
                  }) => (
                    <Container className="no-left-right-margin form-text-align">
                        <Form onSubmit={handleSubmit}>
                            <Card>
                                <Card.Header>
                                    <Form inline>
                                        <Form.Group as={Col} xs={3} className="text-left padding-left-zero">
                                            <h4>{this.state.divisionId !== undefined ? 'Edit Division' : 'Add Division'}</h4>
                                        </Form.Group>
                                        {this.state.divisionId !== undefined &&
                                        <Form.Group as={Col} xs={9} className="justify-content-end no-left-right-padding">
                                            <Button as={Link} to={`/clients/locationList/${this.state.divisionId}`} className="mr-sm-2" variant="success" size="sm" >
                                            Show Delivery Locations
                                            </Button>
                                            <Button as={Link} to={`/clients/addLocation/${this.state.divisionId}`} className="mr-sm-2" variant="success" size="sm" >
                                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Delivery Location
                                            </Button>
                                            <UploadButton clientId={this.state.clientId} divisionId={this.state.division.ItemId} display='Upload Actions' />
                                            <DownloadButton clientId={this.state.clientId} divisionId={this.state.division.ItemId} display='Download Files' />
                                        </Form.Group>}
                                    </Form>
                                    {/* <h4>{this.props.id !== undefined && this.props.id.startsWith('Division') ? 'Edit Division' : 'Add Division'}</h4> */}
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Division Settings</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>                                        
                                        <Form.Group as={Col} xs={4} controlId="client" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Client</Form.Label>
                                            <Form.Control as="select"
                                                size="sm"
                                                disabled={this.state.division !== undefined}
                                                value={values.client}
                                                name="client"
                                                placeholder="Client"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.client}
                                                touched={touched.client}
                                                isInvalid={errors.client && touched.client}
                                            >
                                            <option value=''>Choose...</option>
                                            {this.getClients()}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.client}
                                            </Form.Control.Feedback>
                                        </Form.Group>                    
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="division" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Division Name</Form.Label>
                                            <UserConsumer>
                                                {userType => {
                                                    return (
                                                        <Form.Control
                                                            readOnly={this.state.division !== undefined && userType.toLowerCase() !== 'companyadministrator' && userType.toLowerCase() !== 'globaladministrator'}
                                                            size="sm"
                                                            type="text"
                                                            value={values.division}
                                                            name="division"
                                                            placeholder="Division"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.division}
                                                            touched={touched.division}
                                                            isInvalid={errors.division && touched.division}
                                                        >
                                                        </Form.Control>
                                                    )
                                                }}
                                            </UserConsumer>                                            
                                            <Form.Control.Feedback type="invalid">
                                                {errors.division}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>                                        
                                        <Form.Group as={Col} controlId="autoEmailPODSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="autoEmailPODSwitch"
                                            label="Auto Email POD"
                                            defaultChecked={values.autoEmailPODSwitch}
                                            value={values.autoEmailPODSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="rebateSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="rebateSwitch"
                                            label="Has Rebate"
                                            defaultChecked={values.rebateSwitch}
                                            value={values.rebateSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Collapse in={values.rebateSwitch === true}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={2} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Apply if above</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.rebateSwitch === false ? (values.applyRebateIfAbove = 0 && values.applyRebateIfAbove) : values.applyRebateIfAbove }
                                                name="applyRebateIfAbove"
                                                placeholder="ex: 100"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.applyRebateIfAbove}
                                                touched={touched.applyRebateIfAbove}
                                                isInvalid={errors.applyRebateIfAbove && touched.applyRebateIfAbove}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.applyRebateIfAbove}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={2} controlId="rebate" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Rebate</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.rebateSwitch === false ? (values.rebate = 0 && values.rebate) : values.rebate}
                                                name="rebate"
                                                placeholder="Rebate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.rebate}
                                                touched={touched.rebate}
                                                isInvalid={errors.rebate && touched.rebate}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rebate}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    </Collapse>                                    
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="specialChargeSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="specialChargeSwitch"
                                            label="Special Charge"
                                            defaultChecked={values.specialChargeSwitch}
                                            value={values.specialChargeSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Collapse in={values.specialChargeSwitch === true}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={2} controlId="specialCharge" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Charge Amount</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.specialChargeSwitch === false ? (values.specialCharge = 0 && values.specialCharge) : values.specialCharge }
                                                name="specialCharge"
                                                placeholder="ex: 100"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.specialCharge}
                                                touched={touched.specialCharge}
                                                isInvalid={errors.specialCharge && touched.specialCharge}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.specialCharge}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={2} controlId="specialChargeLabel" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Description</Form.Label>
                                            <Form.Control as="select"
                                                size="sm"
                                                value={values.specialChargeLabel}
                                                name="specialChargeLabel"
                                                placeholder="Description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.specialChargeLabel}
                                                touched={touched.specialChargeLabel}
                                                isInvalid={errors.specialChargeLabel && touched.specialChargeLabel}
                                            >
                                            <option value=''>Choose...</option>
                                            <option>Admin fee</option>
                                            <option>Pickup charge</option>
                                            <option>Demurrage fee</option>
                                            <option>Storage fee</option>
                                            <option>{`Pick & pack fee`}</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.specialChargeLabel}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={2} controlId="recurrence" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Recurrence</Form.Label>
                                            <Form.Control as="select"
                                                size="sm"
                                                value={values.recurrence}
                                                name="recurrence"
                                                placeholder="recurrence"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.recurrence}
                                                touched={touched.recurrence}
                                                isInvalid={errors.recurrence && touched.recurrence}
                                            >
                                            <option>Daily</option>
                                            <option>Weekly</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.recurrence}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    </Collapse>
                                    <Form.Row>                                        
                                        <Form.Group as={Col} controlId="hyperCareSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="hyperCareSwitch"
                                            label="Hypercare"
                                            defaultChecked={values.hyperCareSwitch}
                                            value={values.hyperCareSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="difotSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="difotSwitch"
                                            label="DIFOT"
                                            defaultChecked={values.difotSwitch}
                                            value={values.difotSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Collapse in={values.difotSwitch === true}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} controlId="difotEmail" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">DIFOT Email</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.difotSwitch === false ? (values.difotEmail = '' && values.difotEmail) : values.difotEmail}
                                                name="difotEmail"
                                                placeholder="separate email addresses using comma"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.difotEmail}
                                                touched={touched.difotEmail}
                                                isInvalid={errors.difotEmail && touched.difotEmail}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.difotEmail}
                                            </Form.Control.Feedback>
                                        </Form.Group>                                        
                                    </Form.Row>
                                    </Collapse>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={3} controlId="minimumInterstateCharge" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Minimum Interstate Charge</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.minimumInterstateCharge}
                                                name="minimumInterstateCharge"
                                                placeholder=""
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.minimumInterstateCharge}
                                                touched={touched.minimumInterstateCharge}
                                                isInvalid={errors.minimumInterstateCharge && touched.minimumInterstateCharge}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.minimumInterstateCharge}
                                            </Form.Control.Feedback>
                                        </Form.Group>                                        
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Contact Information</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="firstName" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">First Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.firstName}
                                                name="firstName"
                                                placeholder="First Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.firstName}
                                                touched={touched.firstName}
                                                isInvalid={errors.firstName && touched.firstName}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.firstName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="lastName" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Last Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.lastName}
                                                name="lastName"
                                                placeholder="Last Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.lastName}
                                                touched={touched.lastName}
                                                isInvalid={errors.lastName && touched.lastName}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.lastName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="email" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">eMail</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.email}
                                                name="email"
                                                placeholder="eMail"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.email}
                                                touched={touched.email}
                                                isInvalid={errors.email && touched.email}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        {/* <Form.Group as={Col} xs={4} controlId="division" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Division</Form.Label>
                                            <Form.Control
                                                readOnly={this.state.division !== undefined}
                                                size="sm"
                                                type="text"
                                                value={values.division}
                                                name="division"
                                                placeholder="Division"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.division}
                                                touched={touched.division}
                                                isInvalid={errors.division && touched.division}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.division}
                                            </Form.Control.Feedback>
                                        </Form.Group>                             */}
                                        <Form.Group as={Col} controlId="mobile" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Mobile #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.mobile}
                                                name="mobile"
                                                placeholder="Mobile #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mobile}
                                                touched={touched.mobile}
                                                isInvalid={errors.mobile && touched.mobile}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mobile}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="landline" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Landline #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.landline}
                                                name="landline"
                                                placeholder="Landline #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.landline}
                                                touched={touched.landline}
                                                isInvalid={errors.landline && touched.landline}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.landline}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="faxnumber" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Fax #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.faxnumber}
                                                name="faxnumber"
                                                placeholder="Fax #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.faxnumber}
                                                touched={touched.faxnumber}
                                                isInvalid={errors.faxnumber && touched.faxnumber}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.faxnumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Physical Address</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="physicalAddressCountry" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Country</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.physicalAddressCountry}
                                                name="physicalAddressCountry"
                                                placeholder="Country"
                                                onChange={handleChange}
                                                // onChange={(e) => {
                                                //     //console.log('onchange', e);
                                                //     if(values.mailingAddressSwitch === true)
                                                //         setFieldValue('mailingAddressCountry', e.target.value);
                                                //     handleChange(e);
                                                // }}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressCountry}
                                                touched={touched.physicalAddressCountry}
                                                isInvalid={errors.physicalAddressCountry && touched.physicalAddressCountry}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressCountry}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="physicalAddressStreet" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Street Address</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.physicalAddressStreet}
                                                name="physicalAddressStreet"
                                                placeholder="Street Address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressStreet}
                                                touched={touched.physicalAddressStreet}
                                                isInvalid={errors.physicalAddressStreet && touched.physicalAddressStreet}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressStreet}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="physicalAddressCity" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">City/Suburb</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.physicalAddressCity}
                                                name="physicalAddressCity"
                                                placeholder="City"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressCity}
                                                touched={touched.physicalAddressCity}
                                                isInvalid={errors.physicalAddressCity && touched.physicalAddressCity}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressCity}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="physicalAddressZip" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.physicalAddressZip}
                                                name="physicalAddressZip"
                                                placeholder="Postcode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressZip}
                                                touched={touched.physicalAddressZip}
                                                isInvalid={errors.physicalAddressZip && touched.physicalAddressZip}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressZip}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4} controlId="physicalAddressState" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                                            <Form.Control as="select"
                                                size="sm"
                                                value={values.physicalAddressState}
                                                name="physicalAddressState"
                                                placeholder="State"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressState}
                                                touched={touched.physicalAddressState}
                                                isInvalid={errors.physicalAddressState && touched.physicalAddressState}
                                            >
                                            <option value=''>Choose...</option>
                                            <option>Queensland</option>
                                            <option>New South Wales</option>
                                            <option>Australian Capital Territory</option>
                                            <option>Victoria</option>
                                            <option>South Australia</option>
                                            <option>Western Australia</option>
                                            <option>Tasmania</option>
                                            <option>Northern Territory</option>
                                            <option value='Christchurch'>Christchurch (New Zealand)</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressState}
                                            </Form.Control.Feedback>
                                        </Form.Group>                    
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label className="margin-bottom-zero"><h5 className="margin-bottom-zero">Mailing Address</h5></Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="mailingAddressSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="mailingAddressSwitch"
                                            label="Same as Physical Address"
                                            defaultChecked={values.mailingAddressSwitch}
                                            value={values.mailingAddressSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="mailingAddressCountry" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Country</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                readOnly={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressCountry : values.mailingAddressCountry = values.physicalAddressCountry && values.physicalAddressCountry}
                                                name="mailingAddressCountry"
                                                placeholder="Country"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressCountry}
                                                touched={touched.mailingAddressCountry}
                                                isInvalid={errors.mailingAddressCountry && touched.mailingAddressCountry}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressCountry}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="mailingAddressStreet" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Street Address</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                readOnly={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressStreet : values.mailingAddressStreet = values.physicalAddressStreet && values.physicalAddressStreet}
                                                name="mailingAddressStreet"
                                                placeholder="Street Address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressStreet}
                                                touched={touched.mailingAddressStreet}
                                                isInvalid={errors.mailingAddressStreet && touched.mailingAddressStreet}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressStreet}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="mailingAddressCity" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">City/Suburb</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                readOnly={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressCity : values.mailingAddressCity = values.physicalAddressCity && values.physicalAddressCity}
                                                name="mailingAddressCity"
                                                placeholder="City"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressCity}
                                                touched={touched.mailingAddressCity}
                                                isInvalid={errors.mailingAddressCity && touched.mailingAddressCity}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressCity}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="mailingAddressZip" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                readOnly={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressZip : values.mailingAddressZip = values.physicalAddressZip && values.physicalAddressZip}
                                                name="mailingAddressZip"
                                                placeholder="Postcode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressZip}
                                                touched={touched.mailingAddressZip}
                                                isInvalid={errors.mailingAddressZip && touched.mailingAddressZip}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressZip}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4} controlId="mailingAddressState" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                                            <Form.Control as="select"
                                                size="sm"
                                                disabled={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressState : values.mailingAddressState = values.physicalAddressState && values.physicalAddressState}
                                                name="mailingAddressState"
                                                placeholder="State"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressState}
                                                touched={touched.mailingAddressState}
                                                isInvalid={errors.mailingAddressState && touched.mailingAddressState}
                                            >
                                            <option value=''>Choose...</option>
                                            <option>Queensland</option>
                                            <option>New South Wales</option>
                                            <option>Australian Capital Territory</option>
                                            <option>Victoria</option>
                                            <option>South Australia</option>
                                            <option>Western Australia</option>
                                            <option>Tasmania</option>
                                            <option>Northern Territory</option>
                                            <option value='Christchurch'>Christchurch (New Zealand)</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressState}
                                            </Form.Control.Feedback>
                                        </Form.Group>                    
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Notes</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={8} controlId="notes" className="margin-bottom-8">
                                            {/* <Form.Label className="med-font margin-bottom-zero">Notes</Form.Label> */}
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="Notes" 
                                                name="notes"
                                                value={values.notes}
                                                onChange={handleChange} 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label className="margin-bottom-zero"><h5 className="margin-bottom-zero">SMS Settings</h5></Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="sendSMS" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="sendSMS"
                                            label="Send SMS"
                                            defaultChecked={values.sendSMS}
                                            value={values.sendSMS}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={8} controlId="smsMessageInVan" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">SMS for &quot;In van for delivery&quot; status:</Form.Label>
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="" 
                                                name="smsMessageInVan"
                                                value={values.smsMessageInVan}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.smsMessageInVan}
                                                touched={touched.smsMessageInVan}
                                                isInvalid={errors.smsMessageInVan && touched.smsMessageInVan}
                                            />
                                            <Form.Label className="small-font faded margin-bottom-zero">You can customize the message above but must include the &#123;consignment#&#125; placeholder, which will be replaced with the consignment number. The &#123;division&#125; placeholder is optional.</Form.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.smsMessageInVan}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={8} controlId="smsMessageOnMyWay" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-top-10 margin-bottom-zero">SMS for &quot;On my way there now&quot; status:</Form.Label>
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="" 
                                                name="smsMessageOnMyWay"
                                                value={values.smsMessageOnMyWay}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.smsMessageOnMyWay}
                                                touched={touched.smsMessageOnMyWay}
                                                isInvalid={errors.smsMessageOnMyWay && touched.smsMessageOnMyWay}
                                            />
                                            <Form.Label className="small-font faded margin-bottom-zero">You can customize the message above but must include the &#123;consignment#&#125; placeholder, which will be replaced with the consignment number. The &#123;division&#125; placeholder is optional.</Form.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.smsMessageOnMyWay}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {status && status.msg && 
                                    <Form.Row>
                                    <Form.Group>
                                        <Form.Label className="error-msg">{status.msg}</Form.Label>
                                    </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Form.Row>                                
                                        <span className="d-inline-block">
                                        <Button variant="primary" type="submit" disabled={isSubmitting} size="sm">
                                        {isSubmitting ? 'Loading…' : 'Save'}
                                        </Button>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="d-inline-block">
                                            {/* <Button variant="primary" onClick={() => this.props.navProp.changeClientPage(this.state.clientId !== undefined ? 'divisionList' : 'clientList', this.state.clientId)} size="sm"> */}
                                            <Button as={Link} to={this.state.clientId !== undefined ? `/clients/divisionList/${this.state.clientId}` : '/clients'} variant="primary" size="sm">
                                            Cancel
                                            </Button>
                                        </span>
                                    </Form.Row>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Container>  
                  )}
                </Formik>
                 
            )
        }
        
    }
}