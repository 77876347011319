import React, { Component } from 'react';
//import HPlatform, { HMap, HMapGeoCode, HMapMarker } from "react-here-map";
import {Map as HereMap, TileLayer, Marker} from "react-leaflet";
//import { OpenStreetMapProvider } from 'leaflet-geosearch';
//import Geocode from "react-geocode";
import axios from 'axios';
import config from "../../config";
import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export default class Map extends Component {
    state = {
        x1: 133.323148,
        y1: -26.250804,
        x2: 133.323148,
        y2: -26.250804,
        z: 3.5,
        isMapInit: false,
        showBounds: false
    };
    saveMap = map => {
        this.map = map;
        this.setState({
          isMapInit: true
        });
    };
    unsaveMap = map => {
        this.map = null;
        this.setState({
          isMapInit: false
        });
    };
    
    componentDidMount() {
        L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
        let Lat1 = -26.250804;
        let Long1 = 133.323148;
        let Lat2 = -26.250804;
        let Long2 = 133.323148;
        if(this.props.address.trim() !== ''){
            if(!this.props.isPickup) {
                this.geosearch(this.props.address.trim()).then(result => 
                    {
                        if(result.data.items[0] && result.data.items[0].address)
                        {
                            const Lat = result.data.items[0].position.lat;
                            const Long = result.data.items[0].position.lng;
                            if(this.state.x1 !== Long || this.state.y1 !== Lat){
                                this.setState({x1: Long, y1: Lat, z: 9});
                                this.props.setCoordinates(Lat,Long);
                            }                        
                        }                        
                        else
                            this.setState({x1: 133.323148, y1: -26.250804, z: 3.5});
                    },
                    error => {
                        console.error(error);
                        this.setState({x1: 133.323148, y1: -26.250804, z: 3.5});
                    }
                )
            } 
            else if(this.props.pickupaddress.trim() !== '') {
                
                this.geosearch(this.props.address.trim()).then(result => 
                    {
                        if(result.data.items[0] && result.data.items[0].address)
                        {
                            Lat1 = result.data.items[0].position.lat;
                            Long1 = result.data.items[0].position.lng;
                            if(this.state.x1 !== Long1 || this.state.y1 !== Lat1){
                                this.setState({x1: Long1, y1: Lat1, z: 9});
                                this.props.setCoordinates(Lat1,Long1,Lat2,Long2);
                            }                        
                        }                        
                        else
                            this.setState({x1: 133.323148, y1: -26.250804, z: 3.5});
                    },
                    error => {
                        console.error(error);
                        this.setState({x1: 133.323148, y1: -26.250804, z: 3.5});
                    }
                );
                this.geosearch(this.props.pickupaddress.trim()).then(result => 
                    {
                        if(result.data.items[0] && result.data.items[0].address)
                        {
                            Lat2 = result.data.items[0].position.lat;
                            Long2 = result.data.items[0].position.lng;   
                            if(this.state.x2 !== Long2 || this.state.y2 !== Lat2){
                                this.setState({x2: Long2, y2: Lat2, z: 9});
                                this.props.setCoordinates(Lat1,Long1,Lat2,Long2);
                            }                                                
                        }                        
                        else
                            this.setState({x2: 133.323148, y2: -26.250804, z: 3.5});
                    },
                    error => {
                        console.error(error);
                        this.setState({x2: 133.323148, y2: -26.250804, z: 3.5});
                    }
                );                

            }                       
        }        
    }
    
    componentWillReceiveProps(newProps) {    
        //https://geocoder.api.here.com/6.2/geocode.json?searchtext=${this.props.address.trim()}&app_id=${hereCredentials.id}&app_code=${hereCredentials.code}&gen=8
        //const result = await axios.get(`https://geocoder.api.here.com/6.2/geocode.json?searchtext=${this.props.address.trim()}&app_id=${hereCredentials.id}&app_code=${hereCredentials.code}&gen=8`);
        //console.log('from HERE', result);
        let Lat1 = -26.250804;
        let Long1 = 133.323148;
        let Lat2 = -26.250804;
        let Long2 = 133.323148;
        if((newProps.address !== this.props.address) || (newProps.pickupaddress !== this.props.pickupaddress) || (newProps.isPickup !== this.props.isPickup)) {
            if(!newProps.isPickup) {
                this.geosearch(newProps.address.trim()).then(result => 
                    {
                        console.log('resultGeo', result);
                        if(result.data.items[0] && result.data.items[0].address)
                        {
                            const Lat = result.data.items[0].position.lat;
                            const Long = result.data.items[0].position.lng;
                            if(this.state.x1 !== Long || this.state.y1 !== Lat){
                                this.setState({x1: Long, y1: Lat, z: 9});
                                this.props.setCoordinates(Lat,Long);
                            }                        
                        }                        
                        else
                        {
                            this.setState({x1: 133.323148, y1: -26.250804, z: 3.5});
                            this.props.setCoordinates('','');
                        }
                            
                    },
                    error => {
                        console.error('ERROR', error);
                        this.setState({x1: 133.323148, y1: -26.250804, z: 3.5});
                        this.props.setCoordinates('','');
                    }
                )
            }
            else {
                if(newProps.address.trim().length > 0) {
                    this.geosearch(newProps.address.trim()).then(result => 
                        {
                            //console.log('resultGeo', result.data.Response.View[0].Result[0].Location.DisplayPosition);
                            if(result.data.items[0] && result.data.items[0].address)
                            {
                                Lat1 = result.data.items[0].position.lat;
                                Long1 = result.data.items[0].position.lng;
                                if(this.state.x1 !== Long1 || this.state.y1 !== Lat1){
                                    this.setState({x1: Long1, y1: Lat1, z: 9});
                                    this.props.setCoordinates(Lat1,Long1);
                                }                                                
                            }                        
                            else
                            {
                                console.error('Invalid Address');
                                this.setState({x1: 133.323148, y1: -26.250804, z: 3.5});
                                this.props.setCoordinates('','');
                            }
                                
                        },
                        error => {
                            console.error('ERROR', error);
                            this.setState({x1: 133.323148, y1: -26.250804, z: 3.5});
                            this.props.setCoordinates('','');
                        }
                    )
                }
                this.geosearch(newProps.pickupaddress.trim()).then(result => 
                    {
                        //console.log('resultGeo', result.data.Response.View[0].Result[0].Location.DisplayPosition);
                        if(result.data.items[0] && result.data.items[0].address)
                        {
                            Lat2 = result.data.items[0].position.lat;
                            Long2 = result.data.items[0].position.lng;  
                            if(this.state.x2 !== Long2 || this.state.y2 !== Lat2){
                                this.setState({x2: Long2, y2: Lat2, z: 9});
                                this.props.setPickupCoordinates(Lat2,Long2);
                            }                                               
                        }                        
                        else
                        {
                            console.error('Invalid Address');
                            this.setState({x2: 133.323148, y2: -26.250804, z: 3.5});
                            this.props.setPickupCoordinates('','');
                        }
                            
                    },
                    error => {
                        console.error('ERROR', error);
                        this.setState({x2: 133.323148, y2: -26.250804, z: 3.5});
                        this.props.setPickupCoordinates('','');
                    }
                )                
            }            
        }        
     }
    geosearch = async (address) => {
        let doGoogleGeocode = false;
        let hasHereMapsResult = false;
        if(!address.toLowerCase().endsWith('australia'))
            address = address + ' Australia';
        let result = await axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(address)}&apiKey=${config.map.apikey}`);
        if(result.data.items[0] && result.data.items[0].address)
        {
            hasHereMapsResult = true;
            if(result.data.items[0].address && result.data.items[0].address.countryCode)
            {
                //if heremaps return a location that is not Australian address, do Google geocoding since google has high accuracy.
                if(result.data.items[0].address.countryCode.toLowerCase()  !== 'aus')
                    doGoogleGeocode = true; 
            }                       
        }
        else
            doGoogleGeocode = true;        
                    
        if(doGoogleGeocode)
        {
            let newAddress = address;
            if(hasHereMapsResult === false)
            {
                if(!address.toLowerCase().endsWith('australia'))
                    newAddress = newAddress + ' Australia';
            }
            const googleResult = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(newAddress)}&key=AIzaSyBv8Ld2ZKmFIlXkTs1J3I5_hd3_znvPbTE`);
            if(googleResult.data.results.length > 0)
            {
                if(googleResult.data.results.filter(l => l.formatted_address.toLowerCase().indexOf('australia') > -1).length > 0)
                {
                    newAddress = googleResult.data.results.filter(l => l.formatted_address.toLowerCase().indexOf('australia') > -1)[0].formatted_address;
                }
                else
                {
                    newAddress = googleResult.data.results[0].formatted_address;
                }                
                result = await axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(newAddress)}&apiKey=${config.map.apikey}`);
            }            
        }
        return result;
    }
    
    getBounds() {
        if(this.state.showBounds)
        {
            return L.latLngBounds([[this.state.y1,this.state.x1], [this.state.y2,this.state.x2]]);
        }
        else
        {
            //console.log('bounds default');
            return L.latLngBounds([[-10.070786, 108.625136],[-41.367241, 161.171241]]);;
        }
    }

    render() {
        //const results = this.geosearch(this.props.address.trim());
        //console.log('results', results);
        var myIcon = L.icon({
            iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png',
            //iconRetinaUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png',
            iconSize: [25, 41],
            className: 'default-icon'
            //iconAnchor: [0, -40],
            // popupAnchor: [-3, -76],
            // shadowUrl: 'my-icon-shadow.png',
            // shadowRetinaUrl: 'my-icon-shadow@2x.png',
            // shadowSize: [68, 95],
            // shadowAnchor: [22, 94]
        });
        var pickupIcon = L.icon({
            iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
            //iconRetinaUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png',
            iconSize: [25, 41],
            className: 'default-icon'
            //iconAnchor: [0, -40],
            // popupAnchor: [-3, -76],
            // shadowUrl: 'my-icon-shadow.png',
            // shadowRetinaUrl: 'my-icon-shadow@2x.png',
            // shadowSize: [68, 95],
            // shadowAnchor: [22, 94]
        });
        const position = [this.state.y1, this.state.x1]; //[51.505, -0.09]
        const pickupposition = [this.state.y2, this.state.x2];
        let centerx = 133.323148;
        let centery = -26.250804;
        if(this.state.x1 !== 133.323148 && this.state.y1 !== -26.250804 && this.state.x2 === 133.323148 && this.state.y2 === -26.250804) {
            centerx = this.state.x1;
            centery = this.state.y1;
            this.state.showBounds = false;
        }
        if(this.state.x1 === 133.323148 && this.state.y1 === -26.250804 && this.state.x2 !== 133.323148 && this.state.y2 !== -26.250804) {
            centerx = this.state.x2;
            centery = this.state.y2;
            this.state.showBounds = false;
        }
        if(this.state.x1 !== 133.323148 && this.state.y1 !== -26.250804 && this.state.x2 !== 133.323148 && this.state.y2 !== -26.250804) {
            centerx = (this.state.x1 + this.state.x2) / 2;
            centery = (this.state.y1 + this.state.y2) / 2;
            this.state.showBounds = true;
        }
        let center = [centery, centerx];        
        const zoom = this.state.z;
        const hereTileUrl = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&style=explore.day&&ppi=400&apiKey=${config.map.apikey}`;
        return (            
            <HereMap
            center={center}
            zoom={zoom}
            bounds={this.getBounds()}
            gestureHandling={true}
            //scrollWheelZoom={false}
            maxZoom={19}
            //ref={this.saveMap}
            >
                <TileLayer
                attribution='&copy; HERE 2019'
                url={hereTileUrl}
                />
                {/* <Polyline positions={[[this.state.y,this.state.x],[this.state.y,(this.state.x + 0.0001)]]}/> */}
                {this.state.x1 !== 133.323148 && this.state.y1 !== -26.250804 &&
                <Marker position={position} icon={myIcon}>
                </Marker>}
                {this.props.isPickup && this.state.x2 !== 133.323148 && this.state.y2 !== -26.250804 &&
                <Marker position={pickupposition} icon={pickupIcon}>
                </Marker>}
                {/* {this.state.isMapInit && <Routing from={[27.67, 85.316]} to={[27.68, 85.321]} map={this.map} />} */}
            </HereMap>
        );        
    }
}