import React, { Component } from 'react';
import {Navbar, Form, Button,  Col} from 'react-bootstrap';
import {authorizationHeader} from './utility/Helper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingOverlay from 'react-loading-overlay';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from "moment";
import "moment-timezone";
import { API } from "aws-amplify";

export default class SMSReport extends Component {
    state = {
        error: '',
        smsdata:[],
        isLoading: true,
        sortName: 'User',
        sortOrder: 'asc',
        pageSize: 30,
        page: 1,
        nextToken: false,
        prevToken: false,
        forwardToken: undefined,
        backwardToken: undefined,
        fromDate: new Date(), //moment(),
        toDate: new Date(),//moment()
    }

    componentDidMount() {
        this.getSMSData();
        this.isLoading = true;
    }

    onSortChange = async (sortName, sortOrder) => {
        console.log('sortName', sortName);
        console.log('sortOrder', sortOrder);
        this.setState({
          page: 1, 
          sortName: sortName,
          sortOrder: sortOrder,
          isLoading: true,
          forwardToken: undefined,
          backwardToken: undefined
        },function () {
            this.getSMSData();});
    }

    setNoDataText = async() => {
        if(this.state.isLoading == false){
           return "No record found";
        }else{
            return 'Loading...';
        }
    }

    getSMSData = async() => {
        console.log('getting sms data..');   
        try{
            let filters = '';
            const {sortName, sortOrder, searchKeyword, pageSize, forwardToken, backwardToken, page, userStatus} = this.state;
            const from = moment(this.state.fromDate).format('YYYY-MM-DD');
            const to = moment(this.state.toDate).format('YYYY-MM-DD') + ' 23:59:59';
            filters = '?pageSize=' + pageSize + '&page=' + page;
            if(sortName !== '' && sortOrder !== '')
                filters = filters + '&Sort=' + sortName + '~' + sortOrder;
            if(forwardToken)
                filters = filters + '&ForwardToken=' + forwardToken;
            if(backwardToken)
                filters = filters + '&BackwardToken=' + backwardToken;
            filters = filters + '&Where=Created~between~' + from + '|' + to;
            const authHeader = await authorizationHeader();
            const res = await API.get("api", "/sms" + filters, authHeader);
            this.setState({
                isLoading: false,
                smsdata: res.sms,
                nextToken: res.forwardPageToken,
                prevToken: res.backwardPageToken,
                forwardToken: undefined,
                backwardToken: undefined
            });
        }catch(err) {
            console.log(err.toString().toLowerCase())
            this.error = err.toString().toLowerCase();
        }
    }

    updatePageSize = async(value) => {
        this.state.page = 1;
        this.state.pageSize = value;
        this.state.backwardTokenisLoading = true;
        this.getSMSData();
    }

    setForwardToken = () => {
        this.setState({ page: this.state.page + 1, isLoading: true},function () {
            this.getSMSData();;});
    }

    setBackwardToken = () => {
        this.setState({ page: this.state.page - 1, isLoading: true},function () {
            this.getSMSData();});
    }

    setFromDate = value => {
        let date = value;
        if(value > this.state.toDate)
            date = this.state.toDate;
        this.setState({ page: 1, fromDate: date, isLoading: true }, function () {
            this.getSMSData();});
    }

    setToDate = value => {
        let date = value;
        if(value < this.state.fromDate)
            date = this.state.fromDate;
        this.setState({ page: 1, toDate: date, isLoading: true }, function () {
            this.getSMSData();});
    }

    dateFormatter(cell, row) {
        return <span className="medium-font">{moment(cell).format("DD-MMM-YY")}</span>;
    }

    renderPaginationPanel = (props) => {
        return (
            <Form as={Col} xs={12} className="form-inline bottom-margin">
                <Form.Group as={Col} xs={10}>
                <Form.Label>Records per page:&nbsp;</Form.Label>
                <Form.Control as="select"  onChange={(e) =>
                                              this.updatePageSize(
                                                e.target.value
                                              )
                                            }  defaultValue='30' size='sm'>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>    
                    <option value='25'>25</option>                             
                    <option value='30'>30</option> 
                </Form.Control>    
                </Form.Group>
                <Form.Group as={Col} xs={2}>
                    {!this.state.prevToken &&
                    <Button variant="success" disabled size="sm">Prev</Button>}
                    {this.state.prevToken &&
                    <Button variant="success" size="sm" onClick={this.setBackwardToken}>Prev</Button>}
                    &nbsp;
                    {!this.state.nextToken &&
                    <Button variant="success" disabled size="sm">Next</Button>}
                    {this.state.nextToken &&
                    <Button variant="success" size="sm" onClick={this.setForwardToken}>Next</Button>}
                </Form.Group>         
                
            </Form>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Navbar className="bg-light padding-top-bottom-zero">
                    <Navbar.Brand href="/">
                        <h4 className="padding-top-bottom-zero">SMS Report</h4>
                    </Navbar.Brand>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                    <Form inline>
                    <Form.Label className="mr-sm-2">From</Form.Label>
                        <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={Date.parse(this.state.fromDate)} maxDate={this.state.toDate} onChange={value => this.setFromDate(value)} className='form-control form-control-sm mr-sm-3 width-100' placeholderText='Select from date'/>
                        <Form.Label className="mr-sm-2">To</Form.Label>
                        <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={Date.parse(this.state.toDate)} minDate={this.state.fromDate} onChange={value => this.setToDate(value)} className='form-control form-control-sm width-100' placeholderText='Select to date'/>
                    </Form>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                <Form inline>
                    <Button className="mr-sm-3" variant="success" size="sm" type="button" onClick={() => { this.setState({isLoading: true}); this.getSMSData();}} >
                        Submit
                    </Button>
                    </Form>
                </Navbar>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Loading SMS Logs...'
          >
           <BootstrapTable ref='table' data={this.state.smsdata} options={{onSortChange: this.onSortChange, sortIndicator: false, paginationPanel: this.renderPaginationPanel}}  remote={true} hover pagination>
              <TableHeaderColumn dataField='ItemId' isKey hidden>ItemId</TableHeaderColumn>
              <TableHeaderColumn dataField='User' width='250'   thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }}  dataSort={true}><span className="med-header-font">Sent By</span></TableHeaderColumn>
              <TableHeaderColumn dataField='Message'  thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Message</span></TableHeaderColumn>
              <TableHeaderColumn dataField='MobileNumber' width='200'  thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Sent To</span></TableHeaderColumn>
              <TableHeaderColumn dataField='Created' width='200' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataFormat={this.dateFormatter}  dataSort={true}><span className="med-header-font">Sent On</span></TableHeaderColumn>
          </BootstrapTable></LoadingOverlay>
            </React.Fragment>
        )                
    }
}

