import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import {Form, Button, Container, Row, Col, FormGroup} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

class RequireChangePassword extends Component {

  handleSubmit = async (values) => {
    try {
      await Auth.completeNewPassword(this.props.auth.user, values.newPassword);
      await this.props.auth.setAuthStatus(true);
      const user = await Auth.currentAuthenticatedUser();
      await this.props.auth.setUser(user);
      this.props.history.push("/");
    } catch (error) {
      return error.message;
    }
  }

  render() {
    if(!this.props.auth.isAuthenticated)
      this.props.history.push("/login");
    const schema = yup.object().shape({
        newPassword: yup.string()
          .min(6, 'Must have minimum of 6 characters')
          .required('Required'),
        confirmPassword: yup.string()
          .min(6, 'Must have minimum of 6 characters')
          .required('Required')
          .test('passwords-match', 'Passwords must match', function(value) {
            return this.parent.newPassword === value;
        }),
    });
    return (
        <Formik
            validationSchema={schema}
            onSubmit={async (values, actions) => {
              const msg = await this.handleSubmit(values);
              actions.setStatus({msg: msg});
              actions.setSubmitting(false);
            }}
            initialValues={{
              newPassword: '',
              confirmPassword: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              status,
            }) => (
        <Container>
            <Row>
            <Col></Col>
            <Col md={4}  style={{ border: 'solid thin', padding: '1em' }}>
                <Form className="form-text-align" onSubmit={handleSubmit} >
                    <FormGroup>
                        <Form.Label><h3>Require change password</h3></Form.Label>
                    </FormGroup>

                    <Form.Group controlId="newPassword">
                        <Form.Control 
                            type="password" 
                            placeholder="New password"
                            name="newPassword"
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.newPassword && touched.newPassword} 
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.newPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="confirmPassword">
                        <Form.Control 
                            type="password" 
                            placeholder="Confirm password"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.confirmPassword && touched.confirmPassword} 
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    {status && status.msg && 
                    <Form.Group>
                        <Form.Label className="error-msg">{status.msg}</Form.Label>
                    </Form.Group>}
                    
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                        Change password
                    </Button>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre>                         */}
                </Form>
                </Col>
            <Col></Col>                    
            </Row>
        </Container>
        )}
        </Formik>
    );
  }
}

export default RequireChangePassword;