import React, { Component } from 'react';
import {Container, Row, Col, ListGroup, Form, Button} from 'react-bootstrap';
import { Auth, API } from "aws-amplify";
import { Formik } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import * as yup from 'yup';

export default class Reassign extends Component {
    jobsList () {
        const jobLists = this.props.jobs.map((job) =>
        <ListGroup.Item key={job.ItemId} className="med-font no-left-right-padding top-bottom-padding-2">{job.ConsignmentNumber + ' - ' + job.Name}</ListGroup.Item>
        );
        return (
            <ListGroup variant="flush">{jobLists}</ListGroup>
        );
    }

    getStaff () {
        return this.props.staffs.map((staff, index) => {
            const { FullName, ItemId } = staff
            return (
                <option key={ItemId} value={ItemId} >{FullName}</option>
            )
         })
    }

    reassign = async (values, actions) => {
        try{
            const driver = this.props.staffs.filter(driver =>
                driver.ItemId === values.staff);
            console.log('driver', driver);
            let patchRecords;
            if(values.deliveryDateSwitch === true)
                patchRecords = this.props.jobs.map((job) => ({ItemId: job.ItemId, DriverName: driver[0].FullName, DriverId: driver[0].ItemId, HasDeliveryTime: false, DeliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD') + ' 00:00:00'}));
            else
                patchRecords = this.props.jobs.map((job) => ({ItemId: job.ItemId, DriverName: driver[0].FullName, DriverId: driver[0].ItemId}));
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    jobs: patchRecords
                    }
            };
            
            await API.patch("api", "/jobs", myInit);
            this.props.refresh(true);
        }catch(error)
        {
            console.log('error', error.message);
        }
    }

    render() {
        const schema = yup.object().shape({
            staff: yup.string()
                .ensure()
                .required('Driver is required')
                .test('ok', 'Driver is required', function(value) {
                  return '0' !== value;
                }),
        });
        return(
        <Formik
        validationSchema={schema}
        onSubmit={async (values, actions) => {
            await this.reassign(values, actions);
        }}
        initialValues={{
            staff:'0',
            deliveryDateSwitch: false,
            deliveryDate: new Date()
        }}
        >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              setFieldValue
            }) => (
            <Container>
                <Form onSubmit={handleSubmit}>
                    {this.props.jobs.length > 0 &&
                    <React.Fragment>
                    <Row className="justify-content-md-center bold-font">
                        <Col xs lg="11">
                            <Form.Label>You have selected following {this.props.jobs.length === 1 ? ' 1 job' : this.props.jobs.length + ' jobs'} to reassign.</Form.Label>
                        </Col>                
                    </Row>
                    <Row className="justify-content-md-center top-margin-20">
                        <Col xs lg="11">
                            {this.jobsList()}
                        </Col>                
                    </Row>
                    <Row className="justify-content-md-center top-margin-20">                            
                        <Form.Group as={Col} md={11} controlId="staff">
                            <Form.Label className="bold-font">Select Driver</Form.Label>
                            <Form.Control as="select"
                                size="sm"
                                value={values.staff}
                                name="staff"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.staff}
                                touched={touched.staff}
                                isInvalid={errors.staff && touched.staff}
                            >
                                <option value="0">Choose...</option>
                                {this.getStaff()}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.staff}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="justify-content-md-center top-margin-20">
                        <Form.Group as={Col} xs={11} controlId="deliveryDateSwitch">
                            <Form.Check
                                type="switch"
                                id="deliveryDateSwitch"
                                label="Change Delivery Date"
                                value={values.deliveryDateSwitch}
                                onChange={handleChange}
                                />
                        </Form.Group>
                    </Row>
                    {values.deliveryDateSwitch === true &&
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} xs={11} controlId="deliveryDate">
                            <Form.Label className="med-font margin-bottom-zero">Delivery Date</Form.Label><br/>
                            <DatePicker dateFormat="dd/MM/yyyy" selected={Date.parse(values.deliveryDate)} onChange={value => setFieldValue('deliveryDate', value)} className='form-control form-control-sm' placeholderText='Select delivery date'/>
                        </Form.Group>
                    </Row>
                    }
                    <Form.Row className="justify-content-md-right modal-footer no-border top-margin-20"> 
                        <Button className="mr-sm-1" variant="primary" type="submit" disabled={isSubmitting} size="sm">
                            {isSubmitting ? 'Processing…' : 'Save'}
                        </Button>
                        <Button variant="primary" size="sm" onClick={this.props.onHide}>Cancel</Button>                    
                    </Form.Row>
                    </React.Fragment>}
                    {this.props.jobs.length === 0 &&
                    <Row className="justify-content-md-center">
                        <Col xs lg="11">
                            <Form.Label>Please select the jobs to be reassigned.</Form.Label>
                        </Col>                
                    </Row>
                    }
                </Form>
            </Container>
            )}
        </Formik>
        );        
    }
}