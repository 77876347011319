import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import {Form, Button, Container, Col, Card, Spinner} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';

export default class AddEditNotificationGroup extends Component {
    state = {
        notificationGroup: undefined,
        notificationGroupId: undefined,
        users: undefined,
        selectedUsers: [],
        redirect: null
    }
    addEditNotificationGroup = async (values, actions) => {
        try{
            let myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    workgroups: [
                        {
                            ItemId: this.state.notificationGroupId !== undefined ? this.state.notificationGroupId : undefined,
                            Name: values.name.replace(/[ ]/g, '-'),
                            Members: this.state.selectedUsers.length === 0 ? [] : this.state.selectedUsers.map(user => {
                                const {FirstName, LastName, ItemId, AccountType, Email, MobileNumber} = this.state.users.filter(u => u.ItemId === user)[0];
                                return({
                                    ParentItemId: '',
                                    TopicARN: '',
                                    FirstName: FirstName,
                                    LastName: LastName,
                                    AccountId: ItemId,
                                    AccountType: AccountType,
                                    Email: Email,
                                    MobileNumber: MobileNumber
                                });
                            })
                        }
                    ]
                    }
            };
            //console.log(JSON.stringify(myInit));
            let result;
            // if(this.state.notificationGroupId !== undefined)
            //     result = await API.patch("api", "/workgroups", myInit);
            // else
            result = await API.post("api", "/workgroups", myInit);
            if(result.result === 'success')
            {              
                this.setState({ redirect:'/notificationGroups' });
            }              
            else{
                actions.setStatus({msg: result.errors[0]});
                actions.setSubmitting(false);
            }
        }catch(error)
        {
            console.log('error', error.message);
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
    }
    async componentDidMount() {    
        try{
            if(this.props.match.params.notificationGroupId !== undefined && this.props.match.params.notificationGroupId.length > 0)
            {
                this.setState({notificationGroupId:this.props.match.params.notificationGroupId}, function () {
                    this.fetchNotificationGroup();});
            }
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };
            const drivers = await API.get("api", "/accounts", myInit);
            this.setState({users: drivers.accounts});
        }catch(err) {
            this.setState(() => { throw err; })
        }
    }
    getUsersArray() {
        return this.state.users.filter(u => u.AccountType.toLowerCase() !== 'globaladministrator').map((user, index) => {
          const { FullName, ItemId, AccountType } = user
          return (
            { value: ItemId, label: FullName + (AccountType.toLowerCase().indexOf('admin') > -1 ? ' - Admin' : '') }
          )
        })
    }
    async fetchNotificationGroup() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            const res = await API.get("api", "/workgroups/" + this.state.notificationGroupId, myInit);
            this.setState({
                notificationGroup: res.workgroups[0],
                selectedUsers: res.workgroups[0].Members.map(u => u.AccountId)
            })
        }catch(err) {
            this.setState(() => { throw err; })
        }
    }
    render() {
        const schema = yup.object().shape({
            name: yup.string()
                    .required('Required')
        });
        if (this.state.redirect) 
            return <Redirect to={{pathname: this.state.redirect}} />
        if((this.state.notificationGroupId !== undefined && this.state.notificationGroup === undefined) || this.state.users === undefined)
        {
            return(
                <div><br/><br/><br/>
                <Spinner animation="border" />
                </div>
            );
        }
        else{
            return(
                <Formik
                  validationSchema={schema}
                  onSubmit={async (values, actions) => {
                      await this.addEditNotificationGroup(values, actions);
                  }}
                  
                  initialValues={{
                    name: this.state.notificationGroup === undefined ? '' : this.state.notificationGroup.Name,
                  }}
                >
                    {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    status,
                    setFieldValue
                  }) => (
                    <Container className="no-left-right-margin form-text-align">
                        <Form onSubmit={handleSubmit}>
                            <Card>
                                <Card.Header>
                                    <div inline>
                                        <Form.Group as={Col} xs={6} className="text-left padding-left-zero">
                                            <h4>{this.state.notificationGroupId === undefined ? 'Add Notification Group' : 'Edit Notification Group'}</h4>
                                        </Form.Group>
                                    </div>                                    
                                    </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-bottom-zero">
                                            <Form.Label><h5>Notification Group Name</h5></Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4} controlId="name" className="margin-bottom-8">
                                            <Form.Control
                                                readOnly={this.state.notificationGroup !== undefined}
                                                size="sm"
                                                type="text"
                                                value={values.name}
                                                name="name"
                                                placeholder="Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.name}
                                                touched={touched.name}
                                                isInvalid={errors.name && touched.name}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group> 
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-bottom-zero">
                                            <Form.Label><h5>{this.state.notificationGroup !== undefined ? 'Edit Members' : 'Add Members'}</h5></Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={8} controlId="name" className="margin-bottom-8">
                                        {this.state.users &&
                                        <Select 
                                        closeMenuOnSelect={false}
                                        className="basic-multi-select form-text-align" 
                                        classNamePrefix="select" 
                                        isMulti 
                                        options={this.getUsersArray()} 
                                        name="usersSelect"                                                        
                                        value={this.getUsersArray().filter(option => this.state.selectedUsers.indexOf(option.value) >= 0)}
                                        onChange={(option) => {
                                            console.log('onChange', option);
                                            this.setState({selectedUsers:option ? option.map((item,index) => item.value) : []});                                            
                                        }}/>}
                                        </Form.Group> 
                                    </Form.Row>
                                    
                                    {status && status.msg && 
                                    <Form.Row>
                                        <Form.Group>
                                            <Form.Label className="error-msg">{status.msg}</Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Form.Row>                                
                                        <span className="d-inline-block">
                                        <Button variant="primary" type="submit" disabled={isSubmitting} size="sm">
                                        {isSubmitting ? 'Loading…' : 'Save'}
                                        </Button>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="d-inline-block">
                                            <Button as={Link} to="/notificationGroups" variant="primary" size="sm">
                                            Cancel
                                            </Button>
                                        </span>
                                    </Form.Row>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Container>  
                  )}
                </Formik>
                 
            )
        }
        
    }
}