import React, { useState, useEffect } from 'react';
import {Alert} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { API } from "aws-amplify";
import { saveAs } from 'file-saver';
import PDFDocument from './utility/PDFDocument';
import {pdf} from "@react-pdf/renderer";
import { Link } from 'react-router-dom';

function PublicDownload(props) {
    const [error, setError] = useState('');
    const [displayText, setDisplayText] = useState('Downloading...');

    const fetchData = async(id,type) => {
        try{
            if(!type)
                type = "pod";
            const res = await API.get("api", "/dataForPublicDownload?id=" + id + "&type=" + type);
            const resultImages = res.attachments.map(a => {
                return (
                 { fileName: a.Key, url: a.Value }
                )
            });
            console.log(resultImages);
            const blob = await pdf((
                <PDFDocument imageFiles={resultImages} job={res.job} logo={res.logo} history={res.history} noHistory={true} files={res.files} />
            )).toBlob();
            saveAs(blob, res.job.ConsignmentNumber + '.pdf');
            setDisplayText('Download successful!');
        }catch(err) {
            setError(err.toString().toLowerCase());
        }
    }

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let id = params.get('id');
        let type = params.get('type');
        fetchData(id,type);
    },[])

    if(error.length > 0){
        return(
            <Alert variant='danger'>
                {error.indexOf('network error') > -1 ? 'Network Error encountered. Kindly check internet connection and refresh the page.' : 'Cannot download. Invalid link.'}
            </Alert>
        );        
    }

    return(
        <>
            <div className="margin-top-40">{displayText}</div>
            {displayText.indexOf('Downloading') < 0 &&
            <Link to={`/`} className="small-font">Go to site</Link>}
        </>
    );
}

export default PublicDownload