import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import {Form, Button, Container, Row, Col, FormGroup, Alert} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

class ResetPassword extends Component {
    state = {
        codeRequestSent: false,
        confirmed: false,
        email: ''
    }

    sendCodeRequest = async (values, actions) => {
        try {
        //await Auth.completeNewPassword(this.props.auth.user, values.newPassword);
        await Auth.forgotPassword(values.email.toLowerCase());
        actions.setSubmitting(false);
        this.setState({codeRequestSent: true, email: values.email.toLowerCase()});
        //await this.props.auth.setAuthStatus(true);
        //this.props.history.push("/");
        } catch (error) {
            console.log(error);
            let msg = error.message;
            if(error.code.toLowerCase() === 'usernotfoundexception')
                msg = "User with this email doesn't exist"
            actions.setStatus({msg: msg});
            actions.setSubmitting(false);
        }
    }

    newPasswordConfirm = async (values, actions) => {
        try {
            await Auth.forgotPasswordSubmit(
                this.state.email,
                values.code,
                values.newPassword
            );
            this.setState({confirmed: true});
        } catch (error) {
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
      }

    renderRequestCodeForm() {
        const schema = yup.object().shape({
            email: yup.string()
                .email('Must be a valid email')
                .required('Required'),
        });
        return (
            <Formik
                validationSchema={schema}
                onSubmit={async (values, actions) => {
                    await this.sendCodeRequest(values, actions);
                }}
                initialValues={{
                email: '',
                newPassword: '',
                confirmPassword: '',
                code: ''
                }}
            >
                {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isSubmitting,
                status,
                }) => (
            <Container>
                <Row>
                <Col></Col>
                <Col md={4}  style={{ border: 'solid thin', padding: '1em' }}>
                    <Form className="form-text-align" onSubmit={handleSubmit} >
                        <FormGroup>
                            <Form.Label><h3>Send Confirmation</h3></Form.Label>
                        </FormGroup>

                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter email" 
                                name="email"
                                value={values.email} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isInvalid={errors.email && touched.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        {status && status.msg && 
                        <Form.Group>
                            <Form.Label className="error-msg">{status.msg}</Form.Label>
                        </Form.Group>}
                        
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            Submit
                        </Button>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>                         */}
                    </Form>
                    </Col>
                <Col></Col>                    
                </Row>
            </Container>
            )}
            </Formik>
        );
    }

    renderConfirmNewPassword() {
        const schema = yup.object().shape({
            code: yup.string()
              .required('Required'),
            newPassword: yup.string()
              .min(8, 'Must have minimum of 8 characters')
              .required('Required'),
            confirmPassword: yup.string()
              .min(8, 'Must have minimum of 8 characters')
              .required('Required')
              .test('passwords-match', 'Passwords must match', function(value) {
                return this.parent.newPassword === value;
            }),
        });
        return (
            <Formik
                validationSchema={schema}
                onSubmit={async (values, actions) => {
                    console.log(values);
                  await this.newPasswordConfirm(values, actions);
                }}
                initialValues={{
                    newPassword: '',
                    confirmPassword: '',
                    code: ''
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  status,
                }) => (
            <Container>
                <Row>
                <Col></Col>
                <Col md={4}  style={{ border: 'solid thin', padding: '1em' }}>
                    <Form className="form-text-align" onSubmit={handleSubmit} >
                        <FormGroup>
                            <Alert variant='success'>{`Please check your email ${this.state.email} for the confirmation code`}</Alert>
                        </FormGroup>

                        <Form.Group controlId="code">
                            <Form.Control 
                                placeholder="Code"
                                name="code"
                                value={values.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.code && touched.code} 
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.code}
                            </Form.Control.Feedback> 
                        </Form.Group>
    
                        <Form.Group controlId="newPassword">
                            <Form.Control 
                                type="password" 
                                placeholder="New password"
                                name="newPassword"
                                value={values.newPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.newPassword && touched.newPassword} 
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.newPassword}
                            </Form.Control.Feedback> 
                        </Form.Group>
    
                        <Form.Group controlId="confirmPassword">
                            <Form.Control 
                                type="password" 
                                placeholder="Confirm password"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.confirmPassword && touched.confirmPassword} 
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.confirmPassword}
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        {status && status.msg && 
                        <Form.Group>
                            <Form.Label className="error-msg">{status.msg}</Form.Label>
                        </Form.Group>}
                        
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            Change password
                        </Button>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>                         */}
                    </Form>
                    </Col>
                <Col></Col>                    
                </Row>
            </Container>
            )}
            </Formik>
        );
    }

    renderSuccessMessage() {
        return (
            <div className="success">
                <Alert variant='success'>
                <p>Your password has been changed.</p>
                <p>
                <Link to="/login">
                    Click here to login with your new credentials.
                </Link>
                </p>
                </Alert>
            </div>
        );
    }

    render() {
        if(!this.state.codeRequestSent)
            return this.renderRequestCodeForm();
        else{
            if(this.state.confirmed)
                return this.renderSuccessMessage();
            else
                return this.renderConfirmNewPassword();
        }
    }
}

export default ResetPassword;