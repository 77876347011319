import React, { useState, useEffect } from 'react';
import {Modal, Form, Button} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import "moment-timezone";
import LoadingOverlay from 'react-loading-overlay';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {hasStateCodeInDivisionName, isPostcodeDivisionMatch} from './Helper';

function PostcodeDivisionCheck(props) {
    const [show, setShow] = useState(false);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        if(props.jobs && props.jobs.length > 0){
            validate();
        }else
            setShow(false);
    },[props.jobs])

    const validate = async () => {
        const validatedJobs = props.jobs.map((j,index) => {
            let matchWithPostcode = true;
            if(j.Division && j.Division.length > 0 && j.Division.split(" ").some(hasStateCodeInDivisionName) === true && j.Zip && j.Zip.length > 0)
            {
                matchWithPostcode = isPostcodeDivisionMatch(j.Division, j.Zip);
            }
            return({
                Id: index,
                LocationName: j.LocationName ? j.LocationName : '',
                ConsignmentNumber: j.ConsignmentNumber ? j.ConsignmentNumber : '',
                Street1: j.Street1 ? j.Street1 : '',
                Street2: j.Street2 ? j.Street2 : '',
                Suburb: j.Suburb ? j.Suburb : '',
                Zip: j.Zip ? j.Zip : '',
                DeliveryDate: j.DeliveryDate ? j.DeliveryDate : '',
                Division: j.Division ? j.Division : '',
                DivisionMatchWithPostcode: matchWithPostcode
            })
        });
        if(validatedJobs && validatedJobs.length > 0)
        {
            setJobs(validatedJobs);
            if(validatedJobs.filter(j => j.DivisionMatchWithPostcode === false).length > 0)
                setShow(true);
        }
    }

    return(
        <React.Fragment>
            <Modal
                size="xl"
                show={show}
                onHide={() => setShow(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Potentially set with wrong Division
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <Form.Row>
                        <span className="large-font bold-font margin-bottom-8">
                            Below are found to be potentially set with wrong Division based on the postcode address. Kindly disregard this alert if these jobs are correct.
                        </span>
                    </Form.Row>
                    <Form.Row>
                    <LoadingOverlay
                    active={false}
                    spinner
                    text='Loading...'
                    >
                    <BootstrapTable data={jobs.filter(j => j.DivisionMatchWithPostcode === false)} options={{noDataText: '' }}>
                        <TableHeaderColumn dataField='Id' isKey hidden>ItemId</TableHeaderColumn>
                        <TableHeaderColumn dataField='ConsignmentNumber' dataAlign="middle" dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Consignment #</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='LocationName'  dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Location</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Street1'  dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Address 1</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Street2'  dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Address 2</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Suburb'  dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Suburb</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Zip'  dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Postcode</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Division'  dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Division</span></TableHeaderColumn>
                    </BootstrapTable>
                    </LoadingOverlay>                      
                    </Form.Row>                              
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer>                   
            </Modal>
        </React.Fragment>
    )
}

export default PostcodeDivisionCheck