import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import { Navbar, Form, FormControl, Button, Col} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';
import {SendSMSModal} from './utility/SendSMSModal';

export default class NotificationGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            workgroups: [],
            searchKeyword:'',
            sortName: 'Name',
            sortOrder: 'asc',
            pageSize: '10',
            page: 1,
            nextToken: false, //undefined,
            prevToken: false, //undefined,
            forwardToken: undefined,
            backwardToken: undefined,
            selectedNotificationGroup: undefined
        }
        this.showSendSMSModal = React.createRef();
    }

    componentDidMount() {
        this.fetchNotificationGroups();
    }

    sendSMSModalShow( show ) {
        this.showSendSMSModal.current.setModalShow(true, this.state.selectedNotificationGroup);
    }

    async fetchNotificationGroups() {
        try{
            let filters = '';
            const {sortName, sortOrder, searchKeyword, pageSize, forwardToken, backwardToken, page} = this.state;
            filters = '?pageSize=' + pageSize + '&page=' + page;
            if(sortName !== '' && sortOrder !== '')
                filters = filters + '&Sort=' + sortName + '~' + sortOrder;
            if(searchKeyword !== '')
                filters = filters + '&Condition=SearchField~contains~' + searchKeyword.trim().toLowerCase();
            if(forwardToken)
                filters = filters + '&ForwardToken=' + forwardToken;
            if(backwardToken)
                filters = filters + '&BackwardToken=' + backwardToken;

            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            console.log('filters', filters);
            const res = await API.get("api", "/workgroups" + filters, myInit);
            console.log("workgroups", res);
            this.setState({
                workgroups: res.workgroups,
                nextToken: res.forwardPageToken,
                prevToken: res.backwardPageToken,
                isLoading: false,
                forwardToken: undefined,
                backwardToken: undefined
            })
        }catch(err) {
            this.setState(() => { throw err; })
        }
    }

    searchKeywordOnChange = (e) => {
        this.setState({ searchKeyword: e.target.value });
    }

    searchClick = () => {
        this.setState({ page: 1, isLoading: true }, function () {
            this.fetchNotificationGroups();});
    }

    handleEnterKeySearch = e => {
        if(e.charCode === 13)
        {
            e.preventDefault();
            this.searchClick();
        }            
    }

    nameFormatter(cell, row) {
        // return <a href='' onClick={this.changePage.bind(this,row.ItemId,'editClient')}><span className="med-font">{cell}</span></a>
        return <Link to={`/notificationGroups/editnotificationGroup/${row.ItemId}`} ><span className="med-font">{cell}</span></Link>
    }

    valueFormatter(cell, row) {
        return <span className="med-font">{cell}</span>;
    }

    setForwardToken = () => {
        this.setState({ page: this.state.page + 1, isLoading: true},function () {
            this.fetchNotificationGroups();});
    }

    setBackwardToken = () => {
        this.setState({ page: this.state.page - 1, isLoading: true},function () {
            this.fetchNotificationGroups();});
    }

    updatePageSize = (e) => {
        this.setState({ page: 1, pageSize: e.target.value, isLoading: true }, function () {
            this.fetchNotificationGroups();});
    }

    setNoDataText = () => {
        if(this.state.isLoading === false){
           return "No record found";
        }else{
            return 'Loading...';
        }
    }

    renderPaginationPanel = (props) => {
        return (
            <Form as={Col} xs={12} className="form-inline bottom-margin">
                <Form.Group as={Col} xs={10}>
                <Form.Label>Records per page:&nbsp;</Form.Label>
                <Form.Control as="select" onChange={this.updatePageSize} defaultValue='10' size='sm'>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>    
                    <option value='25'>25</option>                             
                    <option value='30'>30</option> 
                </Form.Control>    
                </Form.Group>
                
                <Form.Group as={Col} xs={2}>
                    {!this.state.prevToken &&
                    <Button variant="success" disabled size="sm">Prev</Button>}
                    {this.state.prevToken &&
                    <Button variant="success" size="sm" onClick={this.setBackwardToken}>Prev</Button>}
                    &nbsp;
                    {!this.state.nextToken &&
                    <Button variant="success" disabled size="sm">Next</Button>}
                    {this.state.nextToken &&
                    <Button variant="success" size="sm" onClick={this.setForwardToken}>Next</Button>}
                </Form.Group>         
                
            </Form>
        );
    }

    onSortChange = (sortName, sortOrder) => {
        console.log('sortName', sortName);
        console.log('sortOrder', sortOrder);
        this.setState({
          page: 1, 
          sortName: sortName,
          sortOrder: sortOrder,
          isLoading: true,
          forwardToken: undefined,
          backwardToken: undefined
        },function () {
            this.fetchNotificationGroups();});
    }

    onRowSelect(row, isSelected, e) {
        this.setState({selectedNotificationGroup: row});
    }
    render() {
        const selectRowProp = {
            mode: 'radio',
            columnWidth: '38px',
            onSelect: this.onRowSelect.bind(this),
            //onSelectAll: this.onSelectAll.bind(this)
        };
        return (
            <React.Fragment>
                <Navbar className="bg-light padding-top-bottom-zero">
                    <Navbar.Brand href="/">
                        <h4 className="padding-top-bottom-zero">Notification Groups</h4>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                            {/* <Navbar.Text>
                                <FormControl type="text" placeholder="Search by Name or Company" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                                <span className='clickable mr-sm-2' onClick={()=> this.searchClick()}>
                                    <i className="fas fa-search" style={{ fontSize: '1em' }} />
                                </span>
                            </Navbar.Text> */}
                            <Button as={Link} to="/notificationGroups/addNotificationGroup" className="mr-sm-2" variant="success" size="sm" >
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Notification Group
                            </Button>
                            {/* <Button as={Link} to="/clients/addDivision" className="mr-sm-2" variant="success" size="sm" disabled={!this.state.enableAddDivision} >
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Division
                            </Button>
                            <Button as={Link} to="/clients/addLocation" className="mr-sm-2" variant="success" size="sm" disabled={!this.state.enableAddLocation} >
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Delivery Location
                            </Button> */}
                            
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <Navbar className="bg-light padding-top-bottom-zero top-border-1">
                    <Button className="mr-sm-3" variant="success" size="sm" type="button" onClick={()=> this.sendSMSModalShow(true)}>
                        <i className="far fa-comment-dots" style={{ fontSize: '1em' }} /> Send SMS
                    </Button>
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                        <Navbar.Text>
                            <FormControl type="text" placeholder="Search by Name" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                            <span className='clickable' onClick={()=> this.searchClick()}>
                                <i className="fas fa-search" style={{ fontSize: '1em' }} />
                            </span>
                        </Navbar.Text>
                        </Form>
                    </Navbar.Collapse>                      
                </Navbar>
                <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text='Loading notification groups...'
                >
                {/* <BootstrapTable ref='table' data={this.state.clients} options={{onSortChange: this.onSortChange, noDataText: this.setNoDataText(), sortIndicator: false, paginationPanel: this.renderPaginationPanel }} remote={true} hover pagination> */}
                <BootstrapTable data={this.state.workgroups} options={{onSortChange: this.onSortChange, noDataText: this.setNoDataText(), sortIndicator: false, paginationPanel: this.renderPaginationPanel}} remote={true} hover pagination selectRow={ selectRowProp }>
                    <TableHeaderColumn dataField='ItemId' isKey hidden>ItemId</TableHeaderColumn>
                    <TableHeaderColumn dataField='Name' dataFormat={this.nameFormatter.bind(this)} thStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Name</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='AdminCount' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Admin Count</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='MemberCount' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Staff Count</span></TableHeaderColumn>
                    {/* <TableHeaderColumn dataField='Landline' width='115' dataFormat={this.valueFormatter} thStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Phone</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='FaxNumber' width='115' dataFormat={this.valueFormatter} thStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Fax</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='PhysicalAddressStreet' width='350' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataFormat={this.addressFormatter}><span className="med-header-font">Physical Address</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='Notes' width='75' dataAlign="middle" dataFormat={this.notesFormatter.bind(this)} thStyle={{ whiteSpace: 'normal' }}><span className="med-header-font">Notes</span></TableHeaderColumn>
                    <TableHeaderColumn dataField='DivisionCount' width='100' dataFormat={this.countFormatter.bind(this)} dataAlign="middle" thStyle={{ whiteSpace: 'normal' }} dataSort={true}><span className="med-header-font">Divisions</span></TableHeaderColumn> */}
                </BootstrapTable>
                </LoadingOverlay>
                {/* <ModalWrapper
                    show={this.state.modalNoteShow}
                    size="sm"
                    headercolor="transparent"
                    onHide={() => this.setModalNoteShow(false)}
                    modaltitle="Note"
                    type="note"
                    note={this.state.note}
                /> */}
                <SendSMSModal ref={this.showSendSMSModal}/>
            </React.Fragment>
        )                
    }
}