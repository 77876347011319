import React, { Component } from 'react';
import {Tab, Row, Col, Nav, Image} from 'react-bootstrap';
import JobsWrapper from './utility/JobsWrapper';
import { Auth } from 'aws-amplify';
import Jobs from './Jobs';
import MapContainer from './MapContainer';
import Accounts from './Accounts';
import AccountsWrapper from './utility/AccountsWrapper';
import ClientsAndDivisionsWrapper from './utility/ClientsAndDivisionsWrapper';
import NotificationGroupsWrapper from './utility/NotificationGroupsWrapper';
import ErrorBoundary from './utility/ErrorBoundary';
import {UserProvider} from './utility/UserContext';
import Invoice from './Invoice';
import KPIReport from './KPIReport';
import SMSReport from './SMSReport';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';

export default class Home extends Component {
    state = {
        jobPage: 'jobList',
        activePane: 'jobs',
        jobId: '',
        accountPage: 'accountList',
        accountId: '',
        clientPage: 'clientList',
        clientId: '',
        userGroup: '',
        mapJobId: undefined
    }
    navigate = path => {
        console.log("path", path);
        this.props.history.push(path);
    }
    changeJobPage = (page,id) => {
        if(page === 'editJob')
            this.setState({ jobPage: page, jobId: id });
        else
            this.setState({ jobPage: page});
    }
    changeToJobPaneFromMap = (page,id) => {
        this.setState({ activePane: 'jobs',jobPage: 'jobList' }, function () {this.setState({jobPage: page, jobId: id});});
    }
    changeAccountPage = (page,id) => {
        if(page === 'editAccount')
            this.setState({ accountPage: page, accountId: id });
        else
            this.setState({ accountPage: page});
    }
    changeClientPage = (page,id) => {
        if(page === 'clientList')
        {
            this.setState({ clientPage: page });
            //this.props.history.push("/home/clients");
        }
        else
        {
            this.setState({ clientPage: page, clientId: id});
            //this.props.history.push("/home/clients/" + page);
            //this.props.history.push("/home/accounts");
        }
    }
    changeMapPage = (pane,id) => {
        this.setState({ activePane: pane, mapJobId: id });
    }
    changePane = pane => {
        console.log("pane", pane);
        this.setState({ activePane: pane });
    }
    onClickJob = () => {
        this.setState({ jobPage: 'jobList', activePane: 'jobs' });
    }
    onClickAccount = () => {
        this.setState({ accountPage: 'accountList', activePane: 'accounts' });
    }
    onClickMap = () => {
        this.setState({ activePane: 'map', mapJobId: undefined });
    }
    onClickInvoice = () => {
        this.setState({ activePane: 'invoice' });
    }
    onClickNotificationGroup = () => {
        this.setState({ activePane: 'notificationGroup' });
    }
    onClickClient = () => {
        this.setState({ clientPage: 'clientList', activePane: 'clients' });
    }
    async componentDidMount() {
        const session = await Auth.currentSession();
        this.setState({ userGroup: session.idToken.payload['cognito:groups'] !== undefined ? session.idToken.payload['cognito:groups'][0] : '' });
        console.log("group", session.idToken.payload['cognito:groups'] !== undefined ? session.idToken.payload['cognito:groups'][0] : '');
    }
    isActive(path, match, location) {
        // console.log('location',location);
        // console.log('match',match);
        // console.log('path',path);
        if(location.pathname.startsWith(path))
            return true;
        else
            return false;
    }
    render() {
        if(!this.props.auth.isAuthenticated)
            this.props.history.push("/login");
        const navProp = {
            nav: this.navigate,
            changeJobPage: this.changeJobPage,
            changePane: this.changePane,
            changeAccountPage: this.changeAccountPage,
            changeClientPage: this.changeClientPage,
            changeMapPage: this.changeMapPage,
            changeToJobPaneFromMap: this.changeToJobPaneFromMap
        }
        console.log("home");
        return (
            <UserProvider value={this.state.userGroup}>
            <Router basename='/home' >
            {/* {this.state.redirect &&  <Redirect to={{pathname: this.state.redirect}} />} */}
            <Tab.Container id="menu" activeKey={this.state.activePane}>
            <Row className="margin-left-right-zero">
                <Col lg={1} className="padding-right-zero">
                <Nav variant="pills" className="flex-column" >
                    <Nav.Item>                        
                        <Nav.Link as={NavLink} to="/jobs" isActive={this.isActive.bind(this,'/jobs')}>                           
                            <svg className="icon-van"><use href="#icon-van"></use></svg>
                            <h6>Jobs</h6>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/map" isActive={this.isActive.bind(this,'/map')}>
                            <i className="fas fa-map" style={{ fontSize: '3em' }} />
                            <br/>
                            <h6>Map</h6>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/invoice" isActive={this.isActive.bind(this,'/invoice')} disabled={this.state.userGroup.toLowerCase() === 'driver'}>
                            <i className="fas fa-file-invoice-dollar" style={{ fontSize: '3em' }} />
                            <br/>
                            <h6>Invoices</h6>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/clients" isActive={this.isActive.bind(this,'/clients')} disabled={this.state.userGroup.toLowerCase() === 'driver' || this.state.userGroup.toLowerCase() === 'officeuser' || this.state.userGroup.toLowerCase() === 'officeadministrator'}>
                            <i className="fas fa-address-card" style={{ fontSize: '3em' }} />
                            <br/>
                            <h6>Clients <br/> and Divisions</h6>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/accounts" isActive={this.isActive.bind(this,'/accounts')} disabled={this.state.userGroup.toLowerCase() === 'driver' || this.state.userGroup.toLowerCase() === 'officeuser' || this.state.userGroup.toLowerCase() === 'officeadministrator'}>
                            <i className="fas fa-users" style={{ fontSize: '3em' }} />
                            <br/>
                            <h6>Staff Accounts</h6>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/notificationGroups" isActive={this.isActive.bind(this,'/notificationGroups')} disabled={this.state.userGroup.toLowerCase() === 'driver' || this.state.userGroup.toLowerCase() === 'officeuser' || this.state.userGroup.toLowerCase() === 'officeadministrator'}>
                            <i className="fas fa-comments" style={{ fontSize: '3em' }} />
                            <br/>
                            <h6>Notification <br/> Groups</h6>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/kpireport" isActive={this.isActive.bind(this,'/kpireport')} disabled={this.state.userGroup.toLowerCase() === 'driver' || this.state.userGroup.toLowerCase() === 'officeuser'}>
                            <i className="fas fa-chart-bar" style={{ fontSize: '3em' }} />
                            <br/>
                            <h6>KPI Report</h6>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/smsreport" isActive={this.isActive.bind(this,'/smsreport')} disabled={this.state.userGroup.toLowerCase() === 'driver' || this.state.userGroup.toLowerCase() === 'officeuser'}>
                            <i className="fas fa-comment-alt"  style={{ fontSize: '3em' }} />
                            <br/>
                            <h6>SMS Report</h6>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                </Col>
                <Col className="padding-left-zero">
                <Tab.Content>
                    <Switch>
                        <Route
                        path={["/jobs","/jobs/editJob/:jobId","/jobs/copyJob/:jobId"]}
                        exact
                        render={(props) => <JobsWrapper {...props} auth={this.props.auth} navProp={navProp} page={this.state.jobPage} id={this.state.jobId}/>}
                        />
                        <Route
                        path="/jobs/:anotherpage"
                        exact
                        render={(props) => <JobsWrapper {...props} auth={this.props.auth} navProp={navProp} page={this.state.jobPage} id={this.state.jobId}/>}
                        />
                        <Route
                        path="/clients"
                        exact
                        render={(props) => <ClientsAndDivisionsWrapper {...props} navProp={navProp} page={this.state.clientPage} id={this.state.clientId}/>}
                        />
                        <Route
                        path="/clients/:subpage"
                        exact
                        render={(props) => <ClientsAndDivisionsWrapper {...props} navProp={navProp} page={this.state.clientPage} id={this.state.clientId}/>}
                        />
                        <Route
                        path="/clients/:anotherpage/:id"
                        exact
                        render={(props) => <ClientsAndDivisionsWrapper {...props} navProp={navProp} page={this.state.clientPage} id={this.state.clientId}/>}
                        />
                        <Route
                        path={["/accounts","/accounts/:subpage","/accounts/:subpage/:id"]}
                        exact
                        render={(props) => <AccountsWrapper {...props} auth={this.props.auth} navProp={navProp} page={this.state.accountPage} id={this.state.accountId}/>}
                        />
                        <Route
                        path={["/map","/map/:jobId"]}
                        exact
                        render={(props) => 
                        <ErrorBoundary>
                            <MapContainer {...props} auth={this.props.auth} userType={this.state.userGroup} navProp={navProp} page={this.state.activePane} jobId={this.state.mapJobId}/>
                        </ErrorBoundary>}
                        />
                        <Route
                        path="/invoice"
                        exact
                        render={(props) => 
                        <ErrorBoundary>
                            <Invoice {...props} userType={this.state.userGroup} auth={this.props.auth} />
                        </ErrorBoundary>}
                        />                        
                        <Route
                        path={["/notificationGroups","/notificationGroups/:subpage","/notificationGroups/:subpage/:id"]}
                        exact
                        render={(props) => <NotificationGroupsWrapper {...props} />}
                        />
                        <Route
                        path="/kpireport"
                        exact
                        render={(props) => 
                        <ErrorBoundary>
                            <KPIReport {...props} userType={this.state.userGroup} auth={this.props.auth} />
                        </ErrorBoundary>}
                        />
                         <Route
                        path="/smsreport"
                        exact
                        render={(props) => 
                        <ErrorBoundary>
                            <SMSReport {...props} userType={this.state.userGroup} auth={this.props.auth} />
                        </ErrorBoundary>}
                        />
                        <Route render={() => <Redirect to={{pathname: "/jobs"}} />} />                        
                    </Switch>
                </Tab.Content>
                </Col>
            </Row>
            </Tab.Container>
            </Router>
            </UserProvider>
        );
    }
}
