import React, { Component } from 'react';
import {Table, Spinner, Navbar, Form, FormGroup, FormControl, Button, Popover, Modal} from 'react-bootstrap';
import Accounts from '../Accounts';
import AddAccount from '../AddAccount';
import EditAccount from '../EditAccount';
import Postcodes from '../Postcodes';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

export default class AccountsWrapper extends Component {

    render() {
        return (
            <Switch>
                <Route
                path="/accounts"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <Accounts {...props} navProp={this.props.navProp}/>
                    </ErrorBoundary>}
                />
                <Route
                path="/accounts/editAccount/:accountId"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <EditAccount {...props} auth={this.props.auth} navProp={this.props.navProp}/>
                    </ErrorBoundary>
                                    }
                />
                <Route
                path="/accounts/addAccount"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <AddAccount {...props} auth={this.props.auth} navProp={this.props.navProp}/>
                    </ErrorBoundary>
                                    }
                />
                <Route
                path="/accounts/postcodes"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <Postcodes {...props} />
                    </ErrorBoundary>
                                    }
                />
                <Route render={() => <Redirect to={{pathname: "/accounts"}} />} /> 
            </Switch>
        );
  }
}

