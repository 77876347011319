import React, { Component } from 'react';
import {Table, Spinner, Navbar, Form, FormGroup, FormControl, Button, Popover, Modal} from 'react-bootstrap';
import Jobs from '../Jobs';
import AddJob from '../AddJob';
import EditJob from '../EditJob';
import Import from '../Import';
import ErrorBoundary from './ErrorBoundary';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';

export default class JobsWrapper extends Component {

    render() {
        return (
            <Switch>
                <Route
                path="/jobs"
                exact
                render={(props) =>
                    <ErrorBoundary>
                        <Jobs {...props} auth={this.props.auth} navProp={this.props.navProp}/>
                    </ErrorBoundary> 
                                    }
                />
                <Route
                path="/jobs/editJob/:jobId"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <EditJob {...props} auth={this.props.auth} navProp={this.props.navProp}/>
                    </ErrorBoundary>
                                    }
                />
                <Route
                path={["/jobs/addJob","/jobs/copyJob/:jobId"]}
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <AddJob {...props} navProp={this.props.navProp} />
                    </ErrorBoundary>
                                    }
                />
                <Route
                path="/jobs/importJob"
                exact
                render={(props) => 
                    <ErrorBoundary>
                        <Import {...props} />
                    </ErrorBoundary>
                                    }
                />
                <Route render={() => <Redirect to={{pathname: "/jobs"}} />} /> 
            </Switch>
        );
  }
}

