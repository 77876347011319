import React, { Component } from 'react';
import {Container, Row, Col, ListGroup, Form, Button} from 'react-bootstrap';
import { Auth, API } from "aws-amplify";
import { Formik } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';

export default class Reschedule extends Component {
    jobsList () {
        const jobLists = this.props.jobs.map((job) =>
        <ListGroup.Item key={job.ItemId} className="med-font no-left-right-padding top-bottom-padding-2">{job.ConsignmentNumber + ' - ' + job.Name}</ListGroup.Item>
        );
        return (
            <ListGroup variant="flush">{jobLists}</ListGroup>
        );
    }

    reschedule = async (values, actions) => {
        try{
            const patchRecords = this.props.jobs.map((job) => ({ItemId: job.ItemId, HasDeliveryTime: false, DeliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD') + ' 00:00:00'}));
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    jobs: patchRecords
                    }
            };            
            await API.patch("api", "/jobs", myInit);
            this.props.refresh(true);
        }catch(error)
        {
            console.log('error', error.message);
        }
    }

    render() {
        return(
        <Formik
        onSubmit={async (values, actions) => {
            await this.reschedule(values, actions);
        }}
        initialValues={{
            deliveryDate: new Date()
        }}
        >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              setFieldValue
            }) => (
            <Container>
                <Form onSubmit={handleSubmit}>
                    {this.props.jobs.length > 0 &&
                    <React.Fragment>
                        <Row className="justify-content-md-center bold-font">
                            <Col xs lg="11">
                                <Form.Label>You have selected following {this.props.jobs.length === 1 ? ' 1 job' : this.props.jobs.length + ' jobs'} to reschedule.</Form.Label>
                            </Col>                
                        </Row>
                        <Row className="justify-content-md-center top-margin-20">
                            <Col xs lg="11">
                                {this.jobsList()}
                            </Col>                
                        </Row>                        
                        <Row className="justify-content-md-center top-margin-20">
                            <Form.Group as={Col} xs={11} controlId="deliveryDate">
                                <Form.Label className="bold-font">Select Delivery Date</Form.Label><br/>
                                <DatePicker dateFormat="dd/MM/yyyy" selected={Date.parse(values.deliveryDate)} onChange={value => setFieldValue('deliveryDate', value)} className='form-control form-control-sm' placeholderText='Select delivery date'/>
                            </Form.Group>
                        </Row>                    
                        <Form.Row className="justify-content-md-right modal-footer no-border top-margin-20"> 
                            <Button className="mr-sm-1" variant="primary" type="submit" disabled={isSubmitting} size="sm">
                                {isSubmitting ? 'Processing…' : 'Save'}
                            </Button>
                            <Button variant="primary" size="sm" onClick={this.props.onHide}>Cancel</Button>                    
                        </Form.Row>
                    </React.Fragment>}
                    {this.props.jobs.length === 0 &&
                    <Row className="justify-content-md-center">
                        <Col xs lg="11">
                            <Form.Label>Please select the jobs to be rescheduled.</Form.Label>
                        </Col>                
                    </Row>
                    }
                </Form>
            </Container>
            )}
        </Formik>
        );        
    }
}