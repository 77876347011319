import React, { useState, useEffect } from 'react';
import { Auth, API } from "aws-amplify";
import { Link, Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import {CardColumns, Card, Form, Col, Button, Modal, Alert} from 'react-bootstrap';
import {authorizationHeader} from './utility/Helper';
import { Formik } from 'formik';
import * as yup from 'yup';

function Operators(props) {
    const [show, setShow] = useState(false);
    const [selectedOperator, setSelectedOperator] = useState('');
    const [error, setError] = useState('');
    const [operators, setOperators] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [newImageUrl, setNewImageUrl] = useState(undefined);
    const [addMode, setAddMode] = useState(false);

    const onChangeHandler = event => {
        setSelectedFile(event.target.files[0]);
    }

    useEffect(() => {
        if(selectedFile)
        {
            const getURL = async() => {
                setNewImageUrl(await readUploadedFileAsDataURL(selectedFile));
            }
            getURL();
        }else
            setNewImageUrl(undefined);
    },[selectedFile])

    const readUploadedFileAsDataURL = (inputFile) => {
        const temporaryFileReader = new FileReader();
      
        return new Promise((resolve, reject) => {
          temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
          };
      
          temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
          };
          temporaryFileReader.readAsDataURL(inputFile);
        });
    };

    useEffect(() => {
        console.log('useEffectOperators');
        if(props.auth.user && props.auth.user.signInUserSession.idToken.payload['cognito:groups'] !== undefined && props.auth.user.signInUserSession.idToken.payload['cognito:groups'][0].toLowerCase() !== 'globaladministrator')
            props.history.push("/");
        props.auth.setIsOnOperatorsPage(true);
        fetchOperators();
        return () => { 
            props.auth.setIsOnOperatorsPage(false);
        }
    },[])

    const fetchOperators = async() => {
        try{
            const requestHeader = await authorizationHeader();
            const res = await API.get("api", "/operators", requestHeader);
            if(res.result === 'success')
            {
                const images = res.operators.map( async (o, index) => {
                    try{
                        const presignedRequest = await API.get('api', '/uploads?type=download&File=Logo.jpg&folder=' + o.ItemId, requestHeader);
                        const base64String = await axios.get(presignedRequest.url, { responseType: 'arraybuffer'}).then(response => new Buffer(response.data, 'binary').toString('base64'));
                        return (
                            {
                                id: o.ItemId,
                                url: 'data:image/jpeg;base64,' + base64String
                            }
                        )
                    }catch(err) {
                        console.log('err', err);
                        if(err.toString().toLowerCase().indexOf('network error'))
                            setError('Network error. Please check internet connection then refresh the page.');
                        else
                            setError('Unexpected error encountered.');
                    }
                });
                const promisedImages = await Promise.all(images);
                console.log('promisedImages',promisedImages);
                const derivedOperators = res.operators.map(o => {
                    let temp = Object.assign({}, o);
                    temp['Url'] = promisedImages.filter(p => p.id === o.ItemId)[0].url
                    return temp;
                });
                setOperators(derivedOperators);
            }else
                setError('Unexpected error encountered.');                           
        }catch(error) {
            if(error.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }
    }

    const displayOperators = () => {
        if(operators && operators.length > 0)
        {
            return operators.map((operator) => {
                const { Name, ItemId, Url } = operator
                return (
                    <LoadingOverlay
                    key={ItemId} 
                    active={selectedOperator === ItemId && !show}
                    spinner
                    >
                    <Card className='clickable' onClick={() => setSelectedOperator(ItemId)}>
                        <Card.Img variant="top" width='100%' height='200px' src={Url} />
                        <Card.ImgOverlay className='padding-top-zero padding-right-zero'>
                            <Card.Title className='right-align'>
                                <Button variant="success" className='btn-cust-sm' onClick={() => {setShow(true)}} >                            
                                    <i className="far fa-edit" style={{ fontSize: '1.5em'}} /> EDIT
                                </Button>
                            </Card.Title>
                        </Card.ImgOverlay>
                        <Card.Body>
                        <Card.Title className='margin-bottom-zero bold-font'>{Name}</Card.Title>
                        </Card.Body>
                    </Card>
                    </LoadingOverlay>
                )
            })
        }
    }

    useEffect(() => {
        console.log('useEffectOperators2');
        if(!props.auth.isAuthenticated)
            props.history.push("/login");
    },[props.auth.isAuthenticated])

    useEffect(() => {
        if(!show)
        {
            console.log('selectedOperator', selectedOperator);
            if(selectedOperator.length > 0)
                updateUsersOperator(selectedOperator);
        }
    },[selectedOperator])

    const updateUsersOperator = async(selectedOperator) =>{
        const previousOperator = props.auth.operator;
        props.auth.setOperator(selectedOperator);
        //console.log('props.auth.user', props.auth.user);
        let res = await API.get("api", "/accounts/" + props.auth.user.attributes.sub, await authorizationHeader());
        if(res.result === 'success' && res.accounts.length > 0)
        {
            let account = res.accounts[0];
            account['OperatorId'] = selectedOperator;
            let myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    accounts: [account]
                }
            };
            res = await API.post("api", "/accounts", myInit);
            if(res.result === 'success')
            {
                props.history.push("/home");
            }else
                props.auth.setOperator(previousOperator);
        }else
            props.auth.setOperator(previousOperator);
    }

    useEffect(() => {
        console.log('show');
        if(!show)
        {
            setAddMode(false);
            setNewImageUrl(undefined);
            setSelectedFile(undefined);
            setSelectedOperator('');
        }
    },[show])

    const addEditOperator = async (values, actions) => {
        try{
            let isOk = true;
            actions.setStatus({msg: undefined});
            if((values.replaceLogo || addMode) && !selectedFile)
            {
                actions.setStatus({msg: 'No file selected'});
                actions.setSubmitting(false);
                isOk = false;
            }
            if((values.replaceLogo || addMode) && selectedFile && selectedFile.size > 1000000)
            {
                actions.setStatus({msg: 'File is too big. Only allowed a maximum of 1MB'});
                actions.setSubmitting(false);
                isOk = false;
            }
            if(isOk){
                if(values.replaceLogo)
                {
                    const res = await API.get("api", "/uploads?File=Logo.jpg&Folder=" + selectedOperator, await authorizationHeader());
                    await axios({ method: 'put', url: res.url, data: selectedFile});
                }
                let myInit = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        operators: [
                            {
                                ItemId: addMode ? undefined : selectedOperator,
                                Name: values.name,
                                Address: values.address,
                                ABN: values.abn,
                                ACN: values.acn,
                                BSB: values.bsb,
                                AccountNumber: values.accountNumber,
                                InvoiceNote: values.note,
                                EmailSender: values.emailSender.toLowerCase(),
                                SendVerificationEmail: (addMode || values.resendVerificationEmail || (!addMode && values.emailSender.toLowerCase().trim() !== operators.filter(o => o.ItemId === selectedOperator)[0].EmailSender.toLowerCase().trim())) ? 'true' : 'false'
                            }
                        ]
                    }
                };
                const result = await API.post("api", "/operators", myInit);
                if(result.result === 'success')
                {
                    if(values.replaceLogo)
                    {
                        let isUploaded = false;
                        for (let i = 0; i < 4; i++){
                            if(isUploaded)
                                break;

                            const fileResponse = await API.get('api', '/uploads?type=download&File=Logo.jpg&Folder=' + selectedOperator, await authorizationHeader());
                            console.log('fileResponse',fileResponse);
                            if(fileResponse['result'] === 'success')
                            {
                                await axios({ method: 'get', url: fileResponse['url']})
                                    .then(response => {
                                        //console.log(response);
                                        isUploaded = true; 
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })            
                            }
                            await sleep(2000);
                        }
                    }
                    if(addMode)
                    {
                        const res = await API.get("api", "/uploads?File=Logo.jpg&Folder=" + result.operators[0].ItemId, await authorizationHeader());
                        await axios({ method: 'put', url: res.url, data: selectedFile});
                    }
                    if(selectedOperator === props.auth.operator)
                    {
                        //needed so Navbar will update
                        props.auth.setOperator('');
                        props.auth.setOperator(selectedOperator);
                    }
                    await fetchOperators();
                    actions.setSubmitting(false);
                    setShow(false);
                }              
                else{
                    actions.setStatus({msg: result.errors[0]});
                    actions.setSubmitting(false);
                }
            }
        }catch(error)
        {
            // console.log('error', error.message);
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const showAddForm = () => {
        setAddMode(true);
        setShow(true);
    }

    const schema = yup.object().shape({
        name: yup.string()
            .required('Required'),
        address: yup.string()
            .required('Required'),
        abn: yup.string()
            .required('Required'),
        acn: yup.string()
            .required('Required'),
        bsb: yup.string()
            .required('Required'),
        accountNumber: yup.string()
            .required('Required'),
        emailSender: yup.string()
            .email('Must be a valid email')
            .required('Required')
    });

    if(error.length > 0)
    {
        return(
            <Form.Row>
                <Form.Group as={Col} xs={12}>
                    <Alert variant='danger'>
                        {error}
                    </Alert>
                </Form.Group>                                    
            </Form.Row>
        )
    }else{
        return(
            <Form.Row className='margin-top-20'>
            <Form.Group as={Col} xs={2}></Form.Group>
            <Form.Group as={Col} >
                <LoadingOverlay
                    active={!operators}
                    spinner
                    //text='Loading notification groups...'
                    >
                    <CardColumns>
                        {displayOperators()}
                        <Card className='clickable' onClick={() => showAddForm()} >
                            <i className="fas fa-user-plus" style={{ width:'100%', height:'200px', color:'#007bff'}} />
                            <Card.Body>
                            <Card.Title className='margin-bottom-zero'>Add New Operator</Card.Title>
                            </Card.Body>
                        </Card>
                    </CardColumns>
                </LoadingOverlay>
            </Form.Group>
            <Form.Group as={Col} xs={2}></Form.Group>
                {(selectedOperator.length > 0 || addMode) &&
                <Modal
                    size="md"
                    show={show}
                    onHide={() => setShow(false)}
                    backdrop= "static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        {selectedOperator.length > 0 ? 'Edit Operator' : 'Add Operator'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body> 
                    <Formik
                    validationSchema={schema}
                    onSubmit={async (values, actions) => {
                        await addEditOperator(values, actions);
                    }}
                    
                    initialValues={{
                        name: selectedOperator.length > 0 ? operators.filter(o => o.ItemId === selectedOperator)[0].Name : '',
                        emailSender: selectedOperator.length > 0 ? operators.filter(o => o.ItemId === selectedOperator)[0].EmailSender : '',
                        address: selectedOperator.length > 0 ? operators.filter(o => o.ItemId === selectedOperator)[0].Address : '',
                        abn: selectedOperator.length > 0 ? operators.filter(o => o.ItemId === selectedOperator)[0].ABN : '',
                        acn: selectedOperator.length > 0 ? operators.filter(o => o.ItemId === selectedOperator)[0].ACN : '',
                        bsb: selectedOperator.length > 0 ? operators.filter(o => o.ItemId === selectedOperator)[0].BSB : '',
                        note: selectedOperator.length > 0 ? operators.filter(o => o.ItemId === selectedOperator)[0].InvoiceNote : '',
                        accountNumber: selectedOperator.length > 0 ? operators.filter(o => o.ItemId === selectedOperator)[0].AccountNumber : '',
                        replaceLogo: false,
                        resendVerificationEmail: false,
                        verified: (selectedOperator.length > 0 && operators.filter(o => o.ItemId === selectedOperator)[0].IsEmailVerified === 'true') ? true : false,
                    }}
                    >
                            {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            status,
                            setFieldValue
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">Operator Name</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="name">
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={values.name}
                                            name="name"
                                            placeholder="Operator Name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.name}
                                            touched={touched.name}
                                            isInvalid={errors.name && touched.name}
                                        >
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row> 
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">Address</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="address">
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={values.address}
                                            name="address"
                                            placeholder="Address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.address}
                                            touched={touched.address}
                                            isInvalid={errors.address && touched.address}
                                        >
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.address}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row> 
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">ABN</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="abn">
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={values.abn}
                                            name="abn"
                                            placeholder="ABN"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.abn}
                                            touched={touched.abn}
                                            isInvalid={errors.abn && touched.abn}
                                        >
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.abn}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row> 
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">ACN</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="acn">
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={values.acn}
                                            name="acn"
                                            placeholder="ACN"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.acn}
                                            touched={touched.acn}
                                            isInvalid={errors.acn && touched.acn}
                                        >
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.acn}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">BSB</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="bsb">
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={values.bsb}
                                            name="bsb"
                                            placeholder="BSB"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.bsb}
                                            touched={touched.bsb}
                                            isInvalid={errors.bsb && touched.bsb}
                                        >
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.bsb}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">Account Number</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="accountNumber">
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={values.accountNumber}
                                            name="accountNumber"
                                            placeholder="Account Number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.accountNumber}
                                            touched={touched.accountNumber}
                                            isInvalid={errors.accountNumber && touched.accountNumber}
                                        >
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.accountNumber}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">Invoice Note</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="note">
                                        <Form.Control
                                            size="sm"
                                            as="textarea"
                                            rows="2"
                                            value={values.note}
                                            name="note"
                                            placeholder="Invoice Note"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.note}
                                            touched={touched.note}
                                            isInvalid={errors.note && touched.note}
                                        >
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.note}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">Email Address</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="emailSender">
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={values.emailSender}
                                            name="emailSender"
                                            placeholder="Email Address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.emailSender}
                                            touched={touched.emailSender}
                                            isInvalid={errors.emailSender && touched.emailSender}
                                        >
                                        </Form.Control>
                                        {values.verified && values.emailSender.toLowerCase().trim() === operators.filter(o => o.ItemId === selectedOperator)[0].EmailSender.toLowerCase().trim() &&
                                        <span className='small-font success bold-font'>Verified</span>
                                        }
                                        {!addMode && !values.verified && values.emailSender.toLowerCase().trim() === operators.filter(o => o.ItemId === selectedOperator)[0].EmailSender.toLowerCase().trim() &&
                                        <Form.Check
                                        defaultChecked={values.resendVerificationEmail}
                                        className="small-font"
                                        id="resendVerificationEmail"
                                        label="Resend Verification Email"
                                        name="resendVerificationEmail"
                                        value={values.resendVerificationEmail}
                                        onChange={handleChange}
                                        />
                                        }
                                        <Form.Control.Feedback type="invalid">
                                            {errors.emailSender}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>  
                                <Form.Row>
                                    <Form.Group as={Col} xs={4} className="margin-bottom-8">
                                        <Form.Label className="med-font margin-bottom-zero">Logo</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={8} controlId="logo">
                                        {((values.replaceLogo && newImageUrl) || (!values.replaceLogo && !addMode) || (newImageUrl && addMode)) &&
                                        <img className="mr-sm-1" height='150px' width='250px' src={(values.replaceLogo || (!values.replaceLogo && addMode && newImageUrl)) ? newImageUrl : operators.filter(o => o.ItemId === selectedOperator)[0].Url}></img>
                                        }
                                        {!addMode &&
                                        <Form.Check 
                                        className="med-font margin-bottom-zero"
                                        defaultChecked={false}
                                        type="switch"
                                        id="replaceLogo"
                                        label="Replace Logo"
                                        value={values.replaceLogo}
                                        onChange={handleChange}
                                        />
                                        }
                                        <input type="file" disabled={isSubmitting} accept="image/*" className={(values.replaceLogo || addMode) ? 'med-font' : 'hide'} name="file" onChange={onChangeHandler}/>
                                    </Form.Group>
                                </Form.Row> 
                                <Form.Row>
                                    <Form.Group as={Col} xs={12} className="margin-top-10">
                                        <Button disabled={isSubmitting} className="mr-sm-2" variant="success" size="sm" type='submit'>
                                            {isSubmitting ? 'Saving...' : 'Save'}
                                        </Button>
                                        <Button disabled={isSubmitting} className="mr-sm-2" variant="success" size="sm" onClick={() => setShow(false)}>
                                            Cancel
                                        </Button>
                                    </Form.Group>
                                </Form.Row>
                                {status && status.msg &&
                                <Form.Row>
                                    <Form.Group>
                                        <Alert variant='danger'>
                                            {status.msg}
                                        </Alert>
                                    </Form.Group>                                    
                                </Form.Row>
                                }
                            </Form>
                        )}
                        </Formik>          
                    </Modal.Body>                    
                </Modal>
                }
            </Form.Row>        
        )
    }
}

export default Operators