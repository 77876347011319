import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import {Form, Button, Container, Col, FormGroup, Card, Spinner, InputGroup} from 'react-bootstrap';
import ModalWrapper from './utility/ModalWrapper';
import Autosuggest from 'react-autosuggest';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import * as yup from 'yup';
import Map from './utility/Map';
import { Link, Redirect } from 'react-router-dom';
import CreateNewLocationModal from './utility/CreateNewLocationModal';
import {UserConsumer} from './utility/UserContext';
import {authorizationHeader,isPODStatus} from './utility/Helper';
import toast, { Toaster } from 'react-hot-toast';

export default class AddJob extends Component {
    state = {
        show: false,
        client: '',
        location: '',
        pickuplocation: '',
        latitude: '',
        longitude: '',
        pickuplat: '',
        pickuplong: '',
        locationItem: null, 
        pickuplocationItem: null,        
        address: '', 
        pickupaddress: '',       
        status: [],
        accounts: [],
        locations: [],    
        clients: [],
        divisions: [],
        suggestions: [],
        pickupsuggestions: [],        
        notificationGroups: [],
        notificationGroupId: '',
        startDate: moment(),
        job: undefined,
        jobId: undefined,
        redirect: null,
        
    };
    // pickupconsignmentItem: null,
    // pickupconsignmentnumber: '',
    // pickupadress: '',
    // pickupconsignments: [],
    // pickupsuggestions: [],
    //static contextType = UserContext;

    setCoordinates = (latitude, longitude) => {
        console.log('latlong', latitude);
        this.setState({latitude: latitude, longitude: longitude});
    }

    setPickupCoordinates = (latitude, longitude) => {
        console.log('latlong', latitude);
        this.setState({pickuplat: latitude, pickuplong: longitude});
    }

    setClient = client => {
        this.setState({client: client});
    }

    setLocation = location => {
        this.setState({location: location});
    }

    setLocationItem = selected => {
        console.log('selected', selected);
        this.setState({locationItem: selected});
    }

    renderSuggestions = suggestion => {
        let address = '';
        if(suggestion.street1 != null && suggestion.street1 !== '')
            address = suggestion.street1;
        if(suggestion.street2 != null && suggestion.street2 !== '' && (suggestion.street1 != null && suggestion.street1.trim().toLowerCase() !== suggestion.street2.trim().toLowerCase()))
            address = (address + ' ' + suggestion.street2).trim();
        if(suggestion.suburb != null && suggestion.suburb !== '')
            address = (address + ' ' + suggestion.suburb).trim();
        if(suggestion.zip != null && suggestion.zip !== '')
            address = (address + ', ' + suggestion.zip).trim();
        if(address.startsWith(','))
            address = address.substring(1).trim();
        return (
            <div><div className='small-font'><span className='bold-font'>Location Name: </span>{suggestion.name}</div><div className='small-font'><span className='bold-font'>Address: </span>{address}</div><div className='small-font'><span className='bold-font'>Division: </span>{suggestion.division}</div></div>
            )
    }

    setSuggestions = suggestions => {
        this.setState({suggestions: suggestions});
    }

    setPickupLocation = pickuplocation => {
        this.setState({pickuplocation: pickuplocation});
    }

    setPickupLocationItem = selected => {
        console.log('selected', selected);
        this.setState({pickuplocationItem: selected});
    }

    setPickupSuggestions = suggestions => {
        this.setState({pickupsuggestions: suggestions});
    }

    // setPickupConsignment = pickupconsignmentnumber => {
    //     this.setState({pickupconsignmentnumber: pickupconsignmentnumber});
    // }

    // setPickupConsignmentItem = selected => {
    //     console.log('selected', selected);
    //     this.setState({pickupconsignmentnumber: selected});
    // }

    // renderPickupSuggestions = suggestion => {
    //     let address = '';
    //     if(suggestion.street1 && suggestion.street1 !== '')
    //         address = suggestion.street1;
    //     if(suggestion.street2 && suggestion.street2 !== '' && (suggestion.street1 && suggestion.street1.trim().toLowerCase() !== suggestion.street2.trim().toLowerCase()))
    //         address = (address + ' ' + suggestion.street2).trim();
    //     if(suggestion.suburb && suggestion.suburb !== '')
    //         address = (address + ' ' + suggestion.suburb).trim();
    //     if(suggestion.zip && suggestion.zip !== '')
    //         address = (address + ', ' + suggestion.zip).trim();
    //     if(address.startsWith(','))
    //         address = address.substring(1).trim();
    //     return (
    //         <div><div className='small-font'><span className='bold-font'>Consignment Number: </span>{suggestion.consignmentNumber}</div><div className='small-font'><span className='bold-font'>Location Name: </span>{suggestion.name}</div><div className='small-font'><span className='bold-font'>Address: </span>{address}</div></div>
    //         )
    // }

    // setPickupSuggestions = pickupsuggestions => {
    //     this.setState({pickupsuggestions: pickupsuggestions});
    // }

    setShow = show => {
        this.setState({ show: show });
        if(show === false)
          this.props.refresh(true);
    }

    setNotificationGroup = group => {
        this.setState({ notificationGroupId: group });
    }
    
    createJobInDatabase = async (values, actions) => {
        try{
            let client = this.state.accounts.filter(account =>
                account.Name != null && account.AccountType.toLowerCase() === 'client' && account.Name.trim().toLowerCase() === values.location.trim().toLowerCase());
            // const client = this.state.accounts.filter(account =>
            //     account.ItemId === values.client);
            // const staff = this.state.clients.filter(account =>
            //     account.AccountType.toLowerCase() === 'staff' && account.FullName.trim().toLowerCase() === values.staff.trim().toLowerCase());
            let staff = null;
            let unallocatedStatus = '';
            if(values.staff === '')
            {
                const filteredDrivers = this.state.accounts.filter(user => user.AccountType.toLowerCase() === 'driver').filter(d => (',' + (d.TerritoryPostcodes ? d.TerritoryPostcodes : '').replace(/\s+/g, '') + ',').indexOf(',' + this.state.locationItem.zip.replace(/\s+/g, '') + ',') > -1 );
                if(filteredDrivers != null && filteredDrivers.length > 0)
                {
                    const driverIndex = Math.round(Math.random() * (filteredDrivers.length - 1));
                    console.log('driverIndex',driverIndex);
                    staff = this.state.accounts.filter(account => account.ItemId === filteredDrivers[driverIndex].ItemId);
                }
                else
                {
                    unallocatedStatus = 'Status-d1f2a16d-aa40-4c65-9428-192c1aac9078';
                }
            }
            else
            {
                staff = this.state.accounts.filter(account =>
                    account.ItemId === values.staff);
            }
            console.log('staff', staff);
            console.log('status', values.status);
            let status;
            if(values.status !== '')
                status = this.state.status.filter(status => status.ItemId === values.status);
            else
                status = this.state.status.filter(status => status.SortOrder === 1);
            // let clientId = client.length > 0 ? client[0].ItemId : '';
            // let staffId = staff.length > 0 ? staff[0].ItemId : '';

            let recurringDays = '';
            ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'].map(d => {
                if(values[d])
                {
                    if(recurringDays.length > 0)
                        recurringDays = recurringDays + ',' + d;
                    else
                        recurringDays = d;
                }
            });
            console.log('recurringDays', recurringDays);
            if(this.state.pickuplocationItem != undefined && this.state.pickuplocationItem != null 
                && this.state.pickuplocationItem.id != null && this.state.pickuplocationItem.id != undefined 
                && this.state.pickuplocationItem.id.length == 0) {
                let pickuploc = this.state.locations.filter(location =>
                    //(client.FullName.toLowerCase().indexOf(inputValue) !== -1 || (client.Address && client.Address.toLowerCase().indexOf(inputValue) !== -1)) && client.AccountType.toLowerCase() === 'client'
                    (location.Name != undefined && location.Name != null && location.Name.indexOf(values.division + ' physical address') !== -1 && location.Division != null && location.Division != undefined && location.Division.indexOf(values.division) !== -1)
                    //(location.Name.toLowerCase().indexOf(inputValue) !== -1 || location.ClientName.toLowerCase().indexOf(inputValue) !== -1 || (location.Address && location.Address.toLowerCase().indexOf(inputValue) !== -1))
                );
                if(pickuploc != undefined && pickuploc != null && pickuploc.length > 0) {
                    actions.setFieldValue('pickuplocation', pickuploc[0].Name);
                    actions.setFieldValue('pickupstreet1', pickuploc[0].Street1);
                    actions.setFieldValue('pickupstreet2', pickuploc[0].Street2);
                    actions.setFieldValue('pickupcity', pickuploc[0].Suburb);
                    actions.setFieldValue('pickupsuburb', pickuploc[0].Suburb);
                    actions.setFieldValue('pickupzip', pickuploc[0].Zip);
                    let staffid = this.getFilteredStaff(pickuploc[0].Zip);
                    actions.setFieldValue('staff', staffid);
                    this.setPickupLocation(pickuploc[0].Name);
                    this.setPickupLocationItem({
                        name: pickuploc[0].Name,
                        id: pickuploc[0].ItemId,
                        division: pickuploc[0].Division,
                        divisionId: pickuploc[0].ParentItemId,
                        operatorId: pickuploc[0].OperatorId,
                        street1: pickuploc[0].Street1,
                        street2: pickuploc[0].Street2,
                        city: pickuploc[0].Suburb,
                        state: pickuploc[0].State,
                        suburb: pickuploc[0].Suburb,
                        zip: pickuploc[0].Zip
                    })
                }
                else {
                    const myLocationInit = {
                        headers: {
                            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                        },
                        body: {
                            locations: [
                                {
                                    ParentItemId: this.state.pickuplocationItem.divisionId,
                                    Name: this.state.pickuplocationItem.name + ' physical address', 
                                    Division: this.state.pickuplocationItem.division,                     
                                    Street1: this.state.pickuplocationItem.street1,
                                    Street2: this.state.pickuplocationItem.street2,
                                    Suburb: this.state.pickuplocationItem.suburb,
                                    State: this.state.pickuplocationItem.state,
                                    Zip: this.state.pickuplocationItem.zip,
                                    Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                }
                            ]
                            }
                    };
                    const result = await API.post("api", "/locations", myLocationInit);
                    console.log("resultLocation", result);
                    if(result.result === 'success') {
                        let pickuploc = this.state.pickuplocationItem;
                        pickuploc.id = result.ItemId;
                        this.setPickupLocationItem(pickuploc);
                    }
                }
                
            }
            // var today = new Date();
            // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            // var dateTime = date+' '+time;            

            const consnum = values.autoGenerateConsignmentNumber === true ? values.ref : values.manualConsignmentNumber;
            if(values.jobType.startsWith("Delivery") || values.jobType.startsWith("Return") || values.jobType.startsWith("Credo Delivery")) { 
                const myInitJob = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        jobs: [
                            {
                                Name: this.state.locationItem.name,
                                ConsignmentNumber: consnum,
                                JobType: values.jobType,
                                OperatorId: this.state.locationItem.operatorId,
                                DeliveryLocationId: this.state.locationItem.id,
                                ParentItemId: this.state.locationItem.divisionId,
                                Division: values.division,
                                Street1: this.state.locationItem.street1,
                                Street2: this.state.locationItem.street2,
                                Suburb: this.state.locationItem.suburb,
                                Zip: this.state.locationItem.zip,
                                Latitude: this.state.latitude,
                                Longitude: this.state.longitude,
                                Cartons: Number(values.box),
                                PoR: values.por,
                                Priority: values.priority ? 'Urgent': 'Normal',
                                ClientCode: values.clientcode,
                                ReferenceNumbers: values.referencenums,
                                Notes: values.notes,
                                PODNotes: values.podnotes,
                                DeliveryDate: values.deliveryTimeSwitch ? moment(values.deliveryDate).format('YYYY-MM-DD') + ' ' + moment(values.deliveryTime).format('HH:mm:ss') : moment(values.deliveryDate).format('YYYY-MM-DD') + ' 16:30:00',                           
                                HasDeliveryTime: values.deliveryTimeSwitch,
                                Created: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                StatusId: unallocatedStatus === '' ? status[0].ItemId : unallocatedStatus,
                                Status: unallocatedStatus === '' ? status[0].Label : 'Unallocated',
                                StatusGroup: unallocatedStatus === '' ? status[0].Type : 4,
                                DriverId: staff !== null ? staff[0].ItemId : '',
                                DriverName: staff !== null ? staff[0].FullName : '',
                                SMS: values.sms,
                                SMSText: values.smstext,
                                PointToPoint: values.pointToPoint,
                                RateOverride: values.pointToPoint === true ? values.rateOverride : 0,
                                IsRecurring: values.recurring ? 'true' : 'false',
                                IsDaily: (values.recurring  && values.recurringType === 'daily') ? 'true' : 'false',
                                IncludeWeekends: (values.recurring  && values.includeWeekends && values.recurringType === 'daily') ? 'true' : 'false',
                                IsWeekly: (values.recurring && values.recurringType === 'weekly') ? 'true' : 'false',
                                Days: recurringDays,
                                ExpiryDate: values.expirationSwitch ? moment(values.expirationDate).format('YYYY-MM-DD') + ' 00:00:00' : undefined,
                                DeliveryType: values.deliveryType,
                                DeliveryConsignmentIds: values.deliveryConsignmentIds,
                                CreatedUTCValid: true,
                                IsInterstateDelivery: values.interstateDeliverySwitch,
                                Length: values.interstateDeliverySwitch ? values.length : undefined,
                                Width: values.interstateDeliverySwitch ? values.width : undefined,
                                Height: values.interstateDeliverySwitch ? values.height : undefined,
                                LocationMobileNumber: values.locationMobileNumber ? values.locationMobileNumber : ''
                            }
                        ]
                        }
                };    
                const resultJob = await API.post("api", "/jobs", myInitJob); 
                if(resultJob.result === 'success')
                    this.setState({redirect: '/jobs'});           
            }
            else if(values.jobType === "Pickup Pallet" || values.jobType === "Pickup Carton" || values.jobType === "Credo Pickup") {
                const myInitJob = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        jobs: [
                            {
                                Name: this.state.pickuplocationItem.name,
                                ConsignmentNumber: consnum,
                                JobType: values.jobType,
                                OperatorId: this.state.pickuplocationItem.operatorId,
                                DeliveryLocationId: this.state.pickuplocationItem.id,
                                ParentItemId: this.state.pickuplocationItem.divisionId,
                                Division: values.division,
                                Street1: this.state.pickuplocationItem.street1,
                                Street2: this.state.pickuplocationItem.street2,
                                Suburb: this.state.pickuplocationItem.suburb,
                                Zip: this.state.pickuplocationItem.zip,
                                State: this.state.pickuplocationItem.state,
                                Latitude: this.state.pickuplat,
                                Longitude: this.state.pickuplong,
                                Cartons: Number(values.pickupbox),
                                PoR: values.por,
                                Priority: values.priority ? 'Urgent': 'Normal',
                                ClientCode: values.clientcode,
                                ReferenceNumbers: values.referencenums,
                                Notes: values.notes,
                                PODNotes: values.podnotes,
                                DeliveryDate: values.pickupTimeSwitch ? moment(values.pickupDate).format('YYYY-MM-DD') + ' ' + moment(values.pickupTime).format('HH:mm:ss') : moment(values.pickupDate).format('YYYY-MM-DD') + ' 06:30:00',                                                                                        
                                HasDeliveryTime: values.pickupTimeSwitch,
                                Created: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                StatusId: unallocatedStatus === '' ? status[0].ItemId : unallocatedStatus,
                                Status: unallocatedStatus === '' ? status[0].Label : 'Unallocated',
                                StatusGroup: unallocatedStatus === '' ? status[0].Type : 4,
                                DriverId: staff !== null ? staff[0].ItemId : '',
                                DriverName: staff !== null ? staff[0].FullName : '',
                                SMS: values.sms,
                                SMSText: values.smstext,
                                PointToPoint: values.pointToPoint,
                                RateOverride: values.pointToPoint === true ? values.rateOverride : 0,
                                IsRecurring: values.recurring ? 'true' : 'false',
                                IsDaily: (values.recurring && values.recurringType === 'daily') ? 'true' : 'false',
                                IncludeWeekends: (values.recurring != null && values.includeWeekends && values.recurringType === 'daily') ? 'true' : 'false',
                                IsWeekly: (values.recurring && values.recurringType === 'weekly') ? 'true' : 'false',
                                Days: recurringDays,
                                ExpiryDate: values.expirationSwitch ? moment(values.expirationDate).format('YYYY-MM-DD') + ' 00:00:00' : undefined,
                                DeliveryType: values.deliveryType,
                                DeliveryConsignmentIds: values.deliveryConsignmentIds,
                                CreatedUTCValid: true,
                                IsInterstateDelivery: values.interstateDeliverySwitch,
                                Length: values.interstateDeliverySwitch ? values.length : undefined,
                                Width: values.interstateDeliverySwitch ? values.width : undefined,
                                Height: values.interstateDeliverySwitch ? values.height : undefined,
                                LocationMobileNumber: ''
                            }
                        ]
                        }
                }; 
                const resultJob = await API.post("api", "/jobs", myInitJob); 
                if(resultJob.result === 'success')
                    this.setState({redirect: '/jobs'});               
            }
            else if(values.jobType.startsWith("Pickup and")) {
                let jobtypeText = values.jobType.includes('Carton') ? 'Carton' : 'Pallet';
                
                const myInitJob = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        jobs: [
                            {
                                Name: this.state.locationItem.name,
                                ConsignmentNumber: consnum,
                                JobType: values.jobType.endsWith("Return to Sender") ? 'Return to Sender' : 'Delivery ' + jobtypeText + (values.jobType.endsWith("Ambient") ? " Ambient" : ""),
                                OperatorId: this.state.locationItem.operatorId,
                                DeliveryLocationId: this.state.locationItem.id,
                                ParentItemId: this.state.locationItem.divisionId,
                                Division: values.division,
                                Street1: this.state.locationItem.street1,
                                Street2: this.state.locationItem.street2,
                                Suburb: this.state.locationItem.suburb,
                                Zip: this.state.locationItem.zip,
                                Latitude: this.state.latitude,
                                Longitude: this.state.longitude,
                                Cartons: Number(values.box),
                                PoR: values.por,
                                Priority: values.priority ? 'Urgent': 'Normal',
                                ClientCode: values.clientcode,
                                ReferenceNumbers: values.referencenums,
                                Notes: values.notes,
                                PODNotes: values.podnotes,
                                DeliveryDate: values.deliveryTimeSwitch ? moment(values.deliveryDate).format('YYYY-MM-DD') + ' ' + moment(values.deliveryTime).format('HH:mm:ss') : moment(values.deliveryDate).format('YYYY-MM-DD') + ' 16:30:00',                           
                                HasDeliveryTime: values.deliveryTimeSwitch,
                                Created: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                StatusId: unallocatedStatus === '' ? status[0].ItemId : unallocatedStatus,
                                Status: unallocatedStatus === '' ? status[0].Label : 'Unallocated',
                                StatusGroup: unallocatedStatus === '' ? status[0].Type : 4,
                                DriverId: staff !== null ? staff[0].ItemId : '',
                                DriverName: staff !== null ? staff[0].FullName : '',
                                SMS: values.sms,
                                SMSText: values.smstext,
                                PointToPoint: values.pointToPoint,
                                RateOverride: values.pointToPoint === true ? values.rateOverride : 0,
                                IsRecurring: values.recurring ? 'true' : 'false',
                                IsDaily: (values.recurring && values.recurringType === 'daily') ? 'true' : 'false',
                                IncludeWeekends: (values.recurring && values.includeWeekends && values.recurringType === 'daily') ? 'true' : 'false',
                                IsWeekly: (values.recurring && values.recurringType === 'weekly') ? 'true' : 'false',
                                Days: recurringDays,
                                ExpiryDate: values.expirationSwitch ? moment(values.expirationDate).format('YYYY-MM-DD') + ' 00:00:00' : undefined,
                                DeliveryType: values.deliveryType,
                                DeliveryConsignmentIds: values.deliveryConsignmentIds,
                                CreatedUTCValid: true,
                                IsInterstateDelivery: values.interstateDeliverySwitch,
                                Length: values.interstateDeliverySwitch ? values.length : undefined,
                                Width: values.interstateDeliverySwitch ? values.width : undefined,
                                Height: values.interstateDeliverySwitch ? values.height : undefined,
                                LocationMobileNumber: values.locationMobileNumber ? values.locationMobileNumber : ''
                            }
                        ]
                        }
                };
                const myPickupJob = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        jobs: [
                            {
                                Name: this.state.pickuplocationItem.name,
                                ConsignmentNumber: consnum,
                                JobType: 'Pickup ' + jobtypeText + (values.jobType.endsWith("Ambient") ? " Ambient" : ""),
                                OperatorId: this.state.pickuplocationItem.operatorId,
                                DeliveryLocationId: this.state.pickuplocationItem.id,
                                ParentItemId: this.state.pickuplocationItem.divisionId,
                                Division: values.division,
                                Street1: this.state.pickuplocationItem.street1,
                                Street2: this.state.pickuplocationItem.street2,
                                Suburb: this.state.pickuplocationItem.suburb,
                                Zip: this.state.pickuplocationItem.zip,
                                Latitude: this.state.pickuplat,
                                Longitude: this.state.pickuplong,
                                Cartons: Number(values.pickupbox),
                                PoR: values.por,
                                Priority: values.priority ? 'Urgent': 'Normal',
                                ClientCode: values.clientcode,
                                ReferenceNumbers: values.referencenums,
                                Notes: values.notes,
                                PODNotes: values.podnotes,
                                DeliveryDate: values.pickupTimeSwitch ? moment(values.pickupDate).format('YYYY-MM-DD') + ' ' + moment(values.pickupTime).format('HH:mm:ss') : moment(values.pickupDate).format('YYYY-MM-DD') + ' 06:30:00',                                                                                        
                                HasDeliveryTime: values.pickupTimeSwitch,
                                Created: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                StatusId: unallocatedStatus === '' ? status[0].ItemId : unallocatedStatus,
                                Status: unallocatedStatus === '' ? status[0].Label : 'Unallocated',
                                StatusGroup: unallocatedStatus === '' ? status[0].Type : 4,
                                DriverId: staff !== null ? staff[0].ItemId : '',
                                DriverName: staff !== null ? staff[0].FullName : '',
                                SMS: values.sms,
                                SMSText: values.smstext,
                                PointToPoint: values.pointToPoint,
                                RateOverride: values.pointToPoint === true ? values.rateOverride : 0,
                                IsRecurring: values.recurring ? 'true' : 'false',
                                IsDaily: (values.recurring && values.recurringType === 'daily') ? 'true' : 'false',
                                IncludeWeekends: (values.recurring && values.includeWeekends && values.recurringType === 'daily') ? 'true' : 'false',
                                IsWeekly: (values.recurring && values.recurringType === 'weekly') ? 'true' : 'false',
                                Days: recurringDays,
                                ExpiryDate: values.expirationSwitch ? moment(values.expirationDate).format('YYYY-MM-DD') + ' 00:00:00' : undefined,
                                DeliveryType: values.deliveryType,
                                DeliveryConsignmentIds: values.deliveryConsignmentIds,
                                CreatedUTCValid: true,
                                IsInterstateDelivery: values.interstateDeliverySwitch,
                                Length: values.interstateDeliverySwitch ? values.length : undefined,
                                Width: values.interstateDeliverySwitch ? values.width : undefined,
                                Height: values.interstateDeliverySwitch ? values.height : undefined,
                                LocationMobileNumber: ''
                            }
                        ]
                        }
                }; 
                const resultJob = await API.post("api", "/jobs", myInitJob); 
                const resultPickupJob = await API.post("api", "/jobs", myPickupJob); 
                if(resultJob.result === 'success' && resultPickupJob.result === 'success')
                    this.setState({redirect: '/jobs'}); 
            }
            
        }catch(error)
        {
            console.log('error', error.message);
        }
        
    }

    componentDidMount() {
        
        // console.log('userConsumer', this.context);
        //this.setState({userType: this.context});
        if(this.props.match.params.jobId !== undefined && this.props.match.params.jobId.length > 0)
        {
            this.setState({jobId: this.props.match.params.jobId}, function(){
                this.fetchJob();
            })
        }
        else{
            this.setState({jobId: ''});
            this.setState({job: null});
        }
        this.fetchClients();
        this.fetchDivisions();
        this.fetchAccounts();
        this.fetchStatus();
        this.fetchNotificationGroups();
    }

    async fetchJob(){
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/jobs/" + this.state.jobId, myInit);
            const initial = {
                name: res.jobs[0].Name,
                id: res.jobs[0].DeliveryLocationId,
                division: res.jobs[0].Division,
                divisionId: res.jobs[0].ParentItemId,
                operatorId: res.jobs[0].OperatorId,
                street1: res.jobs[0].Street1,
                street2: res.jobs[0].Street2,
                state: res.jobs[0].State,
                suburb: res.jobs[0].Suburb,
                zip: res.jobs[0].Zip
            };
            this.setState({
                job: res.jobs[0],
                location: res.jobs[0].Name,
                locationItem: initial
                })
        }catch(err) {
            this.setState(() => { throw err; })
        }
    }
      
    async fetchAccounts() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/accounts", myInit);
            this.setState({
                accounts: res.accounts
                })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }        
    }

    async fetchClients() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/clients?ForAddJobPage=true", myInit);
            this.setState({
                clients: res.clients
            })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    async fetchDivisions() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/divisions?ForAddJobPage=true", myInit);
            this.setState({
                divisions: res.divisions
            })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    fetchLocations = async (clientId, divisionName) => {
        try{
            for (let i = 0; i < 20; i++){
                if((this.state.jobId !== undefined && this.state.jobId !== '' && this.state.job === undefined) || this.state.divisions.length === 0){
                    console.log('wait for job');
                    await this.sleep(1000);
                }
                else
                    break;
            }
            // if(clientId === undefined)
            //     clientId = (this.state.jobId !== undefined && this.state.jobId !== '' && this.state.job !== undefined) ? this.state.job.BillingClientId : '';
            // console.log('clientId', clientId);
            // if(clientId != null && clientId !== '' && divisionName != null && divisionName !== '')
            // {
            //     //const divisions = this.state.divisions.filter(d => d.ParentItemId === clientId);
            //     const authHeader = await authorizationHeader();
            //     let divisionId = this.state.divisions.filter(d => d.ParentItemId === clientId && d.Name === divisionName)[0].ItemId;
            //     let res = await API.get('api', '/locations/unique?ParentItemId=' + divisionId, authHeader);
            //     if(res && res.locationsunique && res.locationsunique.length > 0) {
            //         this.setState({
            //             locations: res.locationsunique
            //         });
            //     }
                            
            // }
        }catch(error) {
            this.setState(() => { throw error; })
        }
    }

    async fetchStatus() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/status", myInit);
            this.setState({
                status: res.status.filter(ss => ss.IsActive)
                })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    async fetchNotificationGroups() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/workgroups", myInit);
            this.setState({
                notificationGroups: res.workgroups
                })
        }catch(err) {
            this.setState(() => { throw err; })
        }        
    }

    getNotificationGroups() {        
        return this.state.notificationGroups.map((group, index) => {
           const { Name, ItemId } = group
           return (
               <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
     }

    getClients() {        
        return this.state.clients.map((client, index) => {
           const { ItemId, Name } = client
           return (
                <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }

    getDivisionOptions = (val) => {        
        if(this.state.divisions.length > 0 && this.state.clients.length > 0){
            if(val.Client !== '')
            {
                return this.state.divisions.filter(d => d.ParentItemId === val.client).map((c) => {
                    const { Name, ItemId } = c
                    return (
                        <option key={ItemId} value={Name} >{Name}</option>
                    )
                });
            }
        }   
    }

    getFilteredStaff = (pin) => {
        var filtered = [];
        if(this.state.notificationGroupId === '')
        {
            filtered = this.state.accounts.filter(user => user.AccountType.toLowerCase() === 'driver' && user.Status === "active" && user.TerritoryPostcodes != null && user.TerritoryPostcodes.includes(pin)); //.filter(d => (',' + (d.TerritoryPostcodes ? d.TerritoryPostcodes : '').replace(/\s+/g, '') + ',').indexOf(',' + pin.replace(/\s+/g, '') + ',') > -1 );            
        }
        else{
            var unfiltered = this.state.notificationGroups.filter(group => group.ItemId === this.state.notificationGroupId)[0].Members.filter(user => user.AccountType.toLowerCase() === 'driver'); //.filter(d => (',' + (d.TerritoryPostcodes ? d.TerritoryPostcodes : '').replace(/\s+/g, '') + ',').indexOf(',' + pin.replace(/\s+/g, '') + ',') > -1 );                   
            unfiltered.forEach((user,index) => {
                var account = this.state.accounts.filter(driver => driver.ItemId === user.AccountId && driver.TerritoryPostcodes != null && driver.TerritoryPostcodes.includes(pin));
                if(account && account.length > 0) { 
                    filtered.push(account[0]);
                }
            });
        }

        if(filtered != null && filtered.length > 0)
        {
            var driverIndex = Math.round(Math.random() * (filtered.length - 1));
            console.log('driverIndex',driverIndex);
            var driver = this.state.accounts.filter(account => account.ItemId === filtered[driverIndex].ItemId);
            return driver[0].ItemId;
        }
        else return "";
    }

    getStaff() {
        if(this.state.notificationGroupId === '')
        {
            const filtered = this.state.accounts.filter(user => user.AccountType.toLowerCase() === 'driver' && user.Status === "active");
            return filtered.map((user, index) => {
                const { FullName, ItemId } = user
                return (
                    <option key={ItemId} value={ItemId} >{FullName}</option>
                )
            })
        }
        else{
            const filtered = this.state.notificationGroups.filter(group => group.ItemId === this.state.notificationGroupId)[0].Members.filter(user => user.AccountType.toLowerCase() === 'driver');
            return filtered.map(user => {
                const { FirstName, LastName, AccountId } = user
                return (
                    <option key={AccountId} value={AccountId} >{FirstName + ' ' + LastName}</option>
                )
            })
        }
     }

     getStatus() {        
        return this.state.status.map((status) => {
           const { Label, ItemId } = status
           return (
               <option key={ItemId} value={ItemId} >{Label}</option>
           )
        })
     }
    
     handleDivisionChange = (clientid, name) => {
        console.log('param', clientid);
        this.setState({ locations: [] });
    }
    
    getDivisionPickupLocation = (type, clientid, name, setValue) => {
        if(type.includes('Pickup') && name && name.length > 0) {
            let pickupdiv = this.state.divisions.filter(d => d.ParentItemId === clientid && d.Name === name)[0];            
            setValue('pickuplocation', pickupdiv.Name);
            setValue('pickupstreet1', pickupdiv.PhysicalAddressStreet);
            setValue('pickupstreet2', '');
            setValue('pickupcity', pickupdiv.PhysicalAddressCity);
            setValue('pickupsuburb', pickupdiv.PhysicalAddressCity);
            setValue('pickupzip', pickupdiv.PhysicalAddressZip);
            let staffid = this.getFilteredStaff(pickupdiv.PhysicalAddressZip);
            setValue('staff', staffid);
            this.setPickupLocation(pickupdiv.name);
            this.setPickupLocationItem({
                name: pickupdiv.Name,
                id: '',
                division: pickupdiv.Name,
                divisionId: pickupdiv.ItemId,
                operatorId: pickupdiv.OperatorId,
                street1: pickupdiv.PhysicalAddressStreet,
                street2: '',
                city: pickupdiv.PhysicalAddressCity,
                state: pickupdiv.PhysicalAddressState,
                suburb: pickupdiv.PhysicalAddressCity,
                zip: pickupdiv.PhysicalAddressZip
            })         
        }
        else {
            setValue('pickuplocation', '');
            setValue('pickupstreet1', '');
            setValue('pickupstreet2', '');
            setValue('pickupcity', '');
            setValue('pickupsuburb', '');
            setValue('pickupzip', '');
            this.setPickupLocation('');
            this.setPickupLocationItem({});
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render() {  
      const schema = yup.object().shape({
        location: yup.string()
            .ensure()
            .test('ok', 'Required', function(value) {
                if((this.parent.jobType !== 'Pickup Carton' && this.parent.jobType !== 'Pickup Pallet' 
                && this.parent.jobType !== 'Credo Pickup') && value === '') {
                    return false;
                }              
                return true;
            }),
        pickuplocation: yup.string()
            .ensure()
            .test('ok', 'Required', function(value) {
                if(this.parent.jobType.includes('Pickup') && value === '') {
                    return false;
                }              
                return true;
            }),
        client: yup.string().required('Required'),
        division: yup.string().required('Required'),
        clientcode: yup.string().max(20, "Max 20 characters"),
        staff: yup.string()
            .ensure()
            .test('ok', 'Required', function(value){
            if(this.parent.userType.toLowerCase() !== 'officeadministrator' && this.parent.userType.toLowerCase() !== 'officeuser' && value === ''){
                return false;
            }              
            return true;
            }),
            //.required('Required'),
        box: yup.number()
            .typeError('Must be number'),
            // .required('Required'),
        pickupbox: yup.number()
            .typeError('Must be number'),
            // .required('Required'),
        rateOverride: yup.number()
            .typeError('Must be number'),
        manualConsignmentNumber: yup.string()
            .ensure()
            .test('ok', 'Required', function(value){
            if(this.parent.autoGenerateConsignmentNumber === false && value === ''){
              return false;
            }              
            return true;
            }),
        length: yup.number()
            .typeError('Must be number')
            .test('ok', 'Required', function(value){
                if(this.parent.interstateDeliverySwitch === true && (value === undefined || value.length === 0)){
                    return false;
                }              
            return true;
            }),
        width: yup.number()
            .typeError('Must be number')
            .test('ok', 'Required', function(value){
                if(this.parent.interstateDeliverySwitch === true && (value === undefined || value.length === 0)){
                    return false;
                }              
            return true;
            }),
        height: yup.number()
            .typeError('Must be number')
            .test('ok', 'Required', function(value){
                if(this.parent.interstateDeliverySwitch === true && (value === undefined || value.length === 0)){
                    return false;
                }              
            return true;
            }),
      });
      console.log('statusLength', this.state.status.length);
      if (this.state.redirect) 
        return <Redirect to={{pathname: this.state.redirect}} />
      if(this.state.status.length === 0 || this.state.jobId === undefined || this.state.job === undefined)
      {
          return(
            <div><br/><br/><br/>
              <Spinner animation="border" />
            </div>
          );
      }
      else
      {
        return (
            <UserConsumer>
                    {userType => {
                        // if(userType.toLowerCase() === 'officeadministrator')
                            // this.setState({userType, userType});
                        return (
          <Formik
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                await this.createJobInDatabase(values, actions);
            }}            
            initialValues={{
                street1: this.state.jobId !== '' ? this.state.job.Street1 : '',
                street2: this.state.jobId !== '' ? this.state.job.Street2 : '',
                city: '',
                state: '',
                suburb: this.state.jobId !== '' ? this.state.job.Suburb : '',
                zip: this.state.jobId !== '' ? this.state.job.Zip : '',
                pickupconsignmentnumber: '',
                pickupstreet1: '',
                pickupstreet2: '',
                pickupcity: '',
                pickupstate: '',
                pickupsuburb: '',
                pickupzip: '',
                location: this.state.jobId !== '' ? this.state.job.Name : '',
                pickuplocation: '',
                division: this.state.jobId !== '' ? this.state.job.Division : '',
                client: this.state.jobId !== '' ? this.state.job.BillingClientId : '',
                deliveryTimeSwitch: this.state.jobId !== '' ? this.state.job.HasDeliveryTime : false,
                billingClient:'',
                jobType: this.state.jobId !== '' ? this.state.job.JobType : 'Delivery Carton',
                jobTitle:'',
                por: this.state.jobId !== '' ? this.state.job.PoR : '',
                clientcode: this.state.jobId !== '' ? this.state.job.ClientCode : '',
                referencenums: this.state.jobId !== '' ? this.state.job.ReferenceNumbers : '',
                notes: this.state.jobId !== '' ? (this.state.job.Notes === null ? '' : this.state.job.Notes) : '',
                podnotes: this.state.jobId !== '' ? (this.state.job.podnotes === null ? '' : this.state.job.podnotes) : '',
                ref: (Math.random() * (9999999 - 1000000) + 1000000).toString().split('.')[0],
                staff: this.state.jobId !== '' ? this.state.job.DriverId : '',
                pickupDate: this.state.jobId !== '' ? new Date(this.state.job.DeliveryDate) : new Date(),
                pickupTime: this.state.jobId !== '' ? (this.state.job.HasDeliveryTime ? new Date(this.state.job.DeliveryDate) : new Date(new Date().setHours(6, 30, 0))) : new Date(new Date().setHours(6, 30, 0)),
                deliveryDate: this.state.jobId !== '' ? new Date(this.state.job.DeliveryDate) : new Date(),
                deliveryTime: this.state.jobId !== '' ? (this.state.job.HasDeliveryTime ? new Date(this.state.job.DeliveryDate) : new Date(new Date().setHours(16, 30, 0))) : new Date(new Date().setHours(16, 30, 0)),                
                priority: false,
                box: this.state.jobId !== '' ? this.state.job.Cartons : '1',
                pickupbox: '1',
                status: this.state.status.length > 0 ? this.state.status.filter(s => s.SortOrder === 1)[0].ItemId.toString() : '',
                sms: this.state.jobId !== '' ? this.state.job.SMS : false,
                smstext: this.state.jobId === '' ? 'New job has been added. Please refresh your app' : '',
                pointToPoint: this.state.jobId !== '' ? this.state.job.PointToPoint : false,
                rateOverride: this.state.jobId !== '' ? this.state.job.RateOverride : 0,
                autoGenerateConsignmentNumber: false,
                manualConsignmentNumber: '',
                recurring: false,
                recurringType: 'daily',
                includeWeekends: false,
                expirationSwitch: false,
                expirationDate: new Date(),
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                userType: userType,
                deliveryType: 'Same Day',
                deliveryConsignmentIds: undefined,
                interstateDeliverySwitch: false,
                length: undefined,
                width: undefined,
                height: undefined,
                locationMobileNumber: this.state.jobId !== '' ? this.state.job.LocationMobileNumber : ''
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              status,
              setFieldValue
            }) => (
            <React.Fragment>
            <Toaster />
            <Container className="no-left-right-margin form-text-align">
                <Form onSubmit={handleSubmit} >
                {/* <UserConsumer>
                    {userType => {
                        // if(userType.toLowerCase() === 'officeadministrator')
                            // this.setState({userType, userType});
                        return ( */}
                            <Card>
                                <Card.Header><h4>Add Job</h4></Card.Header>
                                <Card.Body>
                                {/* <Form onSubmit={handleSubmit} > */}

                                    <Form.Row>
                                        {/* <Form.Group as={Col} xs={4}>                                            
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4}></Form.Group> */}
                                        <Form.Group as={Col} xs={4}>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} className="margin-bottom-zero">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        type="switch"
                                                        defaultChecked={values.autoGenerateConsignmentNumber}
                                                        id="autoGenerateConsignmentNumber"
                                                        label="Auto Generate Consignment Number"
                                                        value={values.autoGenerateConsignmentNumber}
                                                        onChange={handleChange}
                                                    />                                                                                
                                                </Form.Group>
                                                {values.autoGenerateConsignmentNumber === true && 
                                                <Form.Group as={Col} xs={12}>
                                                    <Form.Label><h6 className="bold-font">Consignment #: {values.ref}</h6></Form.Label>
                                                </Form.Group>
                                                }
                                                {values.autoGenerateConsignmentNumber === false &&
                                                <React.Fragment>
                                                    <Form.Group as={Col} xs={5}>
                                                    <Form.Label><h6 className="bold-font">Consignment #:</h6></Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                    <Form.Control
                                                        className="inline"
                                                        size="sm"
                                                        type="text"
                                                        placeholder="Consignment #"
                                                        name="manualConsignmentNumber"
                                                        value={values.manualConsignmentNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.manualConsignmentNumber && touched.manualConsignmentNumber}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.manualConsignmentNumber}
                                                    </Form.Control.Feedback>
                                                    </Form.Group>
                                                </React.Fragment>
                                                }
                                            </Form.Row>
                                        </Form.Group>                                        
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group id="halfcontainer" as={Col} xs={4}>
                                                <Form.Row>
                                                    <Form.Group as={Col} xs={12} controlId="jobType" className="margin-bottom-8">
                                                        <Form.Label className="med-font margin-bottom-zero">Job Type</Form.Label>
                                                        <Form.Control as="select"
                                                            size="sm"
                                                            value={values.jobType}
                                                            name="jobType"
                                                            onChange={(e) => {
                                                                setFieldValue('jobType', e.target.value);
                                                                handleChange(e);
                                                                this.setLocation('');
                                                                this.setLocationItem(null);                                                            
                                                                setFieldValue('location', '');
                                                                setFieldValue('street1', '');
                                                                setFieldValue('street2', '');
                                                                setFieldValue('city', '');
                                                                setFieldValue('suburb', '');
                                                                setFieldValue('zip', '');

                                                                this.setPickupLocation('');
                                                                this.setPickupLocationItem(null);
                                                                setFieldValue('pickuplocation', '');
                                                                setFieldValue('pickupstreet1', '');
                                                                setFieldValue('pickupstreet2', '');
                                                                setFieldValue('pickupcity', '');
                                                                setFieldValue('pickupsuburb', '');
                                                                setFieldValue('pickupzip', '');

                                                                setFieldValue('staff', '');
                                                                this.getDivisionPickupLocation(e.target.value, values.client, values.division, setFieldValue);
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={errors.jobType}
                                                            isInvalid={errors.jobType}
                                                        >
                                                            <option>Delivery Carton</option>
                                                            <option>Delivery Pallet</option>
                                                            <option>Pickup Carton</option>
                                                            <option>Pickup Pallet</option>
                                                            <option>Pickup and Delivery Carton</option>
                                                            <option>Pickup and Delivery Pallet</option>
                                                            <option>Return to Sender</option>
                                                            <option>Pickup and Return to Sender</option>
                                                            <option>Credo Pickup</option>
                                                            <option>Credo Delivery</option>
                                                            <option>Delivery Carton Ambient</option>
                                                            <option>Pickup and Delivery Carton Ambient</option>
                                                            <option>Pickup and Delivery Pallet Ambient</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.jobType}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} xs={12} controlId="deliveryType" className="margin-bottom-8">
                                                        <Form.Label className="med-font margin-bottom-zero">Delivery Type</Form.Label>
                                                        <Form.Control as="select"
                                                            size="sm"
                                                            value={values.deliveryType}
                                                            name="deliveryType"
                                                            onChange={(e) => {
                                                                setFieldValue('deliveryType', e.target.value);
                                                                handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={errors.deliveryType}
                                                            touched={touched.deliveryType}
                                                            isInvalid={errors.deliveryType && touched.deliveryType}
                                                        >
                                                            <option>Same Day</option>
                                                            <option>Next Day</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.deliveryType}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} xs={12} controlId="client" className="margin-bottom-8">
                                                        <Form.Label className="med-font margin-bottom-zero">Client</Form.Label>
                                                            <Form.Control as="select"
                                                            //disabled={isLoading}
                                                            size="sm"
                                                            type="text"
                                                            value={values.client}
                                                            onChange={(e) => {                                                                
                                                                setFieldValue('client', e.target.value);
                                                                setFieldValue('division', '');                                                                
                                                            }}
                                                            placeholder="Loading..."
                                                            isInvalid={errors.client && touched.client}
                                                            >
                                                                <option value="">{this.state.clients.length === 0 ? "Loading..." : "Select Client"}</option>
                                                                {this.getClients()}
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.client}
                                                            </Form.Control.Feedback>                                                        
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} xs={12} controlId="division" className="margin-bottom-8">
                                                        <Form.Label className="med-font margin-bottom-zero">Division</Form.Label>
                                                        {/* <Form.Control as="select" */}
                                                        <Form.Control                                                            
                                                            size="sm"
                                                            as="select"
                                                            value={values.division}
                                                            name="division"
                                                            onChange={(e) => {
                                                                //setSelectedDivision(e.target.value);
                                                                setFieldValue('division', e.target.value);
                                                                this.handleDivisionChange(values.client, e.target.value);

                                                                this.setLocation('');
                                                                this.setLocationItem(null);                                                            
                                                                setFieldValue('location', '');
                                                                setFieldValue('street1', '');
                                                                setFieldValue('street2', '');
                                                                setFieldValue('city', '');
                                                                setFieldValue('suburb', '');
                                                                setFieldValue('zip', '');

                                                                this.setPickupLocation('');
                                                                this.setPickupLocationItem(null);
                                                                setFieldValue('pickuplocation', '');
                                                                setFieldValue('pickupstreet1', '');
                                                                setFieldValue('pickupstreet2', '');
                                                                setFieldValue('pickupcity', '');
                                                                setFieldValue('pickupsuburb', '');
                                                                setFieldValue('pickupzip', '');

                                                                setFieldValue('staff', '');
                                                                this.getDivisionPickupLocation(values.jobType, values.client, e.target.value, setFieldValue);
                                                            }}
                                                            error={errors.division}
                                                            touched={touched.division}
                                                            isInvalid={errors.division && touched.division}
                                                        >       
                                                        <option value="">{this.state.clients.length === 0 ? 'Loading...' : (values.client !== '' ? 'Select Division' : 'Select Client first')}</option>
                                                        {this.getDivisionOptions(values)}                                                     
                                                        </Form.Control>                                                        
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.division}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row> 
                                                <React.Fragment>
                                                    <Map setCoordinates={this.setCoordinates} setPickupCoordinates={this.setPickupCoordinates} isPickup={values.jobType.includes('Pickup')} address={values.street1 + ' ' + (values.street2 ? values.street2 : '') + ' ' + values.suburb + ' ' + values.zip} pickupaddress={values.pickupstreet1 + ' ' + (values.pickupstreet2 ? values.pickupstreet2 : '') + ' ' + values.pickupsuburb + ' ' + values.pickupzip}/>                                                            
                                                </React.Fragment>                                              
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4}>
                                            <Form.Row>
                                            <Form.Label className="med-font margin-bottom-zero">Pickup Location</Form.Label>
                                                <Form.Group as={Col} xs={10} controlId="pickuplocation" className="margin-bottom-8">
                                                <Form.Control
                                                        readOnly
                                                        size="sm"
                                                        type="text"
                                                        placeholder ={values.division === '' ? 'Please select division first.' : 'Pickup location name'}
                                                        name="pickuplocation"
                                                        value={values.pickuplocation}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.pickuplocation}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.pickuplocation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <FormGroup as={Col} className="margin-bottom-zero">
                                                        {
                                                            values.jobType.includes('Pickup') &&
                                                            <CreateNewLocationModal selectedClient={values.client} selectedDivision={values.division} isPickup={true} callBackFunctions={{setFieldValue:setFieldValue, setLocation:this.setPickupLocation, setLocationItem:this.setPickupLocationItem, setCoordinates:this.setPickupCoordinates}} />
                                                        }
                                                </FormGroup>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="pickupstreet1" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Pickup Address 1</Form.Label>
                                                    <Form.Control
                                                        readOnly
                                                        size="sm"
                                                        type="text"
                                                        placeholder="Address 1"
                                                        name="pickupstreet1"
                                                        value={values.pickupstreet1}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.pickupstreet1 && touched.pickupstreet1}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.pickupstreet1}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="pickupstreet2" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Pickup Address 2</Form.Label>
                                                    <Form.Control
                                                        readOnly
                                                        size="sm"
                                                        type="text"
                                                        placeholder="Address 2"
                                                        name="pickupstreet2"
                                                        value={values.pickupstreet2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.pickupstreet2 && touched.pickupstreet2}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.pickupstreet2}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="pickupsuburb" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Pickup Suburb</Form.Label>
                                                    <Form.Control 
                                                    readOnly
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Suburb"
                                                    name="pickupsuburb"
                                                    value={values.pickupsuburb}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.pickupsuburb && touched.pickupsuburb}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.pickupsuburb}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="pickupzip" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Pickup Postcode</Form.Label>
                                                    <Form.Control 
                                                    readOnly
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Postcode"
                                                    name="pickupzip"
                                                    value={values.pickupzip}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.pickupzip && touched.pickupzip}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.pickupzip}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="pickupbox" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">{values.jobType.toLowerCase().includes('carton') ? 'Number of pickup cartons' : 'Number of pickup pallets'}</Form.Label>
                                                    {
                                                        values.jobType.includes('Pickup') ? <React.Fragment>
                                                            <Form.Control as="select"
                                                            readOnly={values.jobType.includes('Pickup') ? false : true}
                                                            size="sm"
                                                            value={values.pickupbox}
                                                            placeholder={values.jobType.toLowerCase().includes('carton') ? 'Number of pickup cartons' : 'Number of pickup pallets'}
                                                            name="pickupbox"
                                                            onChange={(e) => {
                                                                setFieldValue('pickupbox', e.target.value);
                                                                handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            touched={touched.pickupbox}
                                                            isInvalid={errors.pickupbox && touched.pickupbox}
                                                        >
                                                            <option key="1" value="1">1</option>
                                                            <option key="2" value="2">2</option>
                                                            <option key="3" value="3">3</option>
                                                            <option key="4" value="4">4</option>
                                                            <option key="5" value="5">5</option>
                                                            <option key="6" value="6">6</option>
                                                            <option key="7" value="7">7</option>
                                                            <option key="8" value="8">8</option>
                                                            <option key="9" value="9">9</option>
                                                            <option key="10" value="10">10</option>
                                                            <option key="11" value="11">11</option>
                                                            <option key="12" value="12">12</option>
                                                            <option key="13" value="13">13</option>
                                                            <option key="14" value="14">14</option>
                                                            <option key="15" value="15">15</option>
                                                            <option key="16" value="16">16</option>
                                                            <option key="17" value="17">17</option>
                                                            <option key="18" value="18">18</option>
                                                            <option key="19" value="19">19</option>
                                                            <option key="20" value="20">20</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.pickupbox}
                                                        </Form.Control.Feedback>
                                                        </React.Fragment> :
                                                        <React.Fragment>
                                                            <Form.Control 
                                                                readOnly={true}
                                                                size="sm"
                                                                type="text"
                                                                value={0}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                />
                                                        </React.Fragment>
                                                    }                                                                                                        
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={6} controlId="pickupDate" className="margin-bottom-8">
                                                    {/* <Form.Label className="med-font margin-bottom-zero">{values.recurring ? 'Start Date' : values.jobType.toLowerCase().startsWith('pickup') ? 'Pickup Date' : 'Delivery Date'}</Form.Label><br/> */}
                                                    <Form.Label className="med-font margin-bottom-zero">Pickup Date</Form.Label><br/>  
                                                    {/* <DatePicker showTimeSelect dateFormat="Pp" selected={Date.parse(values.deliveryDate)} onChange={value => console.log(value) } className='form-control form-control-sm' placeholderText='Select pickup date'/> */}
                                                    { (values.jobType && values.jobType.includes("Pickup"))
                                                        ? <DatePicker dateFormat="dd/MM/yyyy" selected={Date.parse(values.pickupDate)} onChange={value => setFieldValue('pickupDate', value)} className='form-control form-control-sm' placeholderText='Select pickup date'/>
                                                        : <Form.Control readOnly size="sm" type="text"></Form.Control>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Col} xs={6} controlId="pickupTime" className="margin-bottom-8">                                                
                                                { (values.jobType && values.jobType.includes("Pickup"))  
                                                ? <React.Fragment>
                                                    <Form.Check 
                                                            className="med-font margin-bottom-zero"
                                                            type="switch"
                                                            id="pickupTimeSwitch"
                                                            label={values.jobType.toLowerCase().includes('pickup') ? 'Pick up by' : 'Deliver by'}//"Must be delivered by"
                                                            value={values.pickupTimeSwitch}
                                                            defaultChecked={values.pickupTimeSwitch}
                                                            onChange={handleChange}
                                                            />
                                                        <DatePicker readOnly={!values.pickupTimeSwitch} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" selected={Date.parse(values.pickupTime)} onChange={value => setFieldValue('pickupTime', value)} className={values.pickupTimeSwitch ? 'form-control form-control-sm' : 'form-control form-control-sm gray'} placeholderText='Select pickup time'/>                                                            
                                                    
                                                </React.Fragment> 
                                                    : <React.Fragment>
                                                        <Form.Label className="med-font margin-bottom-zero">Pickup by</Form.Label><br/>
                                                        <Form.Control readOnly size="sm" type="text"></Form.Control>
                                                    </React.Fragment>
                                                }
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                            <Form.Group as={Col} xs={6} controlId="deliveryDate" className="margin-bottom-8">
                                                    {/* <Form.Label className="med-font margin-bottom-zero">{values.recurring ? 'Start Date' : values.jobType.toLowerCase().startsWith('pickup') ? 'Pickup Date' : 'Delivery Date'}</Form.Label><br/> */}
                                                    <Form.Label className="med-font margin-bottom-zero">Delivery Date</Form.Label><br/>  
                                                    {/* <DatePicker showTimeSelect dateFormat="Pp" selected={Date.parse(values.deliveryDate)} onChange={value => console.log(value) } className='form-control form-control-sm' placeholderText='Select pickup date'/> */}
                                                    <DatePicker dateFormat="dd/MM/yyyy" selected={Date.parse(values.deliveryDate)} onChange={value => setFieldValue('deliveryDate', value)} className='form-control form-control-sm' placeholderText='Select delivery date'/>
                                                </Form.Group>
                                                <Form.Group as={Col} xs={6} controlId="deliveryTime" className="margin-bottom-8">
                                                    <Form.Check 
                                                    className="med-font margin-bottom-zero"
                                                    type="switch"
                                                    id="deliveryTimeSwitch"
                                                    label="Deliver by"
                                                    value={values.deliveryTimeSwitch}
                                                    defaultChecked={values.deliveryTimeSwitch}
                                                    onChange={handleChange}
                                                    />
                                                    <DatePicker readOnly={!values.deliveryTimeSwitch} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" selected={Date.parse(values.deliveryTime)} onChange={value => setFieldValue('deliveryTime', value)} className={values.deliveryTimeSwitch ? 'form-control form-control-sm' : 'form-control form-control-sm gray'} placeholderText='Select delivery time'/>
                                                    
                                                </Form.Group>
                                            </Form.Row>
                                            {values.userType.toLowerCase() !== 'officeadministrator' && values.userType.toLowerCase() !== 'officeuser' &&
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="status" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Status</Form.Label>
                                                    <Form.Control as="select"
                                                        size="sm"
                                                        value={values.status}
                                                        name="status"
                                                        onChange={handleChange}
                                                    >
                                                        {this.getStatus()}
                                                    </Form.Control>
                                                </Form.Group>
                                                { status != null && status.msg && 
                                                    <Form.Group>
                                                        <Form.Label className="error-msg">{status.msg}</Form.Label>
                                                    </Form.Group>
                                                }
                                            </Form.Row>
                                            }      
                                            {values.userType.toLowerCase() !== 'officeadministrator' && values.userType.toLowerCase() !== 'officeuser' &&
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="notificationGroup" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Filter driver by Notification Group</Form.Label>
                                                    <Form.Control as="select"
                                                        size="sm"
                                                        value={this.state.notificationGroupId}
                                                        name="notificationGroup"
                                                        onChange={ (e) => {
                                                            this.setNotificationGroup(e.target.value);
                                                            setFieldValue('staff', '');
                                                        }}
                                                    >
                                                        <option value="">Filter by...</option>
                                                        {this.getNotificationGroups()}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form.Row>
                                            }
                                            {values.userType.toLowerCase() !== 'officeadministrator' && values.userType.toLowerCase() !== 'officeuser' &&
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="staff" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Select Driver</Form.Label>
                                                    <Form.Control as="select"
                                                        size="sm"
                                                        value={values.staff}
                                                        name="staff"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.staff && touched.staff}
                                                    >
                                                        <option value="">Choose...</option>
                                                        {this.getStaff()}
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.staff}
                                                    </Form.Control.Feedback>
                                                </Form.Group>                                                
                                            </Form.Row>
                                            }
                                            {values.userType.toLowerCase() !== 'officeadministrator' && values.userType.toLowerCase() !== 'officeuser' &&
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="sms" className="margin-bottom-8">
                                                    <Form.Check 
                                                    className="med-font margin-bottom-zero"
                                                    type="switch"
                                                    id="sms"
                                                    label="send SMS to driver"
                                                    value={values.sms}
                                                    onChange={handleChange}
                                                    defaultChecked={values.sms}
                                                    />                                        
                                                </Form.Group>
                                            </Form.Row>
                                            }
                                            {values.userType.toLowerCase() !== 'officeadministrator' && values.userType.toLowerCase() !== 'officeuser' && values.sms &&
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="smstext" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">SMS Text</Form.Label>
                                                <Form.Control as="textarea" rows="3" 
                                                    size="sm"
                                                    placeholder="SMS text" 
                                                    name="smstext"
                                                    value={values.smstext}
                                                    onChange={handleChange} 
                                                />                           
                                                </Form.Group>
                                            </Form.Row>
                                            }                                                                                  
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4}>
                                            <FormGroup className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">Delivery Location Name</Form.Label>
                                                <Form.Row>
                                                    <FormGroup as={Col} xs={10} className="margin-bottom-zero">
                                                        <Autosuggest
                                                        inputProps={{
                                                            placeholder: (values.division === '' ? 'Please select division first.' : 'Search for a location.'),
                                                            readOnly: (values.division !== '' && values.jobType !== 'Pickup Carton' && values.jobType !== 'Pickup Pallet' && values.jobType !== 'Credo Pickup') ? false : true,
                                                            name: 'location',
                                                            id: 'location',
                                                            autoComplete: 'abcd',
                                                            value: this.state.location,
                                                            className: touched.location && errors.location ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm',
                                                            onChange: (_event, {newValue}) => {
                                                                this.setLocation(newValue);
                                                                if(newValue === '') {
                                                                    this.setLocation('');
                                                                    this.setLocationItem(null);                                                            
                                                                    setFieldValue('location', '');
                                                                    setFieldValue('street1', '');
                                                                    setFieldValue('street2', '');
                                                                    setFieldValue('city', '');
                                                                    setFieldValue('suburb', '');
                                                                    setFieldValue('zip', '');
                                                                }
                                                                //setFieldValue('location', newValue);
                                                            },
                                                            onBlur: (_event, {newValue}) => {
                                                                console.log('onBlur', this.state.location);
                                                                console.log('onblurFormik', values.location);
                                                                if(this.state.location.trim().toLowerCase() !== values.location.trim().toLowerCase())
                                                                {
                                                                    this.setLocation('');
                                                                    this.setLocationItem(null);
                                                                    setFieldValue('location', '');
                                                                    // setFieldValue('division', '');
                                                                    setFieldValue('street1', '');
                                                                    setFieldValue('street2', '');
                                                                    setFieldValue('city', '');
                                                                    setFieldValue('suburb', '');
                                                                    setFieldValue('zip', '');
                                                                }
                                                            }
                                                        }}
                                                        suggestions={this.state.suggestions}
                                                        onSuggestionsFetchRequested={async ({value}) => {
                                                            if(!value){
                                                                this.setSuggestions([]);                                                                
                                                                return;
                                                            }
                                                            try{
                                                                const inputValue = value.trim().toLowerCase();
                                                                //console.log('locations', this.state.locations);
                                                                console.log('inputValue', inputValue);
                                                                if(values.client != null && values.client !== '' && values.division != null && values.division !== '')
                                                                {
                                                                    //const divisions = this.state.divisions.filter(d => d.ParentItemId === clientId);
                                                                    const authHeader = await authorizationHeader();
                                                                    toast.dismiss();
                                                                    var toastId = toast.loading('Fetching Locations...');
                                                                    let divisionId = this.state.divisions.filter(d => d.ParentItemId === values.client && d.Name === values.division)[0].ItemId;
                                                                    let res = await API.get('api', '/locations?pageSize=50&page=1&ParentItemId=' + divisionId + '&Sort=modified~desc&Condition=SearchField~contains~' + inputValue, authHeader);
                                                                    if(res && res.locations && res.locations.length > 0) {
                                                                        toast.dismiss();
                                                                        this.setState({
                                                                            locations: res.locations
                                                                        }, () => {
                                                                            //console.log('filtered', filtered);
                                                                            this.setSuggestions(
                                                                                res.locations.map(row => ({
                                                                                    name: row.Name,
                                                                                    id: row.ItemId,
                                                                                    division: row.Division,
                                                                                    divisionId: row.ParentItemId,
                                                                                    operatorId: row.OperatorId,
                                                                                    // billingClientId: row.BillingClientId,
                                                                                    // address: row.Address,
                                                                                    street1: row.Street1,
                                                                                    street2: row.Street2,
                                                                                    // city: row.City,
                                                                                    state: row.State,
                                                                                    suburb: row.Suburb,
                                                                                    zip: row.Zip
                                                                                }))                                                
                                                                            );
                                                                        });
                                                                    }  
                                                                    else {
                                                                        toast.dismiss();
                                                                        toast.error("No locations found", {
                                                                            duration: 5000
                                                                        });
                                                                        this.setSuggestions([]);
                                                                    }                                                                              
                                                                }                                                                               
                                                            }catch(e){
                                                                this.setSuggestions([]);                                                                
                                                            }
                                                        }}
                                                        onSuggestionSelected={(event, {suggestion, method}) => {
                                                            if (method === 'enter') {
                                                                event.preventDefault();
                                                            }
                                                            this.setLocation(suggestion.name);
                                                            this.setLocationItem(suggestion);
                                                            setFieldValue('location', suggestion.name);
                                                            // if(suggestion.billingClientId)
                                                            //     setFieldValue('billingClient', suggestion.billingClientId);
                                                            // setFieldValue('division', suggestion.division);
                                                            setFieldValue('street1', suggestion.street1);
                                                            setFieldValue('street2', suggestion.street2);
                                                            setFieldValue('city', suggestion.city);
                                                            setFieldValue('suburb', suggestion.suburb);
                                                            setFieldValue('zip', suggestion.zip);
                                                            if(values.pickuplocation === '') {
                                                                let staffid = this.getFilteredStaff(suggestion.zip);
                                                                setFieldValue('staff', staffid);
                                                            }
                                                        }}        
                                                        onSuggestionsClearRequested={() => {
                                                            this.setSuggestions([]);                                                            
                                                        }}
                                                        shouldRenderSuggestions={(value, reason) => {
                                                            return value.trim().length >= 4;
                                                        }}
                                                        getSuggestionValue={suggestion => suggestion.name}
                                                        renderSuggestion={(suggestion) => this.renderSuggestions(suggestion)}
                                                        // renderSuggestion={suggestion => <div><div>{suggestion.name}</div><div className='small-font'><span className='bold-font'>Address: </span>{suggestion.street1}</div></div>}
                                                    /> 
                                                    {errors.location && <div className="invalid-feedback-persist">{errors.location}</div>}
                                                    </FormGroup>                                                    
                                                    <FormGroup as={Col} className="margin-bottom-zero">
                                                        {
                                                            (values.jobType !== 'Pickup Carton' && values.jobType !== 'Pickup Pallet' && values.jobType !== 'Credo Pickup') &&
                                                            <CreateNewLocationModal selectedClient={values.client} selectedDivision={values.division} callBackFunctions={{setFieldValue:setFieldValue, setLocation:this.setLocation, setLocationItem:this.setLocationItem, setCoordinates:this.setCoordinates}} />
                                                        }
                                                    </FormGroup>
                                                </Form.Row>
                                            </FormGroup>                                             
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="street1" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Address 1</Form.Label>
                                                    <Form.Control
                                                        readOnly
                                                        size="sm"
                                                        type="text"
                                                        placeholder="Address 1"
                                                        name="street1"
                                                        value={values.street1}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.street1 && touched.street1}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street1}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="street2" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Address 2</Form.Label>
                                                    <Form.Control
                                                        readOnly
                                                        size="sm"
                                                        type="text"
                                                        placeholder="Address 2"
                                                        name="street2"
                                                        value={values.street2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.street2 && touched.street2}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street2}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="state" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Suburb</Form.Label>
                                                    <Form.Control 
                                                    readOnly
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Suburb"
                                                    name="suburb"
                                                    value={values.suburb}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.suburb && touched.suburb}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.suburb}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="zip" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                                                    <Form.Control 
                                                    readOnly
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Postcode"
                                                    name="zip"
                                                    value={values.zip}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.zip && touched.zip}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.zip}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="locationMobileNumber" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Location Mobile Number</Form.Label>
                                                    <InputGroup className="mb-2">
                                                        <InputGroup.Text>+61</InputGroup.Text>
                                                        <Form.Control
                                                            size="sm"
                                                            type="text"
                                                            name="locationMobileNumber"
                                                            value={values.locationMobileNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.locationMobileNumber && touched.locationMobileNumber}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.locationMobileNumber}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>                                                    
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="box" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">{values.jobType.toLowerCase().includes('carton') ? 'Number of cartons' : 'Number of pallets'}</Form.Label>
                                                    {
                                                        (values.jobType !== 'Pickup Carton' && values.jobType !== 'Pickup Pallet' && values.jobType !== 'Credo Pickup') ? <React.Fragment>
                                                            <Form.Control as="select"
                                                            size="sm"
                                                            value={values.box}
                                                            placeholder={values.jobType.toLowerCase().includes('carton') ? 'Number of pickup cartons' : 'Number of pickup pallets'}
                                                            name="box"
                                                            onChange={(e) => {
                                                                setFieldValue('box', e.target.value);
                                                                handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            touched={touched.box}
                                                            isInvalid={errors.box && touched.box}
                                                        >
                                                            <option key="1" value="1">1</option>
                                                            <option key="2" value="2">2</option>
                                                            <option key="3" value="3">3</option>
                                                            <option key="4" value="4">4</option>
                                                            <option key="5" value="5">5</option>
                                                            <option key="6" value="6">6</option>
                                                            <option key="7" value="7">7</option>
                                                            <option key="8" value="8">8</option>
                                                            <option key="9" value="9">9</option>
                                                            <option key="10" value="10">10</option>
                                                            <option key="11" value="11">11</option>
                                                            <option key="12" value="12">12</option>
                                                            <option key="13" value="13">13</option>
                                                            <option key="14" value="14">14</option>
                                                            <option key="15" value="15">15</option>
                                                            <option key="16" value="16">16</option>
                                                            <option key="17" value="17">17</option>
                                                            <option key="18" value="18">18</option>
                                                            <option key="19" value="19">19</option>
                                                            <option key="20" value="20">20</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.box}
                                                        </Form.Control.Feedback>
                                                        </React.Fragment> :
                                                        <React.Fragment>
                                                            <Form.Control 
                                                                readOnly={true}
                                                                size="sm"
                                                                type="text"
                                                                value={0}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                />
                                                        </React.Fragment>
                                                    }                                                    
                                                </Form.Group>
                                            </Form.Row>
                                            {values.userType.toLowerCase() !== 'driver' && values.userType.toLowerCase() !== 'officeuser' &&
                                            <React.Fragment>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.priority}
                                                        type="switch"
                                                        id="priority"
                                                        label="Mark as Urgent"
                                                        value={values.priority}
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.interstateDeliverySwitch}
                                                        type="switch"
                                                        id="interstateDeliverySwitch"
                                                        label="Cubic size"
                                                        value={values.interstateDeliverySwitch}
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                            </Form.Row>
                                            {values.interstateDeliverySwitch === true &&
                                            <Form.Row>
                                            <Form.Group as={Col} controlId="length" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">Length</Form.Label>
                                                <InputGroup className="mb-2">                                                    
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        placeholder=""
                                                        name="length"
                                                        value={values.length}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.length && touched.length}
                                                        />
                                                    <InputGroup.Text>cm</InputGroup.Text>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.length}
                                                    </Form.Control.Feedback>
                                                </InputGroup>                                                  
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="width" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">Width</Form.Label>
                                                <InputGroup className="mb-2">                                                    
                                                    <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    placeholder=""
                                                    name="width"
                                                    value={values.width}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.width && touched.width}
                                                    />
                                                    <InputGroup.Text>cm</InputGroup.Text>
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.width}
                                                    </Form.Control.Feedback>
                                                </InputGroup>                                                  
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="height" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">Height</Form.Label>
                                                <InputGroup className="mb-2">                                                    
                                                    <Form.Control                                                
                                                    size="sm"
                                                    type="text"
                                                    placeholder=""
                                                    name="height"
                                                    value={values.height}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.height && touched.height}
                                                    />
                                                    <InputGroup.Text>cm</InputGroup.Text>
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.height}
                                                    </Form.Control.Feedback>
                                                </InputGroup>                                                  
                                            </Form.Group>
                                            </Form.Row>}
                                            </React.Fragment>} 
                                            <Form.Row>
                                                {values.userType.toLowerCase() !== 'officeadministrator' && values.userType.toLowerCase() !== 'officeuser' &&
                                                <Form.Group as={Col} xs={values.recurring ? 2 : 12} className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.recurring}
                                                        type="switch"
                                                        id="recurring"
                                                        label="Recurring"
                                                        value={values.recurring}
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                                }
                                            </Form.Row>
                                            <Form.Row>
                                                {values.recurring === true &&
                                                <React.Fragment>
                                                <Form.Group as={Col} className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.recurringType === 'daily' ? true : false}
                                                        type="radio"
                                                        id="daily"
                                                        label="Daily"
                                                        name="recurringType"
                                                        value="daily"
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                                <Form.Group as={Col} className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.recurringType === 'weekly' ? true : false}
                                                        type="radio"
                                                        id="weekly"
                                                        label="Weekly"
                                                        name="recurringType"
                                                        value="weekly"
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                                </React.Fragment>
                                                }
                                            </Form.Row>
                                            <Form.Row>
                                                {values.recurring === true && values.recurringType === 'daily' && 
                                                <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                                    <Form.Check
                                                    defaultChecked={values.includeWeekends}
                                                    className="med-font margin-bottom-zero"
                                                    id="includeWeekends"
                                                    label="Include Weekends"
                                                    name="includeWeekends"
                                                    value={values.includeWeekends}
                                                    onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                }
                                            </Form.Row>
                                            <Form.Row>
                                                {values.recurring === true && values.recurringType === 'weekly' && 
                                                <Form.Group as={Col} xs={12}>
                                                    <Form.Row>
                                                        <Form.Group as={Col} className="margin-bottom-8">
                                                            <Form.Check
                                                            defaultChecked={values.sunday}
                                                            className="med-font margin-bottom-zero"
                                                            id="sunday"
                                                            label="Sunday"
                                                            name="sunday"
                                                            value={values.sunday}
                                                            onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} className="margin-bottom-8">
                                                            <Form.Check
                                                            defaultChecked={values.monday}
                                                            className="med-font margin-bottom-zero"
                                                            id="monday"
                                                            label="Monday"
                                                            name="monday"
                                                            value={values.monday}
                                                            onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.tuesday}
                                                        className="med-font margin-bottom-zero"
                                                        id="tuesday"
                                                        label="Tuesday"
                                                        name="tuesday"
                                                        value={values.tuesday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.wednesday}
                                                        className="med-font margin-bottom-zero"
                                                        id="wednesday"
                                                        label="Wednesday"
                                                        name="wednesday"
                                                        value={values.wednesday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.thursday}
                                                        className="med-font margin-bottom-zero"
                                                        id="thursday"
                                                        label="Thursday"
                                                        name="thursday"
                                                        value={values.thursday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.friday}
                                                        className="med-font margin-bottom-zero"
                                                        id="friday"
                                                        label="Friday"
                                                        name="friday"
                                                        value={values.friday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.saturday}
                                                        className="med-font margin-bottom-zero"
                                                        id="saturday"
                                                        label="Saturday"
                                                        name="saturday"
                                                        value={values.saturday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    </Form.Row>                                                    
                                                </Form.Group>
                                                }
                                            </Form.Row>
                                            <Form.Row>                                                 
                                                {values.recurring === true && 
                                                <Form.Group as={Col} xs={8} controlId="expires" className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        type="switch"
                                                        id="expirationSwitch"
                                                        label="Expires"
                                                        value={values.expirationSwitch}
                                                        defaultChecked={values.expirationSwitch}
                                                        onChange={handleChange}
                                                    />
                                                    {values.expirationSwitch &&
                                                    <DatePicker dateFormat="dd/MM/yyyy" readOnly={!values.expirationSwitch} selected={Date.parse(values.expirationDate)} onChange={value => setFieldValue('expirationDate', value)} className={values.expirationSwitch ? 'form-control form-control-sm' : 'form-control form-control-sm gray'} placeholderText='Select expiration date'/>
                                                    }
                                                    {!values.expirationSwitch &&
                                                    <Form.Control disabled={true} size="sm" type="text" placeholder="Never" name="dummyText"/>
                                                    }
                                                </Form.Group> 
                                                }                                 
                                            </Form.Row>  
                                            {values.userType.toLowerCase() !== 'officeadministrator' && values.userType.toLowerCase() !== 'officeuser' &&
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="pointToPoint" className="margin-bottom-8">
                                                    <Form.Check 
                                                    className="med-font margin-bottom-zero"
                                                    defaultChecked={values.pointToPoint}
                                                    type="switch"
                                                    id="pointToPoint"
                                                    label="Point To Point"
                                                    value={values.pointToPoint}
                                                    onChange={handleChange}
                                                    />
                                                    {values.pointToPoint === true &&  
                                                    <div>
                                                    <Form.Label className="med-font margin-bottom-zero">Rate Override</Form.Label>    
                                                    <Form.Control 
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Rate Override"
                                                    name="rateOverride"
                                                    value={values.rateOverride}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.rateOverride && touched.rateOverride}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.rateOverride}
                                                    </Form.Control.Feedback>
                                                    </div>}                                                                  
                                                </Form.Group>                                    
                                            </Form.Row>
                                            }
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="por" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">PoR</Form.Label>
                                                    {/* <Form.Control as="select" */}
                                                    <Form.Control                                                        
                                                        size="sm"
                                                        type="text"
                                                        value={values.por}
                                                        name="por"
                                                        placeholder="PoR"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.por}
                                                        touched={touched.por}
                                                        isInvalid={errors.por && touched.por}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.por}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="clientcode" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Client Code</Form.Label>
                                                    {/* <Form.Control as="select" */}
                                                    <Form.Control                                                        
                                                        size="sm"
                                                        type="text"
                                                        value={values.clientcode}
                                                        name="clientcode"
                                                        placeholder="Client Code"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.clientcode}
                                                        touched={touched.clientcode}
                                                        isInvalid={errors.clientcode && touched.clientcode}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.clientcode}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                             
                                        </Form.Group>
                                    </Form.Row> 
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} controlId="referencenums" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Reference Numbers</Form.Label>
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="Reference Numbers" 
                                                name="referencenums"
                                                value={values.referencenums}
                                                onChange={handleChange} 
                                                onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} controlId="description" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Notes</Form.Label>
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="Notes" 
                                                name="notes"
                                                value={values.notes}
                                                onChange={handleChange} 
                                            />
                                        </Form.Group>
                                    </Form.Row>      
                                   { isPODStatus(values.status) &&  <Form.Row>
                                        <Form.Group as={Col} xs={12} controlId="podnotes" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">POD Notes</Form.Label>
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="PODNotes" 
                                                name="podnotes"
                                                value={values.podnotes}
                                                onChange={handleChange} 
                                            />
                                        </Form.Group>
                                    </Form.Row>   }                                                                
                                    <ModalWrapper
                                        show={this.state.show}
                                        size="sm"
                                        headercolor="lightgray"
                                        onHide={() => this.setShow(false)}
                                        modaltitle="Saved"
                                        type="message"
                                    />
                                {/* </Form> */}
                                </Card.Body>
                                <Card.Footer className="justify-content-end">
                                    <Form.Row>                                
                                        <span className="d-inline-block">
                                        <Button variant="primary" type="submit" disabled={isSubmitting} size="sm">
                                        {isSubmitting ? 'Loading…' : 'Create'}
                                        </Button>
                                        </span>
                                        &nbsp;&nbsp;
                                                <span className="d-inline-block">
                                                    <Button as={Link} to="/jobs" variant="primary" size="sm">
                                                    Cancel
                                                    </Button>
                                                </span>
                                    </Form.Row>
                                </Card.Footer>
                                                
                            </Card>
                        {/* )
                    }}
                </UserConsumer>                 */}
                </Form>              
            </Container>
            </React.Fragment>
            )}
            </Formik>
            )
        }}
    </UserConsumer> 
        );
    }
    }
}
