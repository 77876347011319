import React, { useState } from 'react';
import {Button} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import "moment-timezone";
import { Auth,API } from "aws-amplify";
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import {authorizationHeader} from './Helper';
import Download from "./Download";

function ExportButton(props) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState([]);
    const process = async () => {
        if(props.isInvoice != undefined && props.isInvoice === true)
            await fetchInvoices();
        else
            await fetchJobs();
    }
    const fetchInvoices = async() => {
        try{
            setIsProcessing(true);
            const authHeader = await authorizationHeader();
            const {sortName, sortOrder, searchKeyword, selectedDivision, selectedBillingClientId, invoiceDate} = props;
            
            let filters = '';
            if(selectedDivision == 'all')
            {
                const invoiceDateArray = invoiceDate.split('|');
                filters = '?InvoiceDateBetween='+invoiceDateArray[0] +'|'+invoiceDateArray[1] + '&DivisionId=' + selectedDivision+ '&BillingClientId='+selectedBillingClientId;
            }
            else
                filters = '?InvoiceDate=' + invoiceDate + '&DivisionId=' + selectedDivision + '&BillingClientId='+selectedBillingClientId;
            if(sortName !== '' && sortOrder !== '')
                filters = filters + '&Sort=' + sortName + '~' + sortOrder;
            if(searchKeyword !== '')
                filters = filters + '&Condition=SearchField~contains~' + searchKeyword.trim().toLowerCase();
            // const invoices = await API.get("api", "/invoices" + filters, authHeader);
            // await download(invoices.invoices, true);  
            const invoices = await API.get("api", "/invoices/download" + filters, authHeader);
            if(invoices !== null && invoices["result"] === "success")
                setDownloadUrl(invoices['presignedurl']);
        }catch(error) {
            console.error(error);
        }
        setIsProcessing(false); 
    }
    const fetchJobs = async () => {
        try{
            setIsProcessing(true);
            let filters = '';
            let isFilteredByStatus = false;
            const authHeader = await authorizationHeader();
            const {sortName, sortOrder, searchKeyword, fromDate, toDate, status, selectedStatusGroup, selectedClient, selectedDivision, staffs, selectedStates} = props;
            const from = moment(fromDate).format('YYYY-MM-DD');
            const to = moment(toDate).format('YYYY-MM-DD') + ' 23:59:59';
            filters = filters + '?Where=DeliveryDate~between~' + from + '|' + to;
            if(sortName !== '' && sortOrder !== '')
            {
                filters = filters + '&Sort=' + sortName + '~' + sortOrder;
            }
            if(selectedClient !== '')
            {
                filters = filters + '&BillingClientId=' + selectedClient;
            }
            if(selectedDivision !== '')
            {
                filters = filters + '&ParentItemId=' + selectedDivision;
            }
            if(status !== '')
            {
                isFilteredByStatus = true;
                filters = filters + '&StatusId=' + status;
            }
            if(selectedStatusGroup > 1)
            {
                filters = filters + '&StatusGroup=' + selectedStatusGroup;
            }
            if(searchKeyword !== '')
            {
                filters = filters + '&Condition=SearchField~contains~' + handleDriverNamesWithExtraSpace(searchKeyword.trim().toLowerCase(), staffs);
            }
            if(selectedStates && selectedStates.length > 0)
            {
                filters = filters + '&Filter=State~in~' + selectedStates;
            }
            filters = filters + '&IncludeUnallocated=true';
            const res = await API.get("api", "/jobs" + filters, authHeader);
            await download(res.jobs);
        }catch(error) {
            console.error(error);
        }
        setIsProcessing(false);
    }

    const download = async (jobs, isInvoice) => {
        let workSheet;
        let row;
        let fileName = 'Jobs.csv';
        const workBook = new ExcelJS.Workbook();
        workSheet = workBook.addWorksheet('Jobs');
        if(isInvoice !== undefined && isInvoice === true)
        {
            fileName = 'InvoiceJobs.csv';
            workSheet.columns = [
                { header: 'Delivery Date', key: 'deliveryDate', width: 15 },
                { header: 'Consignment Number', key: 'consignmentNumber', width: 10},
                { header: 'Location Name', key: 'locationName', width: 10},
                { header: 'Delivery Address', key: 'deliveryAddress', width: 10},
                { header: 'Postcode', key: 'postcode', width: 10},
                { header: 'Division', key: 'division', width: 10},
                { header: 'Rate', key: 'rate', width: 10},
                { header: 'Box Count', key: 'boxCount', width: 10},
                { header: 'Additional Delivery Charge', key: 'additionalDeliveryCharge', width: 10},
                { header: 'Total Charge', key: 'totalCharge', width: 10},
            ];
        }else{
            workSheet.columns = [
                { header: 'Delivery Date', key: 'deliveryDate', width: 15 },
                { header: 'Consignment Number', key: 'consignmentNumber', width: 10},
                { header: 'Job Type', key: 'jobType', width: 10},
                { header: 'Client', key: 'billingClient', width: 10},
                { header: 'Delivery Client', key: 'locationName', width: 10},
                { header: 'Delivery Address', key: 'deliveryAddress', width: 10},
                { header: 'Postcode', key: 'postcode', width: 10},
                { header: 'Suburb', key: 'suburb', width: 10},
                { header: 'ETA/Delivered Date', key: 'eta', width: 10},
                { header: 'Notes', key: 'notes', width: 10},
                { header: 'Division', key: 'division', width: 10},
                { header: 'Cartons', key: 'cartons', width: 10},
                { header: 'Cubic Capacity Metres Cubed', key: 'cubicCapacityMetresCubed', width: 10},
                { header: 'Driver', key: 'driver', width: 10},
                { header: 'Status', key: 'status', width: 10},
                { header: 'Reference Number', key: 'status', width: 10},
            ];
        }
        row = workSheet.lastRow;
        row.eachCell(function(cell, colNumber) {            
            cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thick'},right: {style:'thin'}};
            cell.font = {bold: true};
        });
        jobs.forEach((j,index) => {
            if(isInvoice !== undefined && isInvoice === true)
            {
                workSheet.addRow(
                    [
                        moment(j.DeliveryDate).format("DD-MMM-YY"),
                        j.ConsignmentNumber,
                        j.Name,
                        addressFormatter(j),
                        j.Postcode,
                        j.Division,
                        amountFormatter(j),
                        j.BoxCount,
                        additionalDeliveryChargeFormatter(j),
                        j.TotalCharged
                    ]
                );
            }else{
                workSheet.addRow(
                    [
                        moment(j.DeliveryDate).format("DD-MMM-YY"),
                        j.ConsignmentNumber,
                        j.JobType,
                        j.BillingClient,
                        j.Name,
                        addressFormatter(j),
                        j.Zip,
                        j.Suburb,
                        etaFormatter(j),
                        j.Notes,
                        j.Division,
                        j.Cartons,
                        j.CubicCapacityMetresCubed,
                        j.DriverName,
                        j.Status,
                        j.ReferenceNumbers
                    ]
                );
            }            
            row = workSheet.lastRow;
            row.eachCell(function(cell, colNumber) {
                cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
            });
        });
        const buf = await workBook.csv.writeBuffer()
        saveAs(new Blob([buf]), fileName);
    }
    const handleDriverNamesWithExtraSpace = (searchString, staffs) => {
        try{
            if(searchString.indexOf(' ') > -1){
                const firstName = searchString.substring(0, searchString.indexOf(' ')).trim().toLowerCase();
                const lastName = searchString.substring(searchString.indexOf(' ')).trim().toLowerCase();
                if(staffs !== undefined && staffs.length > 0){
                    const matchedDriver = staffs.filter(i => i.FirstName.trim().toLowerCase() === firstName && i.LastName.trim().toLowerCase() === lastName);
                    if(matchedDriver !== undefined && matchedDriver.length > 0)
                        searchString = matchedDriver[0].FullName.trim().toLowerCase();
                }
            }
            return searchString;
        }catch(err) {
            console.log('error', err);
            return searchString;
        }
    }
    const addressFormatter = (job) => {
        let address = '';
        if(job.Street1 && job.Street1 !== '')
            address = job.Street1;
        if(job.Street2 && job.Street2 !== '' && (job.Street1 !== null ? job.Street1.trim().toLowerCase() : '') !== job.Street2.trim().toLowerCase())
            address = (address + ' ' + job.Street2).trim();
        if(job.Suburb && job.Suburb !== '')
            address = (address + ' ' + job.Suburb).trim();
        if(job.Zip && job.Zip !== '')
            address = (address + ', ' + job.Zip).trim();
        if(job.Postcode && job.Postcode !== '')
            address = (address + ', ' + job.Postcode).trim();
        if(address.startsWith(','))
            address = address.substring(1).trim();
        return address;
    }
    const etaFormatter = (job) => {
        let time = '';
        if(job.StatusGroup === '3')
            time = moment(job.ETA).format('LT').toLowerCase() === 'invalid date' ? '' : moment(job.ETA).format('LT');
        if(job.StatusGroup === '5')
            time = moment(job.DeliveredTime).local().format('LT').toLowerCase() === 'invalid date' ? '' : moment(job.DeliveredTime).local().format('DD-MMM-YY LT');
        return time;
    }
    const amountFormatter = (job) => {
        if(job.NoRate === false && job.NoRateCard === false)
            return job.Rate;
        else
            return 'no rate';
    }
    const additionalDeliveryChargeFormatter = (job) => {
        //console.log('row',row);
        if(job.NoDeliveryCharge === false && job.NoUploadedDeliveryCharge === false)
            return job.AdditionalDeliveryCharge;
        else
            return 'no delivery charge';
    }
    return(
        <>
        <Button variant="success" size="sm" className="mr-sm-1" onClick={() => {process();}} disabled={isProcessing || (props.staffs !== undefined && props.staffs.length === 0) || (props.disabled !== undefined && props.disabled)}>
            <i className="fas fa-file-export" style={{ fontSize: '1em' }} /> {isProcessing === true ? 'Exporting..' : 'Export To CSV'}
        </Button> 
        <Download url={downloadUrl} />
        </>
    )
}

export default ExportButton