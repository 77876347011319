import React, {  useState, useEffect  } from 'react';
import {Container, Row, Col, Form, Button, Modal, Alert} from 'react-bootstrap';
import { Auth, API } from "aws-amplify";
import config from "../../config";
import moment from 'moment';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import Download from "./Download";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {authorizationHeader} from './Helper';

function ManageAttachments(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [downloadUrl, setDownloadUrl] = useState([]);
    const [blurr, setBlurr] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [disableDelete, setDisableDelete] = useState(true);
    let websocketConnection = undefined;

    useEffect(() => {
        websocketConnection = new WebSocket(config.ws.url);
        websocketConnection.onmessage = evt => { 
            if(props.jobId === evt.data)
                fetchAttachments();
        };
        return () => { 
            try{
                websocketConnection.close(); 
            }catch(error){
                console.log(error);
            }
        }
    },[])

    useEffect(() => {
        if(show)
            fetchAttachments();
        else
            setAttachments([]);
    },[show])

    useEffect(() => {
        setDisableDelete(selectedFiles.length > 0 ? false : true);
    },[selectedFiles])

    const fetchAttachments = async () => {
        setIsProcessing(true);
        setShow(true);
        setError('');
        try{         
            const requestHeader = await authorizationHeader();
            const res = await API.get("api", "/files?Sort=Created~desc&ParentItemId=" + props.jobId, requestHeader);
            const files = res.files.map( async (file, index) => {
                try{
                    const presignedRequest = await API.get('api', '/uploads?type=download&File=' + file.Name + '&folder=' + props.jobId, requestHeader);
                    const base64String = await axios.get(presignedRequest.url, { responseType: 'arraybuffer'}).then(response => new Buffer(response.data, 'binary').toString('base64'));
                    return (
                        {
                            fileName: file.Name,
                            url: 'data:image/jpeg;base64,' + base64String
                        }
                    )
                }catch(err) {
                    console.log('err', err);
                    if(err.toString().toLowerCase().indexOf('network error'))
                        setError('Network error. Please check internet connection then refresh the page.');
                    else
                        setError('Unexpected error encountered.');
                }
            });
            
            const promisedFiles = await Promise.all(files);
            const derivedFiles = res.files.map(f => {
                return({
                    ItemId:f.ItemId,
                    ParentItemId:f.ParentItemId,
                    Created:f.Created,
                    Name:f.Name,
                    Description:f.Description,
                    Url:promisedFiles.filter(p => p.fileName === f.Name)[0].url
                })
            });
            setAttachments(derivedFiles);
        }catch(error) {
            if(error.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }  
        setIsProcessing(false);      
    }

    const fileDisplay = (cell, row) => {
        if(cell.toLowerCase().endsWith('.jpeg') || cell.toLowerCase().endsWith('.jpg') || cell.toLowerCase().endsWith('.png'))
            return <span className="medium-font"><img className="mr-sm-1" height='150px' width='250px' src={row.Url}></img><a href='' onClick={(e) => download(row.Name, e)}>{cell}</a></span>;
        else
            return <span className="medium-font"><a href='' onClick={(e) => download(row.Name, e)}>{cell}</a></span>;
    }

    const createdDateFormat = (cell) => {
        return <span className="medium-font">{moment(cell).format('LLL')}</span>;
    }

    const valueFormatter = (cell) => {
        return <span className="medium-font">{(cell && cell !== 'undefined') ? cell : ''}</span>;
    }

    const onRowSelect = (row, isSelected, e) => {
        let records = []
        records = selectedFiles.map(f => {
            return f
        });
        const index = records.findIndex(x => x.ItemId === row.ItemId);
        if(isSelected === true)
        {
            //console.log('selectedFiles',selectedFiles);
            if(index < 0)          
                records.push(row);
        }
        else
        {            
            if(index > -1)
                records.splice(index, 1);
        }
        //this.setState({selectedJobs: records});
        setSelectedFiles(records);
    }
    
    const onSelectAll = (isSelected, rows) => {
        //alert(`is select all: ${isSelected}`);
        const records = [];
        if (isSelected) {
            //alert('Current display and selected data: ');
            for (let i = 0; i < rows.length; i++) {
                records.push(rows[i]);
            }
        }
        setSelectedFiles(records);
        //this.setState({selectedJobs: records});
        // for (let i = 0; i < rows.length; i++) {
        //     alert(rows[i].id);
        // }
    }

    const selectRowProp = {
        mode: 'checkbox',
        columnWidth: '38px',
        onSelect: onRowSelect,
        onSelectAll: onSelectAll
    };

    const download = async (file,e) => {
        e.preventDefault()
        const res = await API.get('api', '/uploads?type=download&File=' + file + '&folder=' + props.jobId, await authorizationHeader());
        if(res['result'] === 'success')
            setDownloadUrl(res['url']);
    }

    const setNoDataText = () => {
        if(isProcessing === false){
           return "No files found";
        }else{
            return 'Loading...';
        }
    }

    return(
        <div className={props.rightAlign ? 'float-right' : 'inline'}>
            <Button className={`mr-sm-2 ${props.size === 'small' ? 'btn-cust-sm' : 'btn-sm'}`} disabled={isProcessing} variant="success" size="sm" onClick={() => setShow(true)}>
                Manage Attachments                             
            </Button>
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                backdrop= "static"
            >
                <LoadingOverlay
                active={blurr}
                spinner={false}
                text=''
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Manage Attachments
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col className='margin-bottom-8 no-left-right-padding'>
                            <DeleteFiles refresh={fetchAttachments} disabled={isProcessing || error.length > 0 || disableDelete} files={selectedFiles} setSelectedFiles={setSelectedFiles} mainModalBlurr={setBlurr} jobId={props.jobId} />
                            <EmailFiles disabled={isProcessing || error.length > 0 || disableDelete} files={selectedFiles} mainModalBlurr={setBlurr} jobId={props.jobId} />
                            <UploadForm refresh={fetchAttachments} disabled={error.length > 0} mainModalBlurr={setBlurr} jobId={props.jobId} />
                        </Col>                    
                        <LoadingOverlay
                        active={isProcessing}
                        spinner
                        text='Loading...'
                        >
                        <BootstrapTable data={attachments} options={{ noDataText: setNoDataText() }} selectRow={ selectRowProp }>
                            <TableHeaderColumn dataField='ItemId' isKey hidden>ItemId</TableHeaderColumn>
                            <TableHeaderColumn dataField='Created' width='200' dataFormat={createdDateFormat} dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Created</span></TableHeaderColumn>
                            <TableHeaderColumn dataField='Name' dataSort={false} dataFormat={fileDisplay} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Filename</span></TableHeaderColumn>
                            <TableHeaderColumn dataField='Description' width='200' dataFormat={valueFormatter} dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Description</span></TableHeaderColumn>
                        </BootstrapTable>
                        </LoadingOverlay>
                        {error.length > 0 &&
                        <Form.Row>
                            <Form.Group>
                                <Alert variant='danger'>
                                    {error}
                                </Alert>
                            </Form.Group>                                    
                        </Form.Row>
                        }
                    </Modal.Body>
                </LoadingOverlay>
            </Modal>
            <Download url={downloadUrl} />
        </div>
    )
}

function DeleteFiles(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');

    const manageShow = (show) => {
        setShow(show);
        setError('');
        props.mainModalBlurr(show);
    }

    const deleteFiles = async () => {
        try{
            setIsProcessing(true);
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    files: props.files
                    }
            };            
            const res = await API.del("api", "/files", myInit);
            setIsProcessing(false);
            props.setSelectedFiles([]);
            props.refresh();
            manageShow(false);
        }catch(error)
        {
            if(error.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }        
    }

    return(
        <React.Fragment>
            <Button variant="success" size="sm"  className="mr-sm-1" disabled={props.disabled} onClick={() => manageShow(true)}>
                <i className="fa fa-trash" style={{ fontSize: '1em' }} /> Delete
            </Button>
            <Modal
                size="sm"
                show={show}
                onHide={() => manageShow(false)}
                backdrop= "static"
            >
                <Modal.Body>
                    <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                        <Form.Label>Are you sure you want to delete the selected files?</Form.Label>                                
                    </Form.Group>
                    <Form.Group as={Col} xs={12} className="margin-top-10">
                        <Button disabled={(error || isProcessing) ? true : false} className="mr-sm-2" variant="success" size="sm" onClick={() => deleteFiles()}>
                            {isProcessing ? 'Deleting...' : 'Yes'}
                        </Button>
                        <Button disabled={(error || isProcessing) ? true : false} className="mr-sm-2" variant="success" size="sm" onClick={() => manageShow(false)}>
                            No
                        </Button>
                    </Form.Group>
                    {error.length > 0 &&
                    <Form.Row>
                        <Form.Group>
                            <Alert variant='danger'>
                                {error}
                            </Alert>
                        </Form.Group>                                    
                    </Form.Row>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

function EmailFiles(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [recipients, setRecipients] = useState('');
    const [subject, setSubject] = useState('');

    const manageShow = (show) => {
        setShow(show);
        setError('');
        setMessage('');
        setRecipients('');
        setSubject('');
        props.mainModalBlurr(show);
    }

    const emailFiles = async () => {
        try{
            setIsProcessing(true);
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    files: props.files,
                    subject: subject.trim(),
                    recipients: recipients.trim(),
                    message: message.trim()
                    }
            };            
            const res = await API.post("api", "/email", myInit);
            if(res.result === 'failure')
                setError(res.errors);
            else
                manageShow(false);
            setIsProcessing(false);
            //props.refresh();

        }catch(error)
        {
            if(error.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }        
    }

    return(
        <React.Fragment>
            <Button variant="success" size="sm"  className="mr-sm-1" disabled={props.disabled} onClick={() => manageShow(true)}>
                <i className="fas fa-envelope" style={{ fontSize: '1em' }} /> Email
            </Button>
            <Modal
                size="md"
                show={show}
                onHide={() => manageShow(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Email Selected Files
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Col} xs={12}>
                        <Form.Label>Subject</Form.Label>   
                        <Form.Control 
                            size="sm"
                            type="text"
                            placeholder='Subject'
                            onChange={(e) => setSubject(e.target.value)}
                        />                            
                    </Form.Group>
                    <Form.Group as={Col} xs={12}>
                        <Form.Label>Recipients</Form.Label>   
                        <Form.Control 
                            size="sm"
                            type="text"
                            placeholder='separate email addresses using comma'
                            onChange={(e) => setRecipients(e.target.value)}
                        />                            
                    </Form.Group>
                    <Form.Group as={Col} xs={12}>
                        <Form.Label>Email Body</Form.Label>   
                        <Form.Control as="textarea" rows="3" 
                            size="sm"
                            onChange={(e) => setMessage(e.target.value)} 
                        />                             
                    </Form.Group>
                    <Form.Group as={Col} xs={12} className="margin-top-10">
                        <Button disabled={isProcessing ? true : false} className="mr-sm-2" variant="success" size="sm" onClick={() => emailFiles()}>
                            {isProcessing ? 'Sending...' : 'Send Email'}
                        </Button>
                        <Button disabled={isProcessing ? true : false} className="mr-sm-2" variant="success" size="sm" onClick={() => manageShow(false)}>
                            Cancel
                        </Button>
                    </Form.Group>
                    {error.length > 0 &&
                    <Form.Row>
                        <Form.Group>
                            <Alert variant='danger'>
                                {error}
                            </Alert>
                        </Form.Group>                                    
                    </Form.Row>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

function UploadForm(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [description, setDescription] = useState(undefined);

    const manageShow = (show) => {
        setShow(show);
        setError('');
        setIsProcessing(false);
        props.mainModalBlurr(show);
    }

    const onChangeHandler = event => {
        setSelectedFile(event.target.files[0]);
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const upload = async () => {
        try{
            setIsProcessing(true);
            const res = await API.get("api", "/uploads?File=" + selectedFile.name.replace(/[^a-zA-Z0-9._]/g, '') + "&Folder=" + props.jobId + "&Description=" + description, await authorizationHeader());
            const uploadResponse = await axios({ method: 'put', url: res.url, data: selectedFile});
            let isUploaded = false;
            for (let i = 0; i < 4; i++){
                if(isUploaded)
                    break;
                const existingFiles = await API.get("api", "/files?Name=" + selectedFile.name.replace(/[^a-zA-Z0-9._]/g, '') + "&ParentItemId=" + props.jobId, await authorizationHeader());
                if(existingFiles['count'] !== '0')
                    isUploaded = true;
                await sleep(2000);
            }
            if(isUploaded){
                setSelectedFile(undefined);
                setIsProcessing(false);
                setDescription('');
                props.refresh();
                manageShow(false);
            }else{
                setError('Unexpected error encountered. File not uploaded');
                setIsProcessing(false);
            }
        }catch(error){
            if(error.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }
    }

    return(
        <React.Fragment>
            <Button variant="success" size="sm"  className="mr-sm-1" disabled={props.disabled} onClick={() => manageShow(true)}>
                <i className="fas fa-upload" style={{ fontSize: '1em' }} /> New Upload
            </Button>
            <Modal
                size="md"
                show={show}
                onHide={() => manageShow(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Upload File
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                        <input type="file" name="file" disabled={isProcessing} onChange={onChangeHandler}/><br/>                                
                    </Form.Group>
                    <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                        <Form.Label>Description:</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Description"
                            onChange={(e) => setDescription(e.target.value)}
                        />                              
                    </Form.Group>
                    <Form.Group as={Col} xs={12} className="margin-top-10">
                        <Button disabled={(error || isProcessing) ? true : (selectedFile ? false : true)} className="mr-sm-2" variant="success" size="sm" onClick={upload}>
                            <i className="fas fa-upload" style={{ fontSize: '1em' }} /> {isProcessing ? 'Uploading...' : 'Upload'}
                        </Button>
                    </Form.Group>
                    {error.length > 0 &&
                    <Form.Row>
                        <Form.Group>
                            <Alert variant='danger'>
                                {error}
                            </Alert>
                        </Form.Group>                                    
                    </Form.Row>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default ManageAttachments