import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Navbar from './components/Navbar';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import LogIn from './components/auth/LogIn';
import RequireChangePassword from './components/auth/RequireChangePassword';
import ResetPassword from './components/auth/ResetPassword';
import LatestHistory  from './components/noAuth/LatestHistory';
import Operators from './components/Operators';
import PublicDownload from './components/PublicDownload';
import DriverChecklist from './components/DriverChecklist';

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    name: null,
    user: null,
    isOnOperatorsPage: false,
    operator: '',
  }

  setIsOnOperatorsPage = isOnOperatorsPage => {
    this.setState({ isOnOperatorsPage: isOnOperatorsPage });
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setOperator = operator => {
    this.setState({ operator: operator });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  setName = name => {
    this.setState({ name: name });
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.setAuthStatus(true);
      //console.log("group", session.idToken.payload['cognito:groups'] !== undefined ? session.idToken.payload['cognito:groups'][0] : '');
      const user = await Auth.currentAuthenticatedUser();
      const attr = await Auth.userAttributes(user);
      const operator = attr.filter(att => att.Name === 'custom:operatorId').length > 0 ? attr.filter(att => att.Name === 'custom:operatorId')[0].Value : '';
      //console.log("currentAuthenticatedUser", user);
      this.setOperator(operator);
      this.setUser(user);
      this.setName(user.attributes.given_name + ' ' + user.attributes.family_name);
    } catch(error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }
  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      name: this.state.name,
      operator: this.state.operator,
      isOnOperatorsPage: this.state.isOnOperatorsPage,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
      setName: this.setName,
      setOperator: this.setOperator,
      setIsOnOperatorsPage: this.setIsOnOperatorsPage
    }
    return (
      !this.state.isAuthenticating &&
      <div className="App">
        <Router>
          <div>
            <Navbar auth={authProps} />
            <Switch>
              <Route exact path="/" render={(props) => <Home {...props} auth={authProps} />} />
              <Route exact path="/home" render={(props) => <Home {...props} auth={authProps} />} />
              <Route exact path="/home/:subpage" render={(props) => {
                return <Home {...props} auth={authProps} />
              }
              } />
              <Route exact path="/home/:subpage/:anotherpage" render={(props) => {
                return <Home {...props} auth={authProps} />
              }
              } />
              <Route exact path="/home/:subpage/:anotherpage/:id" render={(props) => {
                return <Home {...props} auth={authProps} />
              }
              } />
              <Route exact path="/login" render={(props) => <LogIn {...props} auth={authProps} />} />
              <Route exact path="/history" render={(props) => <LatestHistory {...props} auth={authProps} />} />
              <Route exact path="/reset" render={(props) => <ResetPassword {...props} auth={authProps} />} />
              <Route exact path="/requireChangePassword" render={(props) => <RequireChangePassword {...props} auth={authProps} />} />
              <Route exact path="/operators" render={(props) => <Operators {...props} auth={authProps} />} />
              <Route exact path="/publicDownload" render={(props) => <PublicDownload {...props} auth={authProps} />} />
              <Route exact path="/driverchecklist" render={(props) => <DriverChecklist {...props} auth={authProps}/>}/>
              <Route render={
                (props) => {
                return <Redirect to={{pathname: '/home'}} />
              }
              }/>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }

}
export default App;
