import React, { useState } from 'react';
import {Button, Modal, Row, Form, Col} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import "moment-timezone";
import { API } from "aws-amplify";
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import {authorizationHeader} from './Helper';

function InvoiceSummaryButton(props) {
    const [show, setShow] = useState(false);
    const [invoiceDates, setinvoiceDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const showHide = async(show) => {
        setShow(show);
        if(show === true){
            computeInvoiceDates();
        }else{
            setinvoiceDates([]);
            setSelectedDate('');
        }
    }
    const computeInvoiceDates = () => {
        try{
            var start = moment(new Date()).subtract(1, 'years'),
                end   = moment(new Date()),
                day   = 0;                    // Sunday
            var result = [];
            var current = start.clone();
            while (current.day(7 + day).isBefore(end)) {
                result.push(moment(current.clone()).format('YYYY-MM-DD'));
            }
            setinvoiceDates(result.reverse());
        }catch(error) {
            console.error(error);
        } 
    }
    const getDatesOptions = () => {
        if(invoiceDates.length > 0){
            return invoiceDates.map((date, index) => {
                return (
                    <option key={index} value={date} >{'WE ' + moment(date).format("DD/MM/YYYY")}</option>
                )
            });
        }
    }
    const process = async() => {
        try{
            setIsProcessing(true);
            const authHeader = await authorizationHeader();
            const invoicebatches = await API.get("api", "/invoices/summary?InvoiceDate=" + selectedDate, authHeader);
            await download(invoicebatches.batches, invoicebatches.invoiceDate);  
        }catch(error) {
            console.error(error);
        }
        setIsProcessing(false); 
    }
    const download = async (invoiceBatches, invoiceDate) => {
        let workSheet;
        let row;
        let label;
        let fileName = 'INVOICES SUMMARY.xlsx';
        const workBook = new ExcelJS.Workbook();
        workSheet = workBook.addWorksheet('Summary');
        workSheet.columns = [
            { header: '', key: 'division', width: 25},
            { header: '', key: 'invoicenumber', width: 25},
            { header: '', key: 'jobcount', width: 15},
            { header: '', key: 'status', width: 20},
        ];
        workSheet.mergeCells('A1:D1');
        label = workSheet.getCell('A1');
        label.value = 'WE ' + moment(invoiceDate).format("DD/MM/YYYY") + ' INVOICES SUMMARY';
        label.alignment = { vertical: 'bottom', horizontal: 'center' };
        label.font = {name: 'Calibri',family: 4,size: 14,bold: true};
        workSheet.addRow(
            [
                'CLIENT/DIVISION',
                'INVOICE NUMBER',
                'JOB COUNT',
                'STATUS',
            ]
        );
        row = workSheet.lastRow;
        row.eachCell(function(cell, colNumber) {            
            cell.border = {top: {style:'thick'},left: {style:'thick'},bottom: {style:'thick'},right: {style:'thick'}};
            cell.font = {bold: true};
            cell.alignment = { vertical: 'bottom', horizontal: 'center' };
        });
        if(invoiceBatches.length > 0)
        {
            invoiceBatches.forEach((j,index) => {
                workSheet.addRow(
                    [
                        j.Division,
                        j.Name,
                        j.InvoiceCount,
                        statusFormatter(j),
                    ]
                );          
                row = workSheet.lastRow;
                row.eachCell(function(cell, colNumber) {
                    cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
                });
            });
        }else{
            workSheet.mergeCells('A3:D3');
            label = workSheet.getCell('A3');
            label.value = 'Invoice not yet processed or no jobs to invoice';
            label.alignment = { vertical: 'bottom', horizontal: 'center' };
            label.font = {name: 'Calibri',family: 4,size: 12};
        }
        
        const buf = await workBook.xlsx.writeBuffer()
        saveAs(new Blob([buf]), fileName);
    }
    const statusFormatter = (job) => {
        if(job.ErrorMessage === undefined || job.ErrorMessage === null)
            return 'OK';
        else
            return job.ErrorMessage;
    }
    return(
        <React.Fragment>
            <Button variant="success" size="sm" className="mr-sm-1" onClick={() => {showHide(true);}} disabled={isProcessing}>
                <i className="fas fa-file-export" style={{ fontSize: '1em' }} /> {'Invoices Summary'}
            </Button>
            <Modal
                size="sm"
                show={show}
                onHide={() => showHide(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Invoices Summary
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <Row className="justify-content-md-center top-margin-20">                            
                        <Form.Group as={Col} md={11} controlId="summary">
                            <Form.Label className="bold-font">Select Invoice Date</Form.Label>
                            <Form.Control as="select"
                                size="sm"                                        
                                name="invoiceDate"
                                placeholder="Select Invoice Date"
                                onChange={(e) => setSelectedDate(e.target.value)}
                            >   
                                <option key='-1' value=''>{invoiceDates.length === 0 ? 'loading...' : 'select..'}</option>                                 
                                {getDatesOptions()}
                            </Form.Control>
                        </Form.Group>
                    </Row>   
                    <Form.Row className="justify-content-md-right modal-footer no-border top-margin-20"> 
                        <Button className="mr-sm-1" variant="primary" onClick={() => process()} disabled={isProcessing || selectedDate === ''} size="sm">
                            {isProcessing ? 'Downloading...' : 'Download'}
                        </Button>
                        <Button variant="primary" size="sm" onClick={() => showHide(false)}>Cancel</Button>                    
                    </Form.Row>                     
                </Modal.Body>                    
            </Modal>
        </React.Fragment>
    )
}

export default InvoiceSummaryButton