/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Navbar,
  Tab,
  Row,
  Nav,
  Col,
  Accordion,
  Card,
  ListGroup,
  Button,
  Alert,
} from "react-bootstrap";
import { API } from "aws-amplify";
import { authorizationHeader } from "./utility/Helper";
import LoadingOverlay from "react-loading-overlay";
import { Auth } from "aws-amplify";

// Workaround to fix this bug: https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined;

const DriverChecklist = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("Daily");
  const [dailyTabHidden, setDailyTabHidden] = useState(false);
  const [dailyChecklist, setDailyChecklist] = useState(null);
  const [weeklyChecklist, setWeeklyChecklist] = useState(null);
  const [groupedDailyQuestions, setGroupedDailyQuestions] = useState(null);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [addingWeeklyQuestion, setAddingWeeklyQuestion] = useState(false);
  const [newWeeklyQuestionText, setNewWeeklyQuestionText] = useState("");
  const [addingDailyQuestion, setAddingDailyQuestion] = useState(false);
  const [newDailyQuestionText, setNewDailyQuestionText] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleEditClick = (questionId) => {
    setEditingQuestionId(questionId);
  };

  const editChecklist = async (payload) => {
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: {
        checklists: [payload],
      },
    };
    return await API.patch("api", "/checklists", init);
  };

  const handleQuestionsAddedDailyClick = async (event, stage) => {
    if(newDailyQuestionText !== "") {
      setIsLoading(true);

      const updatedChecklist = {
        ...dailyChecklist,
        Questions: [
          ...dailyChecklist.Questions,
          {
            QuestionText: newDailyQuestionText,
            Stage: stage
          },
        ],
      };
      const response = await editChecklist(updatedChecklist);
      if (response.result === "success") {
        setSuccess("Checklist updated successfully");
        setDailyChecklist(updatedChecklist);
        setNewDailyQuestionText("");
        await fetchChecklists("Daily");
      } else setError("Something went wrong in updating checklist");

    }
    setIsLoading(false);
    setAddingDailyQuestion(false);
  };
  const handleQuestionsAddedWeeklyClick = async () => {
    if (newWeeklyQuestionText !== "") {
      setIsLoading(true);
      const newWeeklyChecklist = {
        ...weeklyChecklist,
        Questions: [
          ...weeklyChecklist.Questions,
          {
            QuestionText: newWeeklyQuestionText,
          },
        ],
      };
      const response = await editChecklist(newWeeklyChecklist);
      if (response.result === "success") {
        setSuccess("Checklist updated successfully");
        setWeeklyChecklist(newWeeklyChecklist);
      } else setError("Something went wrong in updating checklist");
    }
    setIsLoading(false);
    setAddingWeeklyQuestion(false);
    setNewWeeklyQuestionText("");
  };

  const handleDeleteDailyQuestion = async (stage, qIndex) => {
    setIsLoading(true);

    const updatedQuestions = [...groupedDailyQuestions[stage]];
    updatedQuestions.splice(qIndex, 1);

    const updatedGroupedQuestions = {
      ...groupedDailyQuestions,
      [stage]: updatedQuestions,
    };
    setGroupedDailyQuestions(updatedGroupedQuestions);

    const flatQuestions = Object.values(updatedGroupedQuestions).flat();
    const updatedChecklist = dailyChecklist;
    updatedChecklist.Questions = flatQuestions;
    const response = await editChecklist(updatedChecklist);
    if (response.result === "success") {
      setSuccess("Checklist updated successfully");
      setDailyChecklist(updatedChecklist);
    } else setError("Something went wrong in updating checklist");

    setIsLoading(false);
  };

  const handleDeleteWeeklyQuestion = async (qIndex) => {
    setIsLoading(true);
    const updatedChecklist = weeklyChecklist;
    updatedChecklist.Questions.splice(qIndex, 1);
    const response = await editChecklist(updatedChecklist);
    if (response.result === "success") {
      setSuccess("Checklist updated successfully");
      setWeeklyChecklist(updatedChecklist);
    } else setError("Something went wrong in updating checklist");

    setIsLoading(false);
  };

  const handleQuestionsSaveClick = async (isDaily) => {
    setEditingQuestionId(null);
    setIsLoading(true);

    const response = await editChecklist(
      isDaily ? dailyChecklist : weeklyChecklist
    );
    if (response.result === "success")
      setSuccess("Checklist updated successfully");
    else setError("Something went wrong in updating checklist");

    setIsLoading(false);
  };

  const handleCancelClick = () => {
    setEditingQuestionId(null);
    setAddingWeeklyQuestion(false);
    setAddingDailyQuestion(false);
    setNewDailyQuestionText("");
    setNewWeeklyQuestionText("");
  };

  const isQuestionEditing = (qIndex) => {
    return editingQuestionId === qIndex;
  };

  const fetchChecklists = async (type) => {
    try {
      setIsLoading(true);
      const authHeader = await authorizationHeader();
      let filters = "?Type=" + type;
      const response = await API.get(
        "api",
        "/checklists" + filters,
        authHeader
      );
      if (type === "Daily") {
        const res = groupQuestions(response.checklists[0].Questions);
        setGroupedDailyQuestions(res);
        setDailyChecklist(response.checklists[0]);
      } else {
        setWeeklyChecklist(response.checklists[0]);
      }
      setIsLoading(false);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchChecklists(activeTab);
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setEditingQuestionId(null);
    setSuccess(null);
    setError(null);
    setDailyTabHidden(tab === "Weekly");
    fetchChecklists(tab);
  };

  const groupQuestions = (questions) => {
    const groupedData = {};
    questions.forEach((question) => {
      const stage = question.Stage;
      if (!groupedData[stage]) {
        groupedData[stage] = [];
      }
      groupedData[stage].push(question);
    });
    return groupedData;
  };

  const handleWeeklyOnChange = (event, question) => {
    const updatedQuestions = weeklyChecklist.Questions.map((q) => {
      if (q.QuestionText === question.QuestionText) {
        return { ...q, QuestionText: event.target.value };
      }
      return q;
    });

    setWeeklyChecklist({ ...weeklyChecklist, Questions: updatedQuestions });
  };

  const handleDailyOnChange = (event, stage, qIndex) => {
    const updatedQuestions = [...groupedDailyQuestions[stage]];
    updatedQuestions[qIndex].QuestionText = event.target.value;

    const updatedGroupedQuestions = {
      ...groupedDailyQuestions,
      [stage]: updatedQuestions,
    };
    setGroupedDailyQuestions(updatedGroupedQuestions);
  };

  return (
    <React.Fragment>
      <Navbar className="bg-light">
        <Navbar.Brand href="/">
          <h4 className="padding-top-bottom-zero">Edit Checklist</h4>
        </Navbar.Brand>
      </Navbar>
      <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="Daily">Daily</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Weekly">Weekly</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <LoadingOverlay active={isLoading} spinner text="Loading...">
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Tab.Content>
                <Tab.Pane eventKey="Daily">
                  {!dailyTabHidden && (
                    <div>
                      <Accordion>
                        {groupedDailyQuestions &&
                          Object.entries(groupedDailyQuestions).map(
                            ([stage, questions], index) => (
                              <Card key={index}>
                                <Accordion.Toggle
                                  className="accordion-header"
                                  as={Card.Header}
                                  eventKey={index.toString()}
                                >
                                  <span className="accordion-header-text left-align">
                                    {stage}
                                  </span>
                                  <i
                                    className="fa fa-angle-down margin-left-10 right-align"
                                    style={{ fontSize: "1em" }}
                                  />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={index.toString()}>
                                  <Card.Body>
                                    <ListGroup variant="flush">
                                      {questions.map((question, qIndex) => (
                                        <ListGroup.Item
                                          className="left-align"
                                          key={qIndex}
                                        >
                                          <div>
                                            {isQuestionEditing(qIndex) ? (
                                              <textarea
                                                className="checkListTextArea"
                                                value={question.QuestionText}
                                                onChange={(e) =>
                                                  handleDailyOnChange(
                                                    e,
                                                    stage,
                                                    qIndex
                                                  )
                                                }
                                              />
                                            ) : (
                                              <span>
                                                {question.QuestionText}
                                              </span>
                                            )}
                                          </div>
                                          {!isQuestionEditing(qIndex) && (
                                            <Button
                                              variant="link"
                                              onClick={() =>
                                                handleEditClick(qIndex)
                                              }
                                            >
                                              <i
                                                className="fa fa-edit"
                                                style={{ fontSize: "1em" }}
                                              />
                                            </Button>
                                          )}
                                          {isQuestionEditing(qIndex) && (
                                            <div>
                                              <Button
                                                variant="link"
                                                onClick={() =>
                                                  handleQuestionsSaveClick(true)
                                                }
                                              >
                                                <i
                                                  className="fa fa-save"
                                                  style={{
                                                    fontSize: "1.2em",
                                                    color: "#2e8540",
                                                  }}
                                                />
                                              </Button>
                                              <Button
                                                variant="link"
                                                onClick={() =>
                                                  handleCancelClick(qIndex)
                                                }
                                              >
                                                <i
                                                  className="fa fa-times"
                                                  style={{ fontSize: "1.4em" }}
                                                />
                                              </Button>
                                            </div>
                                          )}
                                          <Button
                                            variant="link"
                                            onClick={() =>
                                              handleDeleteDailyQuestion(
                                                stage,
                                                qIndex
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{
                                                fontSize: "1em",
                                                color: "#cd2026",
                                              }}
                                            />
                                          </Button>
                                        </ListGroup.Item>
                                      ))}

                                      {/* New question input - Daily */}
                                      {addingDailyQuestion && (
                                        <div className="margin-top-20 left-align">
                                          <textarea
                                            className="checkListTextArea"
                                            value={newDailyQuestionText}
                                            onChange={(e) =>
                                              setNewDailyQuestionText(
                                                e.target.value
                                              )
                                            }
                                          />

                                          <div>
                                            <Button
                                              variant="link"
                                              onClick={(e) =>
                                                handleQuestionsAddedDailyClick(e, stage)
                                              }
                                            >
                                              <i
                                                className="fa fa-save"
                                                style={{
                                                  fontSize: "1.2em",
                                                  color: "#2e8540",
                                                }}
                                              />
                                            </Button>
                                            <Button
                                              variant="link"
                                              onClick={() =>
                                                handleCancelClick()
                                              }
                                            >
                                              <i
                                                className="fa fa-times"
                                                style={{ fontSize: "1.4em" }}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                      )}

                                      {!addingDailyQuestion && (
                                        <Button
                                          variant="success"
                                          className="margin-top-20 add-button"
                                          onClick={() =>
                                            setAddingDailyQuestion(true)
                                          }
                                        >
                                          Add
                                        </Button>
                                      )}
                                    </ListGroup>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            )
                          )}
                      </Accordion>
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="Weekly">
                  {weeklyChecklist && (
                    <Card>
                      <Card.Body>
                        <ListGroup variant="flush">
                          {weeklyChecklist.Questions.map((question, qIndex) => (
                            <ListGroup.Item
                              className="left-align"
                              key={qIndex}
                            >
                              <div>
                                {isQuestionEditing(qIndex) ? (
                                  <textarea
                                    className="checkListTextArea"
                                    value={question.QuestionText}
                                    onChange={(e) =>
                                      handleWeeklyOnChange(e, question)
                                    }
                                  />
                                ) : (
                                  <span>{question.QuestionText}</span>
                                )}
                              </div>
                              {!isQuestionEditing(qIndex) && (
                                <Button
                                  variant="link"
                                  onClick={() => handleEditClick(qIndex)}
                                >
                                  <i
                                    className="fa fa-edit"
                                    style={{ fontSize: "1em" }}
                                  />
                                </Button>
                              )}
                              {isQuestionEditing(qIndex) && (
                                <div>
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      handleQuestionsSaveClick(false)
                                    }
                                  >
                                    <i
                                      className="fa fa-save"
                                      style={{
                                        fontSize: "1.2em",
                                        color: "#2e8540",
                                      }}
                                    />
                                  </Button>
                                  <Button
                                    variant="link"
                                    onClick={() => handleCancelClick(qIndex)}
                                  >
                                    <i
                                      className="fa fa-times"
                                      style={{ fontSize: "1.4em" }}
                                    />
                                  </Button>
                                </div>
                              )}
                              <Button
                                variant="link"
                                onClick={() =>
                                  handleDeleteWeeklyQuestion(qIndex)
                                }
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{ fontSize: "1em", color: "#cd2026" }}
                                />
                              </Button>
                            </ListGroup.Item>
                          ))}

                          {/* New question input - Weekly */}
                          {addingWeeklyQuestion && (
                            <div className="margin-top-20 left-align">
                              <textarea
                                className="checkListTextArea"
                                value={newWeeklyQuestionText}
                                onChange={(e) =>
                                  setNewWeeklyQuestionText(e.target.value)
                                }
                              />

                              <div>
                                <Button
                                  variant="link"
                                  onClick={() =>
                                    handleQuestionsAddedWeeklyClick()
                                  }
                                >
                                  <i
                                    className="fa fa-save"
                                    style={{
                                      fontSize: "1.2em",
                                      color: "#2e8540",
                                    }}
                                  />
                                </Button>
                                <Button
                                  variant="link"
                                  onClick={() => handleCancelClick()}
                                >
                                  <i
                                    className="fa fa-times"
                                    style={{ fontSize: "1.4em" }}
                                  />
                                </Button>
                              </div>
                            </div>
                          )}
                          {!addingWeeklyQuestion && (
                            <Button
                              variant="success"
                              className="margin-top-20 add-button"
                              onClick={() => setAddingWeeklyQuestion(true)}
                            >
                              Add
                            </Button>
                          )}
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </LoadingOverlay>
          </Col>
        </Row>
      </Tab.Container>
    </React.Fragment>
  );
};

export default DriverChecklist;
