import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Form, Button, Container, Row, Col, FormGroup, Card, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import History from '../History';
import { API } from "aws-amplify";
// import {ManageAttachments} from './utility/ManageAttachments';
// import {PDFButton} from './utility/PDFDocument';
import LoadingOverlay from 'react-loading-overlay';

class LatestHistory extends Component {

    state = {
        history: [],
        job: {},
        dataReady: false,
        isLoading: false,
        error: "",
        consignmentNumber: ""
    };

    componentWillMount() {
        let search = window.location.search;        
        if (search !== undefined && search.length > 0) {
            let params = new URLSearchParams(search);
            let consignmentNumber = params.get('consignmentNumber');                      
            if(consignmentNumber != null) { 
                this.setState({ consignmentNumber: consignmentNumber});                               
            }
            else {
                this.setState({ error: "Invalid query parameter."});
            }
        }
    }

    componentDidMount() {        
        this.setState({ "dataReady": false });
        if(this.state.consignmentNumber.length > 0) { 
            this.fetchHistory(this.state.consignmentNumber);                        
        }
    }

    fetchHistory = async (consignmentNumber) => {
        this.setState({ isLoading: true });
        try {
            const res = await API.get("api", "/history/" + consignmentNumber + "/latest");
            if(res && res.histories && res.job) {
                this.setState({
                    history: res.histories.filter(h => h.Category == "status"),
                    job: res.job,
                    dataReady: true, 
                    isLoading: false, 
                    error: "" 
                });
            }
            else {
                this.setState({ dataReady: false, isLoading: false, error: "Consignment not found."});
            }
        }
        catch (error) {
            this.setState({ dataReady: false, isLoading: false, error: "Unexpected error encountered."});
        }
    }

    // hasPOD = (job) => {
    //     if((job.StatusGroup && job.StatusGroup === '5') || job.StatusId === 'Status-d21d124b-8ef7-4e16-8edc-32f2068c22a1' || job.StatusId === 'Status-bc1d97d4-8da0-49b4-a24e-2e73c72e9aac' || job.StatusId === 'Status-3bb71efd-1840-46ab-a867-288aa712edc1' || job.StatusId === 'Status-072fe683-bd07-4810-9c6d-e2f5f38aa54d' || job.StatusId === 'Status-ee604e5c-9056-4756-8609-2f0f45112f6c' || job.StatusId === 'Status-0ae017fd-55fe-45f2-8639-c165bf42e19f' || job.StatusId === 'Status-360b1b1f-74ca-4322-b2ca-2f0db3bf9ca5' || job.StatusId === 'Status-4c1bd50c-60ae-4dfb-856f-0d56411dad9c')
    //         return true;
    //     else
    //         return false;
    // }

    handleSubmit = (values) => {
        this.setState({ "dataReady": false });
        // return await this.fetchHistory(values.consignmentNumber);
        let newUrl = window.location.origin + "/history?consignmentNumber=" + values.consignmentNumber;
        window.open(newUrl, '_self');
    }


    render() {
        const schema = yup.object().shape({
            consignmentNumber: yup.string()
                .required('Required')
        });
        return (

            <Formik
                validationSchema={schema}
                onSubmit={async (values, actions) => {
                    this.handleSubmit(values);
                    actions.setSubmitting(false);
                }}
                initialValues={{
                    consignmentNumber: this.state.consignmentNumber
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    status,
                }) => (
                        <Container>
                            <LoadingOverlay
                                active={this.state.isLoading}
                                spinner
                                text='Loading history...'
                            >
                                <Row style={{ border: 'solid thin', padding: '1em', margin: '0px' }}>
                                    <Col md={12}>
                                        <Form className="form-text-align" onSubmit={handleSubmit} >
                                            <FormGroup>
                                                <Form.Label><h3>Consignment History</h3></Form.Label>
                                            </FormGroup>

                                            <Form.Group controlId="consignmentNumber">
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Control
                                                            readOnly = {true}
                                                            type="text"
                                                            placeholder="Consignment Number"
                                                            name="consignmentNumber"
                                                            value={values.consignmentNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.consignmentNumber}
                                                            touched={touched.consignmentNumber}
                                                            isInvalid={errors.consignmentNumber && touched.consignmentNumber}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Required
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    {/* <Col md={8}>
                                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                            Get History
                                                    </Button>
                                                    </Col> */}
                                                </Row>
                                            </Form.Group>

                                            {this.state.error.length > 0 &&
                                                <Form.Group className="center-align">
                                                <Alert variant='danger'>
                                                    {this.state.error}
                                                </Alert>
                                            </Form.Group>
                                            }
                                        </Form>
                                    </Col>
                                    <Col md={12}>
                                        <Card className="no-border">
                                            <Card.Body>
                                                {this.state.dataReady &&
                                                    <History history={this.state.history} job={this.state.job} showButtons={false} isRecurring={this.state.job.IsRecurring} hideNames={true} />
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="attachments" className="margin-bottom-8">
                                            <ManageAttachments size='small' jobId={this.state.job.ItemId} />
                                            {this.hasPOD(this.state.job) && 
                                            <PDFButton size='small' type='download' attachments={this.state.job.Attachments} job={this.state.job} history={this.state.history} display='Download POD' noHistory={true} />  
                                            }                                 
                                        </Form.Group>
                                    </Col>
                                </Row> */}
                            </LoadingOverlay>
                        </Container>
                    )}
            </Formik>

        );
    }
}

export default LatestHistory;