import React, { useState, useEffect } from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import "moment-timezone";
import { Auth, API } from "aws-amplify";
import LoadingOverlay from 'react-loading-overlay';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';

function LastDeliveryTimeButton(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [error, setError] = useState('');
    //const [fromDate, setFromDate] = useState(props.fromDate ? props.fromDate : new Date());
    const [date, setDate] = useState(props.date ? props.date : new Date());
    const [state, setState] = useState('');

    useEffect(() => {
        if(show)
            fetchJobs();
    },[date, props.date, state])

    useEffect(() => {
        //setFromDate(props.fromDate);
        setDate(props.date);
    },[props.date, show])

    const fetchJobs = async () => {
        setIsProcessing(true);
        setShow(true);
        setError('');
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            let res = await API.get("api", "/accounts?AccountType=Driver", myInit);
            const drivers = res.accounts;
            console.log(drivers);
            const from = moment(date).format('YYYY-MM-DD');
            const to = moment(date).format('YYYY-MM-DD') + ' 23:59:59';
            let filters = '?pageSize=100000&page=0&Filter=DeliveredTime~isnotnull&Sort=ETA~desc&Where=DeliveryDate~between~' + from + '|' + to;
            res = await API.get("api", "/jobs" + filters, myInit);
            const jobs = res.jobs;
            console.log('last delivery', res.jobs);
            const lastDeliveries = drivers.filter(d => ((state !== '' && d.State === state) || (state === ''))).map(driver => {
                return({
                    id: driver.ItemId,
                    driver: driver.FullName,
                    lastDeliveryTime: jobs.filter(j => j.StatusGroup === '5' && j.DriverId === driver.ItemId).length > 0 ? jobs.filter(j => j.StatusGroup === '5' && j.DriverId === driver.ItemId)[0].DeliveredTime : '',
                    lastJob: jobs.filter(j => j.StatusGroup === '5' && j.DriverId === driver.ItemId).length > 0 ? jobs.filter(j => j.StatusGroup === '5' && j.DriverId === driver.ItemId)[0].ItemId : '',
                    refNumber: jobs.filter(j => j.StatusGroup === '5' && j.DriverId === driver.ItemId).length > 0 ? jobs.filter(j => j.StatusGroup === '5' && j.DriverId === driver.ItemId)[0].ConsignmentNumber : '',
                    state: driver.State
                })
            });
            // const remainingJobs = drivers.filter(d => ((state !== '' && d.State === state) || (state === ''))).map(driver => {
            //     return({
            //         id: driver.ItemId,
            //         driver: driver.FullName,
            //         remainingJobs: jobs.filter(j => j.StatusGroup !== '4' && j.StatusGroup !== '5' && j.StatusGroup !== '2' && j.DriverId === driver.ItemId).length,
            //         totalJobs: jobs.filter(j => j.DriverId === driver.ItemId).length,
            //         state: driver.State
            //     })
            // });
            console.log('lastDeliveries',lastDeliveries.filter(j => j.lastDeliveryTime !== '' && j.lastJob !== ''));
            //setJobs(remainingJobs.filter(j => j.totalJobs > 0));
            setJobs(lastDeliveries.filter(j => j.lastDeliveryTime !== '' && j.lastJob !== ''));
        }catch(error) {
            setError(error.toString().toLowerCase());
        }  
        setIsProcessing(false);      
    }

    const download = async () => {
        setIsProcessing(true);
        let workSheet;
        let row;
        const workBook = new ExcelJS.Workbook();
        workSheet = workBook.addWorksheet('Time of Last Delivery');
        if(state !== '')
        {
            workSheet.columns = [
                { header: 'Driver', key: 'driver', width: 15 },
                { header: 'Time', key: 'time', width: 10},
                { header: 'Last Job', key: 'lastJob', width: 10},
            ];
            row = workSheet.lastRow;
            row.eachCell(function(cell, colNumber) {            
                cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thick'},right: {style:'thin'}};
                cell.font = {bold: true};
            });
            jobs.forEach((j,index) => {
                workSheet.addRow([j.driver,moment(j.lastDeliveryTime).local().format('LT').toLowerCase() === 'invalid date' ? '' : moment(j.lastDeliveryTime).local().format('LT'),j.refNumber]);
                row = workSheet.lastRow;
                row.eachCell(function(cell, colNumber) {
                    cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
                });
            });
        }
        else{
            ['Victoria','New South Wales','Queensland','Western Australia','Australian Capital Territory','South Australia','Tasmania','Northern Territory'].forEach(s => {
                workSheet.addRow([s + ':','','']);
                workSheet.addRow(['Driver','Time','Last Job']);
                jobs.filter(j => j.state === s).forEach((j,index) => {
                    workSheet.addRow([j.driver,moment(j.lastDeliveryTime).local().format('LT').toLowerCase() === 'invalid date' ? '' : moment(j.lastDeliveryTime).local().format('LT'),j.refNumber]);
                    row = workSheet.lastRow;
                    row.eachCell(function(cell, colNumber) {
                        cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
                    });
                });
                workSheet.addRow('');
            });
        }
        const buf = await workBook.csv.writeBuffer()
        saveAs(new Blob([buf]), 'TimeOfLastDelivery_' + moment(new Date()).format("DD/MM/YYYY").replace("/", "_").replace("/", "_") + '.csv');
        setIsProcessing(false);
    }

    const timeFormatter = (cell, row) => {
        let time = '';
        time = moment(cell).local().format('LT').toLowerCase() === 'invalid date' ? '' : moment(cell).local().format('LT');
        return <span>{time}</span>;
    }

    const jobFormatter = (cell, row) => {
        return <Link to={`/jobs/editJob/${cell}`}>{row.refNumber}</Link>
    }

    return(
        <React.Fragment>
            <Button variant="success" size="sm" className="mr-sm-1" onClick={() => {fetchJobs();}} disabled={props.disabled ? props.disabled : false}>
                <i className="fas fa-eye" style={{ fontSize: '1em' }} /> Time of Last Delivery
            </Button>
            <Modal
                size="md"
                show={show}
                onHide={() => setShow(false)}
                backdrop= "static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Time of Last Delivery
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            <Button variant="success" size="sm"  className="mr-sm-5" onClick={() => download()} disabled={isProcessing || error.length > 0}>
                                <i className="fa fa-download" style={{ fontSize: '1em' }} /> Download
                            </Button>
                            {/* <Form.Label className="mr-sm-2">From</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={fromDate} maxDate={toDate} onChange={value => setFromDate(value)} className='form-control form-control-sm mr-sm-3 width-100' placeholderText='Select from date'/> */}
                            <Form.Label className="mr-sm-2">Date</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left" selected={date} onChange={value => setDate(value)} className='form-control form-control-sm width-100' placeholderText='Select date'/>
                        </Form.Group>
                        <Form.Group as={Col} xs={12}>
                            <Form.Row>
                                <Form.Group as={Col} xs={1} className="margin-bottom-zero">
                                    <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} className="margin-bottom-zero">
                                    <Form.Control as="select"
                                        size="sm"                                        
                                        name="state"
                                        placeholder="State"
                                        onChange={(e) => setState(e.target.value)}
                                    >   
                                    <option value=''>All</option>                                 
                                    <option>Victoria</option>
                                    <option>New South Wales</option>
                                    <option>Queensland</option>
                                    <option>Western Australia</option>
                                    <option>Australian Capital Territory</option>                                   
                                    <option>South Australia</option>                                   
                                    <option>Tasmania</option>
                                    <option>Northern Territory</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        {error.length > 0 &&
                            <Alert variant='danger'>
                                {error.indexOf('network error') > -1 ? 'Network Error encountered. Kindly check internet connection and refresh the page.' : 'Error encountered. Please refresh the page to try again.'}
                            </Alert>
                        }
                        {error.length === 0 &&
                            <>
                            {state === '' && <h5>Victoria</h5>}
                            <LoadingOverlay
                            active={isProcessing}
                            spinner
                            text='Loading...'
                            >
                            <BootstrapTable data={state === '' ? jobs.filter(j => j.state === 'Victoria') : jobs} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='190' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastDeliveryTime' dataFormat={timeFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Time</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastJob' width='170' dataFormat={jobFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Last Job</span></TableHeaderColumn>
                            </BootstrapTable>
                            </LoadingOverlay>
                            </>
                        } 
                        {error.length === 0 && state === '' &&
                            <>
                                {state === '' && <h5 className='margin-top-20'>New South Wales</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'New South Wales')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='190' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastDeliveryTime' dataFormat={timeFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Time</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastJob' width='170' dataFormat={jobFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Last Job</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Queensland</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Queensland')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='190' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastDeliveryTime' dataFormat={timeFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Time</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastJob' width='170' dataFormat={jobFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Last Job</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Western Australia</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Western Australia')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='190' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastDeliveryTime' dataFormat={timeFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Time</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastJob' width='170' dataFormat={jobFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Last Job</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Australian Capital Territory</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Australian Capital Territory')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='190' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastDeliveryTime' dataFormat={timeFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Time</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastJob' width='170' dataFormat={jobFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Last Job</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>South Australia</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'South Australia')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='190' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastDeliveryTime' dataFormat={timeFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Time</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastJob' width='170' dataFormat={jobFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Last Job</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Tasmania</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Tasmania')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='190' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastDeliveryTime' dataFormat={timeFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Time</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastJob' width='170' dataFormat={jobFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Last Job</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>

                                {state === '' && <h5 className='margin-top-20'>Northern Territory</h5>}
                                <LoadingOverlay
                                active={isProcessing}
                                spinner
                                text='Loading...'
                                >
                                <BootstrapTable data={jobs.filter(j => j.state === 'Northern Territory')} options={{noDataText: '' }}>
                                    <TableHeaderColumn dataField='id' isKey hidden>ItemId</TableHeaderColumn>
                                    <TableHeaderColumn dataField='driver' width='190' dataSort={false} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Driver Name</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastDeliveryTime' dataFormat={timeFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Time</span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastJob' width='170' dataFormat={jobFormatter} dataAlign="middle" dataSort={false} tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} ><span className="med-header-font">Last Job</span></TableHeaderColumn>
                                </BootstrapTable>
                                </LoadingOverlay>
                            </>
                        }                       
                    </Form.Row>                              
                </Modal.Body>                    
            </Modal>
        </React.Fragment>
    )
}

export default LastDeliveryTimeButton