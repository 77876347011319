import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import { VerticalTimeline, VerticalTimelineElement}  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import moment from 'moment';
import {PDFButton} from './utility/PDFDocument';


export default class History extends Component {
    // state = {
    //     history: []
    // };


    history () {
        const createdId = this.props.history.length > 0 ? this.props.history[0].ItemId : '';
        const history = this.props.history.map((history) =>
        <VerticalTimelineElement
            key={history.ItemId} 
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date={moment(history.DateUTC).format('LLL') } //"December 5, 2019 7:45 AM"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
         >
             {/* {job.ConsignmentNumber + ' - ' + job.Name} */}
            <p className="margin-top-zero">
                {this.message(createdId, history, this.props.hideNames)}
            </p>
        </VerticalTimelineElement>
        );
        return (
            <div>
                <div style={{width: '100%', display: 'block'}}>
                <h6 style={{display: 'inline-block'}} className="bold-font">{`Job #: ${this.props.job !== undefined ? this.props.job.ConsignmentNumber + ' - ' + this.props.job.Name : ''}`}</h6>
                { this.props.isRecurring == "true" &&
                    <span className="small text-muted">&nbsp; &nbsp; (This is a recurring job and latest history is of {moment(this.props.job.DeliveryDate).format('LL')})</span>
                }
                { this.props.showButtons &&
                    <React.Fragment>
                        <PDFButton type='download' rightAlign='true' attachments={[]} job={this.props.job} history={this.props.history} display='Download' />
                        <PDFButton type='email' rightAlign='true' attachments={[]} job={this.props.job} history={this.props.history} display='Email' />
                    </React.Fragment>
                }
                </div>
                <br/>
                <VerticalTimeline>{history}</VerticalTimeline>
            </div>
            
        );
    }
    message(createdId, history, hideNames) {
        if (hideNames) {
            if (createdId !== history.ItemId) {
                if (history.Category === 'status')
                    return (`Updated the status to "${history.Status}".`);
            }
            else {
                if (history.Category === 'status')
                    return (`Created the job with status "${history.Status}".`);
            }
        }
        else {
            const name = history.User.toLowerCase() === 'recurring' ? 'The System' : history.User;
            if (createdId !== history.ItemId) {
                if (history.Category === 'status')
                    return (`${name} updated the status to "${history.Status}".`);
                if (history.Category === 'driver')
                    return (`${name} assigned the job to "${history.DriverName}".`);
            }
            else {
                if (history.Category === 'status')
                    return (`${name} created the job with status "${history.Status}".`);
                if (history.Category === 'driver')
                    return (`${name} created the job and assigned to "${history.DriverName}".`);
            }
        }          
    }

    render() {
        return (
            this.history()            
        );
  }
}

