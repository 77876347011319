import React, { Component } from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';
import "moment-timezone";
import axios from 'axios';
import { Auth, API } from "aws-amplify";
import Download from "./Download";

export class UploadButton extends Component {
    state = {
        isRateProcessing: false,
        isDeliveryChargeProcessing: false,
        isInterstateRateProcessing: false,
        modalShow: false,
        selectedRateFile: undefined,
        selectedDeliveryChargeFile: undefined,
        selectedInterstateRateFile: undefined,
        selectedRateFileName: undefined,
        selectedDeliveryChargeFileName: undefined,
        selectedInterstateRateFileName: undefined,
        uploadSuccessRate: undefined,
        uploadSuccessDeliveryCharge: undefined,
        uploadSuccessInterstateRate: undefined,
        errorMessageRate: '',
        errorMessageDeliveryCharge: '',
        errorMessageInterstateRate: '',
        divisionName: undefined,
        displayType: 4,
        url: null,
        isBulk: false, //for bulk invoice download
        clientId: undefined, //for bulk invoice download
        divisionId: undefined, //for bulk invoice download
    };

    async download(file,e) {
        e.preventDefault()
        const myInit = {
            headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
        };
        
        const res = await API.get('api', '/uploads?type=download&File=' + file + '&folder=Sample', myInit);
        if(res['result'] === 'success')
            this.setState({url: res['url']});
    }

    setModalShow = (show, divisionName, displayType, isBulk, clientId, divisionId) => {
        this.setState({ modalShow: show, url: null, isBulk: isBulk, divisionName: divisionName, clientId: clientId, divisionId: divisionId, displayType: displayType === undefined ? 4 : displayType, uploadSuccessRate: undefined, uploadSuccessDeliveryCharge: undefined, uploadSuccessInterstateRate: undefined, errorMessageRate: '', errorMessageDeliveryCharge: '', errorMessageInterstateRate: '', selectedRateFile: undefined, selectedDeliveryChargeFile: undefined, selectedInterstateRateFile: undefined, selectedRateFileName: undefined, selectedDeliveryChargeFileName: undefined, selectedInterstateRateFileName: undefined, isRateProcessing: false, isDeliveryChargeProcessing: false, isInterstateRateProcessing: false });
        if(this.props.reprocessCallback !== undefined && show === false)
            this.props.reprocessCallback.reprocess();
        console.log('click', show);
    }

    onChangeHandlerRate = event => {
        this.setState({
            selectedRateFile: event.target.files[0],
            selectedRateFileName: event.target.files.length > 0 ? event.target.files[0].name : undefined,
        })
    }

    onChangeHandlerDeliveryCharge = event => {
        this.setState({
            selectedDeliveryChargeFile: event.target.files[0],
            selectedDeliveryChargeFileName: event.target.files.length > 0 ? event.target.files[0].name : undefined,
        })
    }

    onChangeHandlerInterstate = event => {
        this.setState({
            selectedInterstateRateFile: event.target.files[0],
            selectedInterstateRateFileName: event.target.files.length > 0 ? event.target.files[0].name : undefined,
        })
    }

    readUploadedFileAsText = (inputFile) => {
        const temporaryFileReader = new FileReader();
      
        return new Promise((resolve, reject) => {
          temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
          };
      
          temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
          };
          temporaryFileReader.readAsText(inputFile);
        });
    };

    onClickHandler = (fileType) => {
        let obj;
        if(fileType === 'rate')
            obj = {isRateProcessing: true, uploadSuccessRate: undefined, errorMessageRate: ''};
        else{
            if(fileType === 'deliveryCharge')
                obj = {isDeliveryChargeProcessing: true, uploadSuccessDeliveryCharge: undefined, errorMessageDeliveryCharge: ''};
            else
                obj = {isInterstateRateProcessing: true, uploadSuccessInterstateRate: undefined, errorMessageInterstateRate: ''};
        }
            
        this.setState(obj,async function () {
            const myInit = {
                headers: {
                  Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                }            
            };

            //check column headers
            const reader = await this.readUploadedFileAsText(fileType === 'rate' ? this.state.selectedRateFile : (fileType === 'deliveryCharge' ? this.state.selectedDeliveryChargeFile : this.state.selectedInterstateRateFile));
            const headers = reader.split(/\r?\n/)[0];
            const requiredColumns = fileType === 'rate' ? ['POSTCODE','AREA','RATE'] : (fileType === 'deliveryCharge' ? ['AREA','BOX','CHARGE'] : ['Origin Min Postcode', 'Origin Max Postcode', 'Destination Min Postcode', 'Destination Max Postcode', 'Interstate Rate']);
            let errorMessage = '';
            requiredColumns.forEach((rc) => {
                const header = headers.split(',').filter(h => h.indexOf(rc) >= 0);
                if(header.length > 0)
                {
                    if(header[0] !== rc)
                    {
                        if(errorMessage === '')
                            errorMessage = 'Cannot find following columns. (Kindly make sure it has the same letter case and has no leading or trailing spaces.) <br>';
                        errorMessage = errorMessage + rc + '<br>';
                    }
                }
                else{
                    if(errorMessage === '')
                            errorMessage = 'Cannot find following columns. (Kindly make sure it has the same letter case and has no leading or trailing spaces.) <br>';
                        errorMessage = errorMessage + rc + '<br>';
                }               
            });
            //end checking

            if(errorMessage === '')
            {
                const preSignedUrl = await API.get("api", "/uploads?File=" + (fileType === 'rate' ? 'RateCard.csv' : (fileType === 'deliveryCharge' ? 'DeliveryCharge.csv' : 'InterstateRateCard.csv')) + "&Folder=" + (!this.state.isBulk ? this.props.clientId : this.state.clientId) + "&SubFolder=" + (!this.state.isBulk ? this.props.divisionId : this.state.divisionId), myInit);
                console.log('preSignedUrl', preSignedUrl);
                const response = await axios({ method: 'put', url: preSignedUrl.url, data: fileType === 'rate' ? this.state.selectedRateFile : (fileType === 'deliveryCharge' ? this.state.selectedDeliveryChargeFile : this.state.selectedInterstateRateFile)});
                if(fileType === 'rate')
                {
                    if(this.state.isBulk)
                    {
                        const reprocessedInvoices = await API.get("api", "/invoices?ReprocessInvoice=true&DivisionId=" + this.state.divisionId, myInit);
                        console.log('reprocessed', reprocessedInvoices);
                    }
                    this.setState({isRateProcessing: false, uploadSuccessRate:response.statusText, errorMessageRate:''});
                }
                else
                {
                    if(fileType === 'deliveryCharge')
                    {
                        if(this.state.isBulk)
                        {
                            const reprocessedInvoices = await API.get("api", "/invoices?ReprocessInvoice=true&DivisionId=" + this.state.divisionId, myInit);
                            console.log('reprocessed', reprocessedInvoices);
                        }
                        this.setState({isDeliveryChargeProcessing: false, uploadSuccessDeliveryCharge:response.statusText, errorMessageDeliveryCharge:''});
                    }
                    else
                        this.setState({isInterstateRateProcessing: false, uploadSuccessInterstateRate:response.statusText, errorMessageInterstateRate:''});
                }
                console.log('response', response);
            }
            else{
                if(fileType === 'rate')
                    this.setState({isRateProcessing: false, errorMessageRate: errorMessage});
                else{
                    if(fileType === 'deliveryCharge')
                        this.setState({isDeliveryChargeProcessing: false, errorMessageDeliveryCharge: errorMessage});
                    else
                        this.setState({isInterstateRateProcessing: false, errorMessageInterstateRate: errorMessage});
                }
            }            
        });        
    }

    render(){
        return(
            <div className={this.props.rightAlign ? 'float-right' : 'inline'}>
                {(this.props.hideButton === undefined || this.props.hideButton === false) &&
                <Button className={`mr-sm-2 ${this.props.size === 'small' ? 'btn-cust-sm' : 'btn-sm'}`} variant="success" size="sm" onClick={()=> this.setModalShow(true)}>
                    <i className="fas fa-upload" style={{ fontSize: '1em' }} /> {this.props.display}                              
                </Button>}
                <Modal
                    size="md"
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    backdrop= "static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            {this.state.divisionName === undefined ? this.props.display : `Need to upload files for ${this.state.divisionName} and then retry downloading invoice.`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body> 
                        {(this.state.displayType === 3 || this.state.displayType === 4 || this.state.displayType === 1) &&
                        <React.Fragment>
                            <Form.Row>
                                <Form.Group as={Col} xs={12}>
                                    Click <span className="no-left-right-padding clickable"><a href='' onClick={this.download.bind(this,'SampleRateCard.csv')}>here</a></span> to download sample rate card.
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                    <Form.Label><h5>Upload Rate Card</h5></Form.Label><br/>
                                    <input type="file" name="file" onChange={this.onChangeHandlerRate}/><br/>                                
                                </Form.Group>                            
                                <Form.Group as={Col} xs={12} className="margin-top-20 margin-bottom-zero">
                                    <Button disabled={(this.state.selectedRateFileName !== undefined && this.state.selectedRateFileName.toLowerCase().endsWith('.csv') === true ? false : true) || this.state.isRateProcessing} className="mr-sm-2" variant="success" size="sm" onClick={() => this.onClickHandler('rate')}>
                                        <i className="fas fa-upload" style={{ fontSize: '1em' }} /> {this.state.isRateProcessing ? 'Uploading...' : 'Upload'}
                                    </Button>
                                </Form.Group>
                                {this.state.selectedRateFileName !== undefined && (this.state.selectedRateFileName.toLowerCase().endsWith('.csv') === false || this.state.errorMessageRate !== '') &&
                                <Form.Group as={Col} xs={12} className="margin-bottom-zero">
                                    <br/>
                                    <Alert variant='danger'>
                                        {this.state.errorMessageRate !== '' && this.state.errorMessageRate.split('<br>').map((item, i) => {
                                            return <p className="margin-bottom-zero" key={i}>{item}</p>;
                                        })}
                                        {this.state.selectedRateFileName.toLowerCase().endsWith('.csv') === false && 'The file is not a .csv format'}
                                    </Alert>
                                </Form.Group>}
                                {this.state.uploadSuccessRate !== undefined && 
                                <Form.Group as={Col} xs={12}>
                                    <br/>
                                    <Alert variant={this.state.uploadSuccessRate === 'OK' ? 'success' : 'danger'}>{this.state.uploadSuccessRate === 'OK' ? 'Uploaded Successfully!' : 'Upload Failed!'}</Alert>
                                </Form.Group>}
                            </Form.Row>  
                        </React.Fragment>
                        }
                        {(this.state.displayType === 3 || this.state.displayType === 4) && <br/>}
                        {(this.state.displayType === 3 || this.state.displayType === 4 || this.state.displayType === 2) &&
                        <React.Fragment>
                            <Form.Row className={(this.state.displayType === 4 || this.state.displayType === 3) ? "gray-top-border" : ""}>
                                <Form.Group as={Col} xs={12} className={(this.state.displayType === 4 || this.state.displayType === 3) ? "top-margin-20" : ""}>
                                    Click <span className="no-left-right-padding clickable"><a href='' onClick={this.download.bind(this,'SampleDeliveryCharge.csv')}>here</a></span> to download sample delivery charge file.
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                    <Form.Label><h5>Upload Delivery Charge</h5></Form.Label><br/>
                                    <input type="file" name="file" onChange={this.onChangeHandlerDeliveryCharge}/><br/>                                
                                </Form.Group>                            
                                <Form.Group as={Col} xs={12} className="margin-top-20 margin-bottom-zero">
                                    <Button disabled={(this.state.selectedDeliveryChargeFileName !== undefined && this.state.selectedDeliveryChargeFileName.toLowerCase().endsWith('.csv') === true ? false : true) || this.state.isDeliveryChargeProcessing} className="mr-sm-2" variant="success" size="sm" onClick={() => this.onClickHandler('deliveryCharge')}>
                                        <i className="fas fa-upload" style={{ fontSize: '1em' }} /> {this.state.isDeliveryChargeProcessing ? 'Uploading...' : 'Upload'}
                                    </Button>
                                </Form.Group>
                                {this.state.selectedDeliveryChargeFileName !== undefined && (this.state.selectedDeliveryChargeFileName.toLowerCase().endsWith('.csv') === false || this.state.errorMessageDeliveryCharge !== '') &&
                                <Form.Group as={Col} xs={12} className="margin-bottom-zero">
                                    <br/>
                                    <Alert variant='danger'>
                                        {this.state.errorMessageDeliveryCharge !== '' && this.state.errorMessageDeliveryCharge.split('<br>').map((item, i) => {
                                            return <p className="margin-bottom-zero" key={i}>{item}</p>;
                                        })}
                                        {this.state.selectedDeliveryChargeFileName.toLowerCase().endsWith('.csv') === false && 'The file is not a .csv format'}
                                    </Alert>
                                </Form.Group>}
                                {this.state.uploadSuccessDeliveryCharge !== undefined && 
                                <Form.Group as={Col} xs={12}>
                                    <br/>
                                    <Alert variant={this.state.uploadSuccessDeliveryCharge === 'OK' ? 'success' : 'danger'}>{this.state.uploadSuccessDeliveryCharge === 'OK' ? 'Uploaded Successfully!' : 'Upload Failed!'}</Alert>
                                </Form.Group>}
                            </Form.Row>  
                        </React.Fragment>
                        }
                        {this.state.displayType === 4 && <br/>}
                        {this.state.displayType === 4 &&
                        <React.Fragment>
                            <Form.Row className={this.state.displayType === 4 ? "gray-top-border" : ""}>
                                <Form.Group as={Col} xs={12} className={this.state.displayType === 4 ? "top-margin-20" : ""}>
                                    Click <span className="no-left-right-padding clickable"><a href='' onClick={this.download.bind(this,'SampleInterstateRateCard.csv')}>here</a></span> to download sample interstate rate card file.
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                    <Form.Label><h5>Upload Interstate Rate Card</h5></Form.Label><br/>
                                    <input type="file" name="file" onChange={this.onChangeHandlerInterstate}/><br/>                                
                                </Form.Group>                            
                                <Form.Group as={Col} xs={12} className="margin-top-20 margin-bottom-zero">
                                    <Button disabled={(this.state.selectedInterstateRateFileName !== undefined && this.state.selectedInterstateRateFileName.toLowerCase().endsWith('.csv') === true ? false : true) || this.state.isInterstateRateProcessing} className="mr-sm-2" variant="success" size="sm" onClick={() => this.onClickHandler('interstateRate')}>
                                        <i className="fas fa-upload" style={{ fontSize: '1em' }} /> {this.state.isInterstateRateProcessing ? 'Uploading...' : 'Upload'}
                                    </Button>
                                </Form.Group>
                                {this.state.selectedInterstateRateFileName !== undefined && (this.state.selectedInterstateRateFileName.toLowerCase().endsWith('.csv') === false || this.state.errorMessageInterstateRate !== '') &&
                                <Form.Group as={Col} xs={12} className="margin-bottom-zero">
                                    <br/>
                                    <Alert variant='danger'>
                                        {this.state.errorMessageInterstateRate !== '' && this.state.errorMessageInterstateRate.split('<br>').map((item, i) => {
                                            return <p className="margin-bottom-zero" key={i}>{item}</p>;
                                        })}
                                        {this.state.selectedInterstateRateFileName.toLowerCase().endsWith('.csv') === false && 'The file is not a .csv format'}
                                    </Alert>
                                </Form.Group>}
                                {this.state.uploadSuccessInterstateRate !== undefined && 
                                <Form.Group as={Col} xs={12}>
                                    <br/>
                                    <Alert variant={this.state.uploadSuccessInterstateRate === 'OK' ? 'success' : 'danger'}>{this.state.uploadSuccessInterstateRate === 'OK' ? 'Uploaded Successfully!' : 'Upload Failed!'}</Alert>
                                </Form.Group>}
                            </Form.Row>  
                        </React.Fragment>
                        }     
                        <Download url={this.state.url} />     
                    </Modal.Body>                    
                </Modal>
            </div>
        )
    }
}