import React, { Component } from 'react';
import {Container, Row, Col, ListGroup, Form, Button} from 'react-bootstrap';
import { Auth, API } from "aws-amplify";
import { Formik } from 'formik';

export default class DeleteJobs extends Component {
    jobsList () {
        const jobLists = this.props.jobs.map((job) =>
        <ListGroup.Item key={job.ItemId} className="med-font no-left-right-padding top-bottom-padding-2">{job.ConsignmentNumber + ' - ' + job.Name}</ListGroup.Item>
        );
        return (
            <ListGroup variant="flush">{jobLists}</ListGroup>
        );
    }

    deleteJobs = async (values, actions) => {
        try{
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    jobs: this.props.jobs
                    }
            };            
            console.log('myInit', myInit);
            const res = await API.del("api", "/jobs", myInit);
            console.log("res", res);
            this.props.refresh(true);
        }catch(error)
        {
            console.log('error', error.message);
        }
    }

    render() {
        return(
        <Formik
        onSubmit={async (values, actions) => {
            await this.deleteJobs(values, actions);
        }}
        initialValues={{
            status:'0'
        }}
        >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
            <Container>
                <Form onSubmit={handleSubmit}>
                    {this.props.jobs.length > 0 &&
                    <React.Fragment>
                    <Row className="justify-content-md-center bold-font">
                        <Col xs lg="11">
                            <Form.Label>You have selected following {this.props.jobs.length === 1 ? ' 1 job' : this.props.jobs.length + ' jobs'} to delete.</Form.Label>
                        </Col>                
                    </Row>
                    <Row className="justify-content-md-center top-margin-20">
                        <Col xs lg="11">
                            {this.jobsList()}
                        </Col>                
                    </Row>
                    <Form.Row className="justify-content-md-right modal-footer no-border top-margin-20"> 
                        <Button className="mr-sm-1" variant="primary" type="submit" disabled={isSubmitting} size="sm">
                            {isSubmitting ? 'Deleting…' : 'Proceed Delete'}
                        </Button>
                        <Button variant="primary" size="sm" onClick={this.props.onHide}>Cancel</Button>                    
                    </Form.Row>
                    </React.Fragment>}
                    {this.props.jobs.length === 0 &&
                    <Row className="justify-content-md-center">
                        <Col xs lg="11">
                            <Form.Label>Please select the jobs to be deleted.</Form.Label>
                        </Col>                
                    </Row>
                    }
                </Form>
            </Container>
            )}
        </Formik>
        );        
    }
}