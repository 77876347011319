import React, { Component } from 'react';
import Badge from 'react-bootstrap/Badge';
import {Container, Row, Col, ListGroup, Form, Button} from 'react-bootstrap';
import { Auth, API } from "aws-amplify";
import { Formik } from 'formik';
import * as yup from 'yup';

export default class UpdateStatus extends Component {

    checkUrgentJobs () {
        var urgentJob = this.props.jobs.filter(j => j.Priority === "Urgent");
        if(urgentJob && urgentJob.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    jobsList () {
        const jobLists = this.props.jobs.map((job) => {
            if(job.Priority === "Urgent") {                
                return <ListGroup.Item key={job.ItemId} className="med-font no-left-right-padding top-bottom-padding-2">
                        <p className='my-2'>{job.ConsignmentNumber + ' - ' + job.Name} <span class="customBadge"><Badge bg="warning" text="dark">Urgent</Badge></span></p>
                    </ListGroup.Item>
            }
            else 
            {
                return <ListGroup.Item key={job.ItemId} className="med-font no-left-right-padding top-bottom-padding-2">
                        <p className='my-2'>{job.ConsignmentNumber + ' - ' + job.Name}</p>
                    </ListGroup.Item>
            }
        });        
        return (
            <ListGroup variant="flush">{jobLists}</ListGroup>
        );
    }

    getStatus () {
        return this.props.status && this.props.status.map((status) => {
            const { Label, ItemId } = status
            return (
                <option key={ItemId} value={ItemId} >{Label}</option>
            )
        })
    }

    updateStatus = async (values, actions) => {
        try{
            const status = this.props.status.filter(s =>
                s.ItemId === values.status);
            const patchRecords = this.props.jobs.map((job) => ({ItemId: job.ItemId, Status: status[0].Label, StatusId: status[0].ItemId}));
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: {
                    jobs: patchRecords
                    }
            };
            
            await API.patch("api", "/jobs", myInit);
            this.props.refresh(true);
        }catch(error)
        {
            console.log('error', error.message);
        }
    }

    render() {
        const schema = yup.object().shape({
            status: yup.string()
                .ensure()
                .required('Status is required')
                .test('ok', 'Status is required', function(value) {
                  return '0' !== value;
                }),
        });
        return(
        <Formik
        validationSchema={schema}
        onSubmit={async (values, actions) => {
            await this.updateStatus(values, actions);
        }}
        initialValues={{
            status:'0'
        }}
        >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
            <Container>
                <Form onSubmit={handleSubmit}>
                    {this.props.jobs.length > 0 &&
                    <React.Fragment>
                    <Row className="justify-content-md-center bold-font">
                        <Col xs lg="11">
                            <Form.Label>You have selected following {this.props.jobs.length === 1 ? ' 1 job' : this.props.jobs.length + ' jobs'} to update the status.</Form.Label>
                        </Col>                
                    </Row>
                    <Row className="justify-content-md-center top-margin-20">
                        <Col xs lg="11">
                            {this.jobsList()}
                        </Col>                
                    </Row>
                    { this.checkUrgentJobs() &&
                        <Row className='justify-content-md-center top-margin-20'>
                            <Col xs lg="11">
                                <p className='info-text mb-0'><i class="fa fa-info-circle" aria-hidden="true"></i> Some jobs are marked as "Urgent", changing the status will reset the job to "Normal"</p>
                            </Col>
                        </Row>
                    }
                    <Row className="justify-content-md-center top-margin-20">                            
                        <Form.Group as={Col} md={11} controlId="status">
                            <Form.Label className="bold-font">Select Status</Form.Label>
                            <Form.Control as="select"
                                size="sm"
                                value={values.status}
                                name="status"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.status}
                                touched={touched.status}
                                isInvalid={errors.status && touched.status}
                            >
                                <option value="0">Choose...</option>
                                {this.getStatus()}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.status}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Row className="justify-content-md-right modal-footer no-border top-margin-20"> 
                        <Button className="mr-sm-1" variant="primary" type="submit" disabled={isSubmitting} size="sm">
                            {isSubmitting ? 'Processing…' : 'Save'}
                        </Button>
                        <Button variant="primary" size="sm" onClick={this.props.onHide}>Cancel</Button>                    
                    </Form.Row>
                    </React.Fragment>}
                    {this.props.jobs.length === 0 &&
                    <Row className="justify-content-md-center">
                        <Col xs lg="11">
                            <Form.Label>Please select the jobs to be updated.</Form.Label>
                        </Col>                
                    </Row>
                    }
                </Form>
            </Container>
            )}
        </Formik>
        );        
    }
}