import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import {Form, Button, Container, Col, Card, Collapse, Spinner} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';

export default class AddEditClient extends Component {
    state = {
        client: undefined,
        clientId: undefined,
        redirect: null
    }
    addClient = async (values, actions) => {
        try{
          const myInit = {
              headers: {
                  Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
              },
              body: {
                  clients: [
                      {
                        ItemId: this.state.clientId !== undefined ? this.state.clientId : undefined,
                        Name: values.company,   
                        ABN: values.abn,   
                        ClientAbbreviation: values.clientAbbreviation,                
                        FirstName: values.firstName,
                        LastName: values.lastName,
                        Email: values.email,
                        MobileNumber: values.mobile,
                        Landline: values.landline,
                        FaxNumber: values.faxnumber,
                        PhysicalAddressCountry: values.physicalAddressCountry,
                        PhysicalAddressStreet: values.physicalAddressStreet,
                        PhysicalAddressCity: values.physicalAddressCity,
                        PhysicalAddressState: values.physicalAddressState,
                        PhysicalAddressZip: values.physicalAddressZip,
                        SameWithPhysicalAddress: values.mailingAddressSwitch,
                        MailingAddressCountry: values.mailingAddressCountry,
                        MailingAddressStreet: values.mailingAddressStreet,
                        MailingAddressCity: values.mailingAddressCity,
                        MailingAddressState: values.mailingAddressState,
                        MailingAddressZip: values.mailingAddressZip,
                        Notes: values.notes,
                        Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                        HasRebate: values.rebateSwitch,
                        ApplyRebateIfAbove: values.applyRebateIfAbove,
                        Rebate: values.rebate,
                        AutoSendPOD: values.autoEmailPODSwitch,
                        HasStatusAPI: values.statusAPISwitch,
                        StatusAPIUrl: values.statusAPISwitch === true ? values.statusAPIUrl : undefined,
                        StatusAPIUsername: values.statusAPISwitch === true ? values.statusAPIUsername : undefined,
                        StatusAPIPassword: values.statusAPISwitch === true ? values.statusAPIPassword : undefined,
                        HasSFTP: values.sftpSwitch,
                        SFTPHost: values.sftpSwitch === true ? values.sftpHost : undefined,
                        SFTPPort: values.sftpSwitch === true ? values.sftpPort : undefined,
                        SFTPPassword: values.sftpSwitch === true ? values.sftpPassword : undefined,
                        SFTPUsername: values.sftpSwitch === true ? values.sftpUsername : undefined,
                        SFTPPath: values.sftpSwitch === true ? values.sftpPath : undefined,
                        SendSMS: values.sendSMS,
                        SMSMessageInVan: values.smsMessageInVan,
                        SMSMessageOnMyWay: values.smsMessageOnMyWay
                      }
                  ]
                  }
          };
          //console.log(JSON.stringify(myInit));
          let result;
          if(this.state.clientId !== undefined)
            result = await API.patch("api", "/clients", myInit);
          else
            result = await API.post("api", "/clients", myInit);
          if(result.result === 'success')
          {
            this.props.navProp.changeClientPage('clientList');
            this.setState({ redirect:'/clients' });
          }              
          else{
            actions.setStatus({msg: result.errors[0]});
            actions.setSubmitting(false);
          }
        }catch(error)
        {
            console.log('error', error.message);
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
    }
    componentDidMount() {    
        if(this.props.match.params.clientId !== undefined && this.props.match.params.clientId.length > 0)
        {
            this.setState({clientId:this.props.match.params.clientId}, function () {
                this.fetchClient();});
        }
        // if(this.props.id !== undefined)
        //     this.fetchClient();
    }
    async fetchClient() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            const res = await API.get("api", "/clients/" + this.state.clientId, myInit);
            this.setState({
                client: res.clients[0]
            })
        }catch(err) {
            this.setState(() => { throw err; })
        } 
    }
    render() {
        const schema = yup.object().shape({
            firstName: yup.string()
                    .required('Required'),
            // lastName: yup.string()
            //         .required('Required'),
            clientAbbreviation: yup.string()
                    .max(10, 'Max length is 10 characters'),
                    // .test('len', 'Max length is 10 characters', val => val.length <= 10),
            company: yup.string()
                    .required('Required'),
            email: yup.string()
                    .email('Must be a valid email')
                    .required('Required'),
            // mobile: yup.string()
            //         .required('Required'),
            // landline: yup.string()
            //         .required('Required'),
            physicalAddressState: yup.string()
                    .required('Required'),
            physicalAddressCountry: yup.string()
                    .required('Required'),
            physicalAddressStreet: yup.string()
                    .required('Required'),
            physicalAddressCity: yup.string()
                    .required('Required'),
            physicalAddressZip: yup.string()
                    .required('Required'),
            mailingAddressState: yup.string()
                    .required('Required'),
            mailingAddressCountry: yup.string()
                    .required('Required'),
            mailingAddressStreet: yup.string()
                    .required('Required'),
            mailingAddressCity: yup.string()
                    .required('Required'),
            mailingAddressZip: yup.string()
                    .required('Required'),
            applyRebateIfAbove: yup.number()
                    //.ensure()
                    .test('ok', 'Required', function(value){
                        if(this.parent.rebateSwitch === true && (value === undefined || value.length === 0)){
                            return false;
                        }              
                    return true;
                    })
                    .typeError('Must be number'),
            rebate: yup.number()
                    .test('ok', 'Required', function(value){
                        if(this.parent.rebateSwitch === true && (value === undefined || value.length === 0)){
                            return false;
                        }              
                    return true;
                    })
                    .typeError('Must be number'),
            statusAPIUrl: yup.string()
                    //.ensure()
                    .test('ok', 'Required', function(value){
                        if(this.parent.statusAPISwitch === true && (value === undefined || value === null || value.length === 0)){
                            return false;
                        }              
                    return true;
                    }),
            statusAPIUsername: yup.string()
                    //.ensure()
                    .test('ok', 'Required', function(value){
                        if(this.parent.statusAPISwitch === true && (value === undefined || value === null || value.length === 0)){
                            return false;
                        }              
                    return true;
                    }),
            statusAPIPassword: yup.string()
                    //.ensure()
                    .test('ok', 'Required', function(value){
                        if(this.parent.statusAPISwitch === true && (value === undefined || value === null || value.length === 0)){
                            return false;
                        }              
                    return true;
                    }),
            sftpHost: yup.string()
                    .test('ok', 'Required', function(value){
                        if(this.parent.sftpSwitch === true && (value === undefined || value === null || value.length === 0)){
                            return false;
                        }              
                    return true;
                    }),
            sftpPort: yup.number()
                    .test('ok', 'Required', function(value){
                        if(this.parent.sftpSwitch === true && (value === undefined || value === null || value.length === 0)){
                            return false;
                        }              
                    return true;
                    })
                    .typeError('Must be number'),
            sftpUsername: yup.string()
                    .test('ok', 'Required', function(value){
                        if(this.parent.sftpSwitch === true && (value === undefined || value === null || value.length === 0)){
                            return false;
                        }              
                    return true;
                    }),
            sftpPassword: yup.string()
                    .test('ok', 'Required', function(value){
                        if(this.parent.sftpSwitch === true && (value === undefined || value === null || value.length === 0)){
                            return false;
                        }              
                    return true;
                    }),
            smsMessageInVan: yup.string()
                    .test('ok', '{consignment#} placeholder is required', function(value){
                        if((value === undefined || value === null || value.length === 0 || value.indexOf('{consignment#}') < 0)){
                            return false;
                        }              
                    return true;
                    }),
            smsMessageOnMyWay: yup.string()
                    .test('ok', '{consignment#} placeholder is required', function(value){
                        if((value === undefined || value === null || value.length === 0 || value.indexOf('{consignment#}') < 0)){
                            return false;
                        }              
                    return true;
                    }),
        });
        if (this.state.redirect) 
            return <Redirect to={{pathname: this.state.redirect}} />
        if(this.state.clientId !== undefined && this.state.client === undefined)
        {
            return(
                <div><br/><br/><br/>
                <Spinner animation="border" />
                </div>
            );
        }
        else{
            return(
                <Formik
                  validationSchema={schema}
                  onSubmit={async (values, actions) => {
                      await this.addClient(values, actions);
                  }}
                  
                  initialValues={{
                    firstName: this.state.client === undefined ? '' : this.state.client.FirstName,
                    lastName: this.state.client === undefined ? '' : this.state.client.LastName,
                    clientAbbreviation: this.state.client === undefined ? '' : this.state.client.ClientAbbreviation,
                    email: this.state.client === undefined ? '' : this.state.client.Email,
                    company: this.state.client === undefined ? '' : this.state.client.Name,
                    mobile: this.state.client === undefined ? '' : this.state.client.MobileNumber,
                    landline: this.state.client === undefined ? '' : this.state.client.Landline,
                    faxnumber: this.state.client === undefined ? '' : this.state.client.FaxNumber,
                    abn: this.state.client === undefined ? '' : this.state.client.ABN,
                    physicalAddressCountry: this.state.client === undefined ? 'Australia' : this.state.client.PhysicalAddressCountry,
                    physicalAddressStreet: this.state.client === undefined ? '' : this.state.client.PhysicalAddressStreet,
                    physicalAddressCity: this.state.client === undefined ? '' : this.state.client.PhysicalAddressCity,
                    physicalAddressZip: this.state.client === undefined ? '' : this.state.client.PhysicalAddressZip,
                    physicalAddressState: this.state.client === undefined ? '' : this.state.client.PhysicalAddressState,
                    mailingAddressSwitch: this.state.client === undefined ? false : this.state.client.SameWithPhysicalAddress,
                    mailingAddressCountry: this.state.client === undefined ? 'Australia' : this.state.client.MailingAddressCountry,
                    mailingAddressStreet: this.state.client === undefined ? '' : this.state.client.MailingAddressStreet,
                    mailingAddressCity: this.state.client === undefined ? '' : this.state.client.MailingAddressCity,
                    mailingAddressZip: this.state.client === undefined ? '' : this.state.client.MailingAddressZip,
                    mailingAddressState: this.state.client === undefined ? '' : this.state.client.MailingAddressState,
                    notes: this.state.client === undefined ? '' : this.state.client.Notes,
                    rebateSwitch: this.state.client === undefined ? false : this.state.client.HasRebate,
                    applyRebateIfAbove: this.state.client === undefined ? '' : (this.state.client.ApplyRebateIfAbove === 0 ? '' : this.state.client.ApplyRebateIfAbove),
                    rebate: this.state.client === undefined ? 0 : this.state.client.Rebate,
                    autoEmailPODSwitch: this.state.client === undefined ? false : this.state.client.AutoSendPOD,
                    statusAPISwitch: this.state.client === undefined ? false : this.state.client.HasStatusAPI,
                    statusAPIUrl: this.state.client === undefined ? '' : (this.state.client.StatusAPIUrl === null ? '' : this.state.client.StatusAPIUrl),
                    statusAPIUsername: this.state.client === undefined ? '' : (this.state.client.StatusAPIUsername === null ? '' : this.state.client.StatusAPIUsername),
                    statusAPIPassword: this.state.client === undefined ? '' : (this.state.client.StatusAPIPassword === null ? '' : this.state.client.StatusAPIPassword),
                    sftpSwitch: this.state.client === undefined ? false : this.state.client.HasSFTP,
                    sftpHost: this.state.client === undefined ? '' : (this.state.client.SFTPHost === null ? '' : this.state.client.SFTPHost),
                    sftpPort: this.state.client === undefined ? '' : (this.state.client.SFTPPort === null ? '' : this.state.client.SFTPPort),
                    sftpUsername: this.state.client === undefined ? '' : (this.state.client.SFTPUsername === null ? '' : this.state.client.SFTPUsername),
                    sftpPassword: this.state.client === undefined ? '' : (this.state.client.SFTPPassword === null ? '' : this.state.client.SFTPPassword),
                    sftpPath: this.state.client === undefined ? '' : (this.state.client.SFTPPath === null ? '' : this.state.client.SFTPPath),
                    sendSMS: this.state.client === undefined ? false : this.state.client.SendSMS,
                    smsMessageInVan: (this.state.client === undefined || this.state.client === null || this.state.client.SMSMessageInVan === null || this.state.client.SMSMessageInVan === '') ? 'Your order {consignment#} from {division} is on board for delivery today with Mediport' : this.state.client.SMSMessageInVan,
                    smsMessageOnMyWay: (this.state.client === undefined || this.state.client === null || this.state.client.SMSMessageOnMyWay === null || this.state.client.SMSMessageOnMyWay === '') ? 'Your order {consignment#} from {division} will be the next delivery made' : this.state.client.SMSMessageOnMyWay
                  }}
                >
                    {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    status,
                    setFieldValue
                  }) => (
                    <Container className="no-left-right-margin form-text-align">
                        <Form onSubmit={handleSubmit}>
                            <Card>
                                <Card.Header>
                                    <Form inline>
                                        <Form.Group as={Col} xs={6} className="text-left padding-left-zero">
                                            <h4>{this.state.clientId === undefined ? 'Add Client' : 'Edit Client'}</h4>
                                        </Form.Group>
                                        {this.state.clientId !== undefined &&
                                        <Form.Group as={Col} xs={6} className="justify-content-end">
                                            <Button as={Link} to={`/clients/divisionList/${this.state.clientId}`} className="mr-sm-2" variant="success" size="sm" >
                                            Show Divisions
                                            </Button>
                                            <Button as={Link} to={`/clients/addDivision/${this.state.clientId}`} className="mr-sm-2" variant="success" size="sm" >
                                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Division
                                            </Button>
                                        </Form.Group>}
                                    </Form>                                    
                                    </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Client Settings</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="company" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Client Name</Form.Label>
                                            <Form.Control
                                                readOnly={this.state.client !== undefined}
                                                size="sm"
                                                type="text"
                                                value={values.company}
                                                name="company"
                                                placeholder="Company"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.company}
                                                touched={touched.company}
                                                isInvalid={errors.company && touched.company}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.company}
                                            </Form.Control.Feedback>
                                        </Form.Group>    
                                        <Form.Group as={Col} xs={4} controlId="clientAbbreviation" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Client Abbreviation</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.clientAbbreviation}
                                                name="clientAbbreviation"
                                                placeholder="Client Abbreviation"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.clientAbbreviation}
                                                touched={touched.clientAbbreviation}
                                                isInvalid={errors.clientAbbreviation && touched.clientAbbreviation}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.clientAbbreviation}
                                            </Form.Control.Feedback>
                                        </Form.Group> 
                                        <Form.Group as={Col} xs={4} controlId="company" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">ABN</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.abn}
                                                name="abn"
                                                placeholder="ABN"
                                                onChange={handleChange}
                                            >
                                            </Form.Control>
                                        </Form.Group>                                   
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="rebateSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="rebateSwitch"
                                            label="Has Rebate"
                                            defaultChecked={values.rebateSwitch}
                                            value={values.rebateSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4} controlId="autoEmailPODSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="autoEmailPODSwitch"
                                            label="Auto Email POD"
                                            defaultChecked={values.autoEmailPODSwitch}
                                            value={values.autoEmailPODSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Collapse in={values.rebateSwitch === true}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={2} controlId="applyRebateIfAbove" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Apply if above</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.rebateSwitch === false ? (values.applyRebateIfAbove = 0 && values.applyRebateIfAbove) : values.applyRebateIfAbove }
                                                name="applyRebateIfAbove"
                                                placeholder="ex: 100"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.applyRebateIfAbove}
                                                touched={touched.applyRebateIfAbove}
                                                isInvalid={errors.applyRebateIfAbove && touched.applyRebateIfAbove}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.applyRebateIfAbove}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={2} controlId="rebate" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Rebate</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.rebateSwitch === false ? (values.rebate = 0 && values.rebate) : values.rebate}
                                                name="rebate"
                                                placeholder="Rebate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.rebate}
                                                touched={touched.rebate}
                                                isInvalid={errors.rebate && touched.rebate}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rebate}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    </Collapse>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="statusAPISwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="statusAPISwitch"
                                            label="Send Job Status through API"
                                            defaultChecked={values.statusAPISwitch}
                                            value={values.statusAPISwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>                                        
                                    </Form.Row>
                                    <Collapse in={values.statusAPISwitch === true}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="statusAPIUrl" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">API URL</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.statusAPIUrl}
                                                name="statusAPIUrl"
                                                placeholder=""
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.statusAPIUrl}
                                                touched={touched.statusAPIUrl}
                                                isInvalid={errors.statusAPIUrl && touched.statusAPIUrl}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.statusAPIUrl}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={2} controlId="statusAPIUsername" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">API Username</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.statusAPIUsername}
                                                name="statusAPIUsername"
                                                placeholder=""
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.statusAPIUsername}
                                                touched={touched.statusAPIUsername}
                                                isInvalid={errors.statusAPIUsername && touched.statusAPIUsername}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.statusAPIUsername}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={2} controlId="statusAPIPassword" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">API Password</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.statusAPIPassword}
                                                name="statusAPIPassword"
                                                placeholder=""
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.statusAPIPassword}
                                                touched={touched.statusAPIPassword}
                                                isInvalid={errors.statusAPIPassword && touched.statusAPIPassword}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.statusAPIPassword}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    </Collapse>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="sftpSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="sftpSwitch"
                                            label="Send Job Status through SFTP"
                                            defaultChecked={values.sftpSwitch}
                                            value={values.sftpSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>                                        
                                    </Form.Row>
                                    <Collapse in={values.sftpSwitch === true}>
                                        <Form.Row>
                                            <Form.Group as={Col} xs={4} controlId="sftpHost" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">SFTP HOST</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    value={values.sftpHost}
                                                    name="sftpHost"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.sftpHost}
                                                    touched={touched.sftpHost}
                                                    isInvalid={errors.sftpHost && touched.sftpHost}
                                                >
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.sftpHost}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs={1} controlId="sftpPort" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">SFTP Port</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    value={values.sftpPort}
                                                    name="sftpPort"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.sftpPort}
                                                    touched={touched.sftpPort}
                                                    isInvalid={errors.sftpPort && touched.sftpPort}
                                                >
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.sftpPort}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs={2} controlId="sftpUsername" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">SFTP Username</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    value={values.sftpUsername}
                                                    name="sftpUsername"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.sftpUsername}
                                                    touched={touched.sftpUsername}
                                                    isInvalid={errors.sftpUsername && touched.sftpUsername}
                                                >
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.sftpUsername}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs={2} controlId="sftpPassword" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">SFTP Password</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    value={values.sftpPassword}
                                                    name="sftpPassword"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.sftpPassword}
                                                    touched={touched.sftpPassword}
                                                    isInvalid={errors.sftpPassword && touched.sftpPassword}
                                                >
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.sftpPassword}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs={2} controlId="sftpPassword" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">Path</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    value={values.sftpPath}
                                                    name="sftpPath"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.sftpPath}
                                                    touched={touched.sftpPath}
                                                    isInvalid={errors.sftpPath && touched.sftpPath}
                                                >
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.sftpPath}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Collapse>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Contact Information</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="firstName" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">First Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.firstName}
                                                name="firstName"
                                                placeholder="First Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.firstName}
                                                touched={touched.firstName}
                                                isInvalid={errors.firstName && touched.firstName}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.firstName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="lastName" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Last Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.lastName}
                                                name="lastName"
                                                placeholder="Last Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.lastName}
                                                touched={touched.lastName}
                                                isInvalid={errors.lastName && touched.lastName}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.lastName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="email" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">eMail</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.email}
                                                name="email"
                                                placeholder="eMail"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.email}
                                                touched={touched.email}
                                                isInvalid={errors.email && touched.email}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        {/* <Form.Group as={Col} xs={4} controlId="company" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Company</Form.Label>
                                            <Form.Control
                                                readOnly={this.state.client !== undefined}
                                                size="sm"
                                                type="text"
                                                value={values.company}
                                                name="company"
                                                placeholder="Company"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.company}
                                                touched={touched.company}
                                                isInvalid={errors.company && touched.company}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.company}
                                            </Form.Control.Feedback>
                                        </Form.Group>                             */}
                                        <Form.Group as={Col} controlId="mobile" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Mobile #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.mobile}
                                                name="mobile"
                                                placeholder="Mobile #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mobile}
                                                touched={touched.mobile}
                                                isInvalid={errors.mobile && touched.mobile}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mobile}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="landline" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Landline #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.landline}
                                                name="landline"
                                                placeholder="Landline #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.landline}
                                                touched={touched.landline}
                                                isInvalid={errors.landline && touched.landline}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.landline}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="faxnumber" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Fax #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.faxnumber}
                                                name="faxnumber"
                                                placeholder="Fax #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.faxnumber}
                                                touched={touched.faxnumber}
                                                isInvalid={errors.faxnumber && touched.faxnumber}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.faxnumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Physical Address</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="physicalAddressCountry" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Country</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.physicalAddressCountry}
                                                name="physicalAddressCountry"
                                                placeholder="Country"
                                                onChange={handleChange}
                                                // onChange={(e) => {
                                                //     //console.log('onchange', e);
                                                //     if(values.mailingAddressSwitch === true)
                                                //         setFieldValue('mailingAddressCountry', e.target.value);
                                                //     handleChange(e);
                                                // }}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressCountry}
                                                touched={touched.physicalAddressCountry}
                                                isInvalid={errors.physicalAddressCountry && touched.physicalAddressCountry}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressCountry}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="physicalAddressStreet" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Street Address</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.physicalAddressStreet}
                                                name="physicalAddressStreet"
                                                placeholder="Street Address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressStreet}
                                                touched={touched.physicalAddressStreet}
                                                isInvalid={errors.physicalAddressStreet && touched.physicalAddressStreet}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressStreet}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="physicalAddressCity" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">City/Suburb</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.physicalAddressCity}
                                                name="physicalAddressCity"
                                                placeholder="City"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressCity}
                                                touched={touched.physicalAddressCity}
                                                isInvalid={errors.physicalAddressCity && touched.physicalAddressCity}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressCity}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="physicalAddressZip" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.physicalAddressZip}
                                                name="physicalAddressZip"
                                                placeholder="Postcode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressZip}
                                                touched={touched.physicalAddressZip}
                                                isInvalid={errors.physicalAddressZip && touched.physicalAddressZip}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressZip}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4} controlId="physicalAddressState" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                                            <Form.Control as="select"
                                                size="sm"
                                                value={values.physicalAddressState}
                                                name="physicalAddressState"
                                                placeholder="State"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.physicalAddressState}
                                                touched={touched.physicalAddressState}
                                                isInvalid={errors.physicalAddressState && touched.physicalAddressState}
                                            >
                                            <option value=''>Choose...</option>
                                            <option>Queensland</option>
                                            <option>New South Wales</option>
                                            <option>Australian Capital Territory</option>
                                            <option>Victoria</option>
                                            <option>South Australia</option>
                                            <option>Western Australia</option>
                                            <option>Tasmania</option>
                                            <option>Northern Territory</option>
                                            <option value='Christchurch'>Christchurch (New Zealand)</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.physicalAddressState}
                                            </Form.Control.Feedback>
                                        </Form.Group>                    
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label className="margin-bottom-zero"><h5 className="margin-bottom-zero">Mailing Address</h5></Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="mailingAddressSwitch" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="mailingAddressSwitch"
                                            label="Same as Physical Address"
                                            defaultChecked={values.mailingAddressSwitch}
                                            value={values.mailingAddressSwitch}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="mailingAddressCountry" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Country</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                readOnly={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressCountry : values.mailingAddressCountry = values.physicalAddressCountry && values.physicalAddressCountry}
                                                name="mailingAddressCountry"
                                                placeholder="Country"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressCountry}
                                                touched={touched.mailingAddressCountry}
                                                isInvalid={errors.mailingAddressCountry && touched.mailingAddressCountry}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressCountry}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="mailingAddressStreet" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Street Address</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                readOnly={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressStreet : values.mailingAddressStreet = values.physicalAddressStreet && values.physicalAddressStreet}
                                                name="mailingAddressStreet"
                                                placeholder="Street Address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressStreet}
                                                touched={touched.mailingAddressStreet}
                                                isInvalid={errors.mailingAddressStreet && touched.mailingAddressStreet}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressStreet}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="mailingAddressCity" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">City/Suburb</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                readOnly={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressCity : values.mailingAddressCity = values.physicalAddressCity && values.physicalAddressCity}
                                                name="mailingAddressCity"
                                                placeholder="City"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressCity}
                                                touched={touched.mailingAddressCity}
                                                isInvalid={errors.mailingAddressCity && touched.mailingAddressCity}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressCity}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="mailingAddressZip" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                readOnly={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressZip : values.mailingAddressZip = values.physicalAddressZip && values.physicalAddressZip}
                                                name="mailingAddressZip"
                                                placeholder="Postcode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressZip}
                                                touched={touched.mailingAddressZip}
                                                isInvalid={errors.mailingAddressZip && touched.mailingAddressZip}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressZip}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={4} controlId="mailingAddressState" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                                            <Form.Control as="select"
                                                size="sm"
                                                disabled={values.mailingAddressSwitch}
                                                value={values.mailingAddressSwitch !== true ? values.mailingAddressState : values.mailingAddressState = values.physicalAddressState && values.physicalAddressState}
                                                name="mailingAddressState"
                                                placeholder="State"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mailingAddressState}
                                                touched={touched.mailingAddressState}
                                                isInvalid={errors.mailingAddressState && touched.mailingAddressState}
                                            >
                                            <option value=''>Choose...</option>
                                            <option>Queensland</option>
                                            <option>New South Wales</option>
                                            <option>Australian Capital Territory</option>
                                            <option>Victoria</option>
                                            <option>South Australia</option>
                                            <option>Western Australia</option>
                                            <option>Tasmania</option>
                                            <option>Northern Territory</option>
                                            <option value='Christchurch'>Christchurch (New Zealand)</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mailingAddressState}
                                            </Form.Control.Feedback>
                                        </Form.Group>                    
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Notes</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={8} controlId="notes" className="margin-bottom-8">
                                            {/* <Form.Label className="med-font margin-bottom-zero">Notes</Form.Label> */}
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="Notes" 
                                                name="notes"
                                                value={values.notes}
                                                onChange={handleChange} 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label className="margin-bottom-zero"><h5 className="margin-bottom-zero">SMS Settings</h5></Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="sendSMS" className="margin-bottom-8">
                                            <Form.Check 
                                            className="med-font margin-bottom-zero"
                                            type="switch"
                                            id="sendSMS"
                                            label="Send SMS"
                                            defaultChecked={values.sendSMS}
                                            value={values.sendSMS}
                                            onChange={handleChange}                                        
                                            />                                        
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={8} controlId="smsMessageInVan" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">SMS for &quot;In van for delivery&quot; status:</Form.Label>
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="" 
                                                name="smsMessageInVan"
                                                value={values.smsMessageInVan}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.smsMessageInVan}
                                                touched={touched.smsMessageInVan}
                                                isInvalid={errors.smsMessageInVan && touched.smsMessageInVan}
                                            />
                                            <Form.Label className="small-font faded margin-bottom-zero">You can customize the message above but must include the &#123;consignment#&#125; placeholder, which will be replaced with the consignment number. The &#123;division&#125; placeholder is optional.</Form.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.smsMessageInVan}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={8} controlId="smsMessageOnMyWay" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-top-10 margin-bottom-zero">SMS for &quot;On my way there now&quot; status:</Form.Label>
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="" 
                                                name="smsMessageOnMyWay"
                                                value={values.smsMessageOnMyWay}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.smsMessageOnMyWay}
                                                touched={touched.smsMessageOnMyWay}
                                                isInvalid={errors.smsMessageOnMyWay && touched.smsMessageOnMyWay}
                                            />
                                            <Form.Label className="small-font faded margin-bottom-zero">You can customize the message above but must include the &#123;consignment#&#125; placeholder, which will be replaced with the consignment number. The &#123;division&#125; placeholder is optional.</Form.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.smsMessageOnMyWay}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {status && status.msg && 
                                    <Form.Row>
                                    <Form.Group>
                                        <Form.Label className="error-msg">{status.msg}</Form.Label>
                                    </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Form.Row>                                
                                        <span className="d-inline-block">
                                        <Button variant="primary" type="submit" disabled={isSubmitting} size="sm">
                                        {isSubmitting ? 'Loading…' : 'Save'}
                                        </Button>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="d-inline-block">
                                            <Button as={Link} to="/clients" variant="primary" size="sm">
                                            Cancel
                                            </Button>
                                        </span>
                                    </Form.Row>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Container>  
                  )}
                </Formik>
                 
            )
        }
        
    }
}