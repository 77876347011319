import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import { Navbar, Form, Button, Col} from 'react-bootstrap';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import ModalWrapper from './utility/ModalWrapper';
import LoadingOverlay from 'react-loading-overlay';
import MainMap from './utility/MainMap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';
import RemainingJobsButton from './utility/RemainingJobsButton';

export default class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            date: new Date(),
            mapDisplayed: false,
            routeDetails: [],
            jobId: undefined,
            status: undefined,
            statusId: '',
            drivers: [],
            clients: undefined,
            divisions: undefined,
            notificationGroups: undefined,
            notificationGroupId: '',
            jobs: [],
            selectedJobs: [],
            selectedJobsOptions: [],
            selectedDrivers:[],
            initialDriverId:'',
            processId:'',
            selectedClient: '',
            selectedDivision: '',
            modalShow: false
        }
        this.mainMap = React.createRef();
    }
    componentWillReceiveProps(newProps){
        //console.log('map container will receive props');
        this.fetch(this.props.match.params.jobId);
        //if(this.state.mapDisplayed === false && newProps.page.trim() !== '' && newProps.page.trim() === 'map')
            //this.setState({mapDisplayed: true});
    }
    setDate = value => {
        //this.setState({ date: value, isLoading:true, jobId: undefined, statusId:'', notificationGroupId:'' }, function () {this.fetchJobs();});
        this.setState({ date: value, isLoading:true, jobId: undefined }, function () {this.fetchJobsWithMoreFilters();});
    }
    refreshFilters = () => {
        this.setState({ isLoading:true, jobId: undefined, statusId:'', notificationGroupId:'', selectedClient: '', selectedDivision: ''  }, function () {this.fetchJobs();});
    }
    componentDidMount() {
        //this.fetch(this.props.jobId);
        this.fetch(this.props.match.params.jobId);
    }
    fetch(jobId){
        this.setState({ isLoading:true, statusId:'', notificationGroupId:'' }, async function () {
            try{
                const entityArray = ['status','accounts','clients','divisions','workgroups'];
                const myInit = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                };
                const entities = entityArray.map( async (entity, index) => {
                    try{
                        let res;
                        if(entity === 'accounts')
                            res = await API.get('api', '/accounts/?AccountType=Driver', myInit);
                        else
                            res = await API.get('api', '/' + entity, myInit);
                        console.log('entity',entity);
                        return (
                            {
                                entity: entity,
                                content: res
                            }
                        )
                    }catch(err) {
                        this.setState(() => { throw err; })
                    }
                });
                const resultEntities = await Promise.all(entities);
                // console.log('resultEntities',resultEntities);
                const status = resultEntities.filter(e => e.entity === 'status')[0].content.status;
                const drivers = resultEntities.filter(e => e.entity === 'accounts')[0].content.accounts;
                const clients = resultEntities.filter(e => e.entity === 'clients')[0].content.clients;
                const divisions = resultEntities.filter(e => e.entity === 'divisions')[0].content.divisions;
                const notificationGroups = resultEntities.filter(e => e.entity === 'workgroups')[0].content.workgroups;
                this.setState({ status:status, drivers:drivers, clients: clients, divisions: divisions, notificationGroups:notificationGroups}, function () {
                    if(jobId !== undefined){
                        console.log(jobId);
                        //this.setState({ isLoading:true }, function () {this.fetchJob(jobId);});
                        this.fetchJob(jobId);
                    }
                    else
                        this.setState({ date:new Date(), jobId:undefined}, function () {this.fetchJobs();});
                });
            }catch(err) {
                this.setState(() => { throw err; })
            }
        });
    }
    async fetchJob(jobId) {
        const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        };
        //console.log('init', myInit);
        const res = await API.get("api", "/jobs/" + jobId, myInit);
        if(res["result"] === "failure" && res["errors"] && res["errors"] === "user is disabled")
        {
            Auth.signOut();
            this.props.auth.setAuthStatus(false);
            this.props.auth.setUser(null);
            this.props.auth.setName(null);
            this.props.history.push("/login");
            console.log("signout");
        }else{
            console.log("job", res);
            this.setState({ jobId:jobId, initialDriverId:res.jobs[0].DriverId, date: new Date(res.jobs[0].DeliveryDate) }, function () {this.fetchJobs();});
        }
    }
    async fetchJobs() {
        const from = moment(this.state.date).format('YYYY-MM-DD');
        const to = moment(this.state.date).format('YYYY-MM-DD') + ' 23:59:59';
        //let driversString = '';            
        let filters;
        filters = '?Where=DeliveryDate~between~' + from + '|' + to;
        if(this.state.jobId !== undefined)
            filters = filters + '&DriverId=' + this.state.initialDriverId;
        console.log('initialDriverId', this.state.initialDriverId);
        const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        };
        const res = await API.get("api", "/jobs" + filters, myInit);
        if(res["result"] === "failure" && res["errors"] && res["errors"] === "user is disabled")
        {
            Auth.signOut();
            this.props.auth.setAuthStatus(false);
            this.props.auth.setUser(null);
            this.props.auth.setName(null);
            this.props.history.push("/login");
            console.log("signout");
        }else{
            console.log("filter", filters);
            const selectedDrivers = Array.from(new Set(res.jobs.filter(i => i.Latitude !== null).map(j => j.DriverId)));
            let i = 1;
            this.setState({ jobs: res.jobs.filter(i => i.Latitude !== null)});

            const markers = res.jobs.filter(i => i.Latitude !== null).map((j,index) => ({
                    id:j.ItemId,
                    consignmentNumber:j.ConsignmentNumber,
                    name:j.Name,
                    lat:j.Latitude,
                    long:j.Longitude,
                    division:j.Division,
                    client:this.state.clients.filter(c => c.ItemId === (this.state.divisions.filter(d => d.ItemId === j.ParentItemId)[0].ParentItemId))[0].Name,
                    showSequence: selectedDrivers.length === 1 && (this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 2 || this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 3) ? true : false,
                    sequence: selectedDrivers.length === 1 && (this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 2 || this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 3) ? i++ : 0,
                    //sequence:selectedDrivers.length === 1 && (this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 2 || this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 3) ? index + 1 : 0,
                    initials:this.state.drivers.filter(d => d.ItemId === j.DriverId)[0].CustomInitials ? this.state.drivers.filter(d => d.ItemId === j.DriverId)[0].CustomInitials : this.state.drivers.filter(d => d.ItemId === j.DriverId)[0].FirstName.charAt(0) + this.state.drivers.filter(d => d.ItemId === j.DriverId)[0].LastName.charAt(0),//j.DriverName.split(" ")[0].charAt(0) + j.DriverName.split(" ")[1].charAt(0),
                    driverId:j.DriverId,
                    driverName:j.DriverName,
                    address: this.addressFormatter(j),
                    showPopup:this.state.jobId !== undefined && this.state.jobId === j.ItemId ? true : false,
                    statusGroup:this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type,
                    status:j.Status
            }));
            // console.log('selectedDrivers', res.jobs.map(j => j.DriverId));
            // console.log('selectedDriversUnique', Array.from(new Set(res.jobs.map(j => j.DriverId))));
            this.setState({ routeDetails:markers, isLoading:false, selectedDrivers:selectedDrivers }, function(){this.mainMap.current.getRoutes(markers);});
        }
    }
    async fetchJobsWithMoreFilters() {
        const processId = (Math.random() * (9999999 - 1000000) + 1000000).toString().split('.')[0];
        this.setState({ routeDetails:[], isLoading:true, processId: processId }, async () => {
            const from = moment(this.state.date).format('YYYY-MM-DD');
            const to = moment(this.state.date).format('YYYY-MM-DD') + ' 23:59:59';
            let driversString = '';
            let filters;
            filters = '?Where=DeliveryDate~between~' + from + '|' + to;
            if(this.state.statusId !== '')
                filters = filters + '&StatusId=' + this.state.statusId;
            if(this.state.selectedClient !== '')
            {
                filters = filters + '&BillingClientId=' + this.state.selectedClient;
            }
            if(this.state.selectedDivision !== '')
            {
                filters = filters + '&ParentItemId=' + this.state.selectedDivision;
            }
            if(this.state.selectedDrivers.length > 0){
                this.state.selectedDrivers.forEach(d => {
                    driversString = driversString === '' ? driversString + d : driversString + '|' + d; //driversString + '&waypoint' + index + '=geo!' + w[0] + ',' + w[1]; 
                });
                console.log('driversString', '&Condition=DriverId~in~' + driversString);
                if(driversString !== '')
                    filters = filters + '&Condition=DriverId~in~' + driversString;
            
                const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                };
                const res = await API.get("api", "/jobs" + filters, myInit);
                console.log("jobs", res);
                const selectedDrivers = Array.from(new Set(res.jobs.filter(i => i.Latitude !== null).map(j => j.DriverId).concat(this.state.selectedDrivers)));
                let i = 1;
                this.setState({ jobs: res.jobs.filter(i => i.Latitude !== null)});
                // console.log("Selected: ", this.state.selectedJobs);
                const markers = res.jobs.filter(i => i.Latitude !== null).map((j, index) => ({
                    id:j.ItemId,
                    consignmentNumber:j.ConsignmentNumber,
                    name:j.Name,
                    lat:j.Latitude,
                    long:j.Longitude,
                    division:j.Division,
                    client:this.state.clients.filter(c => c.ItemId === (this.state.divisions.filter(d => d.ItemId === j.ParentItemId)[0].ParentItemId))[0].Name,
                    showSequence: selectedDrivers.length === 1 && (this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 2 || this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 3) ? true : false,
                    sequence: selectedDrivers.length === 1 && (this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 2 || this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 3) ? i++ : 0,
                    //sequence:selectedDrivers.length === 1 && (this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 2 || this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type === 3) ? index + 1 : 0,
                    initials:this.state.drivers.filter(d => d.ItemId === j.DriverId)[0].CustomInitials ? this.state.drivers.filter(d => d.ItemId === j.DriverId)[0].CustomInitials : this.state.drivers.filter(d => d.ItemId === j.DriverId)[0].FirstName.charAt(0) + this.state.drivers.filter(d => d.ItemId === j.DriverId)[0].LastName.charAt(0),//j.DriverName.split(" ")[0].charAt(0) + j.DriverName.split(" ")[1].charAt(0),
                    driverId:j.DriverId,
                    driverName:j.DriverName,
                    address: this.addressFormatter(j),
                    showPopup:false,
                    statusGroup:this.state.status.filter(s => s.ItemId === j.StatusId)[0].Type,
                    status:j.Status,
                    selected: this.state.selectedJobs.findIndex(s => s.ItemId == j.ItemId) > -1
                }));
                //const drivers = res.jobs.map(j => j.DriverId);
                // console.log('selectedDrivers', res.jobs.map(j => j.DriverId).concat(this.state.selectedDrivers));
                // console.log('selectedDriversUnique', Array.from(new Set(res.jobs.map(j => j.DriverId).concat(this.state.selectedDrivers))));
                if(processId === this.state.processId)
                    this.setState({ routeDetails:markers, selectedDrivers:selectedDrivers }, function(){this.mainMap.current.getRoutes(markers);});
                //console.log('append test', drivers);
            }
            else
                this.setState({ routeDetails:[], isLoading:false, selectedDrivers:[] }, function(){this.mainMap.current.getRoutes([]);});
        });
        
    }
    getStatus() {        
        return this.state.status.map((status) => {
            const { Label, ItemId } = status
            switch (Label.trim().toLowerCase()) {
                case 'new delivery':
                    return (
                        <option key={ItemId} value={ItemId} style={{backgroundColor:'#28a745'}}>{Label}</option>
                    )
                case 'in van for delivery':
                case 'on my way there now':
                    return (
                        <option key={ItemId} value={ItemId} style={{backgroundColor:'orange'}}>{Label}</option>
                    )
                case 'pod name and initials':
                    return (
                        <option key={ItemId} value={ItemId} style={{backgroundColor:'#aaa'}}>{Label}</option>
                    )
                default:
                    return (
                        <option key={ItemId} value={ItemId} >{Label}</option>
                    )
            } 
        })
    }
    getNotificationGroupsDropDown() {        
        return this.state.notificationGroups.map((n) => {
           const { Name, ItemId } = n
           return (
               <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }
    handleStatusChange = e => {
        this.setState({ statusId: e.target.value, isLoading: true }, function () {
            this.fetchJobsWithMoreFilters();});
    }
    handleClientChange = e => {
        this.setState({ selectedClient: e.target.value, selectedDivision: '', isLoading: true }, function () {
            this.fetchJobsWithMoreFilters();});
    }
    handleDivisionChange = e => {
        this.setState({ selectedDivision: e.target.value, isLoading: true }, function () {
            this.fetchJobsWithMoreFilters();});
    }
    handleNotificationGroupChange = e => {
        let selectedDrivers = this.state.selectedDrivers;
        if(e.target.value !== '')
        {
            const notificationGroup = this.state.notificationGroups.filter(n => n.ItemId === e.target.value)[0];
            selectedDrivers = notificationGroup.Members.filter(m => m.AccountType === 'Driver').map(r => r.AccountId);
        }
        this.setState({ notificationGroupId: e.target.value, selectedDrivers:selectedDrivers, isLoading: true }, function () {
            this.fetchJobsWithMoreFilters();});
    }
    getDriversArray() {
        return this.state.drivers.map((driver, index) => {
          const { FullName, ItemId } = driver
          return (
            { value: ItemId, label: FullName }
          )
        })
    }
    getJobsArray() {
        return this.state.jobs.map((job, index) => {
          const { ConsignmentNumber, ItemId } = job
          return (
            { value: ItemId, label: ConsignmentNumber }
          )
        })
    }

    setSelectedJobs = (option) => {
        this.setState({selectedJobsOptions:option ? option.map((item,index) => item.value) : []}, () => {
            let selectedjobs2 = this.state.jobs.filter((j) => this.state.selectedJobsOptions.indexOf(j.ItemId) > -1); 
            this.setState({selectedJobs: selectedjobs2}, () => {
                this.fetchJobsWithMoreFilters();
            }); 
        });                  
    }

    getClientOptions = () => {   
        if(this.state.clients !== undefined && this.state.clients.length > 0){
            return this.state.clients.map((c) => {
                const { Name, ItemId } = c
                return (
                    <option key={ItemId} value={ItemId} >{Name}</option>
                )
             });
        }     
    }
    getDivisionOptions = () => {
        const {clients, divisions, selectedClient, selectedDivision} = this.state;
        if(divisions !== undefined && divisions.length > 0 && clients != undefined && clients.length > 0){
            if(selectedClient !== '')
            {
                return divisions.filter(d => d.ParentItemId === selectedClient).map((c) => {
                    const { Name, ItemId } = c
                    return (
                        <option key={ItemId} value={ItemId} >{Name}</option>
                    )
                });
            }
        }   
    }
    setLoadingDisplay = param => {
        console.log('set loading', param);
        this.setState({ isLoading: param });
    }
    setModalShow = show => {
        this.setState({ modalShow: show });
    }
    updateDisplay = update => {
        if(update === true)
        {
            this.setState({ modalShow: false, selectedJobs: [], selectedJobsOptions: [] }, function () { 
                this.fetchJobsWithMoreFilters();
                console.log("updateDisplay")
            });            
        }
    }
    addressFormatter(job) {
        let address = '';
        if(job.Street1 && job.Street1 !== '')
            address = job.Street1;
        if(job.Street2 && job.Street2 !== '' && (job.Street1 && job.Street1.trim().toLowerCase() !== job.Street2.trim().toLowerCase()))
            address = (address + ' ' + job.Street2).trim();
        if(job.Suburb && job.Suburb !== '')
            address = (address + ' ' + job.Suburb).trim();
        if(job.Zip && job.Zip !== '')
            address = (address + ', ' + job.Zip).trim();
        if(address.startsWith(','))
            address = address.substring(1).trim();
        return address;
    }
    render() {
        return (
            <React.Fragment>
                <Navbar className="bg-light padding-top-bottom-zero">
                    <Navbar.Brand href="/">
                        <h4 className="padding-top-bottom-zero">Map</h4>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                            <Form.Label className="mr-sm-2">Date</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" popperPlacement="left-start" selected={Date.parse(this.state.date)} onChange={value => this.setDate(value)} className='form-control form-control-sm width-100' placeholderText='Select date'/>
                            {/* <Button className="mr-sm-2" variant="success" size="sm" disabled={this.state.divisionId === undefined} onClick={()=> this.props.navProp.changeClientPage('addLocation', this.state.divisionId)}>
                            <i className="fas fa-plus" style={{ fontSize: '1em' }} /> Add Delivery Location
                            </Button>                             */}
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <Navbar className="bg-light top-border-1">
                    <Form inline>
                        <Form.Label className="med-font margin-bottom-zero mr-sm-2">Notification Group</Form.Label>                            
                        <Form.Control as="select"
                            disabled={this.props.userType.toLowerCase() === 'officeadministrator' || this.props.userType.toLowerCase() === 'officeuser'}
                            size="sm"
                            className="mr-sm-3 width-200"
                            value={this.state.notificationGroupId}
                            onChange={this.handleNotificationGroupChange}
                        >
                            <option key='1' value='' >Select</option>
                            {this.state.notificationGroups !== undefined && this.state.notificationGroups.length > 0 && this.getNotificationGroupsDropDown()}
                        </Form.Control>
                        <Form.Label className="med-font margin-bottom-zero mr-sm-2">Status</Form.Label>                            
                        <Form.Control as="select"
                            size="sm"
                            className="mr-sm-3 width-200"
                            value={this.state.statusId}
                            onChange={this.handleStatusChange}
                        >
                            <option key='1' value='' >All</option>
                            {this.state.status !== undefined && this.state.status.length > 0 && this.getStatus()}
                        </Form.Control>

                        {this.props.userType.toLowerCase() !== 'officeadministrator' && this.props.userType.toLowerCase() !== 'officeuser' &&
                            <React.Fragment>
                                <Form.Label className="mr-sm-2">Client</Form.Label>                            
                                <Form.Control as="select"
                                    size="sm"
                                    className="mr-sm-3 width-200"
                                    onChange={this.handleClientChange}
                                    value={this.state.selectedClient}
                                >
                                    <option value="">{(this.state.clients !== undefined && this.state.clients.length === 0) ? 'Loading...' : 'All'}</option>
                                    {this.getClientOptions()}
                                </Form.Control>

                                <Form.Label className="mr-sm-2">Division</Form.Label>                            
                                <Form.Control as="select"
                                    size="sm"
                                    className="mr-sm-3 width-200"
                                    disabled={this.state.selectedClient === ''}
                                    onChange={this.handleDivisionChange}
                                    value={this.state.selectedDivision}
                                >
                                    <option value="">{(this.state.clients === undefined || this.state.clients.length === 0) ? 'Loading...' : (this.state.selectedClient !== '' ? 'All' : 'select client first')}</option>
                                    {this.getDivisionOptions()}
                                </Form.Control>
                            </React.Fragment>
                        }
                        {/* <Form.Label className="med-font margin-bottom-zero mr-sm-2">Drivers</Form.Label>
                        {this.state.drivers !== undefined && this.state.drivers.length > 0 && 
                        <div style={{width:this.state.selectedDrivers.length === 0 ? '200px' : undefined}}>
                            <Select 
                            isDisabled={this.props.userType.toLowerCase() === 'officeadministrator' || this.props.userType.toLowerCase() === 'officeuser'}
                            className="basic-multi-select form-text-align" 
                            classNamePrefix="select" 
                            isMulti 
                            options={this.getDriversArray()} 
                            name="driversSelect"                                                        
                            value={this.getDriversArray().filter(option => this.state.selectedDrivers.indexOf(option.value) >= 0)}
                            onChange={(option) => {
                                console.log('onChange', option);
                                this.setState({selectedDrivers:option ? option.map((item,index) => item.value) : []}, function () {
                                    this.fetchJobsWithMoreFilters();});
                                //setFieldValue('companyArray', option ? option.map((item,index) => item.value) : []);
                            }}
                            // error={errors.companyArray}
                            // touched={touched.companyArray}
                        />
                        </div>
                          }  */}
                    </Form>
                    <Navbar.Collapse className="justify-content-end">
                        <RemainingJobsButton fromDate={this.state.date} toDate={this.state.date} disabled={this.props.userType.toLowerCase() === 'officeadministrator' || this.props.userType.toLowerCase() === 'officeuser' || this.props.userType.toLowerCase() === 'driver'}/>
                        <span className="mr-sm-2"></span>
                        <Button variant="success" size="sm" onClick={() => this.refreshFilters()}><i className="fas fa-sync" style={{ fontSize: '1em' }} /> Refresh</Button>
                        {/* <Form inline>
                        <Navbar.Text>
                            <FormControl type="text" placeholder="Search by Delivery Location" size="sm" className="mr-sm-2 width-300" onChange={this.searchKeywordOnChange} onKeyPress={this.handleEnterKeySearch}/>
                            <span className='clickable' onClick={()=> this.searchClick()}>
                                <i className="fas fa-search" style={{ fontSize: '1em' }} />
                            </span>
                        </Navbar.Text>
                        </Form> */}
                    </Navbar.Collapse>                      
                </Navbar>
                <Navbar className="bg-light top-border-1 form-text-align">
                    <Form as={Col} xs={12} className="no-left-right-margin no-left-right-padding">   
                        <Form.Row>
                        {this.state.drivers !== undefined && 
                            <React.Fragment>
                            <Form.Group as={Col} xs={1}>
                                <Form.Label className="med-font margin-bottom-zero mr-sm-2">Drivers</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={11}>
                                <Select 
                                isDisabled={this.props.userType.toLowerCase() === 'officeadministrator' || this.props.userType.toLowerCase() === 'officeuser'}
                                className="basic-multi-select form-text-align" 
                                classNamePrefix="select" 
                                isMulti 
                                options={this.getDriversArray()} 
                                name="driversSelect"                                                        
                                value={this.getDriversArray().filter(option => this.state.selectedDrivers.indexOf(option.value) >= 0)}
                                onChange={(option) => {
                                    console.log('onChange', option);
                                    this.setState({selectedDrivers:option ? option.map((item,index) => item.value) : []}, function () {
                                        this.fetchJobsWithMoreFilters();});
                                }}
                                />
                            </Form.Group>
                            </React.Fragment>
                        }
                        </Form.Row> 
                        <Form.Row>
                        {this.state.drivers !== undefined && 
                            <React.Fragment>
                            <Form.Group as={Col} xs={1} className="">
                                <Form.Label className="med-font margin-bottom-zero mr-sm-2">Jobs</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={10} className="">
                                {
                                    this.state.jobs.length > 0 && <Select 
                                    isDisabled={this.props.userType.toLowerCase() === 'officeadministrator' || this.props.userType.toLowerCase() === 'officeuser'}
                                    className="basic-multi-select form-text-align" 
                                    classNamePrefix="select" 
                                    isMulti 
                                    options={this.getJobsArray()} 
                                    name="jobsSelect"                                                        
                                    value={this.getJobsArray().filter(option => this.state.selectedJobsOptions.indexOf(option.value) >= 0)}
                                    onChange={(option) => {
                                        console.log('onChange', option);
                                        this.setSelectedJobs(option);
                                    }}
                                    />
                                }
                                
                            </Form.Group>
                            <Form.Group as={Col} xs={1} className="">
                            <Button className="mr-sm-1" variant="success" size="sm" type="button" onClick={()=> this.setModalShow(true)}>
                                    <i className="fas fa-user-friends" style={{ fontSize: '1em' }} /> Reassign
                                </Button>
                            </Form.Group>
                            </React.Fragment>
                        }
                        </Form.Row>                    
                    </Form>               
                </Navbar>
                <Form inline>
                <ModalWrapper
                    show={this.state.modalShow}
                    headercolor="transparent"
                    onHide={() => this.setModalShow(false)}
                    modaltitle="Reassign Job"
                    type="reassign"
                    jobs={this.state.selectedJobs}
                    staffs={this.state.drivers.filter(s => s.Status === "active")}
                    refresh={this.updateDisplay}
                />
                </Form>
                <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text='Loading map...'
                >
                    <div style={{ width: '100%', height: '900px' }}>
                        {/* {this.props.page.trim() === 'map' &&
                        <MainMap details={this.state.routeDetails} setLoadingDisplay={this.setLoadingDisplay} navProp={this.props.navProp}/>} */}
                        {/* <MainMap ref={this.mainMap} details={this.state.routeDetails} setLoadingDisplay={this.setLoadingDisplay} navProp={this.props.navProp}/> */}
                        <MainMap ref={this.mainMap} setLoadingDisplay={this.setLoadingDisplay} navProp={this.props.navProp} selectedJobs={this.state.selectedJobs} setSelectedJobs={this.setSelectedJobs} />
                    </div>
                </LoadingOverlay>
                <ModalWrapper
                    show={this.state.modalNoteShow}
                    size="sm"
                    headercolor="transparent"
                    onHide={() => this.setModalNoteShow(false)}
                    modaltitle="Note"
                    type="note"
                    note={this.state.note}
                />
            </React.Fragment>
        )                
    }
}
