import React, { Component } from 'react';
import {Form, Button, Container, Col, Collapse} from 'react-bootstrap';
import Map from './utility/Map';
import { Formik } from 'formik';
import * as yup from 'yup';

export default class AddLocation extends Component {
  addLocation = (values, actions) => {
    let location = values.street1.trim();
    if(values.street2.trim().length > 0)
      location = location + ' ' + values.street2.trim();
    if(values.city.trim().length > 0)
      location = location + ' ' + values.city.trim();
    if(values.state.trim().length > 0)
      location = location + ' ' + values.state.trim();
    if(values.zip.trim().length > 0)
      location = location + ', ' + values.zip.trim();
    this.props.update({id:(Math.random() * (1000000000 - 1)).toString(),locationName:values.location,address:location,street1:values.street1.trim(),street2:values.street2.trim(),city:values.city.trim(),state:values.state.trim(),zip:values.zip.trim()});
    //this.props.onHide();
    console.log('addLocation');
  }

    render() { 
        const schema = yup.object().shape({
          location: yup.string()
              .required(),
          street1: yup.string()
              .required(),
        });
          return (
            <Formik
              validationSchema={schema}
              onSubmit={(values, actions) => {
                this.addLocation(values, actions);
              }}
              initialValues={{ 
                location: '',               
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isSubmitting,
                status,
              }) => (
              <React.Fragment>
              <Container className="no-left-right-margin">                
                <Form id="addLocation" onSubmit={handleSubmit}>                                                                              
                    <Form.Row>
                        <Form.Group as={Col} xs={3} controlId="address">
                            <Form.Group className="margin-bottom-zero">
                              <Form.Label><h5>Location Name</h5></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} controlId="location">
                                {/* <Form.Label>Street</Form.Label> */}
                                <Form.Control 
                                    type="text"
                                    placeholder="Location Name"
                                    name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.location && touched.location}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.location}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="margin-bottom-zero">
                              <Form.Label><h5>Address</h5></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} controlId="street1">
                                {/* <Form.Label>Street</Form.Label> */}
                                <Form.Control 
                                    type="text"
                                    placeholder="Street 1"
                                    name="street1"
                                    value={values.street1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.street1 && touched.street1}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.street1}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} controlId="street2">
                                {/* <Form.Label>Street</Form.Label> */}
                                <Form.Control 
                                    type="text"
                                    placeholder="Street 2"
                                    name="street2"
                                    value={values.street2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.street2 && touched.street2}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.street2}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} controlId="city">
                                {/* <Form.Label>City</Form.Label> */}
                                <Form.Control 
                                type="text"
                                placeholder="City"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.city && touched.city}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.city}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={6} controlId="state">
                                {/* <Form.Label>State</Form.Label> */}
                                <Form.Control 
                                type="text"
                                placeholder="State"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.state && touched.state}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.state}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={6} controlId="zip">
                                {/* <Form.Label>Zip</Form.Label> */}
                                <Form.Control 
                                type="text"
                                placeholder="Zip"
                                name="zip"
                                value={values.zip}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.zip && touched.zip}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.zip}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Group>
                        <Form.Group as={Col} xs={9}>
                          <Map address={values.street1 + ' ' + values.city + ' ' + values.state + ' ' + values.zip}/>
                        </Form.Group>                       
                    </Form.Row>                
                    
                    <Form.Row>
                      <br/><br/>
                      <span className="d-inline-block">
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Loading…' : 'Add'}
                        </Button>
                      </span>
                    &nbsp;&nbsp;
                      <span className="d-inline-block">
                        <Button variant="primary" disabled={isSubmitting} onClick={this.props.onHide}>Cancel</Button>
                      </span>
                    </Form.Row>                    
                </Form>
              </Container>
              </React.Fragment>
              )}
              </Formik>
          );
    }
  }