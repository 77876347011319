import React from 'react';

const DEFAULT_HEIGHT = 25;

export default class AutoResizeTextArea extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        height: DEFAULT_HEIGHT,
        value: props.value ? props.value : '',
        };

        this.setValue = this.setValue.bind(this);
        this.setFilledTextareaHeight = this.setFilledTextareaHeight.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        this.setFilledTextareaHeight();
    }

    setFilledTextareaHeight() {
        if (this.mounted) {
            const element = this.ghost;

            this.setState({
                height: element.clientHeight,
            });
        }
    }

    setValue(event) {
        const { value }= event.target;

        this.setState({ value });
        this.props.onChange(this.props.valueType, value);
    }

    getExpandableField() {
        const isOneLine = this.state.height <= DEFAULT_HEIGHT;
        const { height, value } = this.state;

        return (
        <div>
            <textarea
            className={this.props.class ? this.props.class : "textarea"}
            name="textarea"
            id="textarea"
            placeholder={this.props.placeholder}
            defaultValue={value}
            style={{
                height,
                resize: isOneLine ? "none" : null
            }}
            onChange={this.setValue}
            onKeyUp={this.setFilledTextareaHeight}
            />
        </div>
        );
    }

    getGhostField() {
        return (
        <div
            className={(this.props.class ? this.props.class : "textarea") + " textarea--ghost"}
            ref={(c) => this.ghost = c}
            aria-hidden="true"
        >
            {this.state.value}
        </div>
        );
    }

    render() {
        return (
        <div>
            {this.getExpandableField()}
            {this.getGhostField()}
        </div>
        );
    }
}

