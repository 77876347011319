import axios from 'axios';

// Create an instance of axios
const api = axios.create({
  baseURL: 'https://geocode.search.hereapi.com/v1/geocode?q=', // Replace with your API base URL
  timeout: 90000,
});

// Retry configuration
const retryConfig = {
  retry: 5, // Number of retry attempts
  retryDelay: (retryCount) => {
    return retryCount * 1000; // Exponential backoff: 1s, 2s, 3s, ...
  },
};

// Add a response interceptor
api.interceptors.response.use(
  response => { 
     return response;
  },
  async error => {
    const { config, response } = error;
    if (response.status === 429) {
      console.log('response im error geoSearch -' + JSON.stringify(response));
      config.__retryCount = config.__retryCount || 0;

      if (config.__retryCount < retryConfig.retry) {
        config.__retryCount += 1;
        const delay = retryConfig.retryDelay(config.__retryCount);
        console.log('delaying.. '+ delay);
        await new Promise(resolve => setTimeout(resolve, delay));
        return api(config);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
